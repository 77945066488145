import {Injectable} from '@angular/core';
import {AbstractService} from '../../services/abstract-service.service';
import {PointOfSaleModel} from '../ek-models/point-of-sale.model';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ADMIN_CONFIG} from '../../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {OrderSplit} from '../ek-models/orderSplit';
import {OrderSplitAdmin} from '../ek-models/OrderSplitAdmin';
import {Response} from '../../_base/crud/models/response';
import {FormGroup} from '@angular/forms';
import {CreditModel} from '../ek-models/credit.model';
import {ConfigurationModel} from '../../e-commerce/_models/configuration.model';
import {DatePipe} from "@angular/common";
import {MonthlyPaymentPipe} from "../../_base/layout";
import {OrderSplitBankTO} from "../ek-models/orderSplitBank.model";
import {OrderItemModel} from "../ek-models/orderItem.model";

@Injectable({
    providedIn: 'root',
})
export class OrderSplitService extends AbstractService<OrderSplit> {
    url: string;

    loadingSubject = new BehaviorSubject<boolean>(false);
    readonly loading$ = this.loadingSubject.asObservable();

    createdNewOrder$ = new BehaviorSubject<OrderSplitAdmin>(null);
    readonly newCreatedOrder$ = this.createdNewOrder$.asObservable();

    simulationInfoSubject$ = new BehaviorSubject<FormGroup>(null);
    readonly simulationInfo$ = this.simulationInfoSubject$.asObservable();

    selectedRelaySubject$ = new BehaviorSubject<PointOfSaleModel>(null);
    readonly selectedRelay$ = this.selectedRelaySubject$.asObservable();

    orderSplitState$ = new BehaviorSubject<boolean>(false);
    readonly orderSplitState_$ = this.orderSplitState$.asObservable();

    creditSubject = new BehaviorSubject<CreditModel>(null);
    readonly credit$ = this.creditSubject.asObservable();

    selectedOrderSubject = new BehaviorSubject<OrderSplit>(null);
    readonly selectedOrder$ = this.selectedOrderSubject.asObservable();

    minCart = 100000;
    maxCart = 2000000;

    _total: number;
    get total(): number {
        return this._total;
    }

    set total(total: number) {
        this._total = total;
    }

    constructor(protected http: HttpClient, private datePipe: DatePipe, private monthlyPaymentPipe: MonthlyPaymentPipe) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}orderSplit`;
    }

    isDateValid(dateString: string): boolean {
        const pattern = /^\d{2}-\d{2}-\d{4}$/;
        return pattern.test(dateString);
    }

    public createOrderSplit(entity: any) {

        //convert date of birth to dd-MM-yyyy format
        entity.dateOfBirthSimulation = this.isDateValid(entity.dateOfBirthSimulation) ? entity.dateOfBirthSimulation : this.datePipe.transform(entity.dateOfBirthSimulation, 'dd-MM-yyyy');

        this.loadingSubject.next(true);

        const url = `${this.url}/create`;

        return this.http.post<any>(url, entity).pipe(
            map((res) => {
                this.loadingSubject.next(false);
                return res.body;
            })
        );
    }

    public updateOrderSplitByAdmin(
        clientId: number,
        orderSplitId: number,
        orderSplitUpdateAdminTO: any
    ): Observable<any> {
        const url = `${this.url}/${orderSplitId}/${clientId}`;
        return this.http
            .put<Response<any>>(url, orderSplitUpdateAdminTO)
            .pipe(map(({body}) => body));
    }

    public updateOrderSplitItemQuantity(orderItemId: number, quantity: number, idFolder: number) {
        this.loadingSubject.next(true)
        const url = `${this.url}/updateQuantity/${orderItemId}/${quantity}/${idFolder}`;
        return this.http.put<Response<any>>(url, null).pipe(map(res => {
            this.loadingSubject.next(false);
            return res;
        }));
    }

    public downloadProformaPdf(id: number): Observable<any> {
        const url = `${this.url}/factureProforma`;
        let params = new HttpParams()
            .set('orderId', id.toString())
        return this.http.get(url, {params});
    }

    public downloadSimulationPdf(
        idClient: number,
        pointOfSaleId: number,
        monthlyPayment: number,
        phoneNumber: string,
        numberOfMonths: number,
        hamicheElDjidia: number,
        total: number,
        salePriceCPAWithTax: number,
        fundingAmount: number,
        folderRequestNumber: string,
        insurancePrime: number
    ): Observable<Response<any>> {
        const url = `${this.url}/simulation`;

        var entity = {
            hamicheElDjidia: hamicheElDjidia,
            idClient: idClient,
            monthlyPayment: monthlyPayment,
            numberOfMonths: numberOfMonths,
            total: total,
            phoneNumber: phoneNumber,
            pointOfSaleId: pointOfSaleId,
            fundingAmount: fundingAmount,
            salePriceCPAWithTax: salePriceCPAWithTax,
            folderRequestNumber: folderRequestNumber,
            takafulInsurance: insurancePrime
        };

        return this.http.post<Response<any>>(url, entity);
    }

    public downloadFacturePdf(id: number): Observable<any> {
        const url = `${this.url}/facture`;
        let params = new HttpParams().set("orderId", id.toString());
        return this.http.get(url, {params});
    }

    public downloadReceptBillPdf(id: number): Observable<any> {
        const url = `${this.url}/receiptBill`;
        let params = new HttpParams().set("orderId", id.toString());
        return this.http.get(url, {params});
    }

    public downloadDeliveryBillPdf(id: number): Observable<any> {
        const url = `${this.url}/deliveryBill`;
        let params = new HttpParams().set("orderId", id.toString());
        return this.http.get(url, {params});
    }

    public getOrderSplitById(idOrderSplit: number): Observable<OrderSplit> {
        console.log('call api for order split ...');
        const url = `${this.url}/${idOrderSplit}`;
        this.http.get<Response<OrderSplit>>(url).subscribe((res) => {
            //this.orderSplit.next(res.body);
            this.selectedOrderSubject.next(res.body);
        });
        return this.http
            .get<Response<OrderSplit>>(url)
            .pipe(map(({body}) => body));
    }

    public addItemSplitToOrder(
        orderId: number,
        configuration: ConfigurationModel,
        quantity: number,
        folderId: number,
        stockDepotId: number,
        stockEkId: number,
        sellingPrice: number
    ) {
        this.loadingSubject.next(true);
        const url = `${this.url}/addItem/${folderId}`;
        const obj = {
            configurationTO: configuration,
            orderSplitId: orderId,
            quantity: quantity,
            stockDepotId: stockDepotId ? stockDepotId : 0,
            stockEkId: stockEkId ? stockEkId : 0
        };
        return this.http.post<Response<OrderItemModel>>(url, obj).pipe(
            map((res) => {
                this.loadingSubject.next(false);
                return res;
            })
        );
    }

    public deleteItemSplit(idItem: number, folderId: number): Observable<Response<any>> {
        const url = `${this.url}/deleteItem/${idItem}/${folderId}`;
        return this.http.delete<Response<void>>(url);
    }

    public getCredit(orderSplitId: number): Observable<CreditModel> {
        console.log('API CALL DETAIL CREDIT')
        const url = `${this.url}/detailCredit/${orderSplitId}`;
        return this.http
            .get<Response<CreditModel>>(url)
            .pipe(map(({body}) => body));
    }

    public changeStateOrderSplit(idOrder: number, event: string) {
        const url = `${this.url}/changestate/${idOrder}/${event}`;
        return this.http.get<Response<string>>(url).pipe(map(({body}) => body));
    }

    public cancelOrderSplit(idOrder: number, cancelObj: any) {
        const url = `${this.url}/cancelOrderSplit/${idOrder}`;
        return this.http
            .patch<Response<string>>(url, cancelObj)
            .pipe(map(({body}) => body));
    }

    saveInvoice(data: any, filename: string): void {
        const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        let blob = new Blob([byteArray], {type: "application / pdf"});
        window.URL.createObjectURL(blob);
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.download = `${filename}.pdf`;
        anchor.href = url;
        anchor.click();
    }

    public downloadInvoices(orderId: number): void {
        const headers = new HttpHeaders({"Content-Type": "application/json"});
        const url = `${this.url}/generateInvoice/${orderId}`;
        this.http
            .get<Response<any[]>>(url)
            .subscribe((response: Response<any[]>) => {
                if (response.body) {
                    const invoices = response.body;

                    this.saveInvoice(invoices[0], "Facture.pdf");
                    this.saveInvoice(invoices[1], "Bon de livraison.pdf");
                    this.saveInvoice(invoices[2], "Bon de réception.pdf");
                } else {
                    // Handle error condition, such as displaying an error message to the user
                    console.error("Failed to retrieve invoice data.");
                }
            });
    }

    public archiveFolder(folderId: number, archived: boolean): Observable<Response<any>> {
        const url = `${this.url}/archived/${folderId}`;
        const params = new HttpParams().set('archive', String(archived));
        return this.http.patch<Response<any>>(url, ' ', {params});
    }


    checkCartLimit() {

        const cartCapacity = JSON.parse(localStorage.getItem("cartCapacity"));

        const NUMBER_OF_TERMS_IN_A_YEAR = 12
        const ANNUAL_RATE = 0.08

        let monthlyRate = ANNUAL_RATE / NUMBER_OF_TERMS_IN_A_YEAR
        // let refundAmount = monthlyPayement * this.duration;
        let hamicheElDjidia = 0
        // let hamicheElDjidia = this.total*0.1
        //Total des achats
        let total = this.total;
        let fundingAmount = total - hamicheElDjidia;
        let monthlyPayement = (fundingAmount * monthlyRate / (1 - Math.pow(1 + monthlyRate, -this.selectedOrderSubject.value.numberOfMonths))) * 1.19;
        let salePriceCPAWithTax = this.selectedOrderSubject.value.numberOfMonths * monthlyPayement;

        // let monthlyPayement = this.monthlyPaymentPipe.transform(this.total, this.selectedOrderSubject.value.numberOfMonths);
        // let salePriceCPAWithTax = this.selectedOrderSubject.value.numberOfMonths * monthlyPayement;


        let cartMessage = {
            isLimited: false,
            messages: "",
        };

        if (salePriceCPAWithTax < this.minCart && salePriceCPAWithTax < cartCapacity) {
            cartMessage.isLimited = true;
            cartMessage.messages = errorCode.LTF;
            return cartMessage;
        }

        if (salePriceCPAWithTax > this.maxCart && salePriceCPAWithTax < cartCapacity) {
            cartMessage.isLimited = true;
            cartMessage.messages = errorCode.HTF;
            return cartMessage;
        }

        if (salePriceCPAWithTax > this.maxCart || salePriceCPAWithTax > cartCapacity) {
            cartMessage.isLimited = true;
            cartMessage.messages = errorCode.HTC;
            return cartMessage;
        }


        if (
            this.monthlyPaymentPipe.transform(this.total, this.selectedOrderSubject.value.numberOfMonths) >
            +this.selectedOrderSubject.value.salarySimulation * 0.3
        ) {
            cartMessage.isLimited = true;
            cartMessage.messages = errorCode.HTM;
            return cartMessage;
        }

        return cartMessage;
    }

    public updateCalculation(
        orderSplitId: number,
        orderSplitBankTO: OrderSplitBankTO
    ): Observable<any> {
        const url = `${this.url}/updateCalculation/${orderSplitId}`;
        return this.http
            .patch<Response<any>>(url, orderSplitBankTO)
            .pipe(map(({body}) => body));
    }

    public updateDiscount(orderProductSplitId: number, discountPercentage: number, price: number, ) {
        const url = `${this.url}/updateDiscount/${orderProductSplitId}`;

        const params = new HttpParams().set('discountPercentage', discountPercentage.toString()).set('price', price.toString());

        return this.http.put<Response<any>>(url, null, {params}).pipe(map(res => {
            this.loadingSubject.next(false);
            return res;
        }));
    }
}


enum errorCode {
    HTF = "Vous avez dépasser la limite max d'achat autorisé",

    LTF = "Montant d'achat inferieur à limite minimum autorisé",

    HTC = "Montant de commande supérieur à la capacité d'achat",

    LTC = "LowerThanCapacity",

    HTM = "Mensualité est supérieur à 30% du salaire",
}
