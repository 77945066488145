import {BaseDataSource, QueryResultsModel} from "../../_base/crud";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import {
    selectOrdersInStore,
    selectOrdersPageLoading,
    selectOrdersShowInitWaitingMessage
} from "../_selectors/order.selector"; 


export class OrdersDatasource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectOrdersPageLoading),
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectOrdersShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectOrdersInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }

}
