import {createAction, props} from "@ngrx/store";
import {Token} from "../_models/token";
import {User} from "..";

export const login = createAction('[App Component] Login Requested ', props<{email:string, password: string}>());
export const loginSucceeded = createAction('[Auth API] Login Succeeded ', props<{token: Token, email: string}>());
export const loginFailed = createAction('[Auth API] Login Failed ', props<{error: any}>());
export const logout = createAction('[App Component] Logout Requested ');
export const appComponentInitialized = createAction('[App Component] Initialized');
export const requestUser = createAction('[App component] User requested');
export const getLoggedInUser = createAction('[App Component] getLoggedInUser',props<{email:string}>());
export const getLoggedInUserSucceed = createAction('[User API] getLoggedIn User Secceed',props<{user:User}>());
export const getLoggedInUserFailed = createAction('[User API] getLoggedIn User Failed',props<{error:any}>());


