import {BaseDataSource, QueryResultsModel} from "../../_base/crud";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import {
    selectSmsDetailsInStore,
    selectSmsDetailsPageLoading,
    selectSmsDetailsShowInitWaitingMessage
} from "../ek-selectors/sms-history-details.selector";


export class SmsHistoryDetailsDatasource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();
        this.loading$ = this.store.pipe(
            select(selectSmsDetailsPageLoading),
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectSmsDetailsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectSmsDetailsInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }
}
