import {createFeatureSelector, createSelector} from '@ngrx/store';
import {each} from 'lodash';
import {HttpExtenstionsModel, QueryResultsModel} from '../../_base/crud';
import {CustomerDiscountState} from '../_reducers/CustomerDiscount.reducers';
import {Discount_CustomerModel} from '../_models/discount_Customer.model';


export const selectDiscountCustomerState = createFeatureSelector<CustomerDiscountState>('discountCustomers');

export const selectDiscontCustomerById = (discountCustomerId: number) => createSelector(
    selectDiscountCustomerState,
    discountCustomerState => discountCustomerState.entities[discountCustomerId]
);

export const selectDiscontCustomerPageLoading = createSelector(
    selectDiscountCustomerState,
    discountCustomerState => discountCustomerState.listLoading
);

export const selectError = createSelector(
    selectDiscountCustomerState,
    discountCustomerState => discountCustomerState.error
);

export const selectlastAction = createSelector(
    selectDiscountCustomerState,
    discountCustomerState => discountCustomerState.lastAction
);

export const selectDiscontCustomerActionLoading = createSelector(
    selectDiscountCustomerState,
    discountCustomerState => discountCustomerState.actionsLoading
);

export const selectLastCreatedDiscontCustomerId = createSelector(
    selectDiscountCustomerState,
    discountCustomerState => discountCustomerState.lastCreatedDiscountId
);

export const selectDiscontCustomerShowInitWaitingMessage = createSelector(
    selectDiscountCustomerState,
    discountCustomerState => discountCustomerState.showInitWaitingMessage
);

export const selectDiscontCustomerInStore = createSelector(
    selectDiscountCustomerState,
    discountCustomerState => {
        const items: Discount_CustomerModel[] = [];
        each(discountCustomerState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Discount_CustomerModel[] = httpExtension.sortArray(items, discountCustomerState.lastQuery.sortField, discountCustomerState.lastQuery.sortOrder);
        return new QueryResultsModel(result, discountCustomerState.totalCount, '');
    }
);
