import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as smsHistoryActions from "../ek-actions/sms-history.actions";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {SmsHistoryService} from "../ek-services/smsHistory.service";

@Injectable()
export class SmsHistoryEffects {

    constructor(private actions$: Actions,
                private smsHistoryService:SmsHistoryService) {}

    smsHistoryPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(smsHistoryActions.SmsHistoryPageRequested),
            switchMap((action) =>
                this.smsHistoryService.getAllHistorySms(action.page.pageNumber,action.page.pageSize, action.page.sortField,action.page.sortOrder)
                    .pipe(
                        map(data => {
                            return smsHistoryActions.SmsHistoryPageLoadedSuccessfully({
                                SmsHistory: data.content,
                                totalCount: data.totalElements,
                                page : data.totalPages
                            });
                        }),
                        catchError(error => of(smsHistoryActions.SmsHistoryPageLoadFailed({error})))
                    ))));

}