// NGRX
import {select, Store} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../reducers';
import {
    selectCommentsInStore,
    selectCommentsPageLoading,
    selectCommentsShowInitWaitingMessage
} from '../_selectors/comment.selectors';

export class CommentDatasource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectCommentsPageLoading),
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectCommentsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectCommentsInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }
}
