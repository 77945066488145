import {Injectable} from "@angular/core";
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {QueryParamsModel} from "../../_base/crud";
import {Page} from "../../_base/crud/models/page";
import {Observable} from "rxjs";
import {QuestionAnswerModel} from "../../home-page/question-answer/questionanswer.model";


@Injectable({
    providedIn: 'root'
})
export class EkQuestionAnswerService extends AbstractService<QuestionAnswerModel> {

    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint + 'questions'}`;
    }

    public GetAllFAQ(queryParams: QueryParamsModel): Observable<Page<QuestionAnswerModel>> {
        var url = `${this.url}` + '/pages';
        const options = {
            params: new HttpParams()
                .append('page', queryParams.pageNumber ? queryParams.pageNumber.toString() : '0')
                .append('size', queryParams.pageSize ? queryParams.pageSize.toString() : '50')
                .append('keyword', queryParams.filter.query ? queryParams.filter.query : '')
                .append('type', queryParams.filter.status ? queryParams.filter.status: '')

        };
        if (queryParams.filter.status) {
            url = `${this.url}` +'/filter-type';
        }
        if (queryParams.filter.query){
            url = `${this.url}` +'/search';
        }
        return this.http.get<Page<QuestionAnswerModel>>(url, options);
    }

    public registerFaq(entity: QuestionAnswerModel): Observable<QuestionAnswerModel> {
        const params = new HttpParams()
            .set('question', entity.question)
            .set('answer', entity.answer)
            .set('questionType', entity.questionType)
        const res = this.http.post<QuestionAnswerModel>(this.url, params);
        return res;
    }

    public updateFaq(_faq: QuestionAnswerModel, id: number): Observable<QuestionAnswerModel> {
        const params = new HttpParams()
            .set('id', String(_faq.id))
            .set('question', _faq.question)
            .set('answer', _faq.answer)
            .set('questionType', _faq.questionType)

        const url = `${this.url}`;
        return this.http.put<QuestionAnswerModel>(url, params)

    }
}
