import {AbstractService} from "../../services/abstract-service.service";
import {PhoneNumberModel} from "../_models/phone-number.model";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";

@Injectable()
export class PhoneNumberService extends AbstractService<PhoneNumberModel> {
    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}phoneNumber`;
    }

    public getAll(): Observable<PhoneNumberModel[]> {
        const url = `${this.url}`;
        //  console.log(url+ "ok");
        const res =  this.http.get<Response<PhoneNumberModel[]>>(url).pipe(map(({body}) =>
            body));
        res.subscribe(res =>{
            // console.log(JSON.stringify(res));
        })
        return  res;
    }


    public getByRelayPointId(RelayPointId: number): Observable<PhoneNumberModel[]> {
        const url = `${this.url}/getByRelayPointId/${RelayPointId}`;
        return this.http.get<Response<PhoneNumberModel[]>>(url).pipe(map(({body}) => body));
    }

}