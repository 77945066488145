// NGRX
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
// Actions
import * as PermissionActions from '../_actions/permission.actions';
// Models
import { Permission } from '../_models/permission.model';
import {PermissionsTable} from "../_server/permissions.table";

export interface PermissionsState extends EntityState<Permission> {
    _isAllPermissionsLoaded: boolean;
}

export const adapter: EntityAdapter<Permission> = createEntityAdapter<Permission>();

export const initialPermissionsState: PermissionsState = adapter.getInitialState({
    _isAllPermissionsLoaded: false
});

const featureReducer = createReducer(
    initialPermissionsState,
    on(PermissionActions.AllPermissionsRequested, state => ({...state, _isAllPermissionsLoaded: false})),
    on(PermissionActions.AllPermissionsLoaded,
        (state,{permissions}) => ({...state, permissions:PermissionsTable.permissions, _isAllPermissionsLoaded: true}))
);

export function permissionsReducer(state:PermissionsState| undefined, action: Action) {
    return featureReducer(state, action);
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
