import { CustomerDiscountService } from './../../../../../../core/e-commerce/_services/customer-discount.service';
import {ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {OrdersService} from "../../../../../../core/e-commerce/_services/orders.service";
import {OrderModel2} from "../../../../../../core/e-commerce/_models/order2.model";
import {ActivatedRoute, Router} from "@angular/router";
import {CallModel} from "../../../../../../core/e-commerce/_models/call.model";
import {CallService} from "../../../../../../core/e-commerce/_services/calls.service";
import {BehaviorSubject, Observable} from "rxjs";
import {NgxPermissionsService} from "ngx-permissions";
import {CustomerModel, CustomersService} from "../../../../../../core/e-commerce";
import {ClientDetailComponent} from "./client-detail/client-detail.component";
import {DeliveryModel} from "../../../../../../core/e-commerce/_models/delivery.model";
import {DeliveryAddressModel} from "../../../../../../core/e-commerce/_models/delivery-address.model";
import {OrderConfirmationModel} from "../../../../../../core/e-commerce/_models/orderConfirmation.model";
import {LayoutUtilsService, MessageType} from "../../../../../../core/_base/crud";
import {OrderItemModel} from "../../../../../../core/e-commerce/_models/OrderItem.model";
import {AddressesService} from "../../../../../../core/e-commerce/_services/addresses.service";
import {Address} from "../../../../../../core/e-commerce/_models/address.model";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../core/reducers";
import * as CustomerAddressesActions from "../../../../../../core/e-commerce/_actions/customer-address.actions";


@Component({
    selector: 'kt-order-control',
    templateUrl: './order-control.component.html',
    styleUrls: ['./order-control.component.scss']
})
export class OrderControlComponent implements OnInit {

    @ViewChild('callhistory', {static: false}) callhistory: ElementRef;
    @ViewChild('callhistory') child: ClientDetailComponent;
    @ViewChild('clientdetails') clientDetailsChild: ClientDetailComponent;

    //private
    loading$ = new Observable<boolean>();
    selectedTab = 0;

    order: OrderModel2;
    order$: BehaviorSubject<OrderModel2> = new BehaviorSubject<OrderModel2>(new OrderModel2());

    orderId: number;
    clientId: number;
    clientDetails;

    //Permissions
    private PERMISSIONS = ['ALL_ORDER', 'UPDATE_ORDER'];
    canEdit = false;

    customer: CustomerModel;
    addingOrder = this.orderService.addingOrder;
    licenseKeysOrderItems: OrderItemModel[];
    userId = JSON.parse(localStorage.getItem('currentUser')).id;

    discountClientValid: boolean

    constructor(private orderService: OrdersService,
                private callService: CallService,
                private route: ActivatedRoute,
                private cd: ChangeDetectorRef,
                private zone: NgZone,
                private customerService: CustomersService,
                private ngxPermissionService: NgxPermissionsService,
                private router: Router,
                private layoutUtilsService: LayoutUtilsService,
                private addressesService: AddressesService,
                private customerDiscountService: CustomerDiscountService,
                private store: Store<AppState>) {
    }

    ngOnInit(): void {
        this.loading$ = this.orderService.loading$;
        if (this.route.url['value'][0].path == 'addOrder')
            this.changeToNewOrder()
        else
            this.loadingData()
        this.orderService.selectedOrder$.subscribe(order => this.order = order);
        this.checkPermissionToUpdate();
        this.orderService.order = this.order

        if (this.order){
            this.customerDiscountService.checkDiscountClient(this.order.clientId).subscribe(res => {
                if (res.body) {
                    this.discountClientValid = true
                } else {
                    this.discountClientValid = false
                }
            })
        }


    }

    getOrderCalls(id: number) {
        this.callService.getByOrderId(id).subscribe(calls => {
            this.order.calls = calls;
        });
    }

    showPromotionCodeDetails() {
        if (this.order.promoCodeTO.id)
            this.router.navigate(['/ecommerce/promotionCode/edit/' + this.order.promoCodeTO.id])
    }

    showDiscountDetails() {
        this.router.navigate(['/ecommerce/customers/edit/' + this.order.clientId])
    }

    loadClientData() {
        this.route.paramMap.subscribe(params => {
            this.loadEmptyOrder();
            this.order.clientId = Number(params.get('id'));
            this.order$.next(this.order)
            this.orderService.selectedOrderSubject.next(this.order)
            this.customerService.getById(this.order.clientId).subscribe(
                reponse => {
                    this.zone.run(() => {
                            this.customer = reponse;
                            this.cd.detectChanges();
                        }
                    )
                }
            );
        })

    }

    loadingData() {
        this.route.paramMap.subscribe(params => {
            this.orderId = Number(params.get('id'));
            this.orderService.getById(this.orderId).subscribe((order: OrderModel2) => {
                this.order = order;
                this.orderService.order = order
                this.order$.next(order)
                this.orderService.selectedOrderSubject.next(this.order)
                this.getOrderCalls(order.id);
                this.customerService.getById(this.order.clientId).subscribe(
                    reponse => {
                        this.zone.run(() => {
                                this.customer = reponse;
                                this.cd.detectChanges();
                            }
                        )
                    }
                );

            });
        });
    }

    

    cancelOrder() {
        this.orderService.changeOrderState(this.order.id, 'CANCEL').subscribe((e) => {
            this.updateOrderState(e.body);
            this.addCall('Replied')
        });
    }

    confirmOrder() {
        this.orderService.changeOrderState(this.order.id, 'PREPARE').subscribe((e) => {
            this.updateOrderState(e.body);
            this.addCall('Replied')
        });
    }

    /**
     * Disable the form if the used doesnt have the permission to update
     */
    checkPermissionToUpdate() {
        this.ngxPermissionService.hasPermission(this.PERMISSIONS).then(hasPermission => {
            this.canEdit = hasPermission;
        });
    }


    updateOrderState(orderState: string) {
        const order = this.orderService.selectedOrderSubject.value;
        order.state = orderState;
        this.orderService.selectedOrderSubject.next(order)
    }

    addCall(callActionType: string) {
        switch (callActionType) {
            case 'Replied' :
                const callReplied = new CallModel(null, 'Replied', this.userId, this.order.state, this.order.id);
                this.callService.save(callReplied).subscribe(e => {
                    this.order.calls.push(e);
                    this.orderService.selectedOrderSubject.next(this.order)
                });
                break;
            case 'Missed' :
                const callMissed = new CallModel(null, 'Missed', this.userId, this.order.state, this.order.id);
                this.callService.save(callMissed).subscribe(e => {
                    this.order.calls.push(e);
                    this.orderService.selectedOrderSubject.next(this.order)
                });
                break;
            case 'VoiceMailed' :
                const call = new CallModel(null, 'VoiceMailed', this.userId, this.order.state, this.order.id);
                this.callService.save(call).subscribe(e => {
                    this.order.calls.push(e);
                    this.orderService.selectedOrderSubject.next(this.order)
                });
                break;
            case 'Ended' :
                const call3 = new CallModel(null, 'Ended', this.userId, this.order.state, this.order.id);
                this.callService.save(call3).subscribe(e => {
                    this.order.calls.push(e);
                    this.orderService.selectedOrderSubject.next(this.order)
                });
                break;
            default:
                break;
        }
    }

    goToCallHistory() {
        setTimeout(() => {
            this.callhistory.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'})
        }, 300);
    }

    changeToNewOrder() {
        this.addingOrder = true;
        this.loadClientData()
    }

    updatedetails(details) {
        this.clientDetails = details;
        this.order.delivery.deliveryPrice = details.deliveryFie
        // this.calculateTotal();
        //this.refreshOrder();
    }

    // calculateTotal() {
    //     console.log('fdsdsfsdfsdffdsfsds'+this.order.total);
        
    //     this.order.total = 0;
    //     this.order.orderItems.forEach(value => {
    //         this.order.total = this.order.total + value.sellingPrice
    //     })
    //     this.order.totalTTC = this.order.total + ((this.order.delivery.deliveryPrice == null) ? 0 : this.order.delivery.deliveryPrice)
    // }

    loadEmptyOrder() {
        this.order = new OrderModel2();
        this.order.delivery = new DeliveryModel()
        this.order.delivery.deliveryAddress = new DeliveryAddressModel()
        this.order.orderItems = [];
        this.order.calls = []
    }

    saveOrder() {
       
        if (this.clientDetailsChild.checkControlValidation()) {
            if (this.order.orderItems.length > 0) {
                let orderConfirmation: OrderConfirmationModel = this.createOrderConfimation()
                this.orderService.addOrder(orderConfirmation, this.order.orderItems.map(val => {
                    return {id: val.configurationTO.id, quantity: val.quantity}
                })).subscribe(
                    res => {
                        if (res != null)
                            this.router.navigateByUrl('/ecommerce/orders/' + res['body'])

                    }
                );
                //add address to client Addresses
                this.addClientAddress();
                this.customerDiscountService.checkDiscountClient(this.order.clientId).subscribe(res => {
                    if (res.body) {
                        this.discountClientValid = true
                    } else {
                        this.discountClientValid = false
                    }
                })

            } else
                this.layoutUtilsService.showActionNotification('Une commande doit contenir au moins un produit!', MessageType.Update, 10000, true, false);
        }
    }

    addClientAddress() {
        const newAdr = new Address();

        if (this.clientDetails.delivery_type === 'POINT_DE_RELAIT'){
            newAdr.province = this.clientDetails.relayPoint;
            newAdr.addressLine1 = this.clientDetails.addressRelayPoint[0];
        }else {
            newAdr.province = this.clientDetails.wilaya;
            newAdr.addressLine1 = this.clientDetails.addressLine1;
        }

        newAdr.addressLine2 = '';
        newAdr.firstname = this.clientDetails.firstname;
        newAdr.lastname = this.clientDetails.lastname;
        newAdr.country = "ALGERIA";
        newAdr.city = this.clientDetails.town;
        newAdr.clientId = this.order.clientId;
        newAdr.fullName = "";
        newAdr.phoneNumber = this.clientDetails.phone;
        newAdr.province = this.clientDetails.wilaya;
        newAdr.type = "DELIVERY";
        newAdr.zipCode = "";
        this.store.dispatch(CustomerAddressesActions.AddressCreated({address: newAdr}));
    }

    createOrderConfimation() {
        
        let orderConfirmation: OrderConfirmationModel = new OrderConfirmationModel();
        orderConfirmation.clientId = this.order.clientId;
        orderConfirmation.deliveryType = this.clientDetails.delivery_type;
        orderConfirmation.livreurId = 1;
        orderConfirmation.address = new DeliveryAddressModel();
        orderConfirmation.address.firstname = this.clientDetails.firstname;
        orderConfirmation.address.lastname = this.clientDetails.lastname;
        orderConfirmation.address.phone = this.clientDetails.phone;
        orderConfirmation.promoCodeId = this.orderService.promoCodeId

        if (this.clientDetails.delivery_type === 'POINT_DE_RELAIT'){

            let wilaya = this.clientDetails.relayPoint;

            switch (this.clientDetails.relayPoint) {
                case 'ALGER2':
                    wilaya = 'ALGER';
                    break;
                case 'ORAN2':
                    wilaya = 'ORAN';
                    break;
                case 'OUARGLA2':
                    wilaya = 'OUARGLA'
                    break;
                case 'OUARGLA3':
                    wilaya = 'OUARGLA'
                    break;
            }

            orderConfirmation.address.wilaya = wilaya;
            orderConfirmation.address.addressLine1 = this.clientDetails.addressRelayPoint[0];
        }else {
            orderConfirmation.address.wilaya = this.clientDetails.wilaya;
            orderConfirmation.address.addressLine1 = this.clientDetails.addressLine1;
        }
        orderConfirmation.address.ville = this.clientDetails.town;
        return orderConfirmation;
    }

    /* getLicenseKeys(event:OrderItemModel[]){
        //this.licenseKeysOrderItems=event;
    }*/


}
