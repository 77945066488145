import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'roundUp',
})
export class RoundUpPipe implements PipeTransform {
    transform(value: number): number {
        value = Math.round(value/100)*100;
        return value;
    }
}