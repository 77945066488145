import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {AbstractService} from "../../services/abstract-service.service";
import {ListingModel} from "../ek-models/listing-model";
import {Observable} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {Page} from "../../_base/crud/models/page";
import {ChangeHistoryModel} from "../ek-models/change-history-model";

@Injectable({
  providedIn: 'root'
})
export class HistoryStateFolderService extends AbstractService<ListingModel> {

  url="";
  constructor(protected http: HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}historyStateFolder`;
  }


  public getAllFolderStateHistoryListing(pageNumber: number = 0, pageSize: number = 50, sortBy : string, direction : string, query: string = "" , firstDate:string , lastDate:string , folderState: string , email : string): Observable<Response<Page<ChangeHistoryModel>>> {

    let params = new HttpParams();

    params = params.append('pageNo', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());
    params = params.append('sortBy',sortBy );
    params = params.append('direction', direction);
    params = query ? params.append('criterion', query) : params;
    params = folderState ? params.append('folderState', folderState) : params;
    params = firstDate ? params.append('firstDate', firstDate) : params;
    params = lastDate ? params.append('lastDate', lastDate) : params;
    params = email ? params.append('email', email) : params;

    const options = {params};
    const url = `${this.url}/lastState`;
    return this.http.get<Response<Page<ChangeHistoryModel>>>(url, options);
  }


  folderStateChangeById(idFolder: number): Observable<Response<Page<ChangeHistoryModel>>> {
    const url = `${this.url}/lastState/${idFolder}`;
    return this.http.get<Response<Page<ChangeHistoryModel>>>(url);
  }
}
