// NGRX
import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
// Actions
import * as CustomerActions from '../_actions/customer.actions';
// Models
import {CustomerModel} from '../_models/customer.model';
import {QueryParamsModel} from '../../_base/crud';

export interface CustomersState extends EntityState<CustomerModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedCustomerId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
}

export const adapter: EntityAdapter<CustomerModel> = createEntityAdapter<CustomerModel>();

export const initialCustomersState: CustomersState = adapter.getInitialState({
    customerForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedCustomerId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
});

const customerReducer = createReducer(
    initialCustomersState,

    //list upload
    on(CustomerActions.CustomersPageRequested,
        (state, action) => ({
            ...state,
            actionsloading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastQuery: action.page
        })),
    on(CustomerActions.CustomersPageLoadedSuccessfully,
        (state, action) =>
            (adapter.addAll(action.customers, {
                ...state,
                actionsloading: false,
                listLoading: false,
                showInitWaitingMessage: false,
                totalCount: action.page
            }))),
    on(CustomerActions.CustomersPageLoadingFailed,
        (state, action) => ({
            ...state,
            error: action.error,
            actionsloading: false,
            listLoading: false,
            showInitWaitingMessage: false
        })),

    //creation
    on(CustomerActions.CustomerCreated,
        (state, action) => ({...state, actionsloading: true, error: null})),
    on(CustomerActions.CustomerCreatedSuccessfully,
        (state, action) => (adapter.addOne(action.customer, {
            ...state,
            actionsloading: false,
            lastCreatedCustomerId: action.customer.id
        }))),
    on(CustomerActions.CustomerCreationFailed,
        (state, action) => ({...state, actionsloading: false, error: action.error})),


    //update
    on(CustomerActions.CustomerUpdated,
        (state, action) => ({...state, actionsloading: true, error: null})),
    on(CustomerActions.CustomerUpdatedSuccessfully,
        (state, action) =>
            (adapter.updateOne(action.partialCustomer, {...state, actionsloading: false,listLoading: false,}))),
    on(CustomerActions.CustomerUpdateFailed,
        (state, action) => ({...state, error: action.error, actionsloading: false})),

    //delete customer
    on(CustomerActions.OneCustomerDeleted,
        (state, action) => ({...state, actionsloading: true, error: null})),
    on(CustomerActions.OneCustomerDeletedSuccessfully,
        (state, action) => (adapter.removeOne(action.id, {
            ...state,
            actionsloading: false,
            lastAction: 'delete Success'
        }))),
    on(CustomerActions.OneCustomerDeleteFailed,
        (state, action) => ({...state, error: action.error, actionsloading: false})),
);

export function customersReducer(state: CustomersState | undefined, action: Action) {
    return customerReducer(state, action)
}
