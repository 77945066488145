import {BaseModel} from "../../_base/crud";

export enum FooterFileType {
    LegalMention='LegalMention',
    GeneralCondition='GeneralCondition'
}

export class FooterFilesModel extends BaseModel{
    id: number;
    status: string;
    fileName: string;
    url: string;
    type: FooterFileType;
}


