import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ConfigurationModel} from "../../../../../../core/e-commerce/_models/configuration.model";
import {fromEvent, merge, Observable, Subscription} from "rxjs";
import {NgbCalendar, NgbDate, NgbDateParserFormatter} from "@ng-bootstrap/ng-bootstrap";
import {SelectionModel} from "@angular/cdk/collections";
import {ConfigurationsDatasource} from "../../../../../../core/e-commerce/_data-sources/configuration.datasource";
import {QueryParamsModel} from "../../../../../../core/_base/crud";
import {ActivatedRoute, Router} from "@angular/router";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../../../../../core/reducers";
import {ConfigurationService} from "../../../../../../core/e-commerce";
import {debounceTime, distinctUntilChanged, skip, tap} from "rxjs/operators";
import {selectProductsPageLastQuerys} from "../../../../../../core/e-commerce/_selectors/configuration.selector";
import {
  DiscountPageRequested, PromotionUpdatedActivate,VenteFlashPageRequested
} from "../../../../../../core/e-commerce/_actions/configurations.actions";
import {MatPaginator, MatSort} from "@angular/material";
import {Update} from "@ngrx/entity";

@Component({
  selector: 'kt-vente-flash',
  templateUrl: './vente-flash.component.html',
  styleUrls: ['./vente-flash.component.scss']
})
export class VenteFlashComponent implements OnInit {
  viewLoading$ = null;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate:  NgbDate | null;
  showDatePicker = true;
  updater: string;
  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  paginatorTotal$: Observable<number>;
  loading$: Observable<boolean>;
  selection = new SelectionModel<ConfigurationModel>(true, []);
  filterByDateActivated = false;
  loadingData = false;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  configuration: ConfigurationModel;
  pageSize: 100;
  totalElements: number;
  private subscriptions: Subscription[] = [];
  categories: ConfigurationModel[];
  promotionResult: ConfigurationModel[] = [];
  selectedState: string = '';
  checked: boolean;

  displayedColumnss = ['Id Conf','idp', 'price','value','priceDiscount','dateDebut','dateFin','image','status'];

  dataSource: ConfigurationsDatasource;
  configurations: ConfigurationModel [] = [];
  lastQuery: QueryParamsModel;

  constructor(
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private calendar: NgbCalendar,
      public formatter: NgbDateParserFormatter,
      private store: Store<AppState>,

      private configurationService: ConfigurationService,

  ) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    this.fromDate = null;
    this.toDate = null;
  }

  ngOnInit(): void {
    this.updater=JSON.parse(localStorage.getItem('currentUser')).username;
    this.paginator._changePageSize(15);
    const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.subscriptions.push(sortSubscription);
    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
        tap(() => this.load())
    )
        .subscribe();
    this.subscriptions.push(paginatorSubscriptions);
    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((v) => {
          this.paginator.pageIndex = 0;
          this.load();
        })
    ).subscribe();
    this.subscriptions.push(searchSubscription);
    this.dataSource = new ConfigurationsDatasource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject.pipe(
        skip(1),
        distinctUntilChanged()
    ).subscribe(res => {
      this.promotionResult = res;
    });
    this.subscriptions.push(entitiesSubscription);
    const lastQuerySubscription = this.store.pipe(select(selectProductsPageLastQuerys)).subscribe(res => {
      this.lastQuery = res
    });
    this.subscriptions.push(lastQuerySubscription);
    // Read from URL itemId, for restore previous state
    const routeSubscription = this.activatedRoute.queryParams.subscribe(params => {
      // First load
      this.load();
    });
    this.subscriptions.push(routeSubscription);

  }
  load(){
    const queryParams = new QueryParamsModel(
        this.filterCategories().query,
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex,
        this.paginator.pageSize
    );

    // Call request from server
    this.store.dispatch(VenteFlashPageRequested({
      page:queryParams,
      startingDate: this.fromDate ? this.formatDate(this.fromDate) : null,
      endingDate: this.toDate ? this.formatDate(this.toDate) : null,
      active:this.selectedState
    }));
  }
  filterCategories(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;
    filter.query = searchText.trim();
    return filter;
  }


  formatDate(date: NgbDate) {
    if (date){
      var month = '' + (date.month),
          day = '' + date.day,
          year = date.year;
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('/');
    }

  }
  //Check all rows are selected
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.promotionResult.length;
    return numSelected === numRows;
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }


  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      this.filterByDateActivated = true;
      this.load();
      this.showDatePicker = false;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }
  disableFilterByDate(event) {
    if (event.checked == false) {
      this.filterByDateActivated = false;
      this.load();
    }
  }
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  //Activate/dis-activate PromoCode
  onChange(event, promotion: ConfigurationModel) {
    if (event.checked === true) {
      this.checked = true;
      {{this.checked}}
      this.OnChangeActivatePromoCode(promotion, true, event);
    }
    if (event.checked === false) {
      this.checked = false;
      this.OnChangeActivatePromoCode(promotion, false, event);
    }
  }
  /**changer le status de la promotion  **/
  OnChangeActivatePromoCode(promotion: ConfigurationModel, value: boolean, event: any) {

    const _promotion =promotion;
    _promotion.id = promotion.id

    promotion.discount.status = value;

    const updatePromotion: Update<ConfigurationModel> = {
      id: _promotion.discount.id,
      changes: _promotion
    };
    this.store.dispatch(PromotionUpdatedActivate({
      Promotion: _promotion,
      partialPromotion: updatePromotion,
      activate: value,
    }));}


  navigate() {
    this.router.navigate(['/ecommerce/products2'], {
      relativeTo: this.activatedRoute,
    });
  }
  editPromotion(c: ConfigurationModel) {
    this.router.navigate([ '/ecommerce/products2/edit/' + c.productId])
  }
}
