import {BaseModel} from "../../_base/crud";

export type FileShippingType = 'POINT_OF_SALE' | 'RELAY_POINT' | 'RESIDENCE';

export type FolderStates = 'EK_FOLDER_CREATED'
    | 'EK_FOLDER_IN_PROGRESS'
    | 'EK_FOLDER_RESERVE'
    | 'EK_FOLDER_REFUSED'
    | 'EK_BANK_IN_PROGRESS'
    | 'GUARTENTEES_SIGNED_IN_PROGRESS_BANK'
    | 'EK_BANK_RESERVE'
    | 'EK_BANK_REFUSED'
    | 'EK_GUARTENTEES_PENDING_SIGNATURE'
    | 'EK_GUARTENTEES_SIGNED_IN_PROGRESS'
    | 'EK_PHYSICAL_FOLDER_PENDING'
    | 'EK_PHYSICAL_FOLDER_WAITING_FOR_PICK'
    | 'EK_PHYSICAL_FOLDER_PICKEDUP'
    | 'EK_PHYSICAL_FOLDER_IN_DELIVERY'
    | 'EK_PHYSICAL_FOLDER_DELIVERED'
    | 'EK_WAITING_FOR_CLIENT'
    | 'EK_CLIENT_NO_RESPONSE'
    | 'EK_FOLDER_PICKUP_FAILED'
    | 'EK_PHYSICAL_FOLDER_IN_PROGRESS'
    | 'EK_BANK_PHYSICAL_FOLDER_ACCEPTED'
    | 'EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY'
    | 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS'
    | 'EK_BANK_PHYSICAL_FOLDER_RESERVE'
    | 'EK_ADMIN_FOLDER_CANCELED'
    | 'EK_CLIENT_FOLDER_CANCELED'
    | 'BANK_NOTIFICATION'
    | 'FINANCIAL_FOLDER'
    | 'EK_CONTROL_BEFORE_GUARANTEES_PRINTING'
    ;

export class FolderModel extends BaseModel {
    id: number;
    folderPreviousState: FolderStates;
    folderState: FolderStates;
    fileShippingType: FileShippingType;
    archive:boolean;
    requestNumber:string;
}
