import {BaseModel} from "../../_base/crud";
import {ConfigurationModel} from "./configuration.model";
import {licenseKeyModel} from "./licenseKey.model";

export class OrderItemModel extends BaseModel {
    id: number;
    orderProductId: number;
    quantity: number;
    configurationTO: ConfigurationModel;
    sellingPrice: number;
    sellingPriceEuro:number;
    sellingPriceDollar:number;
    confirmationDate: string;
    deliveryDate: string;
    productName?: string;
    licenseKeyTOSet:licenseKeyModel[];
    stockDepotId: number;
    stockEkId: number;

    _isEditMode: boolean;

    clear() {
        this._isEditMode = false;
    }
}
