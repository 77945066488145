import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import * as FoldersActions from "../ek-actions/folder.action";
import {FoldersService} from "../ek-services/folders.service";


@Injectable()
export class FoldersEffects {
    constructor(private actions$: Actions,
                private foldersService: FoldersService,
    ) {
    }

    FoldersListingPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FoldersActions.foldersListingPageRequested),
            switchMap((action) =>
                this.foldersService.getAllFoldersListing(action.page.pageNumber, action.page.pageSize,action.page.sortField, action.page.sortOrder, action.page.filter, action.folderState, action.orderState,  action.agencyId, action.firstDate, action.lastDate, action.archived, action.idPos,action.creatorType)
                    .pipe(
                        map(foldersRes => {
                            return FoldersActions.foldersListingPageLoadedSuccessfully(
                                {
                                    folders: foldersRes.body ? foldersRes.body.content : [],
                                    page: foldersRes.body ? foldersRes.body.totalPages : 0,
                                    totalCount: foldersRes.body ? foldersRes.body.totalElements : 0
                                })
                        }),
                        catchError(error => of(FoldersActions.foldersListingPageLoadFailed({error})))
                    ))));
}
