import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { RegionService } from "../_services/region.service";
import * as RegionActions from "../_actions/region.actions";
import { catchError, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { of } from "rxjs";
import { LayoutUtilsService, MessageType } from "../../_base/crud";
import { pipeFromArray } from "rxjs/internal/util/pipe";

@Injectable()
export class RegionEffects {
  constructor(
    private actions$: Actions,
    private regionService: RegionService,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  RegionLoadRequesed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegionActions.loadRegions),
      switchMap((action) =>
        this.regionService.findAll(action.query, action.commercialId).pipe(
          map((regionList) => {
            return RegionActions.loadRegionsSuccess({
              data: regionList["body"],
            });
          }),
          catchError((error) => of(RegionActions.loadRegionsFailure({ error })))
        )
      )
    )
  );

  RegionDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegionActions.RegionDeleted),
      switchMap((action) =>
        this.regionService.delete(action.RegionId).pipe(
          map(() =>
            RegionActions.RegionDeletedSuccessfully({
              RegionId: action.RegionId,
            })
          ),
          catchError((error) => of(RegionActions.RegionDeleteFailed({ error })))
        )
      )
    )
  );

  RegionRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegionActions.RegionRequested),
      switchMap((action) =>
        this.regionService.getById(action.RegionId).pipe(
          map((region) =>
            RegionActions.RegionRequestedSuccessfully({ Region: region })
          ),
          catchError((error) =>
            of(RegionActions.RegionRequestedFailed({ error }))
          )
        )
      )
    )
  );
  RegionSaved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegionActions.SaveRegionRequested),
      switchMap((action) =>
        this.regionService.save(action.Region).pipe(
          map((region) => this.fun(region)),
          catchError((error) => of(RegionActions.SaveRegionFailed({ error })))
        )
      )
    )
  );

  getCommercials$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RegionActions.loadCommercials),
      mergeMap(() =>
        this.regionService.findAllCommercials().pipe(
          map(
            (commercials) => 
              RegionActions.loadCommercialsSucess({ users: commercials }),
            catchError((error) =>
              of(RegionActions.loadCommercialsFailure({ error: error }))
            )
          )
        )
      )
    );
  });

  fun(region) {
    if (region) {
      this.layoutUtilsService.showActionNotification(
        "Région Sauvegarder avec Succès!",
        MessageType.Update,
        10000,
        true,
        false
      );
      return RegionActions.SaveRegionSuccessfully({ Region: region });
    } else {
      this.layoutUtilsService.showActionNotification(
        "Erreur, Verifier que le nom de la region est unique!",
        MessageType.Create,
        10000,
        true,
        false
      );
      throw new Error("Erreur");
    }
  }
}
