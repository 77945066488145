import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "translateOrderState",
})
export class TranslateFolderOrderStatePipe implements PipeTransform {
    transform(State: string): string {
        switch (State) {
            //order split status
            case "EK_ORDER_CREATED":
                return "Commande créée";

            case "EK_ORDER_PENDING":
                return "Commande en attente";

            case "EK_ORDER_IN_PREPARATION":
                return "Commande en préparation";

            case "EK_ORDER_IN_DELIVERY":
                return "Livraison en cours";

            case "EK_ORDER_DELIVERED":
                return "Client livré";

            case "EK_CLIENT_ORDER_CANCELED":
                return "Commande annulée";

            case 'DELIVERED_POS':
                return 'Pos livré';

            //folder status
            case "EK_FOLDER_CREATED":
                return "1. En cours de création";

            case "EK_FOLDER_IN_PROGRESS":
                return "2. En cours de traitement Ekiclik";

            case "EK_FOLDER_RESERVE":
                return "3. Réservé Ekiclik";

            case "EK_FOLDER_REFUSED":
                return "4. Dossier Rejeté";

            case "CENTRAL_RISK_CONSULTATION":
                return "5. Consultation de la Centrale des Risques";

            case "EK_BANK_IN_PROGRESS":
                return "6. Pré-notification banque";

            case "BANK_NOTIFICATION":
                return "7. Notification de la banque (Accord)";

            case "EK_BANK_RESERVE":
                return "8. Dossier réserve banque";

            case "EK_BANK_REFUSED":
                return "9. Dossier Rejeté Banque";

            case "GUARTENTEES_SIGNED_IN_PROGRESS_BANK":
                return "10.Garanties signés";

            case "CONTROL_BEFORE_GUARANTEES_PRINTING_BANK":
                return "11. Control avant impression des garanties banque";

            case "EK_CONTROL_BEFORE_GUARANTEES_PRINTING":
                return "Control avant impression des garnaties EkiClik";

            case "GUARANTEES_TO_CORRECT":
                return "12. Garanties à corriger (Reserve)";

            case "EK_GUARTENTEES_PENDING_SIGNATURE":
                return "13. Garanties en cours de signature";

            case "EK_GUARTENTEES_SIGNED_IN_PROGRESS":
                return "14. Garanties signés en cours validation Ekiclik";

            case "FINANCIAL_FOLDER":
                return "15. Dossiers Financés";

            case "EK_PHYSICAL_FOLDER_PENDING":
                return "16. Dossier physique en préparation";

            case "EK_PHYSICAL_FOLDER_WAITING_FOR_PICK":
                return "17. Dossier physique en attente de recupération";

            case "EK_PHYSICAL_FOLDER_IN_DELIVERY":
                return "18. Dossier physique en cour de livraison";

            case "EK_WAITING_FOR_CLIENT":
                return "19. Dossier physique en attente du client";

            case "EK_CLIENT_NO_RESPONSE":
                return "20. Client ne reponds pas";

            case "EK_PHYSICAL_FOLDER_PICKEDUP":
                return "21. Dossier physique ramassé";

            case "EK_PHYSICAL_FOLDER_DELIVERED":
                return "22. Dossier physique livré";

            case "EK_FOLDER_PICKUP_FAILED":
                return "23. Echec de recuperation du dossier physique";

            case "EK_PHYSICAL_FOLDER_IN_PROGRESS":
                return "24. Dossier physique en traitement Ekiclik";

            case "EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY":
                return "25. Dossier physique en cours d'envoie banque";

            case "EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS":
                return "26. Control dossier physique banque";

            case "EK_BANK_PHYSICAL_FOLDER_ACCEPTED":
                return "27. Dossier physique accepté banque";

            case "EK_BANK_PHYSICAL_FOLDER_RESERVE":
                return "28. Dossier physique réserve banque";

            case "EK_BANK_PHYSICAL_FOLDER_REFUSED":
                return "29. Dossier physique rejeté banque";

            case "EK_CLIENT_FOLDER_CANCELED":
                return "30. Dossier annulé (CLIENT)";

            case "EK_ADMIN_FOLDER_CANCELED":
                return "31. Dossier annulé (ADMIN)";

            case "EK_PHYSICAL_FOLDER_REFUSED":
                return "Dossier physique Rejeté";

            default:
                return State;
        }
    }
}
