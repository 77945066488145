// Angular
import {Injectable} from '@angular/core';
// RxJS
import {catchError, map, switchMap} from 'rxjs/operators';
// NGRX
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// State
import {AppState} from '../../reducers';
// Actions
import {of} from 'rxjs';

import * as BestSellerActions from "../_actions/bestSeller.action";
import {BestSellerService} from "../_services/bestSeller.service";


@Injectable()
export class BestSellerEffects {


    constructor(private actions$: Actions, private bestSellerService: BestSellerService, private store: Store<AppState>) {
    }

    BestSellerPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BestSellerActions.BestSellerPageRequested),
            switchMap((action) =>
                this.bestSellerService.getAllPaged(action.page.pageNumber, action.page.pageSize)
                    .pipe(
                        map(bestSellerPage => BestSellerActions.BestSellerPageLoadedSuccessfully(
                            {
                                bestSeller: bestSellerPage.content,
                                page: bestSellerPage.totalElements,
                                totalCount: bestSellerPage.totalElements
                            })),
                        catchError(error => of(BestSellerActions.BestSellerPageLoadFailed({error})))
                    ))));

    BestSellerCreated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BestSellerActions.BestSellerCreated),
            switchMap((action) =>
                this.bestSellerService.save(action.bestSeller)
                    .pipe(
                        map(data => BestSellerActions.BestSellerCreatedSuccessfully({bestSeller: data})),
                        catchError(error => of(BestSellerActions.BestSellerCreationFailed({error})))
                    ))));

    BestSellerUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BestSellerActions.BestSellerUpdated),
            switchMap((action) =>
                this.bestSellerService.update(action.bestSeller)
                    .pipe(
                        map(bestSeller => BestSellerActions.BestSellerUpdatedSuccessfully({
                            bestSeller,
                            partialBestSeller: action.partialBestSeller
                        })),
                        catchError(error => of(BestSellerActions.BestSellerUpdateFailed({error})))
                    ))));


    BestSellerDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BestSellerActions.BestSellerDeleted),
            switchMap((action) =>
                this.bestSellerService.delete(action.bestSellerId)
                    .pipe(
                        map(() => BestSellerActions.BestSellerDeletedSuccessfully({bestSellerId: action.bestSellerId})),
                        catchError(error => of(BestSellerActions.BestSellerDeleteFailed({error})))
                    ))));


}
