import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Observable, Subscription} from "rxjs";
import {CharacteristicModel} from "../../../../../../../../core/e-commerce/_models/characteristic.model";
import {MatDialog, MatTableDataSource} from "@angular/material";
import {ConfigurationCharacteristicModel} from "../../../../../../../../core/e-commerce/_models/configuration-characteristic.model";
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../../../../../../../core/reducers";
import {
    LayoutUtilsService,
    MessageType,
    QueryParamsModel,
} from "../../../../../../../../core/_base/crud";
import {
    CharacteristicsPageRequestedOfSearch
} from "../../../../../../../../core/e-commerce/_actions/characteristic.actions";
import {selectCharacteristicsInStore} from "../../../../../../../../core/e-commerce/_selectors/characteristic.selector";
import {
 ConfigurationCharacteristicCreatedWithUpdater,
    ConfigurationCharacteristicDeletedSaveUpdater
    , ConfigurationCharacteristicUpdatedSaveUpdater
} from "../../../../../../../../core/e-commerce/_actions/configuration-characteristic.actions";
import {selectConfigurationCharacteristicById} from "../../../../../../../../core/e-commerce/_selectors/configuration-characteristic.selectors";
import {Update} from "@ngrx/entity";
import {ConfigurationService} from "../../../../../../../../core/e-commerce";
import {ResizeEvent} from "angular-resizable-element";
import {selectConfigurationById} from "../../../../../../../../core/e-commerce/_selectors/configuration.selector";
import {map, startWith} from "rxjs/operators";
import {ConfigurationModel} from "../../../../../../../../core/ek-e-commerce/ek-models/configuration.model";

@Component({
  selector: 'kt-lists-caracteristics',
  templateUrl: './lists-caracteristics.component.html',
  styleUrls: ['./lists-caracteristics.component.scss']
})
export class EkListsCaracteristicsComponent implements OnInit {

    displayedColumns = [ 'caracteristique','valeur','actions'];

    characteristics: CharacteristicModel[] = [];
    characteristicsData : ConfigurationCharacteristicModel [] = [];
    @Output() caracteristicAdd = new EventEmitter<ConfigurationCharacteristicModel[]>();
    private componentSubscriptions: Subscription[] = [];
    filteredOptionsEdit: Observable<CharacteristicModel[]>;
    characteristicFindEdit:CharacteristicModel;
    productCharacteristics : ConfigurationCharacteristicModel[];
    dataSource = new MatTableDataSource<ConfigurationCharacteristicModel>();
    id$= 1;
    formGroup: FormGroup;
    charForEdit: ConfigurationCharacteristicModel;
    charForAdd: ConfigurationCharacteristicModel;

    configuration: ConfigurationModel;
    updater:string;

    @Input() configurationId:number;

    constructor(private characteristicFB: FormBuilder, private layoutUtilsService: LayoutUtilsService,
                public dialog: MatDialog,public dialog1: MatDialog,private store: Store<AppState>, private fb: FormBuilder,private configurationService:ConfigurationService) { }
    ngOnInit(): void {
        this.updater = JSON.parse(localStorage.getItem('currentUser')).username;

        this.getChars();

        this.createFormGroup();
        this.executedfilteredOptionsEdit();

        if(this.configurationId&&this.configurationId>0){
        this.loadCharacteristic();
        }


    }
    executedfilteredOptionsEdit(){
        this.filteredOptionsEdit = this.formGroup.get('editCharacteristic').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this._filter(name.toString()) : this.characteristics.slice())
            );
    }
    private _filter(name: any): CharacteristicModel[] {

        const filterValue = name.toLowerCase();

        return this.characteristics.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
    filterCharacteristic(): any {
        const filter: any = {};
        filter.query = '';
        return filter;
    }
    getChars(){

        const queryParams = new QueryParamsModel(
            this.filterCharacteristic(),
            null,
            null,
            0,
            9999
        );
        // Call request from server
        this.store.dispatch(CharacteristicsPageRequestedOfSearch({
            page: queryParams,
        }));

      //  this.store.dispatch(CharacteristicsPageRequested({page: 0, size: 9999}));
        const chars = this.store.select(selectCharacteristicsInStore).subscribe(
            result => {
                this.characteristics = result.items;

            }
        );
    }
    addConfCharButtonOnClick() {
        this.clearAddForm();
        this.charForAdd._isEditMode = true;

    }
    createFormGroup(_item = null) {

        this.formGroup = this.fb.group({
            editValue: ['', Validators.compose([Validators.required])],
            editCharacteristic: ['',this.forbiddenCharacteristicValidator()],

        });

    }
    clearAddForm() {
        const controls = this.formGroup.controls;
        controls.editValue.setValue('');
        controls.editValue.markAsPristine();
        controls.editValue.markAsUntouched();
        controls.editCharacteristic.setValue('');
        controls.editCharacteristic.markAsPristine();
        controls.editCharacteristic.markAsUntouched();

        this.charForAdd.clear(1);
        this.charForAdd._isEditMode = false;

    }
    clearEditForm(){
        const controls = this.formGroup.controls;
        controls.editValue.setValue('');
        controls.editCharacteristic.setValue('');
        this.charForEdit = new ConfigurationCharacteristicModel();
        this.charForEdit.clear(1);
        this.charForEdit._isEditMode = false;
    }
    onResizeEnd(event: ResizeEvent, columnName): void {
        if (event.edges.right) {
            const cssValue = event.rectangle.width + 'px';
            const columnElts = document.getElementsByClassName('mat-column-' + columnName);
            for (let i = 0; i < columnElts.length; i++) {
                const currentEl = columnElts[i] as HTMLDivElement;
                currentEl.style.width = cssValue;
            }
        }
    }

    addCaracteristique() {
        const _characteristic = new ConfigurationCharacteristicModel();
        const controls = this.formGroup.controls;
        controls.editValue.setValue('');
        controls.editValue.markAsPristine();
        controls.editValue.markAsUntouched();
        controls.editCharacteristic.setValue('');
        controls.editCharacteristic.markAsPristine();
        controls.editCharacteristic.markAsUntouched();
        _characteristic._isEditMode = true;

         this.characteristicsData.push(_characteristic);
         this.caracteristicAdd.emit(this.characteristicsData);
         this.dataSource.data = this.characteristicsData;

    }


    editConfiguration(c:ConfigurationCharacteristicModel){

        const controls = this.formGroup.controls;
        this.characteristics.find(value => {
            if (value.id == c.id){
                this.characteristicFindEdit = value;

                const controls = this.formGroup.controls;
                controls.editCharacteristic.setValue(this.characteristicFindEdit.name);
            }
        });
        controls.editValue.setValue(c.value);

         c._isEditMode = true;

    }

    cancelEditButtonOnClick(c:ConfigurationCharacteristicModel){
        if (!c.value){
            this.deleteChara(c);
        }else{
            c._isEditMode = false;
        }


    }
    saveUpdatedConfiguration(c:ConfigurationCharacteristicModel){
        const controls = this.formGroup.controls;
        let index = this.characteristicsData.indexOf(c);
        c.characteristicId = this.characteristicFindEdit.id;
        c.value = this.formGroup.get('editValue').value;
        c.configurationId = this.configurationId;
        if(this.configurationId&&this.configurationId>0){
            if(c.id&&c.id>0){
                const updateConfChar: Update<ConfigurationCharacteristicModel> = {
                    id: c.id,
                    changes: {
                        _isEditMode: true
                    }
                };
                this.store.dispatch(ConfigurationCharacteristicUpdatedSaveUpdater({
                    configurationCharacteristic: c,
                    partialConfigurationCharacteristic: updateConfChar,
                    updater:this.updater
                }));
                c._isEditMode = false;
            }else{
            this.store.dispatch(ConfigurationCharacteristicCreatedWithUpdater({configurationCharacteristic: c,updater:this.updater}));
            const creationSub = this.store.pipe(
                select(selectConfigurationCharacteristicById)
            ).subscribe(res => {
                if (!res) {
                    return;
                }

                c._isEditMode = false;

            const _saveMessage = `Characteristic has been created`;
                this.layoutUtilsService.showActionNotification(_saveMessage, MessageType.Create, 10000, true, true);
                this.loadCharacteristic();


            });
                this.componentSubscriptions.push(creationSub);

                c._isEditMode = false;

            }
        }else{
        this.characteristicsData[index] = c;
        this.dataSource.data = this.characteristicsData;
        c._isEditMode = false;}
        if (!c.value){
            this.deleteChara(c);}
        this.characteristicFindEdit = undefined;
    }
    deleteChara(c:ConfigurationCharacteristicModel) {
        const index: number = this.characteristicsData.indexOf(c);
        if(this.configurationId&&this.configurationId>0){
            const _deleteMessage = `Characteristic has been deleted`;


                this.store.dispatch(ConfigurationCharacteristicDeletedSaveUpdater({configurationCharacteristicId: c.id,updater:this.updater}));
                this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
        }else{
        if (index !== -1) {
            this.characteristicsData.splice(index, 1);
            this.dataSource.data = this.characteristicsData;
        }}
    }
    getTypeStr(_confChar: ConfigurationCharacteristicModel): string {

        const char = this.characteristics.filter(char => char.id == _confChar.characteristicId)[0];
        return char.name;

    }
    alreadyExist(id: number): boolean {
        const res = this.characteristicsData.filter(conf => conf.characteristicId == id)[0];
        return !!res;
    }

 loadCharacteristic(){
     const confSub$ = this.store.select(selectConfigurationById(this.configurationId)).subscribe(
         res => {
             this.configuration = res;
             this.dataSource.data = this.configuration.productCharacteristics;

         }
     );
 }
    /**
     * On destroy
     */
    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.forEach(sub => sub.unsubscribe());
        }
    }
    getCharacteristicSelectedEdit(event) {
        this.formGroup.get('editCharacteristic').setValue('')

        if(this.formGroup.controls.editCharacteristic.dirty){
            this.formGroup.controls.editCharacteristic.enable();
            //this.isEditable = false;
            this.characteristics.find(value => {
                if (value.id == event.option.value){
                    this.characteristicFindEdit = value;

                    const controls = this.formGroup.controls;
                    controls.editCharacteristic.setValue(this.characteristicFindEdit.name);
                    this._filter("");
                }
            });
        }




    }
    forbiddenCharacteristicValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            // below findIndex will check if control.value is equal to one of our options or not

            const index = this.characteristics.findIndex(char => char.name === control.value);
            return index < 0 ? { 'forbiddenCharacteristic': { value: control.value } } : null;
        }}
}

