import {createFeatureSelector, createSelector} from "@ngrx/store";
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";
import {AgencyBankState} from "../ek-reducers/agency-bank.reducer";
import {AgencyBankModel} from "../ek-models/agency-bank-model";


export const selectAgencyBankState = createFeatureSelector<AgencyBankState>('agency-bank');

export const selectAgencyBankById = (AgencyBankId: number) => createSelector(
    selectAgencyBankState,
    agencyBankState => {
        if (agencyBankState) return agencyBankState.entities[AgencyBankId];
    }
);

export const selectAgencyBanksPageLoading = createSelector(
    selectAgencyBankState,
    agencyBankState => agencyBankState.listLoading
);

export const selectError = createSelector(
    selectAgencyBankState,
    agencyBankState => agencyBankState.error
);

export const selectLastAction = createSelector(
    selectAgencyBankState,
    agencyBankState => agencyBankState.lastAction
);


export const selectAgencyBanksActionLoading = createSelector(
    selectAgencyBankState,
    agencyBankState => agencyBankState.actionsLoading
);

export const selectLastCreatedAgencyBankId = createSelector(
    selectAgencyBankState,
    agencyBankState => agencyBankState.lastCreatedAgencyBankId
);

export const selectAgencyBanksShowInitWaitingMessage = createSelector(
    selectAgencyBankState,
    agencyBankState => agencyBankState.showInitWaitingMessage
);

export const selectAgencyBanksPageLastQuerys = createSelector(
    selectAgencyBankState,
    agencyBankState => agencyBankState.lastQuery
);

export const getlastAction = createSelector(
    selectAgencyBankState,
    agencyBankState => agencyBankState.lastAction
);

export const selectAgencyBanksInStore = createSelector(
    selectAgencyBankState,
    agencyBankState => {
        const items: AgencyBankModel[] = [];
        each(agencyBankState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: AgencyBankModel[] = httpExtension.sortArray(items, agencyBankState.lastQuery.sortField, agencyBankState.lastQuery.sortOrder);
        return new QueryResultsModel(result, agencyBankState.totalCount, '');
    }
);

