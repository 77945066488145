import {createAction, props} from "@ngrx/store";
import {Update} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";
import {OrderModel2} from "../_models/order2.model";


export const OrdersAllPageRequested = createAction('[Order List Page] Orders all Page Requested ', props<{ page: QueryParamsModel, orderState: string, region: string, startingDate: string, endingDate: string,paymentMethod:string, orderType:string, deliveryType:string }>());
export const OrdersAllPageLoadedSuccessfully = createAction('[Order API] Orders all Page Loaded Successfully ', props<{ orders: OrderModel2[], totalCount: number, page: number }>());
export const OrdersAllPageLoadFailed = createAction('[Order API] Orders1 Page all Request Failed ', props<{ error: any }>());

export const OrdersArchivedAllPageRequested = createAction('[Order archive List Page] Orders all Archived Page Requested ', props<{ page: QueryParamsModel, orderState: string, region: string, startingDate: string, endingDate: string }>());
export const OrdersArchivedAllPageLoadedSuccessfully = createAction('[Order archive  API] Orders archive all Page Loaded Successfully ', props<{ order: OrderModel2[], totalCount: number, page: number }>());
export const OrdersArchivedAllPageLoadFailed = createAction('[Order archive  API] Orders1 archive Page all Request Failed ', props<{ error: any }>());

export const OrdersByClientPageRequested = createAction('[Order List Page] Orders By Client Page Requested ', props<{ page: QueryParamsModel, idClient: number }>());
export const OrdersByClientPageLoadedSuccessfully = createAction('[Order API]  Orders By Client  Page Loaded Successfully ', props<{ orders: OrderModel2[], totalCount: number, page: number }>());
export const OrdersByClientPageLoadFailed = createAction('[Order API]  Orders By Client  Request Failed ', props<{ error: any }>());

export const OrderCreated = createAction('[Order List Page] Order Created ', props<{ order: OrderModel2 }>());
export const OrderCreatedSuccessfully = createAction('[Order List Page] Order Created Successfully ', props<{ order: OrderModel2 }>());
export const OrderCreationFailed = createAction('[Order List Page] Order Creation Failed ', props<{ error: any }>());

export const OrderUpdated = createAction('[Order List Page] Order Updated ', props<{ order: OrderModel2, partialOrder: Update<OrderModel2> }>());
export const OrderUpdatedSuccessfully = createAction('[Order List Page] Order Updated Successfully ', props<{ order: OrderModel2, partialOrder: Update<OrderModel2> }>());
export const OrderUpdateFailed = createAction('[Order List Page] Order Update Failed ', props<{ error: any }>());

export const OrderDeleted = createAction('[Order List Page] Order Deleted ', props<{ orderId: number }>());
export const OrderDeletedSuccessfully = createAction('[Order API] Order deleted Successfully ', props<{ orderId: number }>());
export const OrderDeleteFailed = createAction('[Order List Page] Order delete Failed ', props<{ error: any }>());

export const OrdersByWilayaRequested = createAction('[Order List Page] Order By Wilaya Requested', props<{ pageNumber: number, pageSize: number, sortField: string, sortOrder: string, wilaya: any }>());
export const OrdersByWilayaRequestedSuccessfuly = createAction('[Order List Page] Order By Wilaya Requested Sucess', props<{ orders: OrderModel2[], totalCount: number }>());
export const OrdersByWilayaRequestedFailed = createAction('[Order List Page] Order By Wilaya Requested Failure', props<{ error: any }>());

export const OrderStateFilterRequested = createAction('[Order List Page] Order State Filter Requested', props<{ filter: string }>())
export const OrderStateFilterRequestedSucessfuly = createAction('[Order List Page] Order State Filter Requested Succcess', props<{ orders: OrderModel2[], totalCount: number, page: number }>())
export const OrderStateFilterRequestedFailure = createAction('[Order List Page] Order State Filter Requested Failure', props<{ error: any }>())

export const OrderPrecedentStateChangeRequested = createAction('[OrderPrecedentState] Order Precedent State Change Requested', props<{ orderId: number, event: string }>())
export const OrderPrecedentStateChangeRequestedSucessfuly = createAction('[OrderPrecedentState] Order Precedent State Change Requested Succcess', props<{ state: any }>())
export const OrderPrecedentStateChangeRequestedFailure = createAction('[OrderPrecedentState] Order Precedent State Change Requested Failure', props<{ error: any }>())

export const updateYalidineState = createAction('[Order State] updateYalidineState Change Requested')
export const updateYalidineStateRequestedSucessfully = createAction('[Order State] updateYalidineState Change Requested Succcess', props<{ state: any }>())
export const updateYalidineStateChangeRequestedFailure = createAction('[Order State] updateYalidineState Change Requested Failure', props<{ error: any }>())
