// Angular
import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
// Material
import {MatDialog, MatPaginator, MatSort} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
// RXJS
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
// NGRX
import {Store} from '@ngrx/store';
// State
import {AppState} from '../../../../../../core/reducers';
// CRUD
import {LayoutUtilsService, MessageType} from '../../../../../../core/_base/crud';
//Actions
import {MatTableDataSource} from "@angular/material/table";
import {NgxPermissionsService} from "ngx-permissions";
import {Update} from "@ngrx/entity";
import {EkCustomerAddressEditComponent} from "../ek-customer-address-edit/ek-customer-address-edit.component";
import {ClientModel} from "../../../../../../core/ek-e-commerce/ek-models/client.model";
import {ClientsService} from "../../../../../../core/ek-e-commerce/ek-services/clients.service";
import * as ClientActions from "../../../../../../core/ek-e-commerce/ek-actions/client.actions";
import * as ClientAddressActions from "../../../../../../core/ek-e-commerce/ek-actions/client-address.actions";
import {EkClientAddress} from "../../../../../../core/ek-e-commerce/ek-models/ek-client-address";
import { AddressesService } from '../../../../../../core/e-commerce/_services/addresses.service';

// Services and Models

@Component({
    selector: 'kt-ek-customer-address-list',
    templateUrl: './ek-customer-address-list.component.html',
    styleUrls: ['./ek-customer-address-list.component.scss']
})
export class EkCustomerAddressListComponent implements OnInit {

    //Table fields
    @Input() customer$: Observable<ClientModel>;

    @Input() addressesSubject$: BehaviorSubject<EkClientAddress[]>;

    @Input() client: ClientModel;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    customer: ClientModel;

    addressList: EkClientAddress[];
    displayedColumns = ['select', 'id', 'city', 'province', 'addressLine1', 'phoneNumber', 'actions'];
    dataSource: MatTableDataSource<EkClientAddress>;

    // Filter fields
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;

    // Selection
    selection = new SelectionModel<EkClientAddress>(true, []);

    //AddAndEdit
    formGroup: FormGroup;
    addressForAdd: EkClientAddress;
    addressForEdit: EkClientAddress;

    //Permissions
    private PERMISSIONS = ['ALL_CLIENT', 'UPDATE_CLIENT'];
    canEdit = false;
    currentRole = '';

    private subscriptions: Subscription[] = [];


    constructor(private store: Store<AppState>,
                public dialog: MatDialog,
                private layoutUtilsService: LayoutUtilsService,
                private ngxPermissionService: NgxPermissionsService,
                private clientsService: ClientsService,
                private addressService: AddressesService) {
    }

    ngOnInit(): void {

        this.dataSource = new MatTableDataSource<EkClientAddress>();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        // Init DataSource
        this.customer$.subscribe(res => {

            if (!res) {
                this.customer = this.client;
                this.addressList = this.client.addresses;
                this.dataSource.data = this.client.addresses;
                return;
            }

            this.addressList = res.addresses;
            this.dataSource.data = res.addresses;
            this.customer = res;
        });

        this.checkPermissionToUpdate();
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }


    loadCustomerFromService(customerId) {
        this.clientsService.getById(customerId).subscribe(res => {
            this.addressList = res.addresses;
            this.dataSource.data = res.addresses;
            this.customer = res;
        });
    }

    checkPermissionToUpdate() {
        this.ngxPermissionService.hasPermission(this.PERMISSIONS).then(hasPermission => {
            this.canEdit = hasPermission;
        });
    }


    /**
     * Retirns object for filter
     */
    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

    }


    ngOnDestroy(): void {
        this.subscriptions.forEach(sb => sb.unsubscribe());
    }


    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: EkClientAddress
): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }


    /**
     * Open add spec dialog and save data
     */
    addAddressButtonClick() {
        // tslint:disable-next-line:prefer-const
        this.addressForAdd = new EkClientAddress();
        this.addressForAdd.clear();
        this.addressForAdd.clientId = this.customer.id;
        this.addressForAdd.id = 2;
        const dialogRef = this.dialog.open(EkCustomerAddressEditComponent, {
            data: {
                address: new EkClientAddress(),
                id: undefined,
                isNew: true,
                client: this.customer
            },
            width: '450px'
        });
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                this.addressForAdd.id = res.value.id;
                this.addressForAdd.fullName = res.value.fullName;
                this.addressForAdd.addressLine1 = res.value.addressLine1;
                this.addressForAdd.addressLine2 = res.value.addressLine2;
                this.addressForAdd.country = res.value.country;
                this.addressForAdd.city = res.value.city;
                this.addressForAdd.province = res.value.province;
                this.addressForAdd.phoneNumber = res.value.phoneNumber;
                this.addressForAdd.type = res.value.type;

                this.addressList = Object.assign([], this.addressList);
                this.addressList.push(this.addressForAdd);
                this.dataSource.data = this.addressList;
                this.addressesSubject$.next(this.addressList);

                const saveMessage = `Address has been created, Save to confirm `;
                this.layoutUtilsService.showActionNotification(saveMessage, MessageType.Create, 10000, true, true);
            }
        });
    }

    editAddress(addrss: EkClientAddress) {
        this.addressForEdit = new EkClientAddress();
        this.addressForEdit.id = addrss.id;
        this.addressForEdit.clientId = addrss.clientId;
        const _item = Object.assign({}, addrss);
        const dialogRef = this.dialog.open(EkCustomerAddressEditComponent, {
            data: {
                address: addrss,
                isNew: false,
                client: this.customer
            },
            width: '450px'
        });
        dialogRef.afterClosed().subscribe(res => {
            if (res && res.isUpdated) {
                this.addressForEdit.fullName = res.value.fullName;
                this.addressForEdit.addressLine1 = res.value.addressLine1;
                this.addressForEdit.addressLine2 = res.value.addressLine2;
                this.addressForEdit.country = res.value.country;
                this.addressForEdit.city = res.value.city;
                this.addressForEdit.province = res.value.province;
                this.addressForEdit.phoneNumber = res.value.phoneNumber;
                this.addressForEdit.type = res.value.type;

                // const temp =this.addressList.filter(address => address.id == addrss.id)[0] ;
                this.addressList = this.addressList.map(elem => {
                    if (elem.id == addrss.id)
                        return this.addressForEdit;
                    else
                        return elem;
                });
                this.dataSource.data = this.addressList;
                this.addressesSubject$.next(this.addressList);
                const saveMessage = `Specification has been updated`;
                this.layoutUtilsService.showActionNotification(saveMessage, MessageType.Update, 10000, true, true);
            }
        });
    }

    deleteAddress(address: any) {

        const _title = 'Address Delete';
        const _description = 'Are you sure to permanently delete this address?';
        const _waitDesciption = 'Address is deleting...';
        const _deleteMessage = `Address has been deleted`;

        this.addressService.deleteAddress(address.id).subscribe(
            () => {
                // On successful deletion, update the address list
                this.addressList = this.addressList.filter(value => value.id !== address.id);
                this.dataSource.data = this.addressList;
                this.addressesSubject$.next(this.addressList);  // Notify about the update

                // Show a notification for successful deletion
                this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
            },
            (error) => {
                // Handle error if deletion fails
                const errorMessage = 'Failed to delete address. Please try again later.';
                this.layoutUtilsService.showActionNotification(errorMessage);
            }
        );
    
        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.store.dispatch(ClientAddressActions.EkOneAddressDeleted({id: address.id}));

            // this.addressList = Object.assign([],this.addressList);
            this.addressList = this.addressList.filter(value => value.id != address.id);
            this.dataSource.data = this.addressList;
            // this.addressesSubject$.next(this.addressList);


            // this.store.dispatch(new OneProductSpecificationDeleted({ id: _item.id }));
            this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
            // this.loadSpecsList();
        });

    }

    fetchAddresses() {

    }

    deleteAddresses() {

    }

    save() {
        const updatedCustomer: Update<ClientModel> = {
            id: this.customer.id,
            changes: this.customer
        };
        this.store.dispatch(ClientActions.CustomerUpdated({
            customer: this.customer,
            partialCustomer: updatedCustomer,
        }));
    }

    getAddressCssClassByType(type: string): string {
        switch (type) {
            case 'BILLING':
                return 'success';
            case 'DELIVERY':
                return 'info';
        }
        return 'metal';
    }

}
