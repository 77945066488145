// Angular
import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GestureConfig, MatProgressSpinnerModule, MatSortModule, MatTableModule} from '@angular/material';
import {OverlayModule} from '@angular/cdk/overlay';
// Angular in memory
// Perfect Scroll bar
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
// SVG inline
import {InlineSVGModule} from 'ng-inline-svg';
// Env
// Hammer JS
import 'hammerjs';
// NGX Permissions
import {NgxPermissionsModule} from 'ngx-permissions';
// NGRX
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {RouterState, StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
// State
import {metaReducers, reducers} from './core/reducers';
// Copmponents
import {AppComponent} from './app.component';
// Modules
import {AppRoutingModule} from './app-routing.module';
import {CoreModule} from './core/core.module';
import {ThemeModule} from './views/theme/theme.module';
// Partials
import {PartialsModule} from './views/partials/partials.module';
// Layout Services
import {
	KtDialogService,
	LayoutConfigService,
	LayoutRefService,
	MenuAsideService,
	MenuConfigService,
	MenuHorizontalService, MonthlyPaymentPipe,
	PageConfigService,
	SplashScreenService,
	SubheaderService
} from './core/_base/layout';
// Auth
import {AuthModule} from './views/pages/auth/auth.module';
import {AuthService} from "./core/auth/_services/auth.service";
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';

// CRUD
import {HttpUtilsService, LayoutUtilsService, TypesUtilsService} from './core/_base/crud';
// Config
import {LayoutConfig} from './core/_config/layout.config';
// Highlight JS
import {HIGHLIGHT_OPTIONS, HighlightLanguage} from 'ngx-highlightjs';
import * as typescript from 'highlight.js/lib/languages/typescript';
import * as scss from 'highlight.js/lib/languages/scss';
import * as xml from 'highlight.js/lib/languages/xml';
import * as json from 'highlight.js/lib/languages/json';
import {DatePipe, registerLocaleData} from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import {InjectableRxStompConfig, RxStompService, rxStompServiceFactory} from "@stomp/ng2-stompjs";
import { FaqEffects } from './core/home-page/question-answer/faq.effects';
import {fAQReducer} from "./core/home-page/question-answer/faq.reducer";
import {regionReducer} from "./core/e-commerce/_reducers/region.reducer";
import {RegionEffects} from "./core/e-commerce/_effects/region.effects";
import { parcelReducer } from './core/e-commerce/_reducers/parcel.reducer';
import {ParcelEffects} from "./core/e-commerce/_effects/parcel.effects";
import {parcelStatesReducer} from "./core/e-commerce/_reducers/parceStates.reducers";
import {ParcelStatesEffects} from "./core/e-commerce/_effects/parcelStates.effects";
import { ImageViewerModule } from '@udhsin/ngx-image-viewer';

// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	wheelSpeed: 0.5,
	swipeEasing: true,
	minScrollbarLength: 40,
	maxScrollbarLength: 300,
};
//Adding FR LOCAL
registerLocaleData(localeFr);

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
	// initialize app by loading default demo layout config
	return () => {
		if (appConfig.getConfig() === null) {
			appConfig.loadConfigs(new LayoutConfig().configs);
		}
	};
}

export function hljsLanguages(): HighlightLanguage[] {
	return [
		{name: 'typescript', func: typescript},
		{name: 'scss', func: scss},
		{name: 'xml', func: xml},
		{name: 'json', func: json}
	];
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		ImageViewerModule.forRoot(),
		MatDatepickerModule,
		MatMomentDateModule,
		MatSortModule,
		MatTableModule,
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		NgxPermissionsModule.forRoot(),
		PartialsModule,
		CoreModule,
		OverlayModule,
		StoreModule.forRoot(reducers, {metaReducers}),
		EffectsModule.forRoot([]),
		// we needed to routerState: RouterState.Minimal because we stumbled on a bug while updating to angular 9
		// Ngrx freezes actions recursively, which also freezes Ivy defs generated by compilers. As a result, Ivy runtime fails to write to component def
		// https://github.com/ngrx/platform/issues/2109#issuecomment-582689060
		StoreRouterConnectingModule.forRoot({stateKey: 'router', routerState: RouterState.Minimal}),
		StoreDevtoolsModule.instrument(),
		AuthModule.forRoot(),
		TranslateModule.forRoot(),
		MatProgressSpinnerModule,
		InlineSVGModule.forRoot(),
		ThemeModule,
		StoreModule.forRoot({
			faq: fAQReducer,
			region: regionReducer,
			parcel: parcelReducer,
			parcelStates: parcelStatesReducer
		}),
		EffectsModule.forRoot([
			FaqEffects,
			RegionEffects,
			ParcelEffects,
			ParcelStatesEffects
		]),
	],
	exports: [],
	providers: [
		AuthService,
		LayoutConfigService,
		LayoutRefService,
		MenuConfigService,
		PageConfigService,
		KtDialogService,
		DatePipe,
		SplashScreenService,
		MonthlyPaymentPipe,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: GestureConfig
		},
		{
			// layout config initializer
			provide: APP_INITIALIZER,
			useFactory: initializeLayoutConfig,
			deps: [LayoutConfigService], multi: true
		},
		{
			provide: HIGHLIGHT_OPTIONS,
			useValue: {languages: hljsLanguages}
		},
		// template services
		SubheaderService,
		MenuHorizontalService,
		MenuAsideService,
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService,

/*		//WebSocket conf
		{
			provide: InjectableRxStompConfig,
			useValue: myRxStompConfig
		},*/
		{
			provide: RxStompService,
			useFactory: rxStompServiceFactory,
			deps: [InjectableRxStompConfig]
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
