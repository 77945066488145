import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {QueryParamsModel} from '../../_base/crud';
import {Response} from '../../_base/crud/models/response';
import {Action, createReducer, on} from '@ngrx/store';
import {Discount_CustomerModel} from '../_models/discount_Customer.model';
import * as CustomerDiscountAction from '../_actions/customer-discount.action';
import * as PromoCodeActions from "../_actions/promoCode.action";


export interface CustomerDiscountState extends EntityState<Discount_CustomerModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedDiscountId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;

    //add state date abch nir condition l dispatch 
}

export const adapter: EntityAdapter<Discount_CustomerModel> = createEntityAdapter<Discount_CustomerModel>();

export const initialCustomerDiscountState: CustomerDiscountState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedDiscountId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
});

const customerDiscountReducer = createReducer(
    initialCustomerDiscountState,
    //get all discount client
    on(CustomerDiscountAction.DiscountSearchRequested,
        (state, action) => {
            return {
                ...state,
                actionsloading: true,
                listLoading: true,
                showInitWaitingMessage: true,
                error: null,
                lastQuery: action.page,

            }
        }),
    on(CustomerDiscountAction.DiscountSearchLoadedSuccessfully,
        (state, action) => (adapter.addAll(action.Discount_Customer,
            {
                ...state,
                actionsLoading: false,
                listLoading: false,
                showInitWaitingMessage: false,
            }))),
    on(CustomerDiscountAction.DiscountSearchLoadFailed,
        (state, action) => ({
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),
    /**  creat new discount client  **/
    on(CustomerDiscountAction.CustomerdiscountCreated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(CustomerDiscountAction.CustomerdiscountCreatedSuccessfully,
        (state, action) => (adapter.addOne(action.discountCustumer, {
            ...state,
            actionsLoading: true,
            lastCreatedDiscountId: action.discountCustumer.id,
            lastAction: 'EDIT.ADD_MESSAGE'
        }))),
    on(CustomerDiscountAction.CustomerdiscountCreationFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
    // Update

    on(CustomerDiscountAction.CustomerdiscountUpdated,
        (state) => ({...state, actionsLoading: true, error: null})),
    on(CustomerDiscountAction.CustomerdiscountUpdatedSuccessfully,
        (state, action) => {
            if (action.partialDiscount){
                return (adapter.updateOne(action.partialDiscount, {
                    ...state,
                    actionsLoading: false,
                    lastAction: 'EDIT.UPDATE_MESSAGE',
                }))
            }
        }
    ),
    on(PromoCodeActions.PromoCodeUpdateFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),

//filter by date
    on(CustomerDiscountAction.DiscountClientFilterByDate,
        state => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
        })),
    on(CustomerDiscountAction.DiscountClientFilterByDateSuccessfully,
        (state, action) => {
            if (action.Discount_Client) {
                return (adapter.addAll(action.Discount_Client,
                    {
                        ...state,
                        actionsLoading: false,
                        listLoading: false,
                        showInitWaitingMessage: false,
                        totalCount: action.totalCount,
                        lastQuery: action.lastRequest
                    }));
            }
        }
    ),
    on(CustomerDiscountAction.DiscountClientFilterByDateFailed,
        (state, action) => ({
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),
    // Delete
    on(CustomerDiscountAction.CustomerdiscountDeleted,
        (state, action) => ({...state, actionsLoading: true, lastAction: ''})),
    on(CustomerDiscountAction.CustomerdiscountDeletedSuccessfully,
        (state, action) => (adapter.removeOne(action.discountCustumerId, {
            ...state,
            actionsLoading: false,
            lastAction: 'DELETE.DELETE_DISCOUNTCUSTOMER_SIMPLE.DELETE_SUCCESS'
        }))),
    on(CustomerDiscountAction.CustomerdiscountDeleteFailed,
        (state, action) => ({...state, error: action.error, actionsLoading: false}))
);

export function customerDiscountReducers(state: CustomerDiscountState | undefined, action: Action) {
    return customerDiscountReducer(state, action);
}
