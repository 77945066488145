import {Injectable} from "@angular/core";
import {Actions, createEffect, Effect, ofType} from "@ngrx/effects";
import {Action, Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import {catchError, concatMap, map, switchMap} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {CategoriesService} from "../_services/categories.service";
//Actions
import * as CategoryActions from '../_actions/category.action';
import {HttpEvent, HttpEventType} from "@angular/common/http";
import {CategoryModel} from "../_models/category.model";

@Injectable()
export class CategoryEffects {


    constructor(private actions$: Actions, private categoryService: CategoriesService, private store: Store<AppState>) {
    }


    CategoriesPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CategoryActions.CategoriesPageRequested),
            switchMap((action) =>
                this.categoryService.getAllSorted(action.page.pageNumber, action.page.pageSize, action.page.sortField, action.page.sortOrder, action.page.filter)
                    .pipe(
                        map(categoryPage => CategoryActions.CategoriesPageLoadedSuccessfully(
                            {
                                categories: categoryPage.content,
                                page: categoryPage.totalElements,
                                totalCount: categoryPage.totalElements
                            })),
                        catchError(error => of(CategoryActions.CategoriesPageLoadFailed({error})))
                    ))));

    SubCategoriesActive$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CategoryActions.SubCategoriesActive),
            switchMap((action) =>
                this.categoryService.getSubCategoriesSearch(action.page.pageNumber, action.page.pageSize, action.page.sortField, action.page.sortOrder, action.page.filter)
                    .pipe(
                        map(subCategoryPage => CategoryActions.SubCategoriesActiveLoadedSuccessfully(
                            {
                                categories: subCategoryPage.content,
                                page: subCategoryPage.totalElements,
                                totalCount: subCategoryPage.totalElements
                            })),
                        catchError(error => of(CategoryActions.SubCategoriesActiveLoadFailed({error})))
                    ))));

    CategoryDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CategoryActions.CategoryDeleted),
            switchMap((action) =>
                this.categoryService.delete(action.categoryId)
                    .pipe(
                        map(() => CategoryActions.CategoryDeletedSuccessfully({categoryId: action.categoryId})),
                        catchError(error => of(CategoryActions.CategoryDeleteFailed({error})))
                    ))));
    CategoryUpdatedActivate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CategoryActions.CategoryUpdatedActivate),
            switchMap((action) =>
                this.categoryService.changeActivateCategory(action.category.id,action.activate, action.updater)
                    .pipe(
                        map(category => CategoryActions.CategoryUpdatedActivateSuccessfully({
                            category:action.category,
                            partialCategory: action.partialCategory,
                            activate:action.activate,
                            updater:action.updater
                        })),
                        catchError(error => of(CategoryActions.CategoryUpdateActivateFailed({error})))
                    ))));

    @Effect()
    createCategory$: Observable<Action> = this.actions$.pipe(
        ofType(CategoryActions.CategoryCreated),
        concatMap(action =>
            this.categoryService.createCategory(action.category).pipe(
                map(event => this.getActionFromHttpEvent(event, 'ADD')),
                catchError(error => of(CategoryActions.CategoryCreationFailed({error})))
            )
        )
    );

    @Effect()
    updateCategory: Observable<Action> = this.actions$.pipe(
        ofType(CategoryActions.CategoryUpdated),
        concatMap(action =>
            this.categoryService.updateCategory(action.category).pipe(
                map(event => this.getActionFromHttpEvent(event, 'UPDATE')),
                catchError(error => of(CategoryActions.CategoryCreationFailed({error})))
            )
        )
    );


    private getActionFromHttpEvent(event: HttpEvent<CategoryModel>, action: String) {
        switch (event.type) {
            case HttpEventType.Sent: {
                return CategoryActions.CategoryUploadProgressed({progress: 0});
            }
            case HttpEventType.UploadProgress: {
                return CategoryActions.CategoryUploadProgressed({
                    progress: Math.round((100 * event.loaded) / event.total)
                });
            }
            case HttpEventType.DownloadProgress: {
                return CategoryActions.CategoryUploadProgressed({progress: 100});
            }
            case HttpEventType.ResponseHeader: {
                return CategoryActions.CategoryUploadProgressed({progress: 100})
            }
            case HttpEventType.Response: {
                if (event.status === 200) {
                    if (action == 'ADD')
                        return CategoryActions.CategoryCreatedSuccessfully({category: event['body']['body']});
                    else {
                        return CategoryActions.CategoryUpdatedSuccessfully({
                            category: event['body']['body'],
                            partialCategory: {
                                id: event['body']['body']['id'],
                                changes: event['body']['body']
                            }
                        })
                    }
                } else {
                    if (action === 'ADD')
                        return CategoryActions.CategoryCreationFailed({
                            error: event.statusText
                        });
                    else
                        return CategoryActions.CategoryUpdateFailed({
                            error: event.statusText
                        })
                }
            }
            default: {
                return CategoryActions.CategoryCreationFailed({
                    error: `Unknown Event: ${JSON.stringify(event)}`
                });
            }
        }
    }


}
