import { FoldersService } from "./../../../../../../../core/ek-e-commerce/ek-services/folders.service";
import { DocumentService } from "./../../../../../../../core/services/document.service";
import { DocumentModel } from "./../../../../../../../core/ek-e-commerce/ek-models/document.model";
import { FolderVerificationService } from "./../../../../../../../core/services/folder-verification.service";
import { DocumentErrorMessages } from "./../../../../../../../core/ek-e-commerce/ek-models/ocr-validations";
import { Component, Inject, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

interface DocumentError {
  documentName: string;
  errorMessages: string[];
  documentId?: number;
}

interface openDate {
  idDoc: number;
  date: string;
}

@Component({
  selector: "kt-documents-messages-popup",
  templateUrl: "./documents-messages-popup.component.html",
  styleUrls: ["./documents-messages-popup.component.scss"],
})
export class DocumentsMessagesPopupComponent implements OnInit {
  documentErrorMessages!: DocumentErrorMessages;
  errorMessages: DocumentError[];
  filteredErrorMessages: DocumentError[] = [];

  openDateObj: openDate[] = [];

  public filteredErrorMessages$: BehaviorSubject<DocumentError[]> =
    new BehaviorSubject<DocumentError[]>(null as any);
  folderId: number;
  orderId: number;

  constructor(
    private folderVerificationService: FolderVerificationService,
    private router: Router,
    private dialogRef: MatDialogRef<DocumentsMessagesPopupComponent>,
    private documentService: DocumentService,
    @Inject(MAT_DIALOG_DATA) public data: { orderId: number; folderId: number }
  ) {}

  ngOnInit(): void {
    this.documentService
      .getDocumentsByFolderId(this.data.folderId)
      .subscribe((res: any) => {
        if (res) {
          this.folderVerificationService.documents$.next(res.body);
        }
      });

    this.documentService
      .getValidationResponse(this.data.folderId)
      .subscribe((res) => {
        if (res) {
          this.folderVerificationService.getMessages(res.body);
        }
      });

    this.folderVerificationService.ocrMessages$.subscribe((messages) => {
      if (messages) {
        this.documentErrorMessages = messages;
        this.errorMessages = this.getAllMessages();
        this.getFilteredMessage(this.errorMessages);
      }
    });
  }

  // getMessagesFormated(element: DocumentError): string {
  //   return element.errorMessages.join(', ');
  // }

  getAllMessages(): DocumentError[] {
    let documentErrors: DocumentError[] = [];
    for (const key in this.documentErrorMessages) {
      if (this.documentErrorMessages.hasOwnProperty(key)) {
        const nestedMessages =
          this.documentErrorMessages[key as keyof DocumentErrorMessages];

        let allMessages: string[] = [];
        for (const nestedKey in nestedMessages) {
          if (nestedMessages.hasOwnProperty(nestedKey)) {
            const message =
              nestedMessages[nestedKey as keyof typeof nestedMessages];

            if (message.message !== "") {
              allMessages.push(message.message);
            }
          }
        }
        let documentError: DocumentError = {
          documentName: key,
          errorMessages: allMessages,
        };
        documentErrors.push(documentError);
      }
    }

    return documentErrors;
  }

  getDocumentId(documents: DocumentModel[], documentGender: string): number {
    let id = 0;
    if (documents) {
      let obj = documents.find((o) => o.documentsGender == documentGender);
      id = obj?.id!;
    }
    return id;
  }

  goToChecklist(cardId: number) {
    //get date of opening doc ....
    const localDateObj = JSON.parse(localStorage.getItem("docsOpenedAt"));

    this.openDateObj = localDateObj ? localDateObj : [];

    const date = new Date();

    const formattedDate = date.toISOString().replace("T", " ").slice(0, -1);

    if (this.openDateObj) {
      const itemExists = this.openDateObj.some((obj) => obj.idDoc === cardId);

      //new open doc
      if (!itemExists) {
        this.openDateObj.push({ idDoc: cardId, date: formattedDate });
      }

      localStorage.setItem("docsOpenedAt", JSON.stringify(this.openDateObj));

      this.router.navigateByUrl(
        `/ek-ecommerce/folder/edit/${this.data.orderId}/${this.data.folderId}/checklist/${cardId}`
      );

      this.closeDialog();
    }
  }

  goToStepTwo() {
    this.router.navigateByUrl(
      `/ek-ecommerce/folder/edit/${this.data.orderId}/${this.data.folderId}?step=2`
    );
    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getFilteredMessage(errors: DocumentError[]) {
    this.folderVerificationService.documents$.subscribe((documents) => {
      if (errors) {
        this.filteredErrorMessages = [];
        errors.find((documentError) => {
          switch (documentError.documentName) {
            case "cni": {
              if (
                errors.find(
                  (error) => error.documentName === documentError.documentName
                ).errorMessages.length !== 0
              ) {
                this.filteredErrorMessages.push({
                  documentName: "Carte national recto",
                  errorMessages: errors.find(
                    (error) => error.documentName === documentError.documentName
                  ).errorMessages,
                  documentId: this.getDocumentId(
                    documents,
                    "FRONT_IDENTITY_CARD"
                  ),
                });
              }
              break;
            }
            case "cni_verso": {
              if (
                errors.find(
                  (error) => error.documentName === documentError.documentName
                ).errorMessages.length !== 0
              ) {
                this.filteredErrorMessages.push({
                  documentName: "Carte national verso",
                  errorMessages: errors.find(
                    (error) => error.documentName === documentError.documentName
                  ).errorMessages,
                  documentId: this.getDocumentId(
                    documents,
                    "BACK_IDENTITY_CARD"
                  ),
                });
              }
              break;
            }
            case "extrait_naissance": {
              if (
                errors.find(
                  (error) => error.documentName === documentError.documentName
                ).errorMessages.length !== 0
              ) {
                this.filteredErrorMessages.push({
                  documentName: "Extrait de naissance",
                  errorMessages: errors.find(
                    (error) => error.documentName === documentError.documentName
                  ).errorMessages,
                  documentId: this.getDocumentId(documents, "BIRTH_ACT"),
                });
              }
              break;
            }
            case "fiche_familiale": {
              if (
                errors.find(
                  (error) => error.documentName === documentError.documentName
                ).errorMessages.length !== 0
              ) {
                this.filteredErrorMessages.push({
                  documentName: "Fiche familliale",
                  errorMessages: errors.find(
                    (error) => error.documentName === documentError.documentName
                  ).errorMessages,
                  documentId: this.getDocumentId(documents, "FAMILY_SHEET"),
                });
              }
              break;
            }
            case "residence": {
              if (
                errors.find(
                  (error) => error.documentName === documentError.documentName
                ).errorMessages.length !== 0
              ) {
                this.filteredErrorMessages.push({
                  documentName: "Residence",
                  errorMessages: errors.find(
                    (error) => error.documentName === documentError.documentName
                  ).errorMessages,
                  documentId: this.getDocumentId(documents, "RESIDENCE"),
                });
              }
              break;
            }
            case "chifa": {
              if (
                errors.find(
                  (error) => error.documentName === documentError.documentName
                ).errorMessages.length !== 0
              ) {
                this.filteredErrorMessages.push({
                  documentName: "Carte chifa",
                  errorMessages: errors.find(
                    (error) => error.documentName === documentError.documentName
                  ).errorMessages,
                  documentId: this.getDocumentId(documents, "CARD_CHIFA"),
                });
              }
              break;
            }
            case "cheque": {
              if (
                errors.find(
                  (error) => error.documentName === documentError.documentName
                ).errorMessages.length !== 0
              ) {
                this.filteredErrorMessages.push({
                  documentName: "Cheque barré",
                  errorMessages: errors.find(
                    (error) => error.documentName === documentError.documentName
                  ).errorMessages,
                  documentId: this.getDocumentId(documents, "CROSSED_CHECK"),
                });
              }
              break;
            }
            case "ccp": {
              if (
                errors.find(
                  (error) => error.documentName === documentError.documentName
                ).errorMessages.length !== 0
              ) {
                this.filteredErrorMessages.push({
                  documentName: "Relevé bancaire",
                  errorMessages: errors.find(
                    (error) => error.documentName === documentError.documentName
                  ).errorMessages,
                  documentId: this.getDocumentId(documents, "BANK_STATEMENT"),
                });
              }
              break;
            }
            case "attestation": {
              if (
                errors.find(
                  (error) => error.documentName === documentError.documentName
                ).errorMessages.length !== 0
              ) {
                this.filteredErrorMessages.push({
                  documentName: "Attestation de travail",
                  errorMessages: errors.find(
                    (error) => error.documentName === documentError.documentName
                  ).errorMessages,
                  documentId: this.getDocumentId(documents, "WORK_CERTIFICATE"),
                });
              }
              break;
            }
            case "retraite": {
              if (
                errors.find(
                  (error) => error.documentName === documentError.documentName
                ).errorMessages.length !== 0
              ) {
                this.filteredErrorMessages.push({
                  documentName: "Attestation des revenus",
                  errorMessages: errors.find(
                    (error) => error.documentName === documentError.documentName
                  ).errorMessages,
                  documentId: this.getDocumentId(
                    documents,
                    "INCOME_CERTIFICATE"
                  ),
                });
              }
              break;
            }
            case "rena": {
              if (
                errors.find(
                  (error) => error.documentName === documentError.documentName
                ).errorMessages.length !== 0
              ) {
                this.filteredErrorMessages.push({
                  documentName: "RENA",
                  errorMessages: errors.find(
                    (error) => error.documentName === documentError.documentName
                  ).errorMessages,
                  documentId: this.getDocumentId(documents, "RENA"),
                });
              }
              break;
            }
            case "attestation_revenue_militaire": {
              if (
                errors.find(
                  (error) => error.documentName === documentError.documentName
                ).errorMessages.length !== 0
              ) {
                this.filteredErrorMessages.push({
                  documentName: "Attestation des revenus",
                  errorMessages: errors.find(
                    (error) => error.documentName === documentError.documentName
                  ).errorMessages,
                  documentId: this.getDocumentId(
                    documents,
                    "INCOME_CERTIFICATE_MILITARY"
                  ),
                });
              }
              break;
            }
            case "radiation": {
              if (
                errors.find(
                  (error) => error.documentName === documentError.documentName
                ).errorMessages.length !== 0
              ) {
                this.filteredErrorMessages.push({
                  documentName: "Attestation de radiation",
                  errorMessages: errors.find(
                    (error) => error.documentName === documentError.documentName
                  ).errorMessages,
                  documentId: this.getDocumentId(
                    documents,
                    "TERMINATION_CERTIFICATE"
                  ),
                });
              }
              break;
            }
            case "fiche_paie_1": {
              if (
                errors.find(
                  (error) => error.documentName === documentError.documentName
                ).errorMessages.length !== 0
              ) {
                this.filteredErrorMessages.push({
                  documentName: "Fiche de paie 1",
                  errorMessages: errors.find(
                    (error) => error.documentName === documentError.documentName
                  ).errorMessages,
                  documentId: this.getDocumentId(documents, "PAY_SLIP"),
                });
              }
              break;
            }
            case "fiche_paie_2": {
              if (
                errors.find(
                  (error) => error.documentName === documentError.documentName
                ).errorMessages.length !== 0
              ) {
                this.filteredErrorMessages.push({
                  documentName: "Fiche de paie 2",
                  errorMessages: errors.find(
                    (error) => error.documentName === documentError.documentName
                  ).errorMessages,
                  documentId: this.getDocumentId(documents, "PAYSLIP_TWO"),
                });
              }
              break;
            }
            case "fiche_paie_3": {
              if (
                errors.find(
                  (error) => error.documentName === documentError.documentName
                ).errorMessages.length !== 0
              ) {
                this.filteredErrorMessages.push({
                  documentName: "Fiche de paie 3",
                  errorMessages: errors.find(
                    (error) => error.documentName === documentError.documentName
                  ).errorMessages,
                  documentId: this.getDocumentId(documents, "PAYSLIP_THREE"),
                });
              }
              break;
            }
          }

          this.filteredErrorMessages$.next(this.filteredErrorMessages);
        });
      }
    });
  }
}
