import {createFeatureSelector, createSelector} from "@ngrx/store";
import {OptimisedTrendingConfModel} from "../_models/optimised-trending-conf.model";
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";
import {TrendingConfState} from "../_reducers/product-trending.reducer";

export const selectTrendingConfigurationsState = createFeatureSelector<TrendingConfState>('trending-configuration');
export const selectTrendingPageLoading = createSelector(
    selectTrendingConfigurationsState,
    configurationsState => configurationsState.listLoading
);
export const selectTrendingInStore = createSelector(
    selectTrendingConfigurationsState,
    configurationsState => {
        const items: OptimisedTrendingConfModel[] = [];
        each(configurationsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: OptimisedTrendingConfModel[] = httpExtension.sortArray(items, configurationsState.lastQuery.sortField, configurationsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, configurationsState.totalCount, '');
    }
);
export const selectTrendingInitWaitingMessage = createSelector(
    selectTrendingConfigurationsState,
    configurationsState => configurationsState.showInitWaitingMessage
);
