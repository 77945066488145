import {Injectable} from "@angular/core";
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {Address} from "../_models/address.model";
import {BehaviorSubject, Observable} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";


@Injectable()
export class AddressesService extends AbstractService<Address> {

    url: string;
    addressSubject: any;


    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}addresses`;
        this.addressSubject = new BehaviorSubject([]);


    }

    public deleteAddress(addressId: number): Observable<Address[]> {
        const url = `${this.url}/${addressId}`;  
        return this.http.delete(url).pipe(
          map(() => {
            const updatedAddresses = this.addressSubject.value.filter((address: Address) => address.id !== addressId);
            this.addressSubject.next(updatedAddresses);  
            return updatedAddresses;
          })
        );
      }
    

    public getByClientId(id: number): Observable<Address[]> {
        const url = `${this.url}/client/${id}`;
        return this.http.get<Response<Address[]>>(url).pipe(map(({body}) => body));
    }

    public addClientAddress(newAddress: Address): Observable<Address> {
        return this.save(newAddress).pipe(
            map(response => {
                return response;
            })
        );
        return null;
    }


    public updateClientAddress(updatedAddress): Observable<Address> {
        return this.update(updatedAddress).pipe(
            map(response => {
                return response;
            })
        );
        return null;
    }
}
