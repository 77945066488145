import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {HistoryStateFolderService} from "../ek-services/history-state-folder.service";
import * as changeHistoryActions from "../ek-actions/FolderStateChange.actions";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";


@Injectable()
export class FolderStateChangeEffects {
    constructor(private actions$: Actions,
                private historyStateFolderService: HistoryStateFolderService,
    ) {
    }

    FolderStateChangePageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(changeHistoryActions.FolderStateChangePageRequested),
            switchMap((action) =>
                this.historyStateFolderService.folderStateChangeById(action.idFolder)
                    .pipe(
                        map(data => {
                            return changeHistoryActions.FolderStateChangePageLoadedSuccessfully({
                                FolderStatesChange: data.body.content,
                                totalCount: data.body.totalElements,
                                page : data.body.totalPages
                            });
                        }),
                        catchError(error => of(changeHistoryActions.FolderStateChangePageLoadFailed({error})))
                    ))));

}
