import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'translateNotification'
})
export class TranslateNotificationPipe implements PipeTransform {

  transform(type: string): string {
    switch (type) {
      case 'EMAIL':
        return 'Email';
      case 'SMS':
        return 'SMS';
      case 'EMAIL_SMS':
        return 'Email, SMS';
      case 'NONE':
        return '';
      default:
        return type;
    }
  }

}
