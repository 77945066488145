import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FooterInformationService} from "../../../../../../core/e-commerce";
import {FooterImformationModel} from "../../../../../../core/e-commerce/_models/footerImformation.model";
import {Router} from "@angular/router";
import {LayoutUtilsService, MessageType} from "../../../../../../core/_base/crud";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'kt-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    contactInformationForm: FormGroup;
    footerInformations: FooterImformationModel;

    private PHONE_REGEX = /^(00213|\+213|0)(5|6|7)(\s*?[0-9]\s*?){5,8}$/;

    constructor(
        private footerService: FooterInformationService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private layoutUtilsService: LayoutUtilsService,
        private formBuilder: FormBuilder
    ) {
    }

    ngOnInit(): void {
        this.footerService.getById(1).subscribe(res => {
            this.footerInformations = res
            this.initForm();
            // this.updateFileNames()
            this.cdr.detectChanges()
        });
    }

    initForm() {
        this.contactInformationForm = this.formBuilder.group({
            wissalInfoEmail: [this.footerInformations.wissalInfoEmail, [Validators.required, Validators.email]],
            wissalPhoneNumber: [this.footerInformations.wissalPhoneNumber, Validators.compose([Validators.required, Validators.minLength(9), Validators.pattern(this.PHONE_REGEX)])],
            wissalPhoneNumber2: [this.footerInformations.wissalPhoneNumber2, Validators.compose([Validators.required, Validators.minLength(9), Validators.pattern(this.PHONE_REGEX)])],
            address: [this.footerInformations.address, [Validators.required]],
            longitude: [this.footerInformations.longitude, [Validators.required]],
            latitude: [this.footerInformations.latitude, [Validators.required]],
        });
    }

    prepareFooter(): FooterImformationModel {
        const controls = this.contactInformationForm.controls;
        const _footer = this.footerInformations
        _footer.address = controls.address.value;
        _footer.wissalInfoEmail = controls.wissalInfoEmail.value;
        _footer.wissalPhoneNumber = controls.wissalPhoneNumber.value;
        _footer.wissalPhoneNumber2 = controls.wissalPhoneNumber2.value;
        _footer.latitude = controls.latitude.value;
        _footer.longitude = controls.longitude.value;
        return _footer;
    }

    onSubmitForm() {
        if (this.contactInformationForm.invalid) {
            this.contactInformationForm.markAsTouched();
            return;
        }
        if (!this.contactInformationForm.dirty) {
            const message = `Aucune Modification.`;
            this.layoutUtilsService.showActionNotification(message, MessageType.Create, 10000, true, false);
        } else {
            const _footer = this.prepareFooter();
            this.footerService.updateInfo(_footer).subscribe();
            const message = `Modification avec succès.`;
            this.layoutUtilsService.showActionNotification(message, MessageType.Create, 10000, true, false);
        }
    }

    faq() {
        this.router.navigateByUrl('/ecommerce/home-page/footer/question-answer', {replaceUrl: true});
    }

    legalMention() {
        this.router.navigateByUrl('ecommerce/home-page/footer/legal-mention');
    }

    generalCondition() {

        this.router.navigateByUrl('ecommerce/home-page/footer/general-condition');
    }

}
