// NGRX
import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
// Actions
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {ProductModel} from '../_models/product.model';
import {Response} from "../../_base/crud/models/response";
import * as ProductActions from "../_actions/product.actions";

export interface ProductState extends EntityState<ProductModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedProductId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
}

export const adapter: EntityAdapter<ProductModel> = createEntityAdapter<ProductModel>();
//export const adapter1: EntityAdapter<boolean> = createEntityAdapter<boolean>();

export const initialProductState: ProductState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedProductId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
});

const productReducer = createReducer(
    initialProductState,
    on(ProductActions.ProductsPageRequested,
        (state, action) => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: '',
            lastQuery: action.page
        })),
    on(ProductActions.ProductsPageLoadedSuccessfully,
        (state, action) => (adapter.addAll(action.products,
            {
                ...state,
                actionsLoading: false,
                listLoading: false,
                showInitWaitingMessage: false,
                totalCount: action.totalCount
            }))),
    on(ProductActions.ProductsPageLoadFailed,
        (state, action) => ({
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),
    //creation
    on(ProductActions.ProductCreated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(ProductActions.ProductCreatedSuccessfully,
        (state, action) => (adapter.addOne(action.product, {
            ...state,
            actionsLoading: true,
            lastCreatedProductId: action.product.id,
            lastAction: 'EDIT.ADD_MESSAGE'
        }))),
    on(ProductActions.ProductCreationFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
    //Update
    on(ProductActions.ProductUpdated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(ProductActions.ProductUpdatedSuccessfully,
        (state, action) =>
            (adapter.updateOne(action.partialProduct, {
                ...state,
                actionsLoading: false,
                lastAction: 'EDIT.UPDATE_MESSAGE'
            }))),
    on(ProductActions.ProductUpdateFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),

    // Update activate
    on(ProductActions.ProductUpdatedActivate,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(ProductActions.ProductUpdatedActivateSuccessfully,
        (state, action) =>
            (adapter.updateOne(action.partialProduct, {
                ...state,
                actionsLoading: false,
                lastAction: 'EDIT.UPDATE_MESSAGE'
            }))),
    on(ProductActions.ProductUpdateActivateFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),


    //Delete
    on(ProductActions.ProductDeleted,
        (state, action) => ({...state, actionsLoading: true, lastAction: ''})),
    on(ProductActions.ProductDeletedSuccessfully,
        (state, action) => (adapter.removeOne(action.productId, {
            ...state,
            actionsLoading: false,
            lastAction: 'DELETE.DELETE_CATEGORY_SIMPLE.DELETE_SUCCESS'
        }))),
    on(ProductActions.ProductDeleteFailed,
        (state, action) => ({...state, error: action.error, actionsLoading: false}))
);

export function productsReducer(state: ProductState | undefined, action: Action) {
    return productReducer(state, action)
}
