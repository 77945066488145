import {BaseModel} from "../../_base/crud";
import {ConfigurationModel} from "./configuration.model";

export class OrderItemModel extends BaseModel {
    id: number;
    orderProductId: number;
    quantity: number;
    configurationTO: ConfigurationModel;
    sellingPrice: number;
    deliveryDate: string;
    productName?: string;
    stockDepotId: number;
    stockEkId: number;
    discount: number;

    _isEditMode: boolean;

    clear() {
        this._isEditMode = false;
        this.discount = 0;
    }
}
