export const BANKER_STATES = [
    'EK_BANK_IN_PROGRESS',
    'EK_BANK_RESERVE',
    'EK_GUARTENTEES_PENDING_SIGNATURE',
    'GUARTENTEES_SIGNED_IN_PROGRESS_BANK',
    'EK_CLIENT_FOLDER_CANCELED',
    'BANK_NOTIFICATION',
    'EK_BANK_REFUSED',
    'FINANCIAL_FOLDER',
];
