// Angular
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {AbstractService} from "../../services/abstract-service.service";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {ProductModel} from "..";
import {Observable} from "rxjs";
import {Page} from "../../_base/crud/models/page";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";



@Injectable()
export class ProductsService extends AbstractService<ProductModel> {

    url: string;
    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}products`;
    }

    public findByFilterSorted(page: number = 0, size: number = 100, sorting: string, direction: string, query: any): Observable<Page<ProductModel>> {
        const options = {
            params: new HttpParams()
                .append('pageNo', page.toString())
                .append('pageSize', size.toString())
                .append('sortBy', sorting)
                .append('direction', direction)
                .append('query', query.query)
        };
        const url = `${this.url}/search-sorted`;
        return this.http.get<Response<Page<ProductModel>>>(url, options).pipe(map(({body}) => body));
    }

    public changeActivateProduct(id: number, activate: boolean, updater: string): Observable<Response<ProductModel>> {
        const url = `${this.url}/${id}/${activate}/${updater}`;
        return this.http.patch<Response<ProductModel>>(url,"").pipe(
            map((response: Response<ProductModel>) => {
                if (response.body) {
                    return response;
                } else {
                    throw new Error();
                }
            })
        );
    }

    public generateRefrence() {
        const url = `${this.url}/reference`;
        return this.http.get(url);}
    public nameExists(name: string): Observable<boolean> {
        let params = new HttpParams();
        params = params.append('name', name);
        const url = `${this.url}/name`;
        return this.http.get<Response<boolean>>(url, {params: params}).pipe(
            map((e) => {
                return e.body != null;
            }))
    }
    public add(entity: ProductModel): Observable<ProductModel> {
        let url;
        if (ADMIN_CONFIG.apiEndpoint=='https://adminapi.wissalstore.com:442/'){
            url = 'https://api.wissalstore.com:444'+'/products'
        }
        else if(ADMIN_CONFIG.apiEndpoint=='http://devapi.wissalstore.com:8080/'){
            url = 'http://devapi.wissalstore.com'+'/products'
        }
        else{
            url = this.url;
        }
        const res = this.http.post<Response<ProductModel>>(url, entity).pipe(map(({body}) => body));
        return res;
    }
}
