import {BaseModel} from "../../_base/crud";

export class DiscountModel extends BaseModel {
    id: number;
    startingDate: Date;
    endingDate: Date;
    value: number;
    configurationId: number;
    _isEditMode: boolean;
    status:boolean
    clear() {
        this.value = 0;
        this._isEditMode = false;
    }
}
