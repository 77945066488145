import {createFeatureSelector, createSelector} from "@ngrx/store";
import {ImageModel} from "../_models/image.model";
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";
import {DiscountState} from "../_reducers/discount.reducer";
import {DiscountModel} from "../_models/discount.model";


export const discountState = createFeatureSelector<DiscountState>('discount');

export const selectDiscountById = (discountId: number) => createSelector(
    discountState,
    discountState => discountState.entities[discountId]
);

export const selectDiscountPageLoading = createSelector(
    discountState,
    discountState => discountState.listLoading
);

export const selectError = createSelector(
    discountState,
    discountState => discountState.error
);

export const selectlastAction = createSelector(
    discountState,
    discountState => discountState.lastAction
);

export const selectDiscountsActionLoading = createSelector(
    discountState,
    discountState => discountState.actionsLoading
);

export const selectLastCreatedDiscountId = createSelector(
    discountState,
    discountState => discountState.lastCreatedDiscountId
);

export const selectDiscountsShowInitWaitingMessage = createSelector(
    discountState,
    discountState => discountState.showInitWaitingMessage
);

export const selectDiscountInStore = createSelector(
    discountState,
    discountState => {
        const items: DiscountModel[] = [];
        each(discountState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ImageModel[] = httpExtension.sortArray(items, discountState.lastQuery.sortField, discountState.lastQuery.sortOrder);
        return new QueryResultsModel(result, discountState.totalCount, '');
    }
);
