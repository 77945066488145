import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {ADMIN_CONFIG} from '../../../../environments/environment';
import {Token} from "../_models/token";


@Injectable()
export class AuthService {
    constructor(private http: HttpClient) {}
    // Authentication/Authorization
    login(email: string, password: string): Observable<Token> {

        const options = {
            headers: new HttpHeaders({
                'Authorization': `Basic ${btoa(`${ADMIN_CONFIG.clientId}:${ADMIN_CONFIG.secret}`)}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        };

        const params = new HttpParams()
            .set('username', email)
            .set('password', password)
            .set('grant_type', 'password')
            .toString();

        return this.http.post<Token>(`${ADMIN_CONFIG.apiEndpoint}oauth/token`, params, options)
            .pipe(map(
                (response: Token) => {
                    return response;
                })
            );

    }

}
