import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { User } from '../../../../../../core/auth';
import { RegionRequested, SaveRegionRequested } from '../../../../../../core/e-commerce/_actions/region.actions';
import { RegionDatasource } from '../../../../../../core/e-commerce/_data-sources/region.datasource';
import { RegionModel } from '../../../../../../core/e-commerce/_models/region.model';
import { selectRegionById } from '../../../../../../core/e-commerce/_selectors/region.selectors';
import { RegionService } from '../../../../../../core/e-commerce/_services/region.service';
import { WilayaService } from '../../../../../../core/e-commerce/_services/wilaya.service';
import { AppState } from '../../../../../../core/reducers';

interface Wilaya {
    name: string;
    value: string;
}

@Component({
    selector: 'kt-region-edit',
    templateUrl: './region-edit.component.html',
    styleUrls: ['./region-edit.component.scss']
})
export class RegionEditComponent implements OnInit {

    dataSource: RegionDatasource;
    region: RegionModel = new RegionModel();
    regionForm: FormGroup;
    wilayaFormControl = new FormControl();
    wilayaList: Wilaya[];
    usedWilayas: string[] = [];
    selected: Wilaya[] = [];
    commercials: User[];
    hasErrors = false

    constructor(private store: Store<AppState>,
                private regionFB: FormBuilder,
                private cdr: ChangeDetectorRef,
                private wilayaService: WilayaService,
                private route: ActivatedRoute,
                private regionService: RegionService) {
    }

    ngOnInit(): void {
        this.prepare();
        this.route.paramMap.subscribe(res => {
            if (res.has('id'))
                this.loadRegion(res.get('id'));
        })
    }

    loadRegion(id) {
        this.store.dispatch(RegionRequested({RegionId: id}));
        this.dataSource = new RegionDatasource(this.store);
        this.store.pipe(select(selectRegionById(id))).subscribe(res => {
            if (res != null) {
                this.region = res;
                this.selected = this.region.wilayas.map(value => {
                    return {name: value, value: value}
                })
                this.wilayaFormControl.setValue(this.selected)
                this.createForm()
            }
        });
    }

    createForm() {
        this.regionForm = this.regionFB.group(
            {
                name: [this.region.name, Validators.required],
                commercial: [this.region.marketingAgent, Validators.required],
            }
        )
        this.cdr.detectChanges()
    }

    compareObjects(o1: any, o2: any): boolean {
        return o1 != null && o2 != null && o1.value == o2.value;
    }

    compareCommercial(o1: any, o2: any): boolean {
        return o1 != null && o2 != null && o1.id == o2.id;
    }

    prepare() {
        this.getUsedWilayas()
        this.createForm()
        this.wilayaFormControl.setErrors(Validators.required)
        this.regionService.findAllCommercials().subscribe(res => {
            this.commercials = res
            this.cdr.detectChanges()
        })
        this.wilayaList = this.wilayaService.getWilayasItems().map(value => {
            return {name: value.name, value: value.value}
        });
    }

    saveRegion() {
        if (this.regionForm.valid && this.wilayaFormControl.value.length > 0) {
            this.region.name = this.regionForm.value.name;
            this.region.marketingAgent = this.regionForm.value.commercial;
            this.region.wilayas = this.wilayaFormControl.value.map(value => value.value)
            this.store.dispatch(SaveRegionRequested({Region: this.region}))
        } else {
            this.hasErrors = true
            this.detectInvalid()
        }
    }

    detectInvalid() {
        const control = this.regionForm.controls
        Object.keys(control).forEach(controlName =>
            control[controlName].markAsTouched()
        );
        this.wilayaFormControl.markAsTouched()
    }

    onAlertClose(event) {
        this.hasErrors = false;
    }

    getUsedWilayas() {
        this.regionService.getUsedWilayas().subscribe(res => {
            this.usedWilayas = res['body']
        })
    }

    isWilayaUsed(wilaya) {
        return this.usedWilayas.includes(wilaya) && !this.selected.some(e => e.value == wilaya)
    }

}
