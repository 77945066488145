import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {PointRelaitModel} from "../../../../../core/e-commerce";
import {MatTableDataSource} from "@angular/material/table";
import {PointRelaitService} from "../../../../../core/e-commerce";
import {LayoutUtilsService, MessageType} from "../../../../../core/_base/crud";
import {PhoneNumberService} from "../../../../../core/e-commerce";
import {PhoneNumberModel} from "../../../../../core/e-commerce/_models/phone-number.model";

@Component({
  selector: 'kt-point-relait',
  templateUrl: './point-relait.component.html',
  styleUrls: ['./point-relait.component.scss']
})
export class PointRelaitComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  columndefs : any[] = ['id','Wilaya', 'originalPrixDomicile', 'originalPrixPointRelait', 'remiseDomicile', 'prixDomicile', 'remisePointRelait',  'prixPointrelait', 'updateAt', 'updater'];
  dataSource: MatTableDataSource<PointRelaitModel> = new MatTableDataSource(null);
  phoneNumbers: PhoneNumberModel [] = [];
  updater:string= '';

  constructor(private  pointRelaitService: PointRelaitService,
              private  layoutUtilsService: LayoutUtilsService,
              private  phoneNumberService: PhoneNumberService ) {
  }

  ngOnInit(): void {
      this.updater=JSON.parse(localStorage.getItem('currentUser')).username;
      this.refresh();
  }

    updateDiscountHome(Id: number, discountHome: string,discountDesk :number, updater: string) {
        this.pointRelaitService.updateDiscountById(Id,parseInt(discountHome,10),discountDesk,updater).subscribe(res =>{
            this.layoutUtilsService.showActionNotification('discountHome updated seccessfully', MessageType.Update);
            this.refresh();
        })
    }
    updateDiscountDesk(Id: number, discountHome: number, discountDesk:string, updater: string) {
        this.pointRelaitService.updateDiscountById(Id,discountHome,parseInt(discountDesk,10),updater).subscribe(res =>{
            this.layoutUtilsService.showActionNotification('discountDesk updated seccessfully', MessageType.Update);
            this.refresh();
        })
    }

     refresh(){
         this.pointRelaitService.getAll().subscribe(
             points=>{
                 this.dataSource.data = points;
                 this.dataSource.sort = this.sort;
                 this.dataSource.paginator = this.paginator;
             }
         )
     }

    getByRelayPointId(RelayPointId:number){
          this.phoneNumberService.getByRelayPointId(RelayPointId).subscribe(
          num=>{this.phoneNumbers=num;
                       if (this.phoneNumbers.length==0){console.log("vide")}
                       else{ this.phoneNumbers.forEach(p=>console.log("valeur"+p.phoneNumber));}
                    })
     }

    getDiscountPrice(price,remise){
      return Math.abs(price * (100-remise) / 100);
    }




}
