import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'kt-order-statistic-cart',
    templateUrl: './order-statistic-cart.component.html',
    styleUrls: ['./order-statistic-cart.component.scss']
})
export class OrderStatisticCartComponent implements OnInit {

    @Input() state: string = null;
    @Input() count: number = null;
    @Input() icon: string = null;

    constructor() {
    }

    ngOnInit(): void {
    }

    getTotalColorClass(state) {

        if (state === 'EK_FOLDER_CREATED' || state === "EK_ORDER_PENDING" ||
            state === 'EK_ORDER_CREATED') return 'created'


        if (state === 'EK_FOLDER_IN_PROGRESS' ||
            state === 'EK_BANK_RESERVE' ||
            state === 'EK_FOLDER_RESERVE' ||
            state === 'EK_BANK_IN_PROGRESS' ||
            state === 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS' ||
            state === "EK_ORDER_IN_PREPARATION" ||
            state === "EK_ORDER_IN_DELIVERY" ||
            state === 'EK_BANK_PHYSICAL_FOLDER_RESERVE')
            return 'pending'


        if (state === 'EK_GUARTENTEES_PENDING_SIGNATURE' ||
            state === 'EK_PHYSICAL_FOLDER_IN_PROGRESS' ||
            state === 'EK_BANK_PHYSICAL_FOLDER_ACCEPTED') return 'confirmed'


        if (state === 'EK_GUARTENTEES_SIGNED_IN_PROGRESS' ||
            state === 'EK_WAITING_FOR_CLIENT' ||
            state == 'EK_PHYSICAL_FOLDER_IN_DELIVERY' ||
            state === 'EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY') return 'in-delivering'


        if (state === 'EK_PHYSICAL_FOLDER_PENDING' ||
            state === 'EK_PHYSICAL_FOLDER_PENDING' ||
            state === 'EK_PHYSICAL_FOLDER_PICKEDUP' ||
            state === 'EK_ORDER_DELIVERED' ||
            state === 'EK_PHYSICAL_FOLDER_DELIVERED') return 'delivered'

        if (state === 'NOT_PAID_RETURNED') return 'returned'


        if (state === 'TRASH' ||
            state === 'EK_ADMIN_FOLDER_CANCELED' ||
            state === 'EK_PHYSICAL_FOLDER_REFUSED' ||
            state === 'EK_BANK_PHYSICAL_FOLDER_REFUSED' ||
            state === 'EK_FOLDER_REFUSED' ||
            state === 'EK_CLIENT_FOLDER_CANCELED' ||
            state === 'EK_FOLDER_PICKUP_FAILED' ||
            state === 'EK_CLIENT_NO_RESPONSE' ||
            state === 'EK_BANK_REFUSED' ||
            state === 'EK_PHYSICAL_FOLDER_REFUSED' ||
            state === 'EK_ADMIN_FOLDER_CANCELED' ||
            state === 'EK_CLIENT_ORDER_CANCELED' ||
            state === 'EK_BANK_PHYSICAL_FOLDER_REFUSED') return 'canceled'
    }


    getIcon(state) {


        if (state === 'EK_FOLDER_CREATED' || state === 'EK_ORDER_PENDING' ||
            state === 'EK_ORDER_CREATED') return "assets/media/icons/edit-grey.svg";


        if (state === 'EK_FOLDER_IN_PROGRESS' ||
            state === 'EK_BANK_RESERVE' ||
            state === 'EK_FOLDER_RESERVE' ||
            state === 'EK_BANK_IN_PROGRESS' ||
            state === 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS' ||
            state === "EK_ORDER_IN_PREPARATION" ||
            state === "EK_ORDER_IN_DELIVERY" ||
            state === 'EK_BANK_PHYSICAL_FOLDER_RESERVE')
            return "assets/media/icons/settings.svg"


        if (state === 'EK_GUARTENTEES_PENDING_SIGNATURE' ||
            state === 'EK_PHYSICAL_FOLDER_IN_PROGRESS' ||
            state === 'EK_BANK_PHYSICAL_FOLDER_ACCEPTED') return "assets/media/icons/edit-grey.svg"


        if (state === 'EK_GUARTENTEES_SIGNED_IN_PROGRESS' ||
            state === 'EK_WAITING_FOR_CLIENT' ||
            state == 'EK_PHYSICAL_FOLDER_IN_DELIVERY' ||
            state === 'EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY') return "assets/media/icons/settings.svg"


        if (state === 'EK_PHYSICAL_FOLDER_PENDING' ||
            state === 'EK_PHYSICAL_FOLDER_PENDING' ||
            state === 'EK_PHYSICAL_FOLDER_PICKEDUP' ||
            state === 'EK_ORDER_DELIVERED' ||
            state === 'EK_PHYSICAL_FOLDER_DELIVERED') return "assets/media/icons/settings.svg"

        if (state === 'NOT_PAID_RETURNED') return "assets/media/icons/settings.svg"


        if (state === 'TRASH' ||
            state === 'EK_ADMIN_FOLDER_CANCELED' ||
            state === 'EK_PHYSICAL_FOLDER_REFUSED' ||
            state === 'EK_BANK_PHYSICAL_FOLDER_REFUSED' ||
            state === 'EK_FOLDER_REFUSED' ||
            state === 'EK_CLIENT_FOLDER_CANCELED' ||
            state === 'EK_FOLDER_PICKUP_FAILED' ||
            state === 'EK_CLIENT_NO_RESPONSE' ||
            state === 'EK_BANK_REFUSED' ||
            state === 'EK_CLIENT_ORDER_CANCELED' ||
            state === 'EK_PHYSICAL_FOLDER_REFUSED' ||
            state === 'EK_ADMIN_FOLDER_CANCELED' ||
            state === 'EK_BANK_PHYSICAL_FOLDER_REFUSED') return "assets/media/icons/light-delete.svg"
    }


    getleftBorderColor(state) {

        if (state === 'EK_FOLDER_CREATED' || state === "EK_ORDER_PENDING" ||
            state === 'EK_ORDER_CREATED') return 'createdBorder'


        if (state === 'EK_FOLDER_IN_PROGRESS' ||
            state === 'EK_BANK_RESERVE' ||
            state === 'EK_FOLDER_RESERVE' ||
            state === 'EK_BANK_IN_PROGRESS' ||
            state === 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS' ||
            state === "EK_ORDER_IN_PREPARATION" ||
            state === "EK_ORDER_IN_DELIVERY" ||
            state === 'EK_BANK_PHYSICAL_FOLDER_RESERVE')
            return 'pendingBorder'


        if (state === 'EK_GUARTENTEES_PENDING_SIGNATURE' ||
            state === 'EK_PHYSICAL_FOLDER_IN_PROGRESS' ||
            state === 'EK_BANK_PHYSICAL_FOLDER_ACCEPTED') return 'confirmedBorder'


        if (state === 'EK_GUARTENTEES_SIGNED_IN_PROGRESS' ||
            state === 'EK_WAITING_FOR_CLIENT' ||
            state == 'EK_PHYSICAL_FOLDER_IN_DELIVERY' ||
            state === 'EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY') return 'in-deliveringBorder'


        if (state === 'EK_PHYSICAL_FOLDER_PENDING' ||
            state === 'EK_PHYSICAL_FOLDER_PENDING' ||
            state === 'EK_PHYSICAL_FOLDER_PICKEDUP' ||
            state === 'EK_ORDER_DELIVERED' ||
            state === 'EK_PHYSICAL_FOLDER_DELIVERED') return 'deliveredBorder'

        if (state === 'NOT_PAID_RETURNED') return 'returnedBorder'


        if (state === 'TRASH' ||
            state === 'EK_ADMIN_FOLDER_CANCELED' ||
            state === 'EK_PHYSICAL_FOLDER_REFUSED' ||
            state === 'EK_BANK_PHYSICAL_FOLDER_REFUSED' ||
            state === 'EK_FOLDER_REFUSED' ||
            state === 'EK_CLIENT_FOLDER_CANCELED' ||
            state === 'EK_FOLDER_PICKUP_FAILED' ||
            state === 'EK_CLIENT_NO_RESPONSE' ||
            state === 'EK_BANK_REFUSED' ||
            state === 'EK_PHYSICAL_FOLDER_REFUSED' ||
            state === 'EK_ADMIN_FOLDER_CANCELED' ||
            state === 'EK_CLIENT_ORDER_CANCELED' ||
            state === 'EK_BANK_PHYSICAL_FOLDER_REFUSED') return 'canceledBorder'
    }

    getDscription(state) {
        switch (state) {
            case 'EK_FOLDER_CREATED':
                return 'Cette section affiche les dossiers qui sont en cours de création par un client digital ou via le point de vente (POS)';
                break;
            case 'EK_ORDER_PENDING':
                return 'Cette section affiche les commandes qui sont en attente par un client digital ou via le point de vente (POS)';
                break;
            case 'EK_FOLDER_IN_PROGRESS':
                return 'Cette section dédiée aux dossiers en cours de traitement en tant qu\'analyste de crédit.';
                break;
            case 'TRASH':
                return 'Cette section affiche les dossiers de crédit qui sont actuellement en attente de votre examen en tant qu\'analyste de crédit';
                break;
        }
    }

}
