// Angular
import {Injectable} from '@angular/core';
// RxJS
import {catchError, map, switchMap} from 'rxjs/operators';
// NGRX
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// State
import {AppState} from '../../../core/reducers';
// Actions
import {of} from 'rxjs';
import {EkProductsService} from "../ek-services/ek-product.service";
import * as EkProductActions from "../ek-actions/ek-product.actions";
import {LayoutUtilsService} from "../../_base/crud";

@Injectable()
export class EkProductEffects {


    constructor(private actions$: Actions, private productService: EkProductsService, private layoutUtilsService: LayoutUtilsService, private store: Store<AppState>) {
    }

    EkProductPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EkProductActions.EkProductsPageRequested),
            switchMap((action) =>
                this.productService.findByFilterSorted(action.page.pageNumber, action.page.pageSize, action.page.sortField, action.page.sortOrder, action.page.filter)
                    .pipe(
                        map(productPage => {
                            return EkProductActions.EkProductsPageLoadedSuccessfully(
                                {
                                    products: productPage.content,
                                    page: productPage.totalElements,
                                    totalCount: productPage.totalElements
                                })
                        }),
                        catchError(error => of(EkProductActions.EkProductsPageLoadFailed({error})))
                    ))));

}
