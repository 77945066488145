import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";
import {Action, createReducer, on} from "@ngrx/store";
import * as notificationRoleActions from "../ek-actions/notificationRole.actions";
import {NotificationRole} from "../ek-models/NotificationRole";

export interface NotificationRoleState extends EntityState<NotificationRole> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedNotificationId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
    startingDate: string;
    endingDate: string;
}

export const adapter: EntityAdapter<NotificationRole> = createEntityAdapter<NotificationRole>();

export const initialNotificationRoleState: NotificationRoleState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedNotificationId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
    entities: [],
    startingDate: '',
    endingDate: '',
});

const NotificationRoleReducer = createReducer(
    initialNotificationRoleState,
    //NotificationRole list
    on(notificationRoleActions.notificationManagementPageRequested, (state, action) => ({
        ...state,
        actionsLoading: true,
        listLoading: true,
        showInitWaitingMessage: true,
        error: null,
        lastAction: '',
    })),
    on(notificationRoleActions.notificationManagementPageLoadedSuccessfully, (state, action) =>
        adapter.addAll(action.notificationListing, {
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            totalCount: action.totalCount,
        })
    ),
    on(notificationRoleActions.notificationManagementPageLoadFailed, (state, action) => ({
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
        error: action.error,
    })),
);

export function notificationRolesReducer(state: NotificationRoleState | undefined, action: Action) {
    return NotificationRoleReducer(state, action)
}
