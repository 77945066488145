// Angular
import {Component, Input, OnInit} from '@angular/core';
// RxJS
import {BehaviorSubject, Observable, of} from 'rxjs';
// NGRX
import {Store} from '@ngrx/store';
// Lodash
import {each, find, remove} from 'lodash';
// State
import {AppState} from '../../../../../../core/reducers';
// Auth
import {Role, User} from '../../../../../../core/auth';
import {RolesTable} from "../../../../../../core/auth/_server/roles.table";
import {NgxPermissionsService} from "ngx-permissions";
import {ActivatedRoute} from "@angular/router";
import {UserService} from "../../../../../../core/auth/_services/user.service";

@Component({
    selector: 'kt-user-roles-list',
    templateUrl: './user-roles-list.component.html',
    styleUrls: ['./user-roles-list.component.scss'],

})
export class UserRolesListComponent implements OnInit {
    // Public properties
    // Incoming data
    @Input() loadingSubject = new BehaviorSubject<boolean>(false);
    @Input() rolesSubject: BehaviorSubject<string>;
    @Input() User:User
    // Roles
    allUserRoles$: Observable<Role[]>;
    allRoles: Role[] = [];
    unassignedRoles: Role[] = [];
    assignedRoles: Role[] = [];
    roleIdForAdding: string;
    usersResult: User[] = [];
    rolesUser:string = '';
    /**
     * Component constructor
     *
     * @param store: Store<AppState>
     */
    constructor(private store: Store<AppState>,
                private activatedRoute: ActivatedRoute,
                private userService:UserService,
                private ngxPermissions: NgxPermissionsService) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        const id = this.activatedRoute.snapshot.paramMap.get('id');
        let num = Number(id);

        this.userService.getUserById(num).subscribe(res=>{
            this.rolesUser=res.roles
            return this.rolesUser
        })

        this.ngxPermissions.hasPermission("ROLE_SUPERADMIN").then(permission=>{
            if (permission){
                this.allUserRoles$ = of(RolesTable.roles);


            }
            else
                this.allUserRoles$ = of(RolesTable.roles.filter(role => role.id != 'ROLE_SUPERADMIN'))
            this.allUserRoles$.subscribe((res: Role[]) => {

                each(res, (_role: Role) => {
                    this.allRoles.push(_role);
                    this.unassignedRoles.push(_role);
                });

                each(this.rolesSubject.value, (roleId: string) => {
                    const role = find(this.allRoles, (_role: Role) => {
                        return _role.id === roleId;
                    });

                    if (role) {
                        this.assignedRoles.push(role);
                        remove(this.unassignedRoles, (el) => el.id === role.id);
                    }
                });
            });
        });
    }

    /**
     * Assign role
     */
    assignRole() {


        if (this.roleIdForAdding === undefined) {
            return;
        }

        const role = find(this.allRoles, (_role: Role) => {
            return _role.id === (this.roleIdForAdding);
        });

        if (role) {
            this.assignedRoles.push(role);
            remove(this.unassignedRoles, (el) => el.id === role.id);
            this.roleIdForAdding = '';
            this.updateRoles();
        }

    }

    /**
     * Unassign role
     *
     * @param role: Role
     */
    unassingRole(role: Role) {
        this.roleIdForAdding = '';
        this.unassignedRoles.push(role);
        remove(this.assignedRoles, el => el.id === role.id);
        this.updateRoles();
    }

    /**
     * Update roles
     */
    updateRoles() {
        // const _roles = [];
        // each(this.assignedRoles, elem => _roles.push(elem.id));
        // this.rolesSubject.next(_roles);
    }
}
