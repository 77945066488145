import { AgencyBankListModel } from './../../../../../../../core/ek-e-commerce/ek-models/agency-bank-List-model';
import { FoldersService } from './../../../../../../../core/ek-e-commerce/ek-services/folders.service';
import { AgencyBankService } from './../../../../../../../core/ek-e-commerce/ek-services/agency-bank.service';
import { SelectionModel } from '@angular/cdk/collections';
import { EkSnackBarService } from './../../../../../../../core/services/ek-snackBar.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'kt-agencies-bank-popup',
  templateUrl: './agencies-bank-popup.component.html',
  styleUrls: ['./agencies-bank-popup.component.scss']
})
export class AgenciesBankPopupComponent implements OnInit {

  dataSource: MatTableDataSource<AgencyBankListModel>;
  selection: SelectionModel<AgencyBankListModel>;

  displayedColumns = ['select', 'id', 'firstNameManager', 'lastNameManager', 'code', 'address', 'phone', 'fax', 'num_folder'];
  currentAgencyId: any;



  constructor(
    public dialogRef: MatDialogRef<AgenciesBankPopupComponent>,
    private ekSnackBar: EkSnackBarService,
    private agencyBankService: AgencyBankService,
    private folderService: FoldersService,
    @Inject(MAT_DIALOG_DATA) public data: number[]
  ) {
    this.dataSource = new MatTableDataSource<AgencyBankListModel>();
    this.selection = new SelectionModel<AgencyBankListModel>(true, []);
   }

  ngOnInit(): void {
    
    this.agencyBankService.getAllAgencyBankWithNumFolder().subscribe(res => {
      if(res) {
        this.dataSource.data = res.body
      }
  })
  }

  selectRow(event: any, row: any) {
    this.currentAgencyId = row.id
  }

  assignAgency() {
    this.folderService.affectAgency(this.data, this.currentAgencyId).subscribe({
      next : (res) => {
        if(res) {
          const msg = "Les dossiers ont été affecté avec succés"
          this.ekSnackBar.openSnackBar(msg, ' ', 'error-snackbar')
          this.dialogRef.close(true)
        } else {
          const msg = "Une erreur est survenu"
          this.ekSnackBar.openSnackBar(msg, ' ', 'error-snackbar')
          this.dialogRef.close(false)
        }
      },
      error: (error) => {
        const msg = "Une erreur est survenu"
          this.ekSnackBar.openSnackBar(msg, ' ', 'error-snackbar')
          this.dialogRef.close()
      }
    })
  }

  closeDialog() {
    this.dialogRef.close()
  }

}
