import {createFeatureSelector, createSelector} from "@ngrx/store";
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";
import {ChangeHistoryModel} from "../ek-models/change-history-model";
import {FolderStateChangeState} from "../ek-reducers/FolderStateChange.reducers";


export const selectFolderStateChangeState = createFeatureSelector<FolderStateChangeState>('folder-state-change');

export const selectPOSById = (posId: number) => createSelector(
    selectFolderStateChangeState,
    FolderStateChangeState => {
        if (FolderStateChangeState) return FolderStateChangeState.entities[posId];
    }
);

export const selectFolderStateChangePageLoading = createSelector(
    selectFolderStateChangeState,
    FolderStateChangeState => FolderStateChangeState.listLoading
);

export const selectError = createSelector(
    selectFolderStateChangeState,
    FolderStateChangeState => FolderStateChangeState.error
);

export const selectLastAction = createSelector(
    selectFolderStateChangeState,
    FolderStateChangeState => FolderStateChangeState.lastAction
);


export const selectFolderStateChangeActionLoading = createSelector(
    selectFolderStateChangeState,
    FolderStateChangeState => FolderStateChangeState.actionsLoading
);

export const selectLastCreatedPOSId = createSelector(
    selectFolderStateChangeState,
    FolderStateChangeState => FolderStateChangeState.lastCreatedPOSId
);

export const selectFolderStateChangeShowInitWaitingMessage = createSelector(
    selectFolderStateChangeState,
    FolderStateChangeState => FolderStateChangeState.showInitWaitingMessage
);

export const selectFolderStateChangePageLastQuerys = createSelector(
    selectFolderStateChangeState,
    FolderStateChangeState => FolderStateChangeState.lastQuery
);

export const selectFolderStateChangeInStore = createSelector(
    selectFolderStateChangeState,
    FolderStateChangeState => {
        const items: ChangeHistoryModel[] = [];
        each(FolderStateChangeState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ChangeHistoryModel[] = httpExtension.sortArray(items, FolderStateChangeState.lastQuery.sortField, FolderStateChangeState.lastQuery.sortOrder);
        return new QueryResultsModel(result, FolderStateChangeState.totalCount, '');
    }
);
