// NGRX
import {createAction, props} from '@ngrx/store';
// Models
import {Update} from '@ngrx/entity';
import {QueryParamsModel} from "../../_base/crud";
import {BestSellerModel} from "../_models/bestSeller.model";

export const BestSellerPageRequested = createAction('[BestSeller List Page] BestSeller Page Requested ',props<{page: QueryParamsModel}>());
export const BestSellerPageLoadedSuccessfully = createAction('[BestSeller API] BestSeller Page Loaded Successfully ',props<{bestSeller: BestSellerModel[],totalCount: number, page: number}>());
export const BestSellerPageLoadFailed = createAction('[BestSeller API] BestSeller Page Request Failed ',props<{error: any}>());

export const BestSellerCreated = createAction('[BestSeller List Page] BestSeller Created ',props<{bestSeller: BestSellerModel}>());
export const BestSellerCreatedSuccessfully = createAction('[BestSeller List Page] BestSeller Created Successfully ',props<{bestSeller: BestSellerModel}>());
export const BestSellerCreationFailed = createAction('[BestSeller List Page] BestSeller Creation Failed ',props<{error: any}>());

export const BestSellerUpdated = createAction('[BestSeller List Page] BestSeller Updated ',props<{bestSeller: BestSellerModel, partialBestSeller: Update<BestSellerModel>}>());
export const BestSellerUpdatedSuccessfully = createAction('[BestSeller List Page] BestSeller Updated Successfully ',props<{bestSeller: BestSellerModel, partialBestSeller: Update<BestSellerModel>}>());
export const BestSellerUpdateFailed = createAction('[BestSeller List Page] BestSeller Update Failed ',props<{error: any}>());


export const BestSellerDeleted = createAction('[BestSeller List Page] BestSeller Deleted ',props<{bestSellerId: number}>());
export const BestSellerDeletedSuccessfully = createAction('[BestSeller List Page] BestSeller deleted Successfully ',props<{bestSellerId: number}>());
export const BestSellerDeleteFailed = createAction('[BestSeller List Page] BestSeller delete Failed ',props<{error: any}>());


