import {Injectable} from "@angular/core";
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {BrandModel} from "../_models/brand.model";

@Injectable()
export class BrandsService extends AbstractService<BrandModel>{

    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}brands`;
    }

}
