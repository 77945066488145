import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'orderState',
})
export class OrderStatePipe  implements PipeTransform {

    transform(value : string): string {
        let orderState = "-";
        switch (value) {
            case 'EK_ORDER_PENDING':
                return 'Commande en attente';
            case 'EK_ORDER_CREATED':
                return 'Commande créée';
            case 'EK_ORDER_IN_PREPARATION':
                return 'Commande en préparation';
            case 'EK_ORDER_IN_DELIVERY':
                return 'Livraison en cours';
            case 'DELIVERED_POS':
                return 'Pos livré';
            case 'EK_ORDER_DELIVERED':
                return 'Client livré';
            case 'EK_CLIENT_ORDER_CANCELED':
                return 'Commande annulée';

        }
    }
}
