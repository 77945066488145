import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {QuestionAnswerDatasource} from "../../../../../../../core/home-page/question-answer/questionAnswerDatasource";
import {FormControl} from "@angular/forms";
import {fromEvent, merge, Observable, of, Subscription} from "rxjs";
import {SelectionModel} from "@angular/cdk/collections";
import {QuestionAnswerModel} from "../../../../../../../core/home-page/question-answer/questionanswer.model";
import {LayoutUtilsService, MessageType, QueryParamsModel} from "../../../../../../../core/_base/crud";
import {MatSort, SortDirection} from "@angular/material/sort";
import {ActivatedRoute, Router} from "@angular/router";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../../../../../../core/reducers";
import {SubheaderService} from "../../../../../../../core/_base/layout";
import { QuestionAnswerService} from "../../../../../../../core/e-commerce";
import {debounceTime, distinctUntilChanged, skip, tap} from "rxjs/operators";
import {
  selectFAQsInitWaitingMessage,
  selectFAQsPageLastQuery
} from "../../../../../../../core/home-page/question-answer/faq.selectors";
import {FAQDeleted, FAQPageRequested} from "../../../../../../../core/home-page/question-answer/faq.actions";
import {MatPaginator} from "@angular/material/paginator";
import {MatDialog} from "@angular/material/dialog";
import {EkFooterInformationService} from "../../../../../../../core/ek-e-commerce/ek-services/ek-footer-information.service";

@Component({
  selector: 'kt-ek-question-answer',
  templateUrl: './ek-question-answer.component.html',
  styleUrls: ['./ek-question-answer.component.scss']
})
export class EkQuestionAnswerComponent implements OnInit {
  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('sort1', {static: true}) sort: MatSort;

  dataSource: QuestionAnswerDatasource;
  faqId: number;
  filterStatus = new FormControl('');
  displayedColumns = ['id', 'question', 'answer', 'questionType', 'actions'];
  private subscriptions: Subscription[] = [];
  filterCondition = '';

  loading$: Observable<boolean> = of(true);
  selection = new SelectionModel<QuestionAnswerModel>(true, []);
  faqResult: QuestionAnswerModel[] = [];
  lastQuery: QueryParamsModel;
  direction: SortDirection;
  currentRole = '';

  constructor(public dialog: MatDialog,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private store: Store<AppState>,
              private subheaderService: SubheaderService,
              private layoutUtilsService: LayoutUtilsService,
              private questionAnswerService: QuestionAnswerService,
              private footerService :EkFooterInformationService

  ) {
  }

  ngOnInit(): void {
    this.loadFAQPage();
    this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.subscriptions.push(sortSubscription);

    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
        tap(() => this.loadFAQPage())
    )
        .subscribe();

    this.filterStatus.valueChanges.subscribe( () => {
      this.paginator.pageIndex = 0;
      this.loadFAQPage();
    });
    this.subheaderService.setTitle('FAQs');

    this.dataSource = new QuestionAnswerDatasource(this.store);
    // this.dataSource = new FAQDatasource( this.faqService, this.store);
    const entitiesSubscription = this.dataSource.entitySubject.pipe(
        skip(1),
        distinctUntilChanged()
    ).subscribe(res => {
      this.faqResult = res;
    });
    this.subscriptions.push(entitiesSubscription);

    const lastQuerySubscription = this.store.pipe(select(selectFAQsPageLastQuery)).subscribe(res => this.lastQuery = res);
    // Load last query from store
    this.subscriptions.push(lastQuerySubscription);
    this.loadFAQPage(this.lastQuery);


    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadFAQPage();
        })
    )
        .subscribe();


    const initWaitingMessageSubscription = this.store.pipe(select(selectFAQsInitWaitingMessage)).subscribe(
        v =>  {
          if(!v) {
            this.loading$ = of(false);
          }
        }
    )


    const routeSubscription = this.activatedRoute.queryParams.subscribe(params => {
      if (params.id) {
        this.restoreState(this.lastQuery, +params.id);
      }

      const pageSize = this.lastQuery.pageSize;
      const pageNumber = this.lastQuery.pageNumber;
      this.paginator._changePageSize(pageSize);
      this.paginator.pageIndex = pageNumber;
      this.loadFAQPage(this.lastQuery);
    });

    this.paginator._intl.itemsPerPageLabel="Eléments par page";
    this.subscriptions.push(paginatorSubscriptions, lastQuerySubscription, initWaitingMessageSubscription, routeSubscription, searchSubscription );
  }

  showNotification(messageText: string, messageType: MessageType) {
    this.layoutUtilsService.showActionNotification(messageText, messageType);

  }
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  loadFAQPage(filter?: QueryParamsModel) {
    this.selection.clear();
    let queryParams: any;
    if(filter) {
      queryParams = filter;
    } else {
      queryParams = new QueryParamsModel(
          this.filterConfiguration(),
          this.sort.direction,
          this.sort.active,
          this.paginator.pageIndex,
          this.paginator.pageSize
      );
    }
    // console.log(
    //     queryParams);
    this.store.dispatch(FAQPageRequested({ page: queryParams}));
    this.selection.clear();
  }
  deleteFAQ(id: number) {
    this.store.dispatch(FAQDeleted({FAQId: id}))
    this.showNotification('Element Deleted Successfully !', MessageType.Delete);
  }

  editFAQ(id) {
    this.router.navigate(['/service-client/ek-question-answer/edit', id], { relativeTo: this.activatedRoute });
  }




  filterConfiguration(): any {
    const filter: any = {};
    if (this.filterStatus.value && this.filterStatus.value != '') {
      filter.status = this.filterStatus.value;
      // filter.status.push(this.filterStatus.value);
    }

    filter.query = this.searchInput.nativeElement.value;
    return filter;
  }

  restoreState(queryParams: QueryParamsModel, id: number) {

    if (!queryParams.filter) {
      return;
    }

    if ('status' in queryParams.filter) {
      this.filterStatus = queryParams.filter.status;
    }


  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.faqResult.forEach(row => this.selection.select(row));
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.faqResult.length;
    return numSelected === numRows;
  }

  ngAfterViewInit(): void {
  }

  uploadFAQ(file) {
    this.footerService.updateQuestions(file).subscribe(res =>{
      this.loadFAQPage();
    })
  }

  downloadQuestions(){
    const message = `Telechargement des questions.`;
    this.layoutUtilsService.showActionNotification(message, MessageType.Create, 3000, true, false);
    this.footerService.downloadQuestion();
  }

}
