import {createFeatureSelector, createSelector} from "@ngrx/store";
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";
import {OrderSplitState} from "../ek-reducers/orderSplit.reducers";
import {OrderSplitAdmin} from "../ek-models/OrderSplitAdmin";


export const selectOrderSplitState = createFeatureSelector<OrderSplitState>('order-splits');

export const selectOrderSplitById = (folderId: number) => createSelector(
    selectOrderSplitState,
    foldersState => {
        if (foldersState) return foldersState.entities[folderId];
    }
);

export const selectOrderSplitsPageLoading = createSelector(
    selectOrderSplitState,
    foldersState => foldersState.listLoading
);

export const selectError = createSelector(
    selectOrderSplitState,
    foldersState => foldersState.error
);

export const selectLastAction = createSelector(
    selectOrderSplitState,
    foldersState => foldersState.lastAction
);


export const selectOrderSplitActionLoading = createSelector(
    selectOrderSplitState,
    foldersState => foldersState.actionsLoading
);

export const selectLastCreatedOrderSplitId = createSelector(
    selectOrderSplitState,
    foldersState => foldersState.lastCreatedOrderSplitId
);

export const selectOrderSplitsShowInitWaitingMessage = createSelector(
    selectOrderSplitState,
    foldersState => foldersState.showInitWaitingMessage
);

export const selectOrderSplitsPageLastQuerys = createSelector(
    selectOrderSplitState,
    foldersState => foldersState.lastQuery
);

export const selectOrderSplitsInStore = createSelector(
    selectOrderSplitState,
    foldersState => {
        const items: OrderSplitAdmin[] = [];
        each(foldersState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: OrderSplitAdmin[] = httpExtension.sortArray(items, foldersState.lastQuery.sortField, foldersState.lastQuery.sortOrder);
        return new QueryResultsModel(result, foldersState.totalCount, '');
    }
);
