import {BaseModel} from "../../_base/crud";
import {FolderStates} from "./folder.model";


export class CommentFolderModel extends BaseModel{



    id : number;
    folderState :  FolderStates ;
    userId : number ;
    username : string ;
    folderId : number ;
    content : string ;
    title : String ;
    email:string;
    roles:string;
}