import { ClientsListPopUpComponent } from './../folders/clients-list-pop-up/clients-list-pop-up.component';
import {Component, OnInit} from '@angular/core';
import {FoldersService} from "../../../../../core/ek-e-commerce/ek-services/folders.service";
import {CountFolderState, Statistics} from "../../../../../core/ek-e-commerce/ek-models/count-statistics.model";
import {BehaviorSubject} from "rxjs";
import { MatDialog } from '@angular/material';
import {MenuConfigService} from "../../../../../core/_base/layout";

@Component({
    selector: 'kt-order-statistics',
    templateUrl: './order-statistics.component.html',
    styleUrls: ['./order-statistics.component.scss']
})
export class OrderStatisticsComponent implements OnInit {

    statisticsSubject$ = new BehaviorSubject<Statistics>(null);
    countStatistics$ = this.statisticsSubject$.asObservable();
    currentRole: string;
    foldersSubject$  = new BehaviorSubject<CountFolderState[]>(null)

    constructor(private folderService: FoldersService
        ,public dialog: MatDialog,
                private menuConfigService: MenuConfigService
) {
this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
}

    ngOnInit(): void {
        this.menuConfigService.statisticsSubject$.subscribe((data) => {
            this.foldersSubject$.next(data) ;
        })

        this.folderService.ordersStatistics().subscribe({
            next: (res) => {
                this.statisticsSubject$.next(res);
            }
        });

    }

    openClientPopUp() {
        this.dialog.open(ClientsListPopUpComponent, {
            width: '92rem',
        });
    }
}
