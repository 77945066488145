import {BaseModel} from '../../_base/crud';
import {AttachmentsModel} from './attachments.model';

export class CommentModel extends BaseModel{
    id: number;
    clientId: number;
    clientName: string;
    productId: number;
    value: string;
    attachments: AttachmentsModel [];
    isEditMode: boolean;
    updater : string;
    clear() {
        this.clientName = '';
        this.value = '';
       // this.attachments = new AttachmentsModel();
    }
}
