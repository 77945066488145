import {Component, OnInit} from '@angular/core';
import {ParcelService} from "../../../../../core/e-commerce/_services/parcel.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../core/reducers";
import {loadParcels} from "../../../../../core/e-commerce/_actions/parcel.actions";
import {ParcelsDatasource} from "../../../../../core/e-commerce/_data-sources/parcels.datasource";
import {MatDialog} from "@angular/material/dialog";
import {ParcelDetailsDialogComponent} from "./parcel-details-dialog/parcel-details-dialog.component";
import {Wilaya, WilayaService} from "../../../../../core/e-commerce/_services/wilaya.service";
import {FormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {debounceTime, distinctUntilChanged, map, startWith} from "rxjs/operators";


@Component({
    selector: 'kt-parcel',
    templateUrl: './parcel.component.html',
    styleUrls: ['./parcel.component.scss']
})
export class ParcelComponent implements OnInit {
    displayedColumns = ["select", 'tracking', 'state', 'client', 'commune', "wilaya", 'action'];
    pageParams = {page: '1', pageSize: '10', wilayaId: '', state: ''}
    dataSource: ParcelsDatasource;
    wilayas: Wilaya[] = [];
    states: string[] = [];
    wilayaControl = new FormControl();
    stateControl = new FormControl();
    stateFilteredOptions: Observable<string[]>;
    wilayaFilteredOptions: Observable<Wilaya[]>;


    constructor(
        private store: Store<AppState>,
        private parcelService: ParcelService,
        private dialog: MatDialog,
        private wilayaService: WilayaService,) {
    }

    ngOnInit(): void {
        this.dataSource = new ParcelsDatasource(this.store);
        this.prepareFilters()
        this.loadParcels();
    }

    loadParcels() {
        this.store.dispatch(loadParcels({
            page: this.pageParams.page,
            pageSize: this.pageParams.pageSize,
            wilayaId: this.pageParams.wilayaId,
            state:this.pageParams.state,
        }));
    }

    prepareFilters() {
        this.wilayas = this.wilayaService.getWilayasItems()
        this.states = this.parcelService.getStates();
        this.wilayaFilteredOptions = this.wilayaControl.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.name),
                map(name => name ? this.parcelService.filterWilaya(name, this.wilayas) : this.wilayas.slice())
            );
        this.stateFilteredOptions = this.stateControl.valueChanges.pipe(
            startWith(''),
            map(value => this.parcelService.filterState(value))
        );
        this.detectEmptyFilter()
    }

    detectEmptyFilter() {
        this.wilayaControl.valueChanges
            .pipe(
                debounceTime(500),
                distinctUntilChanged(),
            )
            .subscribe(searchPhrase => {
                if (searchPhrase.length == 0) {
                    this.pageParams.wilayaId = '';
                    this.loadParcels()
                }
            });
        this.stateControl.valueChanges
            .pipe(
                debounceTime(500),
                distinctUntilChanged(),
            )
            .subscribe(searchPhrase => {
                if (searchPhrase.length == 0) {
                    this.pageParams.state = '';
                    this.loadParcels()
                }
            });
    }


    filterByWilaya(event) {
        this.pageParams.wilayaId = this.wilayas.filter(
            value => {
                if (value.value == event.option.value) return value
            })[0].id;
        this.loadParcels();
    }

    filterByState(event) {
        this.pageParams.state =  event.option.value
        this.loadParcels();
    }

    copytracking(tracking) {
        this.parcelService.copytracking(tracking)
    }

    page(event) {
        this.pageParams.pageSize = event.pageSize
        this.pageParams.page = (event.pageIndex + 1).toString();
        this.loadParcels()
    }

    showdetail(parcel) {
        this.dialog.open(ParcelDetailsDialogComponent, {
            data: {order: parcel}, width: '40%', maxHeight: '95vh'
        });
    }

    exportPercel() {
    }

    printParcel() {
    }

}
