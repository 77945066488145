/**
 * Datasource that doesnt use NGRX
 * check this course https://blog.angular-university.io/angular-material-data-table/
 */
import {BaseDataSource, QueryResultsModel} from "../../_base/crud";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import {
    selectParcelsInitWaitingMessage,
    selectParcelsInStore,
    selectParcelsPageLoading
} from "../_selectors/parcel.selectors";


export class ParcelsDatasource extends BaseDataSource {
    constructor( private store: Store<AppState>) {
        super();
        this.loading$ = this.store.pipe(
            select(selectParcelsPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectParcelsInitWaitingMessage)
        );

        this.store.pipe(
            select(selectParcelsInStore)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }
}

