import {Injectable} from "@angular/core";
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {CharacteristicModel} from "../_models/characteristic.model";
import {Observable, of, throwError} from "rxjs";
import {Page} from "../../_base/crud/models/page";
import {Response} from "../../_base/crud/models/response";
import {map, mergeMap} from "rxjs/operators";



@Injectable()
export class CharacteristicsService extends AbstractService<CharacteristicModel> {

    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}characteristics`;
    }
    public search(page: number = 0, size: number = 100, query: any): Observable<Page<CharacteristicModel>> {
        let params = new HttpParams()
            .append('pageNo', page.toString())
            .append('pageSize', size.toString())
            .append('query', query.query)

        const url = `${this.url}/search`;
        return this.http.get<Response<Page<CharacteristicModel>>>(url, {params}).pipe(map(({body}) => body));
    }

    public deleteCharacteristic(id: number): Observable<Response<any>> {
        const url = `${this.url}/${id}`;
        return this.http.delete<Response<CharacteristicModel>>(url).pipe(
            map((response: Response<CharacteristicModel>) => {
                if (response) {
                    return response;
                } else {
                    throw new Error();
                }
            })
        )
    }

}
