import {configurationQuantity} from '../../../../../../../core/ek-e-commerce/ek-models/configuration-quantity.model';
import {ConfigurationModel} from '../../../../../../../core/ek-e-commerce/ek-models/configuration.model';
import {
    ConfigurationQuantityService
} from '../../../../../../../core/ek-e-commerce/ek-services/configuration-quantity.service';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ProductModel} from "../../../../../../../core/e-commerce";
import {LayoutUtilsService, MessageType} from "../../../../../../../core/_base/crud";
import {OrderSplitService} from "../../../../../../../core/ek-e-commerce/ek-services/order-split.service";
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'kt-order-split-product-details',
    templateUrl: './order-split-product-details.component.html',
    styleUrls: ['./order-split-product-details.component.scss']
})
export class OrderSplitProductDetailsComponent implements OnInit {

    public selectedConfiguration: ConfigurationModel;
    public productConfigurations: ConfigurationModel [] = [];
    public selectedQuantity: number = 1;
    public selectedImageUrl: string;
    addingOrder = false;

    configQuantity$: Observable<configurationQuantity[]>
    configQuantity: configurationQuantity;

    configQuantitySubject$ = new BehaviorSubject<configurationQuantity>(null as any);
    readonly configQuantityObs$ = this.configQuantitySubject$.asObservable();

    configForm: FormGroup;
    currentRole: string = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            product: ProductModel,
            orderId: number,
            idFolder: number,
            addingOrder: boolean,
            posId: number
        },
        private dialogRef: MatDialogRef<OrderSplitProductDetailsComponent>,
        private layoutUtilsService: LayoutUtilsService,
        private orderSplitService: OrderSplitService,
        private configurationQuantityService: ConfigurationQuantityService,
        private formBuilder: FormBuilder
    ) {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
        this.productConfigurations = this.data.product.configurations;
    }

    ngOnInit(): void {
        this.addingOrder = this.data.addingOrder
        this.selectedConfiguration = this.data.product.configurations[0];
        if (this.selectedConfiguration.images[0])
            this.selectedImageUrl = this.selectedConfiguration.images[0].url;

        this.configForm = this.formBuilder.group({
            configuration: ['', Validators.required],
            stock: ['', Validators.required],
            quantity: ['', Validators.required]
        })
    }

    selectConfiguration(event) {

        let configurationID = event.value;

        this.selectedConfiguration = this.productConfigurations.find(item => item.id === configurationID);

        //user = ROLE_CREDIT_ANALYST_EK , get only stocks of his preselected pos
        if (this.currentRole === 'ROLE_CREDIT_ANALYST_EK') {

            this.configurationQuantityService.getConfigActivatedStocksByIdPOS(configurationID, this.data.posId).subscribe({
                next: (res) => {
                    this.configQuantity$ = of(res);
                },
                error: (err) => {
                    this.configQuantity$ = of([]);
                    this.layoutUtilsService.showActionNotification('cette configuration n\'a pas de stock ou ses stocks ne sont pas activés !', MessageType.Create);
                }
            });
        } else {
            this.configQuantity$ = this.configurationQuantityService.getConfigQuanOrderByConfigId(configurationID).pipe(map(({body}) => body))
        }

    }


    selectStock(selectedStock: configurationQuantity) {
        this.configQuantity = selectedStock;
        this.configQuantitySubject$.next(this.configQuantity);
    }

    //View Control
    selectImage(imageUrl: string) {
        this.selectedImageUrl = imageUrl;
    }

    changeQuantity(quantity: number): number {
        this.selectedQuantity = quantity;
        return quantity;
    }

    checkIfSelected(imgUrl: string): string {
        if (imgUrl == this.selectedImageUrl)
            return 'selected';
        else
            return '';
    }


    submite() {
        if (this.configForm.invalid) {
            (Object).values(this.configForm.controls).forEach(control => {
                control.markAsTouched();
            })
        } else {
            if (this.addingOrder) {
                this.dialogRef.close({
                    configuration: this.selectedConfiguration,
                    quantity: this.selectedQuantity,
                    configQuan: this.configQuantity
                })
            } else {

                this.orderSplitService.addItemSplitToOrder(this.data.orderId, this.selectedConfiguration, this.selectedQuantity, this.data.idFolder, this.configQuantity.stockDepotId, this.configQuantity.stockEkId, this.configQuantity.sellingPrice).subscribe({
                    next: (e) => {
                        if (e.body) {
                            this.layoutUtilsService.showActionNotification('Le porduit a été ajouté à la commande', MessageType.Create);
                        } else if (e.message === "quantity.unavailable") {
                            this.layoutUtilsService.showActionNotification("La quantité" + this.selectedQuantity + "de produit n'est pas disponible !!", MessageType.Delete);
                        }
                        this.dialogRef.close();
                    },
                    error: (error) => {
                        if (error && error.status == 400) {
                            this.layoutUtilsService.showActionNotification("La quantité" + this.selectedQuantity + "de produit n'est pas disponible !!", MessageType.Delete);
                        } else {
                            this.layoutUtilsService.showActionNotification('error in adding order item!', MessageType.Delete);
                        }
                        this.dialogRef.close();

                    }
                });
            }
        }
    }

    onCancel() {
        this.dialogRef.close();
    }
}
