import {createFeatureSelector, createSelector} from "@ngrx/store";
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";
import {BestSellerState} from "../_reducers/products-best-seller.reducer";
import {ProductsBestSellerModel} from "../_models/products-best-seller.model";

export const selectBestSellerConfigurationsState = createFeatureSelector<BestSellerState>('best-seller-configuration');
export const selectBestSellerPageLoading = createSelector(
    selectBestSellerConfigurationsState,
    configurationsState => configurationsState.listLoading
);
export const selectBestSellerInStore = createSelector(
    selectBestSellerConfigurationsState,
    configurationsState => {
        const items: ProductsBestSellerModel[] = [];
        each(configurationsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ProductsBestSellerModel[] = httpExtension.sortArray(items, configurationsState.lastQuery.sortField, configurationsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, configurationsState.totalCount, '');
    }
);
export const selectBestSellerInitWaitingMessage = createSelector(
    selectBestSellerConfigurationsState,
    configurationsState => configurationsState.showInitWaitingMessage
);
