import {BaseModel} from "../../_base/crud";

export class BrandModel extends BaseModel {
    id: number;
    name: string;
    reference: string;

    _isEditMode: boolean;

    clear() {
        this.name = '';
        this.reference = '';
        this._isEditMode = false;
    }
}
