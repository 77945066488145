import {createFeatureSelector, createSelector} from "@ngrx/store";
import {FAQState} from './faq.reducer';
import {QuestionAnswerModel} from './questionanswer.model';
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";


export const getFAQState = createFeatureSelector<FAQState>('faq');
export const selectFAQById = (faqId: number) => createSelector(
    getFAQState,
    faqState => faqState.entities[faqId]
);



export const selectFAQStore = createSelector(
    getFAQState,
    (faqState: FAQState) => {
        const faqs: QuestionAnswerModel[] = [];
        Object.entries(faqState.entities).forEach(([k,v]) => faqs.push(v));
        return faqs;
    }
);

export const selectFAQsInStore = createSelector(
    getFAQState,
    faqState => {
        const items: QuestionAnswerModel[] = [];
        each(faqState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: QuestionAnswerModel[] = httpExtension.sortArray(items, faqState.lastQuery.sortField, faqState.lastQuery.sortOrder);
        return new QueryResultsModel(result, faqState.totalCount, '');
    }
);

export const selectFAQsPageLoading = createSelector(
    getFAQState,
    faqsState => faqsState.listLoading
);

export const selectFAQsActionLoading = createSelector(
    getFAQState,
    faqsState => faqsState.actionsLoading
);

export const selectFAQsPageLastQuery = createSelector(
    getFAQState,
    faqState => faqState.lastQuery
);

export const selectLastCreatedFAQId = createSelector(
    getFAQState,
    faqState => faqState.lastCreatedFAQId
);

export const selectFAQsInitWaitingMessage = createSelector(
    getFAQState,
    faqState => faqState.showInitWaitingMessage
);

export const selectHasFAQsInStore = createSelector(
    selectFAQsInStore,
    queryResult => {
        return queryResult.totalCount;


    }
);
