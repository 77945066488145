import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import * as XLSX from 'xlsx';

export interface wrongOrders {
    orderIds: number;
    hasError: boolean;
}

@Component({
    selector: 'kt-wrong-orders-bill',
    templateUrl: './wrong-orders-bill.component.html',
    styleUrls: ['./wrong-orders-bill.component.scss']
})
export class WrongOrdersBillComponent implements OnInit {

    today: Date;
    fileName = "export-wrong-orders-global.xlsx";

    constructor(private dialogRef: MatDialogRef<WrongOrdersBillComponent>,
                @Inject(MAT_DIALOG_DATA) public data: wrongOrders[]) {

    }

    ngOnInit() {
        this.today = new Date();
        setTimeout(() => {
                this.exportExcel()
            }
            , 1000);
    }

    exportExcel(): void {
        //pass here the table id
        let element = document.getElementById('wrongOrder_table');
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

        //generate workbook and add the worksheet
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        //save to file
        XLSX.writeFile(wb, this.fileName);

    }

}
