import {createAction, props} from "@ngrx/store";
import {Update} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";
import {ProductsBestSellerModel} from "../_models/products-best-seller.model";



export const BestSellerPageRequested = createAction('[BestSeller List Page] BestSeller Page Requested ',props<{page: QueryParamsModel}>());
export const BestSellerPageLoadedSuccessfully = createAction('[BestSeller API] BestSeller Page Loaded Successfully ',props<{bestSeller: ProductsBestSellerModel[],totalCount: number, page: number}>());
export const BestSellerPageLoadFailed = createAction('[BestSeller API] BestSeller Page Request Failed ',props<{error: any}>());

export const BestSellerUpdated = createAction('[BestSeller List Page] BestSeller Updated ',props<{bestSellerId: number , partialBestSeller:Update<ProductsBestSellerModel> ,display: boolean, updater: string}>());
export const BestSellerUpdatedSuccessfully = createAction('[BestSeller List Page] BestSeller Updated Successfully ',props<{bestSeller: ProductsBestSellerModel, partialBestSeller: Update<ProductsBestSellerModel>}>());
export const BestSellerUpdateFailed = createAction('[BestSeller List Page] BestSeller Update Failed ',props<{error: any}>());
