import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ThresholdService} from "../../../../../core/ek-e-commerce/ek-services/threshold.service";
import {ThresholdModel} from "../../../../../core/ek-e-commerce/ek-models/threshold.model";
import {LayoutUtilsService, MessageType} from "../../../../../core/_base/crud";
import {CoefficientsWilayaService} from "../../../../../core/ek-e-commerce/ek-services/coefficientsWilaya.service";
import {ScaleIntervalService} from "../../../../../core/ek-e-commerce/ek-services/scaleInterval.service";
import {SocioProfessionalService} from "../../../../../core/ek-e-commerce/ek-services/socioProfessional.service";
import {MatTableDataSource} from "@angular/material/table";
import {ScaleIntervalModel} from "../../../../../core/ek-e-commerce/ek-models/scaleInterval.model";
import {SocioProfessionalModel} from "../../../../../core/ek-e-commerce/ek-models/socioProfessional.model";
import {CoefficientsWilayaModel} from "../../../../../core/ek-e-commerce/ek-models/coefficientsWilaya.model";

@Component({
    selector: 'kt-scoring-threshold',
    templateUrl: './scoring-threshold.component.html',
    styleUrls: ['./scoring-threshold.component.scss']
})
export class ScoringThresholdComponent implements OnInit {

    thresholdForm: FormGroup;
    ratingOptions: number[] = [];  // Array to hold threshold options
    Threshold: ThresholdModel;
    displayedColumns: string[] = ['interval', 'scale','rating'];
    displayedColumnsInfo: string[] = ['personalInfo', 'value'];
    displayedColumnsWilaya: string[] = ['num', 'wilaya','valueWilaya'];

    dataSource: MatTableDataSource<ScaleIntervalModel>;
    dataSourceInfo:MatTableDataSource<SocioProfessionalModel>;
    dataSourceWilaya:MatTableDataSource<CoefficientsWilayaModel>;


    constructor(private fb: FormBuilder,
                private thresholdService: ThresholdService,
                private layoutUtilsService: LayoutUtilsService,
                private coefficientsWilayaService:CoefficientsWilayaService,
                private scaleIntervalService:ScaleIntervalService,
                private socioProfessionalService:SocioProfessionalService) {
    }

    ngOnInit(): void {

        // Initialize form and options
        this.thresholdForm = this.fb.group({
            minThreshold: ['', Validators.compose([Validators.required])],
            maxThreshold: ['', Validators.compose([Validators.required])]
        });

        //get rating threshold min/max
        this.thresholdService.getAll().subscribe({
            next: (res) => {
                if (res && res[0]) {
                    this.Threshold = res[0];
                    this.thresholdForm.get('minThreshold').setValue(res[0].min);
                    this.thresholdForm.get('maxThreshold').setValue(res[0].max);
                }
            },
            error: () => {
                const message = `Une erreur s'est produite lors du chargement du seuil !`;
                this.layoutUtilsService.showActionNotification(
                    message,
                    MessageType.Update,
                    5000,
                    true,
                    true
                );
            }
        });

        // Populate the rating options from 1 to 10
        this.ratingOptions = Array.from({length: 10}, (_, i) => i + 1);
        this.fetchScales();
        this.fetchWilayas();
        this.fetchInfo();


    }

    verifyThresHold(min: number, max: number) {
        if (min > max) {
            const message = `Le seuil 1 doit être < ou égal à seuil 2 !`;
            this.layoutUtilsService.showActionNotification(
                message,
                MessageType.Update,
                5000,
                true,
                true
            );
            return false;
        }
        return true;
    }

    updateThresHold() {

        const minThreshold = Number(this.thresholdForm.get('minThreshold').value);
        const maxThreshold = Number(this.thresholdForm.get('maxThreshold').value);

        //Validate thresholds : Minimum threshold must be less than or equal to the maximum threshold
        if (!this.verifyThresHold(minThreshold, maxThreshold)) {
            return;
        }

        if (this.Threshold) {

            this.Threshold.min = minThreshold;
            this.Threshold.max = maxThreshold;

            this.thresholdService.update(this.Threshold).subscribe({
                next: (res) => {
                    const message = `Le seuil de rating a été mis à jour avec succès `;
                    this.layoutUtilsService.showActionNotification(
                        message,
                        MessageType.Update,
                        5000,
                        true,
                        true
                    );
                }, error: () => {
                    const message = `Une erreur est survenue lors de la mis à jour du seuil. Veuillez réessayer !`;
                    this.layoutUtilsService.showActionNotification(
                        message,
                        MessageType.Update,
                        5000,
                        true,
                        true
                    );
                }
            });


        } else {
            this.addNewThresHold();
        }

    }

    addNewThresHold() {

        let threshold: ThresholdModel = {
            id: null,
            max: Number(this.thresholdForm.get('maxThreshold').value),
            min: Number(this.thresholdForm.get('minThreshold').value)
        };

        this.thresholdService.save(threshold).subscribe({
            next: (res) => {
                this.Threshold = res;
                const message = `Le seuil de rating a été sauvegardé avec succès `;
                this.layoutUtilsService.showActionNotification(
                    message,
                    MessageType.Update,
                    5000,
                    true,
                    true
                );
            },
            error: () => {
                const message = `Une erreur est survenue lors de la sauvegarde du seuil. Veuillez réessayer !`;
                this.layoutUtilsService.showActionNotification(
                    message,
                    MessageType.Update,
                    5000,
                    true,
                    true
                );
            }
        });

    }

    fetchScales(): void {
        this.scaleIntervalService.getAll().subscribe(
            (data: ScaleIntervalModel[]) => {
                // Sort the data based on the interval number (e.g., "Intervalle 1", "Intervalle 2", ...)
                const sortedData = data.sort((a, b) => {
                    const intervalA = parseInt(a.interval.replace('Intervalle ', ''));
                    const intervalB = parseInt(b.interval.replace('Intervalle ', ''));
                    return intervalA - intervalB;
                });

                // Assign the sorted data to the dataSource
                this.dataSource = new MatTableDataSource(sortedData);
            },
            (error) => {
                console.error('Failed to fetch scales', error);
            }
        );
    }

    fetchInfo(): void {
        this.socioProfessionalService.getAll().subscribe(
            (data: SocioProfessionalModel[]) => {
                // Directly assign the fetched data to the dataSource
                this.dataSourceInfo = new MatTableDataSource(data);
            },
            (error) => {
                console.error('Failed to fetch scales', error);
            }
        );
    }

    fetchWilayas(): void {
        this.coefficientsWilayaService.getAll().subscribe(
            (data: CoefficientsWilayaModel[]) => {
                // Directly assign the fetched data to the dataSource
                this.dataSourceWilaya = new MatTableDataSource(data);
            },
            (error) => {
                console.error('Failed to fetch scales', error);
            }
        );
    }

    // This method will be triggered when the "Sauvegarder" button is clicked
    saveAllUpdateWilayas() {
        // Here, we assume 'dataSourceWilaya' is already bound to the table's data
        const updatedData = this.dataSourceWilaya.data.map(row => ({
            id: row.id,
            createdAt:row.createdAt,
            updatedAt:row.updatedAt,
            num: row.num,
            wilaya: row.wilaya,
            value: row.value,
   // Set the updatedAt field with the current time
        }));

        // Call the API to update all data at once
        this.coefficientsWilayaService.updateAll(updatedData).subscribe(
            response => {
                const message = `Les coefficients des wilayas ont été modifiés avec succès.`;
                this.layoutUtilsService.showActionNotification(
                    message,
                    MessageType.Update,
                    5000,
                    true,
                    true
                );
            },
            error => {
                console.error('Error updating data', error);
                // Handle the error and provide feedback to the user if necessary
            }
        );
    }


    saveAllUpdateInfo() {
        // Here, we assume 'dataSourceWilaya' is already bound to the table's data
        const updatedData = this.dataSourceInfo.data.map(row => ({
            id: row.id,
            createdAt:row.createdAt,
            updatedAt:row.updatedAt,
            socioProfessional: row.socioProfessional,
            value: row.value,
   // Set the updatedAt field with the current time
        }));

        // Call the API to update all data at once
        this.socioProfessionalService.updateAll(updatedData).subscribe(
            response => {
                const message = `Les coefficients des informations socio-professionnelles ont été modifiés avec succès.`;
                this.layoutUtilsService.showActionNotification(
                    message,
                    MessageType.Update,
                    5000,
                    true,
                    true
                );
            },
            error => {
                console.error('Error updating data', error);
                // Handle the error and provide feedback to the user if necessary
            }
        );
    }

    saveAllUpdateScales() {
        // Here, we assume 'dataSourceWilaya' is already bound to the table's data
        const updatedData = this.dataSource.data.map(row => ({
            id: row.id,
            createdAt:row.createdAt,
            updatedAt:row.updatedAt,
            value: row.value,
            interval: row.interval,
            rating: row.rating,
   // Set the updatedAt field with the current time
        }));

        // Call the API to update all data at once
        this.scaleIntervalService.updateAll(updatedData).subscribe(
            response => {
                const message = `Les coefficients des informations socio-professionnelles ont été modifiés avec succès.`;
                this.layoutUtilsService.showActionNotification(
                    message,
                    MessageType.Update,
                    5000,
                    true,
                    true
                );
            },
            error => {
                console.error('Error updating data', error);
                // Handle the error and provide feedback to the user if necessary
            }
        );
    }
}
