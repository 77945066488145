import {ADMIN_CONFIG} from "../../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import { Injectable } from '@angular/core';
import {AbstractService} from "../../services/abstract-service.service";
import {CommentModel} from "../_models/comment.model";
import {Observable} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";
import { Page } from '../../_base/crud/models/page';

@Injectable({
  providedIn: 'root'
})
export class CommentService extends AbstractService<CommentModel> {
  url: string;

  constructor(protected http: HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}comments`;
  }
  public addComment(comment: CommentModel, files: File[]): Observable<CommentModel>
  {
    const url = `${this.url}/createByAdmin`;
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file, file.name);
    }
    formData.append('comment', JSON.stringify(comment));
    return this.http.post<Response<CommentModel>>(url, formData).pipe(map(({body}) => body));
  }

  public deleteImg(Id: number): Observable<any> {
    const url = `${ADMIN_CONFIG.apiEndpoint}attachements/${Id}`;
    return this.http.delete(url);
  }
  public getByProductId( page: number = 0, size: number = 100, sorting: string, direction: string,id: number )

  : Observable<Page<CommentModel>> {
    const options = {
      params: new HttpParams()
          .append('pageNo', page.toString())
          .append('pageSize', size.toString())
          .append('sortBy', sorting)
          .append('direction', 'desc')
    };
      const url = `${this.url}/product/${id}`;
      return this.http.get<Response<Page<CommentModel>>>(url, options).pipe(map(({body}) => body));
    }
  public commentUpdate(comment:CommentModel,clientName:string, files: File[]):Observable<CommentModel>{
    const url = `${this.url}/updateComment`;
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file, file.name);
    }
    formData.append('clientName' ,clientName);
    formData.append('comment', JSON.stringify(comment));

    return this.http.put<CommentModel>(url, formData);

  }
  public findByFilterSorted(id:number, query :string): Observable<Page<CommentModel>> {
    const options = {
      params: new HttpParams()
          .append('query', query.toString())

    };
    const url = `${this.url}/${id}/search`;
    return this.http.get<Response<Page<CommentModel>>>(url, options).pipe(map(({body}) => body));
  }

  public filterByDate(id:number, date: string, date1: string,direction: string,): Observable<Page<CommentModel>> {
    const options = {
      params: new HttpParams()
          .append('direction', direction)
          //.append('sortBy', sortBy)
          .append('date',date)
          .append('date1', date1)
          //.append('pageNo', page.toString())
          //.append('pageSize', size.toString())
    };
    const url = `${this.url}/${id}/filterByDate`;
    return this.http.get<Response<Page<CommentModel>>>(url, options).pipe(map(({body}) => body));

  }
  public searchsorted(direction: string,sortBy: string ,page: number = 0, size: number = 100, query: any,id:number): Observable<Page<CommentModel>> {
    let params = new HttpParams()
        .append('pageNumber', page.toString())
        .append('pageSize', size.toString())
        .append('query', query.query)
        .append('sort.unsorted', "true")
        .append('unpaged', "false")
    const url = `${this.url}/${id}/search/`;
    return this.http.get<Response<Page<CommentModel>>>(url, {params}).pipe(map(({body}) => body));
  }

}

