import {Directive, ElementRef, HostListener, Input} from "@angular/core";

@Directive({
    selector: "[maxDegits]"
})
export class OnlyXdegitisDirective {

    @Input() maxDegitsNumber: number;
    constructor(private el: ElementRef) {
    }

    @HostListener("keydown", ["$event"]) onKeydown(event) {

        const value = this.el.nativeElement.value;

        if (value.length > this.maxDegitsNumber - 1) {
            this.el.nativeElement.value = value.slice(0, this.maxDegitsNumber - 1);
        }
    }
}