// Angular
import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
// Material
import {SelectionModel} from '@angular/cdk/collections';
import {MatPaginator, MatSort} from '@angular/material';
// RXJS
import {debounceTime, delay, distinctUntilChanged, skip, take, tap} from 'rxjs/operators';
import {fromEvent, merge, of, Subscription} from 'rxjs';
// LODASH
import {each, find} from 'lodash';
// NGRX
import {Store} from '@ngrx/store';
import {AppState} from '../../../../core/reducers';

// Services
import {LayoutUtilsService, MessageType, QueryParamsModel} from '../../../../core/_base/crud';
// Models
import {Role, User, UsersDataSource,} from '../../../../core/auth';
import {SubheaderService} from '../../../../core/_base/layout';
//Actions
import * as UserActions from "../../../../core/auth/_actions/user.actions";
import {RolesTable} from "../../../../core/auth/_server/roles.table";
import {UserService} from "../../../../core/services/user.service";
import {Actions, ofType} from "@ngrx/effects";
import {JwtHelperService} from "@auth0/angular-jwt";

@Component({
    selector: 'kt-ek-users-list',
    templateUrl: './ek-users-list.component.html',
    styleUrls: ['./ek-users-list.component.scss']
})
export class EkUsersListComponent implements OnInit {

// Table fields
    dataSource: UsersDataSource;
    displayedColumns = ['select', 'id', 'username', 'email', 'firstname', 'lastname', '_roles', 'actions'];
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    //sorting table
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    lastQuery: QueryParamsModel;
    // Selection
    selection = new SelectionModel<User>(true, []);
    usersResult: User[] = [];
    allRoles: Role[] = RolesTable.roles;

    // Subscriptions
    private subscriptions: Subscription[] = [];
    selectedRole= ''
    hasPermission: boolean;
    //Permissions
    PERMISSIONS = ['ROLE_SUPERADMIN'];
    currentRole: string;
    /**
     *
     * @param activatedRoute
     * store
     * @param store
     * @param router
     * @param actions$
     * @param layoutUtilsService
     * @param subheaderService
     * @param subheaderService
     * @param subheaderService
     * @param subheaderService
     * @param subheaderService
     * @param subheaderService
     * @param subheaderService
     * @param userService
     * @param cdr
     */
    constructor(
        private activatedRoute: ActivatedRoute,
        private store: Store<AppState>,
        private router: Router,
        private actions$: Actions,
        private layoutUtilsService: LayoutUtilsService,
        private subheaderService: SubheaderService,
        private userService: UserService,
        private cdr: ChangeDetectorRef) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        // load roles list
        // const rolesSubscription = this.store.pipe(select(selectAllRoles)).subscribe(res => this.allRoles = res);
        // this.subscriptions.push(rolesSubscription);
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        /* Data load will be triggered in two cases:
        - when a pagination event occurs => this.paginator.page
        - when a sort event occurs => this.sort.sortChange
        **/
        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => {
                this.loadUsersList();
            })
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);


        // Filtration, bind to searchInput
        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            // tslint:disable-next-line:max-line-length
            debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
            distinctUntilChanged(), // This operator will eliminate duplicate values
            tap(() => {
                this.paginator.pageIndex = 0;
                this.loadUsersList();
            })
        )
            .subscribe();
        this.subscriptions.push(searchSubscription);

        // Set title to page breadCrumbs
        this.subheaderService.setTitle('User management');

        // Init DataSource
        this.dataSource = new UsersDataSource(this.store);
        const entitiesSubscription = this.dataSource.entitySubject.pipe(
            skip(1),
            distinctUntilChanged()
        ).subscribe(res => {
            this.usersResult = res;
        });
        this.subscriptions.push(entitiesSubscription);

        // First Load
        of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
            this.loadUsersList();
        });
    }

    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    /**
     * Load users list
     */
    loadUsersList() {

        // const currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
        //
        // this.userService.findAllEkUsers( this.searchInput.nativeElement.value , this.selectedRole).subscribe({
        //     next : (response) => {
        //         // if the user is not superadmin remove the superadmins from the list
        //         if (currentRole !== 'ROLE_SUPERADMIN')
        //             this.dataSource.entitySubject.next(response.filter(user => user.role !== 'ROLE_SUPERADMIN'));
        //         else
        //             this.dataSource.entitySubject.next(response);
        //         },
        //     error : () =>{
        //         this.dataSource.entitySubject.next([]);
        //     }
        // })
        this.selection.clear();
        const queryParams = new QueryParamsModel(
            this.filterConfiguration(),
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex,
            this.paginator.pageSize
        );
        this.store.dispatch(
            UserActions.AllUsersRequested({
                page: queryParams,
                role: this.selectedRole,
                key: this.searchInput.nativeElement.value
            }));
        this.selection.clear();
    }

    /** FILTRATION */
    filterConfiguration(): any {
        const filter: any = {};
        const searchText: string = this.searchInput.nativeElement.value;

        filter.lastName = searchText;

        filter.username = searchText;
        filter.email = searchText;
        filter.fillname = searchText;
        return filter;
    }

    /** ACTIONS */
    /**
     * Delete user
     *
     * @param _item
     */
    deleteUser(_item: User) {
        const _title = 'User Delete';
        const _description = 'Are you sure to permanently delete this user?';
        const _waitDesciption = 'User is deleting...';
        const _deleteMessage = `User has been deleted`;
        const _errorMessage = `error!`;

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {

            if (!res) {
                return;
            }

            this.store.dispatch(UserActions.UserDeleted({id: _item.id}));


            this.actions$.pipe(ofType(UserActions.UserDeletedSuccessfully)).subscribe((data) => {
                if (data) {
                    this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
                }
            });


            this.actions$.pipe(ofType(UserActions.UserDeletingFailed)).subscribe((data) => {
                if (data) {
                    this.layoutUtilsService.showActionNotification(" Vous devez d'abord supprimer tous les rôles et changer les affectations en relation avec cet utilisateur ", MessageType.Delete);
                }
            });

        });
    }

    /**
     * Fetch selected rows
     */
    fetchUsers() {
        const messages = [];
        this.selection.selected.forEach(elem => {
            messages.push({
                text: `${elem.firstname}, ${elem.email}`,
                id: elem.id.toString(),
                status: elem.username
            });
        });
        this.layoutUtilsService.fetchElements(messages);
    }

    /**
     * Check all rows are selected
     */
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.usersResult.length;
        return numSelected === numRows;
    }

    /**
     * Toggle selection
     */
    masterToggle() {
        if (this.selection.selected.length === this.usersResult.length) {
            this.selection.clear();
        } else {
            this.usersResult.forEach(row => this.selection.select(row));
        }
    }

    /* UI */
    /**
     * Returns user roles string
     *
     * @param user
     */
    getUserRolesStr(user: User): string {
        const titles: string[] = [];
        each(user.roles, (roleId: string) => {
            const _role = find(this.allRoles, (role: Role) => role.id === roleId);
            if (_role) {
                titles.push(_role.title);
            }
        });
        return titles.join(', ');
    }


    translateRole(role) {
        const roleTitle =  RolesTable.roles.find(item => item.id === role)?.title;
        return roleTitle ? roleTitle : '';
    }

    getUserPOSStr(user: User): string {
        const titles: string[] = [];
        user.pointOfSales.forEach(pos => titles.push(pos.posWilaya));
        return titles.join(', ');
    }

    /**
     * Redirect to edit page
     *
     * @param id
     */
    editUser(id) {
        this.router.navigate(['../users/edit', id], {relativeTo: this.activatedRoute});
    }

}
