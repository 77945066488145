import {EkSnackBarService} from '../../../../../../core/services/ek-snackBar.service';
import {PointOfSaleService} from '../../../../../../core/ek-e-commerce/ek-services/point-of-sale.service';
import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgbDate, NgbDateParserFormatter} from "@ng-bootstrap/ng-bootstrap";
import {SelectionModel} from "@angular/cdk/collections";
import {FolderModel} from "../../../../../../core/ek-e-commerce/ek-models/folder.model";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../core/reducers";
import {PointOfSaleModel} from "../../../../../../core/ek-e-commerce/ek-models/point-of-sale.model";
import {PointOfSaleDatasource} from "../../../../../../core/ek-e-commerce/ek-data-sources/point-of-sale.datasource";
import {LayoutUtilsService, QueryParamsModel} from "../../../../../../core/_base/crud";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {BehaviorSubject, fromEvent, merge, Observable, Subscription} from "rxjs";
import {debounceTime, distinctUntilChanged, map, startWith, tap} from "rxjs/operators";
import * as PointOfSaleActions from "../../../../../../core/ek-e-commerce/ek-actions/point-of-sale.action";
import {
    POSCreatedSuccessfully,
    POSPageRequested,
    POSUpdatedSuccessfully
} from "../../../../../../core/ek-e-commerce/ek-actions/point-of-sale.action";
import {Update} from "@ngrx/entity";
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {
    forbiddenProvinceValidator,
    Wilaya,
    WilayaService
} from "../../../../../../core/e-commerce/_services/wilaya.service";
import {Town} from "../../../../../../core/e-commerce/_models/town";
import {MiniAnalyst} from '../../../../../../core/_base/crud/models/mini-credit-analystr.model';
import {RegionService} from "../../../../../../core/ek-e-commerce/ek-services/region.service";
import {Actions, ofType} from "@ngrx/effects";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';



@Component({
    selector: 'kt-point-of-sale-list',
    templateUrl: './point-of-sale-list.component.html',
    styleUrls: ['./point-of-sale-list.component.scss']
})
export class PointOfSaleListComponent implements OnInit {
    viewLoading$ = null;
    hoveredDate: NgbDate | null = null;
    fromDate: NgbDate | null = null;
    toDate: NgbDate | null = null;
    filterByDateActivated = false;
    loadingData = false;
    editPOSLine = -1;
    posForm: FormGroup;
    hasFormErrors = false;
    analysts$: Observable<MiniAnalyst[]>
    POSES$: Observable<MiniAnalyst[]>
    Commercials$: Observable<MiniAnalyst[]>
    analysts: MiniAnalyst[] = []
    POSES: MiniAnalyst[] = []
    commercialPoses: MiniAnalyst[] = []
    currentRole: string = '';


    //mat table
    dataSource: PointOfSaleDatasource;
    selection = new SelectionModel<FolderModel>(true, []);
    foldersResult: FolderModel[] = [];
    filterByStatus = new FormControl();
    displayedColumns: string[] = ['namePos', 'creditAnalyst', 'pos_person','commercial_pos', 'posWilaya', 'posCommune', 'addressPos', 'posPhoneOne', 'posPhoneTwo', 'codification', 'actions'];
    displayedColumns2: string[] = ['namePos', 'creditAnalyst', 'pos_person','commercial_pos', 'posWilaya', 'posCommune', 'addressPos', 'posPhoneOne', 'posPhoneTwo', 'codification', 'nameRegion', 'actions'];
    displayedColumns3 = [
        'add-namePos',
        'add-creditAnalyst',
        'add-posPerson',
        'add-commercialPos',
        'add-posWilaya',
        'add-posCommune',
        'add-addressPos',
        'add-posPhoneOne',
        'add-posPhoneTwo',
        'add-codification',
        'add-region',
        'add-actions'
    ];
    displayedColumns4 = [
        'add-namePos',
        'add-creditAnalyst',
        'add-posPerson',
        'add-commercialPos',
        'add-posWilaya',
        'add-posCommune',
        'add-addressPos',
        'add-posPhoneOne',
        'add-posPhoneTwo',
        'add-codification',
        'add-actions'
    ];
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    private subscriptions: Subscription[] = [];
    filteredOptions: Observable<Wilaya[]>;
    townFilteredOptions: Observable<Town[]>;
    public wilayasList: Wilaya[] = [];
    public towns: Town[] = [];
    PHONE_REGEX = /^(00213|\+213|0)(5|6|7|9)(\s*?[0-9]\s*?){5,8}$/;
    townsSubject$ = new BehaviorSubject<Town[]>([]);
    readonly town$ = this.townsSubject$.asObservable();
    filterByRegion = new FormControl();
    regionList = [];
    selectedRegion = "";

    loadingSubject$ = new BehaviorSubject<boolean>(false);
    readonly loading = this.loadingSubject$.asObservable();

    constructor(public formatter: NgbDateParserFormatter,
                private posFB: FormBuilder,
                private store: Store<AppState>,
                private _actions$: Actions,
                private layoutUtilsService: LayoutUtilsService,
                private wilayaService: WilayaService,
                private pointOfSaleService: PointOfSaleService,
                private snackBarService: EkSnackBarService,
                private regionService: RegionService,
                private changeDetectorRef: ChangeDetectorRef,
                private sanitizer: DomSanitizer
    ) {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }

    ngOnInit(): void {
        this.getAllPaged();
        this.getRegionsList();
        this.analysts$ = this.pointOfSaleService.getCreditAnalysts();
        this.POSES$ = this.pointOfSaleService.getPos();
        this.Commercials$ = this.pointOfSaleService.getCommercialPos();
        this.POSES$.subscribe(poses => this.POSES = poses)
        this.pointOfSaleService.getCreditAnalysts().subscribe(analysts => {
            this.analysts = analysts
        });
        this.pointOfSaleService.getPos().subscribe(pos => {
            this.POSES = pos
        });
        this.Commercials$.subscribe(commercials => {
            this.commercialPoses = commercials;
        });
        this.wilayasList = this.wilayaService.getWilayasItems();
        this.getWilayaListByRegion();

        this.town$.subscribe(town => {
            if (town.length > 0) {
                this.towns = town;
            }
        });
        this.posForm = this.posFB.group({
            id: [null],
            namePos: ['', Validators.required],
            posCommune: ['', [Validators.required, this.forbiddenTownsValidator()]],
            addressPos: ['', Validators.required],
            posPhoneOne: ['', [Validators.required, Validators.pattern(this.PHONE_REGEX)]],
            posPhoneTwo: ['', Validators.pattern(this.PHONE_REGEX)],
            posWilaya: ['', Validators.compose([forbiddenProvinceValidator(this.wilayasList), Validators.required])],
            codification: ['', Validators.compose([Validators.required, this.forbiddenCodification()])],
            analystName: ['', Validators.required],
            posPersonName: [[], Validators.required],
            commercialPosName: [''], // Ensure this is configured correctly for commercial IDs
        });

        this.dataSource = new PointOfSaleDatasource(this.store);
        // this.getAllPaged();
        this.paginator._changePageSize(50);

        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this.getAllPaged())
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);
        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            debounceTime(700), // The user can type quite quickly in the input box, and that could trigge_filterr a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
            distinctUntilChanged(), // This operator will eliminate duplicate values
            map(() => {
                this.paginator.pageIndex = 0;
                this.getAllPaged();
            })).subscribe();

        this.filterAnalysts()

        this.filteredOptions = this.posForm.get('posWilaya').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this._filter(name.toString()) : this.wilayasList.slice())
            );
        this.subscriptions.push(searchSubscription);
        this.changeDetectorRef.detectChanges();

    }

    private _filter(name: string): Wilaya[] {
        const filterValue = name.toLowerCase();
        return this.wilayasList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    private _filterAnalysts(name: string): MiniAnalyst[] {
        const filterValue = name.toLowerCase();
        return this.analysts.filter(option => option.username.toLowerCase().indexOf(filterValue) === 0);
    }

    getWilayaListByRegion() {
        if (this.currentRole === 'ROLE_COMMERCIAL_REGION_MANAGER') {
            let listFinal = [];
            this.regionService.getWilayaByRegion().subscribe({
                next: (res) => {
                    res.forEach(item => {
                        const resWilaya = this.wilayasList.find(i => i.value === item);
                        listFinal.push(resWilaya);
                    });
                    this.wilayasList = listFinal;
                }
            });
        } else {
            this.wilayasList = this.wilayaService.getWilayasItems();
        }
    }

    getAllPaged() {
        const queryParams = new QueryParamsModel(
            this.filterConfiguration(),
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex,
            this.paginator.pageSize,
        );
        this.store.dispatch(POSPageRequested({
            page: queryParams,
            regionId: this.filterByRegion.value ? this.filterByRegion.value : null
        }));
    }

    getWilayaTowns(event) {
        const selectedWilaya = event.option.value;

        this.loadingSubject$.next(true);

        //reset towns
        this.posForm.get('posCommune').reset();

        if (this.posForm.controls.posWilaya.dirty) {
            this.posForm.controls.posCommune.enable();
        }

        this.getRegionByWilaya(selectedWilaya);

        this.getTowns(this.wilayasList.filter(value => {
            if (value.value == selectedWilaya)
                return value
        })[0].id);

    }

    getTowns(wilayaId) {
        this.wilayaService.getTownsByWilayaId(wilayaId).subscribe(res => {
            this.towns = res;
            this.townsSubject$.next(res);
            this.townFilteredOptions = this.posForm.get('posCommune').valueChanges
                .pipe(
                    startWith(''),
                    map(value => typeof value === 'string' ? value : value),
                    map(name => name ? this.townFilter(name.toString()) : this.towns.slice())
                );
        })
    }

    private townFilter(name: string): Town[] {
        const filterValue = name.toLowerCase();
        return this.towns.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    filterConfiguration(): string {
        const filter: any = {};
        if (this.filterByStatus.value && this.filterByStatus.value !== '') {
            filter.status = this.filterByStatus.value;
        }
        filter.query = this.searchInput.nativeElement.value;
        return filter;
    }

    forbiddenCodification(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            return control?.value?.length === 21 ? null : {CodificationValid: true};
        };
    }

    navigate() {
        this.editPOSLine = 0;
        this.selectedRegion = '';
    }

    deletePOS(pos) {
        const _title = 'Supprimer point de vente';
        const _description = 'Êtes-vous sûr de vouloir supprimer définitivement ce point de vente';
        const _waitDesciption = 'en cours de suppression...';

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            if (pos.namePos.toLowerCase() == 'ekiclik') {
                const msg = 'Vous ne pouvez pas supprimer le stock principale'
                this.snackBarService.openSnackBar(msg, '', 'ok')
            } else {
                this.store.dispatch(PointOfSaleActions.POSDeleted({posId: pos.id}));
            }
        });
    }

    changePOS(pos, value) {
        let status;
        if (value.checked == true) {
            status = 'ACTIVE'
        } else {
            status = 'DEACTIVATE'
        }
        const updatedPOS = Object.assign({}, pos);
        updatedPOS.status = status;
        const updatePOS: Update<PointOfSaleModel> = {
            id: pos.id,
            changes: updatedPOS,
        };
        this.store.dispatch(
            PointOfSaleActions.ChangePOSStatus(
                {
                    partialPOS: updatePOS,
                    pos: updatedPOS
                }
            )
        );
    }

    filterAnalysts() {
        this.pointOfSaleService.getCreditAnalysts().subscribe(result => {
            this.analysts = result;
            this.changeDetectorRef.detectChanges();

            this.analysts$ = this.posForm.get('analystName').valueChanges
                .pipe(
                    startWith(''),
                    map(value => typeof value === 'string' ? value : value),
                    map(name => name ? this._filterAnalysts(name.toString()) : this.analysts.slice()));
        });
    }

    saveNewPOS() {

        const regionId = this.dataSource.entitySubject.value[0].regionId;

        this.loadingSubject$.next(true);

        const controls = this.posForm.controls;

        if (this.posForm.invalid) {
            Object.keys(this.posForm.controls).forEach(controlName =>
                this.posForm.controls[controlName].markAsTouched()
            );
            this.hasFormErrors = true;
            this.loadingSubject$.next(false);
            return;
        } else {
            const selectedPosIds = this.posForm.controls.posPersonName.value;

            const payload: PointOfSaleModel = {
                id: null,
                addressPos: controls.addressPos.value,
                namePos: controls.namePos.value,
                posCommune: controls.posCommune.value,
                posPhoneOne: controls.posPhoneOne.value,
                posPhoneTwo: controls.posPhoneTwo.value,
                posWilaya: controls.posWilaya.value,
                status: 'ACTIVE',
                createdAt: '',
                updatedAt: '',
                codification: controls.codification.value,
                regionId: this.currentRole === 'ROLE_COMMERCIAL_REGION_MANAGER' ? regionId : this.getRegionByName(this.selectedRegion),
                wsUserIds: [this.getAnalystIdByName(this.posForm.controls.analystName.value),...controls.commercialPosName.value, ...selectedPosIds]
            };
            this.store.dispatch(PointOfSaleActions.POSCreated({pos: payload}));

            this._actions$
                .pipe(ofType(POSCreatedSuccessfully))
                .subscribe((data: any) => {

                    this.getAllPaged();

                    this.loadingSubject$.next(false);

                    this.editPOSLine = -1;
                });

        }
    }

    editPOS(pos: PointOfSaleModel) {
        this.editPOSLine = pos.id;
        this.posForm.get('id').setValue(pos.id);
        this.posForm.get('namePos').setValue(pos.namePos);
        this.posForm.get('posCommune').setValue(pos.posCommune);
        this.posForm.get('addressPos').setValue(pos.addressPos);
        this.posForm.get('posWilaya').setValue(pos.posWilaya);
        this.posForm.get('posPhoneOne').setValue(pos.posPhoneOne);
        this.posForm.get('posPhoneTwo').setValue(pos.posPhoneTwo);
        this.posForm.get('codification').setValue(pos.codification);
        this.posForm.get('analystName').setValue(this.getAnalystName(pos));
        const selectedCommercialIds = pos.wsUserIds.filter(id =>
            this.commercialPoses.some(commercial => commercial.id === id)
        );
        this.posForm.get('commercialPosName').setValue(selectedCommercialIds);
        this.posForm.get('posPersonName').setValue(pos.wsUserIds);
        this.selectedRegion = pos.nameRegion;
        //this.posForm.get('region').setValue(this.getRegionName(pos.regionId));
    }

    getCommercialPosNames(pos: PointOfSaleModel): string[] {
        return pos.wsUserIds
            .filter(id => this.commercialPoses.find(c => c.id === id))
            .map(id => this.commercialPoses.find(c => c.id === id).username);
    }

    getRegionByName(name: string): number | undefined {
        if (name) {
            const region = this.regionList.find((i) => i[1] === name);
            return region[0];
        }
        return null;
    }

    getAnalystName(pos: PointOfSaleModel): string {
        for (const value of pos.wsUserIds) {
            if (this.analysts.find(item => item.id === value)) {
                return this.analysts.find(item => item.id === value).username
            }
        }
    }

    getPosNames(pos: PointOfSaleModel): SafeHtml {
        const names = pos.wsUserIds
            .map(id => {
                const matchedPos = this.POSES.find(item => item.id === id);
                return matchedPos ?
                    `<div style="margin-bottom: 8px; color: #333;">${matchedPos.username}</div>` : '';
            })
            .filter(name => name)
            .join(''); // No separator needed as each item is displayed on a new line
        return this.sanitizer.bypassSecurityTrustHtml(names);
    }

    cancelEditPOS() {
        this.editPOSLine = -1;
        this.posForm.reset();
    }

    forbiddenTownsValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (this.towns.length > 0) {
                const index = this.towns.findIndex(town => {
                    return (new RegExp('\^' + town.name + '\$')).test(control.value);
                });
                return index < 0 ? {'forbiddentowns': {value: control.value}} : null;
            }

        };
    }

    getAnalystIdByName(name: string): number | undefined {
        const analyst = this.analysts.find((i) => i.username === name);
        return analyst?.id;
    }

    getPosIdByName(name: string): number | undefined {
        const Pos = this.POSES.find((i) => i.username === name);
        return Pos?.id;
    }

    saveEditedPOS(pos) {
        const controls = this.posForm.controls;

        if (this.posForm.invalid) {
            Object.keys(this.posForm.controls).forEach(controlName =>
                this.posForm.controls[controlName].markAsTouched()
            );
            this.hasFormErrors = true;
            this.loadingSubject$.next(false);
            return;
        }
        const selectedPosIds = this.posForm.controls.posPersonName.value;


        const payload: PointOfSaleModel = {
            id: controls.id.value,
            addressPos: controls.addressPos.value,
            namePos: controls.namePos.value,
            posCommune: controls.posCommune.value,
            posPhoneOne: controls.posPhoneOne.value,
            posPhoneTwo: controls.posPhoneTwo.value,
            posWilaya: controls.posWilaya.value,
            status: 'ACTIVE',
            createdAt: '',
            updatedAt: '',
            codification: controls.codification.value,
            regionId: this.getRegionByName(this.selectedRegion),
            wsUserIds: [
                this.getAnalystIdByName(controls.analystName.value),
                this.getPosIdByName(controls.posPersonName.value),
                ...controls.commercialPosName.value,
                ...selectedPosIds
            ]
        };

        const updatePOS: Update<PointOfSaleModel> = {
            id: payload.id,
            changes: payload,
        };

        this.store.dispatch(PointOfSaleActions.POSUpdated({ pos: payload, partialPOS: updatePOS }));

        this.editPOSLine = -1;

    }




    getRegionsList() {
        this.regionService.getRegionList().subscribe({
            next: (res) => {
                this.regionList = res;
            }
        });
    }

    getRegionByWilaya(selectedWilaya: string) {
        this.regionService.getRegionNameByWilaya(selectedWilaya).subscribe({
            next: (res) => {
                this.selectedRegion = res;
                this.loadingSubject$.next(false);
            }
        });
    }

}
