import {BaseDataSource, QueryResultsModel} from "../../_base/crud";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import {
    selectFolderStateChangeInStore,
    selectFolderStateChangePageLoading,
    selectFolderStateChangeShowInitWaitingMessage
} from "../ek-selectors/FolderStateChange.selector";

export class FolderStateChangeDatasource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();
        this.loading$ = this.store.pipe(
            select(selectFolderStateChangePageLoading),
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectFolderStateChangeShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectFolderStateChangeInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }
}
