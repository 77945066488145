import {Component, Inject, OnInit} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {MY_FORMATS, WrongOrders} from "../../orders2/order-excel-monthly/order-excel-monthly.component";
import {MAT_DATE_LOCALE, MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {DatePipe} from "@angular/common";
import {PromotionComponent} from "../promotion/promotion.component";
import {finalize} from "rxjs/operators";
import {ConfigurationService} from "../../../../../../core/e-commerce";

@Component({
  selector: 'kt-prmotion-excel',
  templateUrl: './prmotion-excel.component.html',
  styleUrls: ['./prmotion-excel.component.scss'],
  providers: [

    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class PrmotionExcelComponent implements OnInit {
  dateSystem = new Date();
  private monthSysytem: number;
  public myDate = new Date();
  systemDate: Date;
  laodingExcel = false;
  messageSuccess = true;
  date: Date;
  error: string = '';
  wrongOrders: WrongOrders [];
  fileName = "export-promotion-monthly.xlsx";

  constructor(
      private dialogRef: MatDialogRef<PromotionComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private configurationService: ConfigurationService,
      private datePipe: DatePipe
  ) {
  }

  ngOnInit(): void {
    this.datePipe.transform(this.dateSystem, "yyyy-MM-dd");
    this.monthSysytem = this.dateSystem.getMonth();
  }

  monthSelected(event, dp, input) {
    dp.close();
    input.value = event.toISOString().split('-').join('/').substr(0, 7);
    const d = new Date();
    d.setFullYear(input.value.toString().substr(0, 4))
    d.setMonth(input.value.toString().substr(5, 6));
    d.setMonth(d.getMonth() - 1);
    this.date = d;
    this.error = '';
  }

  exportPromotionsExcelMonthly() {


    this.laodingExcel = true;
    this.configurationService.ExportExcelPromotionsMonthly(this.date).pipe(finalize(() => this.laodingExcel = false)).subscribe((response) => {
          if (response.message == 'success' && response.body) {
            const byteCharacters = atob(response.body);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers [i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            let blob = new Blob([byteArray], {type: 'application / vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob);
              return;
            }
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.download = `Promotions.xlsx`;
            anchor.href = url;

            anchor.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
            this.laodingExcel = false;
            setTimeout(function () {
              window.URL.revokeObjectURL(url);

              anchor.remove();

            }, 100)
          }
        },
    );

  }
}
