import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";

@Component({
  selector: "app-files-second-error-popup",
  templateUrl: "./files-second-error-popup.component.html",
  styleUrls: ["./files-second-error-popup.component.scss"],
})
export class FilesSecondErrorPopupComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<FilesSecondErrorPopupComponent>,
    private router: Router
  ) {}

  ngOnInit(): void {}
  closeDialog() {
    this.dialogRef.close();
  }

  goToSecondStep() {
    this.router.navigate([], {
      queryParams: { step: 2 },
    });
    this.closeDialog();
  }
}
