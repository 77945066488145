import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";
import {Action, createReducer, on} from "@ngrx/store";
import * as FolderStateChangeStateActions from './../ek-actions/FolderStateChange.actions';
import {ChangeHistoryModel} from "../ek-models/change-history-model";

export interface FolderStateChangeState extends EntityState<ChangeHistoryModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedPOSId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
    errorMessage: string;
}

export const adapter: EntityAdapter<ChangeHistoryModel> =
    createEntityAdapter<ChangeHistoryModel>();

export const initialFolderStateChangeState: FolderStateChangeState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedPOSId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
    errorMessage: '',
});
const posReducer = createReducer(
    initialFolderStateChangeState,
    on(FolderStateChangeStateActions.FolderStateChangePageRequested, (state, action) => ({
        ...state,
        actionsLoading: true,
        listLoading: true,
        showInitWaitingMessage: true,
        error: null,
        lastAction: '',
    })),
    on(FolderStateChangeStateActions.FolderStateChangePageLoadedSuccessfully, (state, action) =>
        adapter.addAll(action.FolderStatesChange, {
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            totalCount: action.totalCount,
        })
    ),
    on(FolderStateChangeStateActions.FolderStateChangePageLoadFailed, (state, action) => ({
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
        error: action.error,
    })),
);

export function FolderStateChangeReducer(state: FolderStateChangeState | undefined, action: Action) {
    return posReducer(state, action);
}

