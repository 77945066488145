import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';

@Component({
    selector: 'kt-circular-slider',
    templateUrl: './circular-slider.component.html',
    styleUrls: ['./circular-slider.component.scss']
})
export class CircularSliderComponent implements OnInit {

    @Input() scoreValue = 0;
    @Input() minThresHold = 0;
    @Input() maxThresHold = 0;

    red = "#ff6261";
    orange = "#ff893c";
    green = "#3eda91";

    arcWidth = 200;
    arcCenter = 250
    strokeWidth = 16;
    min = 0;
    max = 100;
    range = this.max - this.min;
    span1 = 0;
    span2 = 0;
    scoreColor = '';
    score = 0;
    margin = 0;
    scoreText = "";


    @ViewChild('sliderSvg') sliderSvg: ElementRef<SVGSVGElement>;
    @ViewChild('numberInput') numberInput: ElementRef<HTMLInputElement>;


    constructor(private renderer: Renderer2) {
    }

    ngOnInit() {
        this.scoreValue = 100;
        this.score = this.scoreValue;
        //if the Rafting threshold interval is [1;10] extend it to the interval [0;11].
        this.minThresHold = this.minThresHold === 1 ? 0 : this.minThresHold * 10;
        this.maxThresHold = this.maxThresHold === 10 ? 110 : this.maxThresHold * 10;
        this.span1 = this.minThresHold;
        this.span2 = this.maxThresHold;
    }

    ngAfterViewInit(): void {
        this.score = this.scoreValue;
        this.setSlides();
    }

    polarToCartesian(centerX, centerY, radius, angleInDegrees) {
        let angleInRadians = (angleInDegrees - 180) * Math.PI / 180.0;

        return {
            x: centerX + (radius * Math.cos(angleInRadians)),
            y: centerY + (radius * Math.sin(angleInRadians))
        };
    }

    describeArc(x, y, radius, startAngle, endAngle) {

        let start = this.polarToCartesian(x, y, radius, endAngle);
        let end = this.polarToCartesian(x, y, radius, startAngle);

        let largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

        let d = [
            "M", start.x, start.y,
            "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
        ].join(" ");

        return d;
    }

    moveCircle(x, y, radius, endAngle, color) {

        const svgElement = this.sliderSvg.nativeElement;
        const circle = svgElement.getElementById('circle');
        const textScore = svgElement.getElementById('score');
        const textStatus = svgElement.getElementById('status');

        let start = this.polarToCartesian(x, y, radius, endAngle);

        if (endAngle >= 0 || endAngle <= 180) {
            circle.setAttribute("cx", start.x);
            if (Number(start.y) === 300) {
                circle.setAttribute("cy", '282')
            } else {
                circle.setAttribute("cy", start.y)
            }
            circle.setAttribute("r", "15");
            circle.setAttribute("stroke", color);
            circle.setAttribute("stroke-width", "8");

            textScore.innerHTML = this.scoreText;
            textScore.setAttribute("fill", color);

            const tempRating = this.scoreValue / 10;
            textStatus.innerHTML = tempRating.toString();

        } else {
            this.renderer.setStyle(circle, 'display', 'none');
        }
    }

    filterRange(span) {

        span = span - this.min;
        span = Math.round(span / this.range * 180);
        return span;

    }

    alterArc(arc, color, start, end) {
        arc.setAttribute("d", this.describeArc(this.arcCenter, 300, this.arcWidth, start, end));
        arc.setAttribute("stroke", color);
        arc.setAttribute("stroke-width", this.strokeWidth.toString());

    }

    setSlides() {

        if (this.score <= this.span1) {
            this.scoreColor = this.green;
            this.scoreText = "à financer";
        } else if (this.score > this.span1 && this.score <= this.span2) {
            this.scoreColor = this.orange;
            this.scoreText = "à passer en comité";
        } else if (this.score > this.span2 && this.score <= this.max) {
            this.scoreColor = this.red;
            this.scoreText = "à ne pas financer";
        }


        this.span1 = this.filterRange(this.span1);
        this.span2 = this.filterRange(this.span2);
        this.max = this.filterRange(this.max);
        this.score = this.filterRange(this.score);

        let range1S = this.margin;
        let range1E = this.span1 - this.margin;
        let range2S = this.span1 + this.margin;
        let range2E = this.span2 - this.margin;
        let range5S = this.span2 + this.margin;
        let range5E = this.max - this.margin;

        // var arc0 = document.getElementById("arc0");
        let arc1 = document.getElementById("arc1");
        let arc2 = document.getElementById("arc2");
        let arc3 = document.getElementById("arc3");
        let arc4 = document.getElementById("arc4");
        let arc5 = document.getElementById("arc5");
        let circle = document.getElementById("circle");
        let textScore = document.getElementById("score");
        let textStatus = document.getElementById("status");
        this.moveCircle(this.arcCenter, 300, this.arcWidth, this.score, this.scoreColor);

        this.alterArc(arc1, this.green, range1S, range1E)
        this.alterArc(arc2, this.orange, range2S, range2E)

        //if the Rafting threshold interval is [1;10] no need to third arc.
        if (this.minThresHold > 0 && this.maxThresHold < 110) {
            this.alterArc(arc5, this.red, range5S, range5E)
        }
    }
}
