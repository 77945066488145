import {ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {OrderModel2} from "../../../../../../../core/e-commerce/_models/order2.model";
import {CustomerModel} from "../../../../../../../core/e-commerce/_models/customer.model";
import {DeliveryAddressService} from "../../../../../../../core/e-commerce/_services/delivery-address.service";
import {DeliveryAddressModel} from "../../../../../../../core/e-commerce/_models/delivery-address.model";
import {LayoutUtilsService, MessageType} from "../../../../../../../core/_base/crud";
import {DeliveryService} from "../../../../../../../core/e-commerce/_services/delivery.service";
import {OrdersService} from "../../../../../../../core/e-commerce/_services/orders.service";
import {CustomersService} from "../../../../../../../core/e-commerce";
import {NgxPermissionsService} from "ngx-permissions";
import {Observable} from "rxjs";
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
// @ts-ignore
import relay from "./relayPoints.json";
import {filter, map, startWith, take} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";
import {DeliveryModel} from "../../../../../../../core/e-commerce/_models/delivery.model";
import {OrderItemModel} from "../../../../../../../core/e-commerce/_models/OrderItem.model";
import {ConfigurationModel} from "../../../../../../../core/e-commerce/_models/configuration.model";
import {AddressesService} from "../../../../../../../core/e-commerce/_services/addresses.service";
import {Town} from "../../../../../../../core/e-commerce/_models/town";
import {
    forbiddenProvinceValidator,
    Wilaya,
    WilayaService
} from "../../../../../../../core/e-commerce/_services/wilaya.service";

export interface relayPointList {
    province: string;
    value: string;
    address: string[];
    tels: string[];
}


@Component({
    selector: 'kt-client-detail',
    templateUrl: './client-detail.component.html',
    styleUrls: ['./client-detail.component.scss']
})
export class ClientDetailComponent implements OnInit {

    @Output() goToHistoryClicked = new EventEmitter<void>();
    @Input() order$: Observable<OrderModel2>;
    @Output() newItemEvent = new EventEmitter<OrderModel2>();
    public wilayasList: Wilaya[] = [];
    public towns: Town[] = [];
    public pointDeRelaitList: relayPointList[] = relay;

    selectedWilaya = null
    selectedDeliveryType = null
    filteredOptions: Observable<Wilaya[]>;
    townFilteredOptions: Observable<Town[]>;
    filteredOptionsRely$: Observable<relayPointList[]>;

    showWilayaLabel = true;
    showTownLabel = true;
    deliveryPrice_: number = 0;
    selectedRelayPointAdr: string[];
    rows = 2;
    @ViewChild('autosize') autosize: CdkTextareaAutosize;

    $
    order: OrderModel2;

    customer: CustomerModel;
    // @Input() product:ProductModel;
    isEditable: boolean = true;

    //Permissions
    PERMISSIONS = ['ALL_ORDER', 'UPDATE_ORDER'];
    canEdit = false;

    customerForm: FormGroup;
    hasFormErrors = false;
    addingOrder = false;

    //payment method
    payTypesList = [
        {name: 'A la livraison', value: 'CASH_EN_DELIVERY'},
        {name: 'CIB/EDAHABIA', value: 'CIB_ELDAHABIA'},
        {name: 'Virement', value: 'VIREMENT'}
    ]

    constructor(private userFB: FormBuilder,
                private deliveryAddressService: DeliveryAddressService,
                private layoutUtilsService: LayoutUtilsService,
                private deliveryService: DeliveryService,
                private orderService: OrdersService,
                private wilayaService: WilayaService,
                private addressesService: AddressesService,
                private customerService: CustomersService,
                private cd: ChangeDetectorRef,
                private zone: NgZone,
                private ngxPermissionService: NgxPermissionsService,
                private route: ActivatedRoute,
                private router: Router
    ) {
    }

    ngOnInit(): void {

        if (this.route.url['value'][0].path == 'addOrder') {
            this.addingOrder = true

        }

        this.order$.pipe(filter(order => order.clientId != null)).subscribe(order => {
            this.customerService.getById(order.clientId).subscribe(reponse => {
                if (this.addingOrder) {
                    this.loadEmptyOrder(order)
                }
                else {
                    this.order = order;
                }

                // this.createForm();
                this.zone.run(() => {
                    this.customer = reponse;
                    this.createForm();
                    // NOTE: you may be able to delete this next line depending on your use case
                    this.cd.detectChanges();

                })
            });
        })

        this.wilayasList = this.wilayaService.getWilayasItems();

    }

    onRelayClick() {
        this.customerForm.get('relayPoint').reset();
        this.customerForm.get('addressRelayPoint').reset();
    }

    sendOrderdetails() {
        this.newItemEvent.emit(this.customerForm.value);
    }

    checkoutClient() {
        this.router.navigate(['/ecommerce/customers/edit/' + this.order.clientId])
    }

    /**
     * Create form
     */
    createForm() {

        if (this.addingOrder) {

            this.customerForm = this.userFB.group({

                firstname: [this.customer.firstname, Validators.required],
                lastname: [this.customer.lastname, Validators.required],
                email: [this.customer.email, Validators.required],
                phone: [this.customer.addresses [0] ? this.customer.addresses[0].phoneNumber : "", Validators.required],
                delivery_type: ['', Validators.required],
                relayPoint: [''],
                addressRelayPoint: [''],
                town: [''],
                wilaya: [''],
                addressLine1: [''],
                remarks: [this.customer ? this.customer.remark : ''],
                source: [''],
                deliveryFie: [this.order.delivery.deliveryPrice],
                paymentMethod:[this.order.paymentMethod],
            });

        } else {

            this.customerForm = this.userFB.group({
                firstname: [this.order.delivery.deliveryAddress.firstname, Validators.required],
                lastname: [this.order.delivery.deliveryAddress.lastname, Validators.required],
                email: [this.order.delivery.deliveryAddress.email ? this.order.delivery.deliveryAddress.email : this.customer.email, Validators.required],
                phone: [this.order.delivery.deliveryAddress.phone, Validators.required],
                delivery_type: [this.order.delivery.deliveryType, Validators.required],
                type_order: [this.order.orderType?this.order.orderType=="GUEST"?"Achat direct":this.order.orderType:''],
                relayPoint: [this.order.delivery.deliveryAddress.wilaya],
                addressRelayPoint: [this.order.delivery.deliveryAddress.addressLine1],
                town: new FormControl({value: this.order.delivery.deliveryAddress.ville}),
                wilaya: [this.order.delivery.deliveryAddress.wilaya],
                addressLine1: [this.order.delivery.deliveryAddress.addressLine1],
                remarks: [this.customer ? this.customer.remark : '',],
                source: [this.order ? this.order.source : ''],
                paymentMethod:[this.order ? this.order.paymentMethod : ''],
                deliveryFie: [this.order.delivery.deliveryPrice]
            });

            this.selectedWilaya = this.order.delivery.deliveryAddress.wilaya;
            this.selectedDeliveryType = this.order.delivery.deliveryType;
        }


        this.checkPermissionToUpdate();

        this.customerForm.get('wilaya').valueChanges.subscribe(value => {
            if (value === '') {
                this.showWilayaLabel = false;
            } else {
                this.showWilayaLabel = true;
            }
        });

        this.filteredOptionsRely$ = this.customerForm.get('relayPoint').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this._filterPointRelay(name.toString()) : this.pointDeRelaitList.slice())
            );

        this.filteredOptions = this.customerForm.get('wilaya').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this._filter(name.toString()) : this.wilayasList.slice())
            );


        this.customerForm.valueChanges.subscribe(() => {
            this.sendOrderdetails();
        });

        this.getWilayaTownsDefault(this.selectedWilaya);


        this.customerForm.get('town').valueChanges.subscribe(value => {
            this.getDeliveryFie(this.selectedWilaya);
            if (value === '') {
                this.showTownLabel = false;
            } else {
                this.showTownLabel = true;
            }
        });

        //store old delivery address
        let oldDeliveryAddress = {
            "price": this.order.delivery.deliveryPrice,
            "type": this.order.delivery.deliveryType,
            "wilaya": this.order.delivery.deliveryAddress.wilaya,
            "town": this.order.delivery.deliveryAddress.ville,
            "addressLine1": this.order.delivery.deliveryAddress.addressLine1
        }
        localStorage.setItem("previousAdress", JSON.stringify(oldDeliveryAddress));

    }

    /**
     * Returns prepared deliveryAddress
     */
    prepareDeliveryAddress() {
        const controls = this.customerForm.controls;
        const _deliveryAddress = new DeliveryAddressModel();
        _deliveryAddress.id = this.order.delivery.deliveryAddress.id;
        _deliveryAddress.orderId = this.order.id
        _deliveryAddress.firstname = controls.firstname.value;
        _deliveryAddress.lastname = controls.lastname.value;
        this.customer.email = controls.email.value;
        _deliveryAddress.phone = controls.phone.value;
        _deliveryAddress.wilaya = this.selectedWilaya;
        _deliveryAddress.addressLine1 = controls.addressLine1.value;
        _deliveryAddress.ville = this.selectedDeliveryType === 'POINT_DE_RELAIT' ? '' : controls.town.value;
        this.customer.remark = controls.remarks.value;
        return _deliveryAddress;
    }

    onSubmit() {
        this.getDeliveryFie(this.selectedWilaya);
        const adr = this.prepareDeliveryAddress();
        this.customerService.update(this.customer).subscribe();
        this.updateOrderPayMethode();
        this.addSourceToOrder();
        //update delivery adr
        this.deliveryAddressService.update(adr).subscribe(value => {
                const delivery = this.order.delivery;
                delivery.deliveryType = this.customerForm.controls.delivery_type.value;
                delivery.deliveryPrice = this.deliveryPrice_;
                this.deliveryService.update(delivery).subscribe(res => {
                    if (!this.addingOrder) {
                        if (this.deliveryPrice_)
                            this.deliveryService.updateDeliveryPrice(this.deliveryPrice_, delivery.id).subscribe();

                        const oldOrder = this.orderService.selectedOrderSubject.value;
                        oldOrder.delivery.deliveryType = delivery.deliveryType;
                        oldOrder.delivery.deliveryAddress = adr;
                        oldOrder.delivery.deliveryPrice = this.deliveryPrice_;
                        this.sendOrderdetails();
                        this.refresh();
                    }
                }, error => {
                    console.log('ERROR :' + JSON.stringify(error))
                })
                const message = `mise à jour avec succès`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
            }

            , error => this.layoutUtilsService.showActionNotification("Error Updating info" + error, MessageType.Create, 5000, false, false)
        )

    }

    /** update order payment methode */
    updateOrderPayMethode(){
        if ( this.customerForm.controls.paymentMethod.dirty && this.customerForm.controls.paymentMethod.value ){
            this.orderService.updateOrderPayMethod(this.order.id,this.customerForm.controls.paymentMethod.value).subscribe();
        }
    }

    addSourceToOrder(){
        if (this.customerForm.controls.source.value) {
            this.orderService.addSourceToOrder(this.order.id, this.customerForm.controls.source.value).subscribe();
        }
    }
    /**
     * Close Alert
     *
     * @param $event: Event
     */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    /**
     * Disable the form if the used doesnt have the permission to update
     */
    checkPermissionToUpdate() {
        this.ngxPermissionService.hasPermission(this.PERMISSIONS).then(hasPermission => {
            if (!hasPermission) {
                this.customerForm.disable();
                this.canEdit = false;
            } else
                this.canEdit = true;
        });
    }

    refresh() {
        this.orderService.getById(this.order.id).subscribe(res => {
            this.orderService.selectedOrderSubject.next(this.order);
        });
    }

    loadEmptyOrder(order) {
        this.order = new OrderModel2();
        this.order.clientId = order.clientId
        this.order.delivery = new DeliveryModel()
        this.order.delivery.deliveryAddress = new DeliveryAddressModel()
        this.order.orderItems = [new OrderItemModel()];
        this.order.orderItems[0].configurationTO = new ConfigurationModel()
    }

    checkControlValidation() {
        if (this.customerForm.invalid) {
            this.hasFormErrors = true
            Object.keys(this.customerForm.controls).forEach(controlName =>
                this.customerForm.controls[controlName].markAsTouched()
            );
        }
        return this.customerForm.valid
    }

    selectDeliveryOption(event) {

        this.selectedDeliveryType = event.value;

        if (this.selectedWilaya != null)
            this.getDeliveryFie(this.selectedWilaya);

        //clear selected wilaya and town
        this.customerForm.get('town').reset();
        this.customerForm.get('wilaya').reset();

        //make all validators not required
        this.customerForm.get('wilaya').clearValidators();
        this.customerForm.get('town').clearValidators();
        this.customerForm.get('addressLine1').clearValidators();
        this.customerForm.get('relayPoint').clearValidators();
        this.customerForm.get('addressRelayPoint').clearValidators();

        switch (this.selectedDeliveryType) {
            case "POINT_DE_RELAIT":
                this.customerForm.get('addressRelayPoint').setValidators([Validators.required]);
                this.customerForm.get('relayPoint').setValidators(Validators.compose([Validators.required, this.forbiddenRelayPointValidator(this.pointDeRelaitList)]));

                break;
            case "DOMICILE" || "DELIVERY_BY_TAXI":
                this.customerForm.get('wilaya').setValidators(Validators.compose([Validators.required, forbiddenProvinceValidator(this.wilayasList)]));
                this.customerForm.get('town').setValidators(Validators.compose([Validators.required, this.forbiddenTownsValidator()]));
                this.customerForm.get('addressLine1').setValidators([Validators.required]);

                //keep selected address in delivery type switch
                const previousAdress = JSON.parse(localStorage.getItem('previousAdress'));
                this.customerForm.get('wilaya').setValue(previousAdress.wilaya);
                this.customerForm.get('town').setValue(previousAdress.town);
                this.customerForm.get('addressLine1').setValue(previousAdress.addressLine1);
                this.deliveryPrice_ = previousAdress.price;
                break;
        }

        this.customerForm.controls['wilaya'].updateValueAndValidity();
        this.customerForm.controls['town'].updateValueAndValidity();
        this.customerForm.controls['addressLine1'].updateValueAndValidity();
        this.customerForm.controls['relayPoint'].updateValueAndValidity();
        this.customerForm.controls['addressRelayPoint'].updateValueAndValidity();
    }

    getDeliveryFie(wilaya) {
        if (wilaya && this.selectedDeliveryType) {
            this.deliveryService.getDeliveryFie(wilaya, this.selectedDeliveryType).subscribe(
                res => {
                    this.customerForm.value.deliveryFie = res['body']
                    this.deliveryPrice_ = res['body'];
                    this.sendOrderdetails()
                }
            )
        }
    }

    getWilayaTowns(event) {

        this.selectedWilaya = event.option.value;

        //reset towns
        this.customerForm.get('town').reset();

        if (this.customerForm.controls.wilaya.dirty) {
            this.customerForm.controls.town.enable();
        }
        this.getTowns(this.wilayasList.filter(value => {
            if (value.value == this.selectedWilaya)
                return value
        })[0].id)

        if (this.selectedDeliveryType != null)
            this.getDeliveryFie(this.selectedWilaya);

    }

    getTowns(wilayaId) {
        this.wilayaService.getTownsByWilayaId(wilayaId).subscribe(res => {
            this.towns = res;
            this.townFilteredOptions = this.customerForm.get('town').valueChanges
                .pipe(
                    startWith(''),
                    map(value => typeof value === 'string' ? value : value),
                    map(name => name ? this.townFilter(name.toString()) : this.towns.slice())
                );
        })
    }

    private townFilter(name: string): Town[] {
        const filterValue = name.toLowerCase();
        return this.towns.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    private _filter(name: string): Wilaya[] {
        const filterValue = name.toLowerCase();
        return this.wilayasList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    private _filterPointRelay(name: string): relayPointList[] {
        const filterValue = name.toLowerCase();
        return this.pointDeRelaitList.filter(option => option.value.toLowerCase().indexOf(filterValue) === 0);
    }

    getWilayaTownsDefault(selectedWilaya) {
        if (this.selectedWilaya) {
            this.customerForm.get('town').setValue(this.order.delivery.deliveryAddress.ville);

            if (this.customerForm.controls.wilaya.dirty) {
                this.customerForm.controls.town.enable();
            }

            this.getTowns(this.wilayasList.filter(value => {
                if (value.value == selectedWilaya)
                    return value
            })[0].id)
        }
    }

    forbiddenTownsValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const index = this.towns.findIndex(town => {
                return (new RegExp('\^' + town.name + '\$')).test(control.value);
            });
            return index < 0 ? {'forbiddentowns': {value: control.value}} : null;
        };
    }

    relayPointChange(value) {
        let selectedRelay = this.pointDeRelaitList.find(item => item.value === value);
        let wilaya = selectedRelay.value;
        switch (selectedRelay.value) {
            case 'ALGER2':
                wilaya = 'ALGER';
                break;
            case 'ORAN2':
                wilaya = 'ORAN';
                break;
            case 'OUARGLA2':
                wilaya = 'OUARGLA'
                break;
            case 'OUARGLA3':
                wilaya = 'OUARGLA'
                break;

            default:
                wilaya = selectedRelay.value
                break;
        }
        this.selectedRelayPointAdr = selectedRelay.address;
        this.selectedWilaya = wilaya;
        this.getDeliveryFie(wilaya);
        this.customerForm.get('addressRelayPoint').setValue(selectedRelay.address);
    }

    forbiddenRelayPointValidator(relayPoint: any[]): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            // below findIndex will check if control.value is equal to one of our options or not
            const index = relayPoint.findIndex(relayPoint => {
                return (new RegExp('\^' + relayPoint.value + '\$')).test(control.value);
            });
            return index < 0 ? {'forbiddenRelayPoints': {value: control.value}} : null;
        };
    }
}
