import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";
import {Action, createReducer, on} from "@ngrx/store";
import * as changeHistoryActions from './../ek-actions/change-history.actions';
import {ChangeHistoryModel} from "../ek-models/change-history-model";

export interface ChangeHistoryState extends EntityState<ChangeHistoryModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedPOSId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
    errorMessage: string;
}

export const adapter: EntityAdapter<ChangeHistoryModel> =
    createEntityAdapter<ChangeHistoryModel>();

export const initialChangeHistoryState: ChangeHistoryState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedPOSId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
    errorMessage: '',
});
const posReducer = createReducer(
    initialChangeHistoryState,
    on(changeHistoryActions.ChangeHistoryPageRequested, (state, action) => ({
        ...state,
        actionsLoading: true,
        listLoading: true,
        showInitWaitingMessage: true,
        error: null,
        lastAction: '',
        lastQuery: action.page,
    })),
    on(changeHistoryActions.ChangeHistoryPageLoadedSuccessfully, (state, action) =>
        adapter.addAll(action.ChangeHistory, {
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            totalCount: action.totalCount,
        })
    ),
    on(changeHistoryActions.ChangeHistoryPageLoadFailed, (state, action) => ({
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
        error: action.error,
    })),
);

export function changeHistoryReducer(state: ChangeHistoryState | undefined, action: Action) {
    return posReducer(state, action);
}

