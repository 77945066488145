import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { NgxPermissionsService } from 'ngx-permissions';

import { LayoutUtilsService, MessageType } from '../../../../../../../core/_base/crud';
import { DiscountModel, ProductsService } from '../../../../../../../core/e-commerce';
import { OrderModel2 } from '../../../../../../../core/e-commerce/_models/order2.model';
import { OrderItemModel } from '../../../../../../../core/e-commerce/_models/OrderItem.model';
import { DeliveryService } from '../../../../../../../core/e-commerce/_services/delivery.service';
import { ImagesService } from '../../../../../../../core/e-commerce/_services/images.service';
import { OrdersService } from '../../../../../../../core/e-commerce/_services/orders.service';
import { CustomerDiscountService } from './../../../../../../../core/e-commerce/_services/customer-discount.service';
import { AddOrderProductDialogComponent } from './add-order-product-dialog/add-order-product-dialog.component';
import { LicenseKeysPopUpComponent } from './license-keys-pop-up/license-keys-pop-up.component';
import {OrdersListDialogComponent} from "../../orders-list-dialog/orders-list-dialog.component";


@Component({
    selector: 'kt-order-products',
    templateUrl: './order-products.component.html',
    styleUrls: ['./order-products.component.scss']
})

export class OrderProductsComponent implements OnInit {
    order: OrderModel2;
    addingOrder = false;
   // orderItems: OrderItemModel [];
    orderItems : OrderItemModel[];
    defaultWidth = 200;
    matTableMaxHeight;

    disabled: boolean = false;
    isDeliveryEditMode: boolean = false;
    masqueTest:boolean
    showOrderProductsOf: number = null;
    shownProducts: any[] = [];
    justify="end"
    // Table fields
    dataSource = new MatTableDataSource<OrderItemModel>();
    dataSource2 = new MatTableDataSource<OrderModel2>();
    productIndex: number;
    total: number;
    beforeTotal: number;
    currentOrderItemEuro:any;
    currentOrderItemDollar:any;
    deliveryCost: number = 0;
    displayedColumns = ['productName', 'image', 'sellingPrice', 'quantity', 'totalPrice', 'activatKey', 'actions'];
    columns = [
        {
            columnDef: 'products',
            type: 'products',
            header: 'Produits',
            cell: (element: OrderItemModel) => `${element.configurationTO.name }`,
            selected: true
        },
        {
            "columnDef": 'image',
            header: 'Image',
            cell: (element: OrderItemModel) => ``,
            selected: true
        },
        {
            "columnDef": 'prixRemise',
            header: 'PrixRemise',

            cell: (element: OrderModel2) => ``,
            selected: true
        },
        {
            "columnDef": 'sellingPrice',
            header: 'Prix',
            cell: (element: OrderItemModel) => `${element.sellingPrice/ element.quantity }`,
            selected: true
        },
        {
            "columnDef": 'quantity',
            header: 'Quantity',
            cell: (element: OrderItemModel) => ``,
            selected: true
        },
        {
            "columnDef": 'key',
            header: 'Clé',
            cell: (element: OrderItemModel) => `${element.configurationTO.name}`,
            selected: false
        },
        {
            "columnDef": 'dollar',
            header: 'Dollar',
            cell: (element: OrderItemModel) => `${element.sellingPriceDollar/element.quantity}`,
            selected: false
        },

        {
            "columnDef": 'euro',
            header: 'Euro',
            cell: (element: OrderItemModel) => `${element.sellingPriceEuro/element.quantity}`,
            selected: false
        },

        {
            "columnDef": 'totalPrice',
            header: 'TotalPrix',
            cell: (element: OrderItemModel) => ``,
            selected: true
        },
        {
            "columnDef": 'action',
            cell: (element: OrderItemModel) => ``,
            selected: true
        },
  ]
    orderResumedColumns = ['subTotal', 'delivery', 'total', 'isEdit'];

    //Permissions
    PERMISSIONS = ['ALL_ORDER', 'UPDATE_ORDER'];
    canEdit = false;
    displayedColumn = this.columns.filter(e => e.selected).map(c => c.columnDef);

    discounts: DiscountModel[] = [];
    discountClientValid = false;


    constructor(private orderService: OrdersService,
                private productService: ProductsService,
                private changeDetectorRefs: ChangeDetectorRef,
                private layoutUtilsService: LayoutUtilsService,
                private dialog: MatDialog,
                private ngxPermissionService: NgxPermissionsService,
                private route: ActivatedRoute,
                private imagesService: ImagesService,
                private fb: FormBuilder,
                private clipboardApi: ClipboardService,
                private deliveryService: DeliveryService,
                private customerDiscountService: CustomerDiscountService,
                private router: Router
    ) {
    }

    ngOnInit() {
        if (this.route.url['value'][0].path == 'addOrder')
            this.addingOrder = true;


        this.orderService.selectedOrder$.subscribe(order => {
            this.order = order;

            this.deliveryCost = this.order.delivery.deliveryPrice;
            this.orderItems = this.order.orderItems;
            this.dataSource.data = this.order.orderItems;
            this.dataSource2.data = [this.order];

        })
            if(this.order.clientId) {
                this.customerDiscountService.checkDiscountClient(this.order.clientId).subscribe(res => {
                    if (res.body) {
                        this.discountClientValid = true
                    } else {
                        this.discountClientValid = false
                    }
                })
            };

        this.matTableMaxHeight = 200;

        this.checkPermissionToUpdate();

        //test if there is euro dollar prix
        if(this.currentOrderItemDollar ||this.currentOrderItemEuro){
            this.masqueTest=true
        }
        if (this.addingOrder) {
            this.deliveryCost = 0;
        }

        if(this.orderItems) {
            this.orderItems.forEach(i => {
                if(i) {
                    if (i.configurationTO?.discount) {
                        this.discounts.push(i.configurationTO.discount)
                    } else {
                        return
                    }
                }
            })
        }
    }

    deleteOrderItem(id: number, index: number) {
        if (this.addingOrder) {
            this.order.orderItems.splice(index, 1);
            this.dataSource.data = this.order.orderItems;
            this.calculateTotal()
        } else {

            const _title: string = 'Delete Order Item';
            const _description: string = 'You want to delete this Item ?';
            const _waitDesciption: string = 'Deleting Order Item ...';

            const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
            dialogRef.afterClosed().subscribe(res => {
                if (!res) {
                    return;
                }
                this.orderService.deleteOrderItem(id).subscribe(result => {
                    if (result.code == 0)
                        this.layoutUtilsService.showActionNotification('Le porduit a été Supprimé', MessageType.Delete);
                    else if (result.code == 8)
                        this.layoutUtilsService.showActionNotification('Une commande doit contenir au moins un produit!', MessageType.Delete, 10000, true, false);
                    else
                        this.layoutUtilsService.showActionNotification('Une erreur s\'est produite!', MessageType.Delete, 10000, true, false);
                    this.refresh()
                })
            });

        }
    }

    /**
     * Disable the form if the used doesnt have the permission to update
     */
    checkPermissionToUpdate() {
        this.ngxPermissionService.hasPermission(this.PERMISSIONS).then(hasPermission => {
            this.canEdit = hasPermission;
        });
    }


    addOrderItem() {
        const dialogRef = this.dialog.open(AddOrderProductDialogComponent, {
            data: {order: this.order, addingOrder: this.addingOrder}
        });
        dialogRef.afterClosed().subscribe(res => {
            if (this.addingOrder) {
                if (res) {
                    this.addItemToEmptyOrder(res)
                    this.calculateTotal()
                }
            } else
                this.refresh();
        })
    }

    updateQuantity(index, orderItemId: number, quantity: string) {
        let newQuantity = Number(quantity);
        this.orderService.updateOrderItemQuantity(orderItemId, newQuantity).subscribe(res => {
            if (res) {
                this.layoutUtilsService.showActionNotification('quantity updated successfully', MessageType.Update);
                this.refresh();
            }
        })
    }

    refresh() {

        this.orderService.getById(this.order.id).subscribe(res => {
            res.orderItems = res.orderItems.sort((a, b) => a.orderProductId - b.orderProductId);
            this.dataSource.data = res.orderItems;
            this.dataSource2.data = [res];
            this.orderService.selectedOrderSubject.next(res);
        })

    }

    addItemToEmptyOrder(res) {

        const found = this.order.orderItems.find((element) => {
            return element.configurationTO.id === res.configuration.id;
        });

        if (found) {
            this.order.orderItems.find(item => {
                if (item.configurationTO.id === found.configurationTO.id) {
                    item.quantity++;
                    item.sellingPrice = found.configurationTO.price * item.quantity;
                }
            });
        } else {
            let item = new OrderItemModel();
            item.configurationTO = res.configuration;
            item.productName = res.configuration.productName
            item.sellingPrice = res.configuration.price * res.quantity;
            item.quantity = res.quantity
            this.order.orderItems.push(item)
        }

        this.dataSource.data = this.order.orderItems;
    }

    changeQuantity(index, quantity) {
        if (this.addingOrder) {
            this.order.orderItems[index].sellingPrice = this.order.orderItems[index].sellingPrice * quantity / this.order.orderItems[index].quantity
            this.order.orderItems[index].quantity = quantity;
            this.calculateTotal()
        }
    }

    calculateTotal() {
        this.order.total = 0
        this.order.orderItems.forEach(value => {
            this.order.total = this.order.total + value.sellingPrice
        })
        this.order.totalTTC = this.order.total + ((this.order.delivery.deliveryPrice == null) ? 0 : this.order.delivery.deliveryPrice)
    }

    editDeliveryPrice(delivery) {
        this.isDeliveryEditMode = false;
        this.deliveryService.updateDeliveryPrice(this.deliveryCost, delivery.id).subscribe(res => {
            if (res) {
                const message = `le prix de livraison a été modifié avec succès `;
                this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
                this.refresh();
            }
        });

    }

    getNewTotal(data: any) {
        this.total = data.body.total
        this.order = data.body
        this.refresh()
    }
    /** personaliser l'affichage de table itemOrder  */
    showSettings() {
        const dialogRef = this.dialog.open(OrdersListDialogComponent, {
            width: '600px',
            data: this.columns,
        });

        dialogRef.afterClosed().subscribe(rep => {
            if (rep) {
                this.displayedColumn = [];
                this.columns = rep;
                localStorage.setItem('saved-order', JSON.stringify(this.columns));
                this.columns.forEach(e =>{
                    if (e.selected){
                        this.displayedColumn.push(e.columnDef);
                    }
                });
            }
        })
    }
    showProductsOf(orderId: number) {
        //TODO retrieve the products name
        if (this.showOrderProductsOf === null) {
            this.showOrderProductsOf = orderId;
            this.shownProducts = [];
           // this.dataSource.data.find(o => o.id === orderId).  .map(p => this.shownProducts.push(p));
            this.shownProducts.splice(0, 1);
        } else {
            this.showOrderProductsOf = null;
        }
    }

    checkoutClient() {
        this.router.navigate(['/ecommerce/customers/edit/' + this.order.clientId])
    }

    showPromotionCodeDetails() {
        if (this.order.promoCodeTO.id)
            this.router.navigate(['/ecommerce/promotionCode/edit/' + this.order.promoCodeTO.id])
    }
}
