import {createAction, props} from "@ngrx/store";
import {Update} from "@ngrx/entity";
import {CollaboratorModel} from "../_models/collaborator.model";


export const CollaboratorsPageRequested = createAction('[Collaborator List Page] Collaborators Page Requested ',props<{page: number, size: number}>());
export const CollaboratorsPageLoadedSuccessfully = createAction('[Collaborator API] Collaborators Page Loaded Successfully ',props<{collaborators: CollaboratorModel[],totalCount: number, page: number}>());
export const CollaboratorsPageLoadFailed = createAction('[Collaborator API] Collaborators Page Request Failed ',props<{error: any}>());

export const CollaboratorCreated = createAction('[Collaborator List Page] Collaborator Created ',props<{collaborator: CollaboratorModel}>());
export const CollaboratorCreatedSuccessfully = createAction('[Collaborator List Page] Collaborator Created Successfully ',props<{collaborator: CollaboratorModel}>());
export const CollaboratorCreationFailed = createAction('[Collaborator List Page] Collaborator Creation Failed ',props<{error: any}>());

export const CollaboratorUpdated = createAction('[Collaborator List Page] Collaborator Updated ',props<{collaborator: CollaboratorModel, partialCollaborator: Update<CollaboratorModel>}>());
export const CollaboratorUpdatedSuccessfully = createAction('[Collaborator List Page] Collaborator Updated Successfully ',props<{collaborator: CollaboratorModel, partialCollaborator: Update<CollaboratorModel>}>());
export const CollaboratorUpdateFailed = createAction('[Collaborator List Page] Collaborator Update Failed ',props<{error: any}>());

export const CollaboratorDeleted = createAction('[Collaborator List Page] Collaborator Deleted ',props<{collaboratorId: number}>());
export const CollaboratorDeletedSuccessfully = createAction('[Collaborator List Page] Collaborator deleted Successfully ',props<{collaboratorId: number}>());
export const CollaboratorDeleteFailed = createAction('[Collaborator List Page] Collaborator delete Failed ',props<{error: any}>());


