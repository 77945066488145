import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mensual'
})
export class MonthlyPaymentPipe implements PipeTransform {
    transform(value: number, numberOfMonths: number): number {
        const numberOfYears = numberOfMonths/12
        const tauxAnnuel = 0.10408
        const tauxAnnuel2 = 1.10408
        const mensual = (value * tauxAnnuel) / (1 - Math.pow( tauxAnnuel2,-numberOfYears))
        return mensual/12
    }

}
