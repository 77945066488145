import { Injectable } from '@angular/core';
import {AbstractService} from "../../services/abstract-service.service";
import {ArchivedConfigurationModel} from "../_models/archived-configuration.model";
import {HttpClient} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {Page} from "../../_base/crud/models/page";

@Injectable()
export class ArchivedConfigurationService extends AbstractService<ArchivedConfigurationModel>{

  url: string;

  constructor(protected http: HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}product-configuration/archived`;
  }



}
