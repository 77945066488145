import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {BrandsService} from "../_services/brands.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import * as BrandActions from "../_actions/brand.action";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";


@Injectable()
export class BrandEffects {


    constructor(private actions$: Actions, private brandService: BrandsService, private store: Store<AppState>) {
    }

    BrandPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BrandActions.BrandsPageRequested),
            switchMap((action) =>
                this.brandService.getAllPaged(action.page, action.size)
                    .pipe(
                        map(brandPage => BrandActions.BrandsPageLoadedSuccessfully(
                            {
                                brands: brandPage.content,
                                page: brandPage.totalElements,
                                totalCount: brandPage.totalElements
                            })),
                        catchError(error => of(BrandActions.BrandsPageLoadFailed({error})))
                    ))));

    BrandCreated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BrandActions.BrandCreated),
            switchMap((action) =>
                this.brandService.save(action.brand)
                    .pipe(
                        map(data => BrandActions.BrandCreatedSuccessfully({brand: data})),
                        catchError(error => of(BrandActions.BrandCreationFailed({error})))
                    ))));

    BrandUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BrandActions.BrandUpdated),
            switchMap((action) =>
                this.brandService.update(action.brand)
                    .pipe(
                        map(brand => BrandActions.BrandUpdatedSuccessfully({
                            brand,
                            partialBrand: action.partialBrand
                        })),
                        catchError(error => of(BrandActions.BrandUpdateFailed({error})))
                    ))));

                    BrandDeleted$ = createEffect(() =>
                        this.actions$.pipe(
                            ofType(BrandActions.BrandDeleted),
                            switchMap((action) =>
                                this.brandService.delete(action.brandId).pipe(
                                    map((response) => {
                                        if (response.body) {
                                            // If body is NOT null, dispatch failure action
                                            return BrandActions.BrandDeleteFailed({ error: 'Brand can’t be deleted' });
                                        } else {
                                            // If body is null, dispatch success action
                                            return BrandActions.BrandDeletedSuccessfully({ brandId: action.brandId });
                                        }
                                    }),
                                    catchError(error => of(BrandActions.BrandDeleteFailed({ error: error.message || 'Unknown error' })))
                                )
                            )
                        )
                    );
                    
}
