import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatSort} from "@angular/material/sort";
import {Subscription} from "rxjs";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AppState} from "../../../../../../core/reducers";
import {Store} from "@ngrx/store";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {
  FolderStateChangeDatasource
} from "../../../../../../core/ek-e-commerce/ek-data-sources/folderStateChange.datasource";
import {
  FolderStateChangePageRequested
} from "../../../../../../core/ek-e-commerce/ek-actions/FolderStateChange.actions";


@Component({
  selector: 'kt-folder-state-change-details',
  templateUrl: './folder-state-change-details.component.html',
  styleUrls: ['./folder-state-change-details.component.scss']
})
export class FolderStateChangeDetailsComponent implements OnInit {

  clientName: string;
  dataSource: FolderStateChangeDatasource;
  displayedColumns: string[] = ['update-date', 'by-user', 'prev-state', 'last-state'];
  //sorting table
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  private subscriptions: Subscription[] = [];

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: { folderId: number, clientFirstName: string, clientLastName: string },
      public dialogRef: MatDialogRef<FolderStateChangeDetailsComponent>,
      public dialog: MatDialog,
      private activatedRoute: ActivatedRoute,
      private store: Store<AppState>,
  ) {
  }

  ngOnInit(): void {

    //init dataSource
    this.dataSource = new FolderStateChangeDatasource(this.store);



    this.activatedRoute.paramMap.subscribe((res: ParamMap) => {
      this.getAllPaged();
    });

  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
  }

  getAllPaged() {
    this.store.dispatch(FolderStateChangePageRequested({
      idFolder: this.data.folderId,
    }));
  }

}
