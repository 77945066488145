import { BaseModel } from '../../_base/crud';
import {Role} from "./role.model";
import {PointOfSale} from "../../../../../../ek-guichet/src/app/Models/point-of-sale";


export class User extends BaseModel {
    id: number;
    username: string;
    password: string ;
    email: string;
    roles: string;
    firstname: string;
    lastname: string;
    state: number;
    createdAt: string;
    updatedAt: string;
	phone: string;
    address: string;
    pic: string;
    pointOfSales: PointOfSale[];
    codification: string;

    clear(): void {
        this.id = undefined;
        this.username = '';
        this.password = '';
        this.email = '';
        this.roles = '';
        this.firstname = '';
        this.lastname = '';
        this.state = 0;
        this.createdAt = '';
        this.updatedAt = '';
        this.pic = './assets/media/users/default.jpg';
        this.phone = '';
        this.address = '';
        this.pointOfSales = [];
        this.codification = '';
    }
}
