// Anglar
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
// Layout Directives
// Services
import {
    ContentAnimateDirective,
    DiscountRemainingDays,
    FirstLetterPipe,
    GetObjectPipe,
    HeaderDirective,
    IsUsedConverter,
    JoinPipe,
    MenuDirective,
    MonthlyPaymentPipe,
    OffcanvasDirective,
    OnlyNumbersDirective,
    OnlyXdegitisDirective,
    PhoneNumberMaxLengthDirective,
    RoundUpPipe,
    RoundUpTo5,
    SafePipe,
    ScrollTopDirective,
    SparklineChartDirective,
    StickyDirective,
    TabClickEventDirective,
    TimeElapsedPipe,
    ToggleDirective
} from './_base/layout';
import {UserService} from "./services/user.service";
import {JwtInterceptor} from "./_base/crud/utils/jwt.interceptor";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {
    ArchivedConfigurationService,
    CategoriesService,
    ConfigurationService,
    CustomersService,
    DiscountsService,
    FooterInformationService,
    NotificationService,
    PhoneNumberService,
    PointRelaitService,
    PromoCodeService
} from "./e-commerce";
import {AddressesService} from "./e-commerce/_services/addresses.service";
import {BrandsService} from "./e-commerce/_services/brands.service";
import {ConfigurationCharacteristicsService} from "./e-commerce/_services/configuration-characteristics.service";
import {CharacteristicsService} from "./e-commerce/_services/characteristics.service";
import {ImagesService} from "./e-commerce/_services/images.service";
import {OrdersService} from "./e-commerce/_services/orders.service";
import {CarouselsService} from "./home-page/carousel/carousels.service";
import {OrderproductsService} from "./e-commerce/_services/orderproducts.service";
import {DeliveryAddressService} from "./e-commerce/_services/delivery-address.service";
import {CallService} from "./e-commerce/_services/calls.service";
import {DeliveryService} from "./e-commerce/_services/delivery.service";
import {EffectsModule} from '@ngrx/effects';
import {RegionEffects} from './e-commerce/_effects/region.effects';
import {ParcelEffects} from './e-commerce/_effects/parcel.effects';
import {ParcelStatesEffects} from "./e-commerce/_effects/parcelStates.effects";
import {CollaboratorService} from "./e-commerce/_services/collaborator.service";
import {CommentService} from "./e-commerce/_services/comment.service";
import {CustomerDiscountService} from "./e-commerce/_services/customer-discount.service";
import {FoldersService} from "./ek-e-commerce/ek-services/folders.service";
import {ClientsService} from "./ek-e-commerce/ek-services/clients.service";
import {EkFooterInformationService} from "./ek-e-commerce/ek-services/ek-footer-information.service";
import {FilterUrlPipe} from './_base/layout/pipes/filter-url.pipe';
import {GetIdPipe} from './_base/layout/pipes/get-id.pipe';
import {DragDirective} from './_base/layout/directives/drag.directive';
import {ClientOcrInformationService} from "./ek-e-commerce/ek-services/clientOcrInformation.service";
import {GenderTranslatePipe} from "./_base/layout/pipes/gender-translate.pipe";
import {ChecklistfilesTranslatePipe} from "./_base/layout/pipes/checklistfiles-translate.pipe";
import {FilterStatePipe} from "./_base/layout/pipes/filter-state.pipe";
import {DecimalFormatPipe} from './_base/layout/pipes/decimal-format.pipe';
import {TranslateFolderStatePipe} from './_base/layout/pipes/translate-folder-state.pipe';
import {MonthlyPayCPAPipe} from './_base/layout/pipes/monthly-pay-cpa.pipe';
import {SmsHistoryService} from "./ek-e-commerce/ek-services/smsHistory.service";
import {OrderStatePipe} from "./_base/layout/pipes/order-state.pipe";
import {TranslateFolderOrderStatePipe} from "./_base/layout/pipes/translate-folder-order-state.pipe";
import {TranslateNotificationPipe} from './_base/layout/pipes/translate-notification.pipe';
import {NumberInputDirective} from './_base/layout/directives/number-input.directive';
import {NameRolePipe} from "./_base/layout/pipes/name-role.pipe";
import {DateToStringFormatPipe} from './_base/layout/pipes/date-to-string-format.pipe';
import {TranslateFamilyStatePipe} from './_base/layout/pipes/translate-family-state.pipe';
import {SocioProfessionalService} from "./ek-e-commerce/ek-services/socioProfessional.service";
import {ScaleIntervalService} from "./ek-e-commerce/ek-services/scaleInterval.service";
import {CoefficientsWilayaService} from "./ek-e-commerce/ek-services/coefficientsWilaya.service";

@NgModule({
    imports: [CommonModule, EffectsModule.forFeature([RegionEffects, ParcelEffects, ParcelStatesEffects])],
    declarations: [
        // directives
        ScrollTopDirective,
        HeaderDirective,
        PhoneNumberMaxLengthDirective,
        OnlyXdegitisDirective,
        OnlyNumbersDirective,
        OffcanvasDirective,
        ToggleDirective,
        MenuDirective,
        TabClickEventDirective,
        SparklineChartDirective,
        ContentAnimateDirective,
        StickyDirective,
        // pipes
        TimeElapsedPipe,
        JoinPipe,
        GetObjectPipe,
        SafePipe,
        IsUsedConverter,
        FirstLetterPipe,
        RoundUpPipe,
        RoundUpTo5,
        DiscountRemainingDays,
        MonthlyPaymentPipe,
        FilterUrlPipe,
        FilterStatePipe,
        GetIdPipe,
        DragDirective,
        GenderTranslatePipe,
        ChecklistfilesTranslatePipe,
        DecimalFormatPipe,
        TranslateFolderStatePipe,
        TranslateFolderOrderStatePipe,
        MonthlyPayCPAPipe,
        OrderStatePipe,
        TranslateNotificationPipe,
        NumberInputDirective,
        NameRolePipe,
        DateToStringFormatPipe,
        TranslateFamilyStatePipe,
    ],
    exports: [
        // directives
        ScrollTopDirective,
        HeaderDirective,
        PhoneNumberMaxLengthDirective,
        OnlyXdegitisDirective,
        OnlyNumbersDirective,
        OffcanvasDirective,
        ToggleDirective,
        MenuDirective,
        TabClickEventDirective,
        SparklineChartDirective,
        ContentAnimateDirective,
        StickyDirective,
        NumberInputDirective,
        // pipes
        TimeElapsedPipe,
        JoinPipe,
        GetObjectPipe,
        SafePipe,
        IsUsedConverter,
        FirstLetterPipe,
        RoundUpPipe,
        RoundUpTo5,
        DiscountRemainingDays,
        MonthlyPaymentPipe,
        FilterUrlPipe,
        FilterStatePipe,
        DecimalFormatPipe,
        GetIdPipe,
        GenderTranslatePipe,
        ChecklistfilesTranslatePipe,
        DragDirective,
        ChecklistfilesTranslatePipe,
        TranslateFolderOrderStatePipe,
        TranslateFolderStatePipe,
        MonthlyPayCPAPipe,
        OrderStatePipe,
        TranslateNotificationPipe,
        NameRolePipe,
        DateToStringFormatPipe,
        TranslateFamilyStatePipe
    ],
    providers: [
        CommentService,
        CustomerDiscountService,
        OrderproductsService,
        UserService,
        CustomersService,
        AddressesService,
        CategoriesService,
        BrandsService,
        PromoCodeService,
        CollaboratorService,
        ConfigurationService,
        FooterInformationService,
        CharacteristicsService,
        ConfigurationCharacteristicsService,
        DiscountsService,
        ImagesService,
        OrdersService,
        CallService,
        CarouselsService,
        DeliveryAddressService,
        DeliveryService,
        PointRelaitService,
        PhoneNumberService,
        NotificationService,
        ArchivedConfigurationService,
        FoldersService,
        ClientsService,
        EkFooterInformationService,
        ClientOcrInformationService,
        SmsHistoryService,
        SocioProfessionalService,
        ScaleIntervalService,
        CoefficientsWilayaService,
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    ]
})
export class CoreModule {
}
