// Angular
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
// CRUD
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {AbstractService} from "../../services/abstract-service.service";
import {BehaviorSubject, Observable} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";
import {Page} from "../../_base/crud/models/page";
import {OrderModel2} from "../_models/order2.model";
import {OrderItemModel} from "../_models/OrderItem.model";
import {OrderConfirmationModel} from "../_models/orderConfirmation.model";
import {ConfigurationModel} from "../_models/configuration.model";


@Injectable()
export class OrdersService extends AbstractService<OrderModel2> {

    selectedOrderSubject = new BehaviorSubject<OrderModel2>(null);
    loadingSubject = new BehaviorSubject<boolean>(false)
    readonly loading$ = this.loadingSubject.asObservable();
    readonly selectedOrder$ = this.selectedOrderSubject.asObservable();

    _order: OrderModel2;
    set order(value: OrderModel2) {
        this._order = value
    }

    get order(): OrderModel2 {
        return this._order
    }

    _promoCodeName: string;
    set promoCodeName(value: string) {
        this._promoCodeName = value
    }

    get promoCodeName(): string {
        return this._promoCodeName
    }

    _promoCodeId: number;
    set promoCodeId(value: number) {
        this._promoCodeId = value
    }

    get promoCodeId(): number {
        return this._promoCodeId
    }

    _sellingPrice: number[];
    set sellingPrice(value: number[]) {
        this.sellingPrice = value
    }

    get sellingPrice(): number[] {
        return this._sellingPrice
    }

    _addingOrder: boolean = false;
    set addingOrder(value: boolean) {
        this._addingOrder = value
    }

    get addingOrder(): boolean {
        return this._addingOrder
    }


    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}orders`;
    }

    public applyPromoOnUpdate(orderId: number, promoCode: string) {
        this.loadingSubject.next(true)
        const options = {
            params: new HttpParams()
                .append('orderId', orderId.toString().trim())
                .append('promoCode', promoCode.toString().trim())
        };
        const url = `${this.url}/applyPromoCode`
        return this.http.get<void>(url, options)
    }

    public getById(id: number): Observable<OrderModel2> {
        this.loadingSubject.next(true)
        const url = `${this.url}/${id}`;
        return this.http.get<Response<OrderModel2>>(url).pipe(map(({body}) => {
            this.loadingSubject.next(false);
            this.selectedOrderSubject.next(body)
            return body;
        }));
    }


    public getAllSearch(page: number = 0, size: number = 5, sorting: string, direction: string, criteria: any, orderState: string, region: string, startingDate: string, endingDate: string, paymentMethod:string, orderType:string, deliveryType:string): Observable<Page<OrderModel2>> {
        this.loadingSubject.next(true);

        let params = new HttpParams();

        params = params.append('pageNo', page.toString())
        params = params.append('pageSize', size.toString())
        params = params.append('sortBy', sorting)
        params = params.append('direction', direction)
        params = params.append('criteria', criteria)
        params = orderState ? params.append('orderState', orderState) : params;
        params = region ? params.append('region', region) : params;
        params = startingDate ? params.append('startingDate', startingDate) : params;
        params = endingDate ? params.append('endingDate', endingDate) : params;
        params = paymentMethod ? params.append('paymentMethod', paymentMethod) : params;
        params = orderType ? params.append('orderType', orderType) : params;
        params = deliveryType ? params.append('deliveryType', deliveryType) : params;


        const options = {params};

        const url = `${this.url}/searchAll`;
        return this.http.get<Response<Page<OrderModel2>>>(url, options).pipe(map(({body}) => body));

    }


    public getByClientId(direction: string, id: number, pageNo: number, pageSize: number, sortBy: string): Observable<Page<OrderModel2>> {
        const url = `${this.url}/client/${id}`;
        let params = new HttpParams()
            .set('direction', direction)
            .set('pageNo', pageNo.toString())
            .set('pageSize', pageSize.toString())
            .set('sortBy', sortBy);
        const options = {params};
        return this.http.get<Response<Page<OrderModel2>>>(url, options).pipe(map(({body}) => body));
    }

    public filterByWilaya(page: number = 0, size: number = 5, sorting: string, direction: string, wilaya: any): Observable<Page<OrderModel2>> {
        this.loadingSubject.next(true)
        const options = {
            params: new HttpParams()
                .append('page', page.toString())
                .append('size', size.toString())
                .append('sortBy', sorting)
                .append('direction', direction)
                .append('wilaya', wilaya)
        };
        const url = `${this.url}/wilaya`;
        return this.http.get<Response<Page<OrderModel2>>>(url, options).pipe(map(({body}) => body));

    }

    public archiveOrder(id: number): Observable<any> {
        const url = `${this.url}/archiveOrder/${id}`;
        return this.http.delete<Response<void>>(url);
    }
    public getAllArchivedOrderPageSearch(page: number = 0, size: number = 5, sorting: string, direction: string, criteria: any, orderState: string, region: string, startingDate: string, endingDate: string): Observable<Page<OrderModel2>>{


        let params = new HttpParams();

        params = params.append('pageNo', page.toString())
        params = params.append('pageSize', size.toString())
        params = params.append('sortBy', sorting)
        params = params.append('direction', direction)
        params = params.append('criteria', criteria)
        params = orderState ? params.append('orderState', orderState) : params;
        params = region ? params.append('region', region) : params;
        params = startingDate ? params.append('startingDate', startingDate) : params;
        params = endingDate ? params.append('endingDate', endingDate) : params;

        const options = {params};
        const url = `${this.url}/searchAllArchivesOrder`;
        return this.http.get<Response<Page<OrderModel2>>>(url, options).pipe(map(({body}) => body));
    }
    unarchiveOrder(idOrder: number) : Observable<any>{
        const url = `${this.url}/unArchiveOrder/${idOrder}`;
        return this.http.delete<Response<void>>(url);
    }
    public changeOrderState(orderId: number, _event: string) {
        this.loadingSubject.next(true)
        const url = `${this.url}/changestate/${orderId}/${_event}`;
        return this.http.get<Response<any>>(url).pipe(map(res => {
            this.loadingSubject.next(false);
            return res
        }));
    }

    public changeOrderRemarkState(orderId: number, remarkState: string) {
        this.loadingSubject.next(true)
        const url = `${this.url}/changeRemarkState/${orderId}/${remarkState}`;
        return this.http.patch<Response<any>>(url, "").pipe(map(res => {
            this.loadingSubject.next(false);
            return res
        }));
    }

    public addProductToOrder(orderId: number, configurationId: number, quantity: number) {
        this.loadingSubject.next(true)
        const url = `${this.url}/addItem`;
        const obj = {
            configurationTO: {
                id: configurationId,
            },
            orderId: orderId,
            quantity: quantity
        }
        return this.http.post<OrderItemModel>(url, obj).pipe(map(res => {
            this.loadingSubject.next(false);
            return res;
        }));
    }

    public addOrder(orderConfirmation: OrderConfirmationModel, listIds: { id: number, quantity: number }[]) {
        this.loadingSubject.next(true)
        const url = `${this.url}/createOrder`;
        const obj = {
            clientId: orderConfirmation.clientId,
            paymentMethod: orderConfirmation.paymentMethod,
            address: orderConfirmation.address,
            priceDelivery: null,
            recoveryMode: null,
            livreurId: orderConfirmation.livreurId,
            promoId: orderConfirmation.promoCodeId,
            deliveryType: orderConfirmation.deliveryType,
            configurationsdetails: listIds,
        }
        return this.http.post<OrderItemModel>(url, obj).pipe(map(res => {
            this.loadingSubject.next(false);
            return res;
        }));
    }

    public updateOrderItemQuantity(orderItemId: number, quantity: number) {
        this.loadingSubject.next(true)
        const url = `${this.url}/updateQuantity/${orderItemId}/${quantity}`;
        return this.http.put<Response<any>>(url, null).pipe(map(res => {
            this.loadingSubject.next(false);
            return res;
        }));
    }

    public deleteOrderItem(id: number): Observable<Response<any>> {
        const url = `${this.url}/deleteItem/${id}`;
        return this.http.delete<Response<void>>(url);
    }

    //Skip
    public ExportExcelOrders(): Observable<Response<any>> {
        const url = `${this.url}/export/excel`;
        return this.http.get<Response<any>>(url);

    }


    public ExportExcelOrdersMonthly(startDate:Date,endDate:Date): Observable<Response<any>> {
        const url = `${this.url}/export/excel/${startDate}/${endDate}`;
        return this.http.get<Response<any>>(url);
    }

    public ExportExcelProductsMonthly(startDate:Date,endDate:Date): Observable<Response<any>> {
        const url = `${this.url}/OrderedProductExport/products/${startDate}/${endDate}`;
        return this.http.get<Response<any>>(url);
    }
    public ExportExcelSansDetail(startDate:Date,endDate:Date): Observable<Response<any>> {
        const url = `${this.url}/OrderedProductExport/orders/${startDate}/${endDate}`;
        return this.http.get<Response<any>>(url);
    }
    public updateStateOrderFromYalidine(): Observable<any> {
        const url = `${this.url}/updateStateOrderFromYalidine`;
        const res = this.http.put<Response<any>>(url, null).pipe(map(({message}) => message));
        return res;
    }
    public ExportExcelAllOrders(): Observable<Response<any>> {
        const url = `${this.url}/export/excel/all`;
        return this.http.get<Response<any>>(url);
    }

    public addActivationKey(orderProductTO: OrderItemModel[]) {
        this.loadingSubject.next(true)
        const url = `${this.url}/addkey`;
        return this.http.post(url, orderProductTO).pipe(map(res => {
            return res;
        }));
    }

    public updateTrackingCode(orderId: number, trackingCode: string): Observable<Response<any>> {
        const params = new HttpParams()
            .set('id', String(orderId))
            .set('tracking', trackingCode)

        const url = `${this.url}/updateTracking`

        return this.http.patch<Response<any>>(url, params).pipe(map(res => {
            return res
        }));

    }


    public addSourceToOrder(orderItemId: number, source: string) {

        const params = new HttpParams()
            .set('source', source.toString())
        const url = `${this.url}/addSource/${orderItemId}`;
        return this.http.patch<Response<OrderItemModel>>(url, params).pipe(map(res => {
            this.loadingSubject.next(false);
            return res
        }));
    }

    public updateOrderPayMethod(orderId: number, paymentMethod: string) {

        const params = new HttpParams()
            .set('paymentMethod',paymentMethod)
            .set('orderId', orderId.toString())

        const url = `${this.url}/paymentMethod`;
        return this.http.patch<Response<OrderItemModel>>(url, params).pipe(map(res => {
            this.loadingSubject.next(false);
            return res
        }));
    }

}


