import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as smsHistoryDetailsActions from "../ek-actions/sms-history-details.actions";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {SmsHistoryService} from "../ek-services/smsHistory.service";

@Injectable()
export class SmsHistoryDetailsEffects {

    constructor(private actions$: Actions,
                private smsHistoryService:SmsHistoryService) {}

    smsHistoryDetailsPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(smsHistoryDetailsActions.SmsHistoryDetailsPageRequested),
            switchMap((action) =>
                this.smsHistoryService.getLastHistorySmsByIdFolder(action.idFolder)
                    .pipe(
                        map(data => {
                            return smsHistoryDetailsActions.SmsHistoryDetailsPageLoadedSuccessfully({
                                SmsHistory: data.content,
                                totalCount: data.totalElements,
                                page : data.totalPages
                            });
                        }),
                        catchError(error => of(smsHistoryDetailsActions.SmsHistoryDetailsPageLoadFailed({error})))
                    ))));

}