import {Injectable} from "@angular/core";
import {AbstractService} from "../../services/abstract-service.service";
import {OrderModel} from "../_models/order.model";
import {HttpClient} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";


@Injectable()
export class OrderproductsService extends AbstractService<OrderModel> {
    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}OrderProducts`;
    }
}
