import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";
import {Response} from "../../_base/crud/models/response";
import {Action, createReducer, on} from "@ngrx/store";
import * as PromoCodeActions from "../_actions/promoCode.action";
import {PromotionCodeModel} from "../_models/promotionCode.model";

export interface PromoCodeState extends EntityState<PromotionCodeModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedPromoCodeId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
    promotionCodeType:string;
    promoState:string;
    startingDate: string;
    endingDate: string;
}

export const adapter: EntityAdapter<PromotionCodeModel> = createEntityAdapter<PromotionCodeModel>();

export const initialPromoCodeState: PromoCodeState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedPromoCodeId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
    entities: [],
    promotionCodeType:'Toutes',
    promoState:'Toutes',
    startingDate: '',
    endingDate: '',
});

const promoCodeReducer = createReducer(
        initialPromoCodeState,

        //get all code promo sorted
        on(PromoCodeActions.PromotionCodesPageRequested,
            (state, action) => {
                return {
                    ...state,
                    actionsloading: true,
                    listLoading: true,
                    showInitWaitingMessage: true,
                    error: null,
                    lastQuery: action.page,
                    promotionCodeType: action.promotionCodeType,
                    promoState: action.promoState,
                    startingDate: action.startingDate,
                    endingDate: action.endingDate
                }
            }),
        on(PromoCodeActions.PromotionCodesLoadedSuccessfully,
            (state, action) => (adapter.addAll(action.promotionCodes,
                {
                    ...state,
                    actionsLoading: false,
                    listLoading: false,
                    showInitWaitingMessage: false,
                    totalCount: action.totalCount
                }))),
        on(PromoCodeActions.PromotionCodesLoadFailed,
            (state, action) => ({
                ...state,
                actionsLoading: false,
                listLoading: false,
                showInitWaitingMessage: false,
                error: action.error
            })),

        //creation
        on(PromoCodeActions.PromoCodeCreated,
            (state) => ({...state, actionsLoading: true, lastAction: ''})),

        on(PromoCodeActions.PromoCodeCreatedSuccessfully,
            (state, action) => (adapter.addOne(action.response, {
                ...state,
                actionsLoading: true,
                lastCreatedPromoCodeId: action.response ? action.response.id : null,
                lastAction: 'EDIT.ADD_MESSAGE',
            }))),

        on(PromoCodeActions.PromoCodeCreationFailed,
            (state, action) => ({...state, actionsLoading: false, error: action.error})),

        //Update
        on(PromoCodeActions.PromoCodeUpdated,
            (state) => ({...state, actionsLoading: true, error: null})),
        on(PromoCodeActions.PromoCodeUpdatedSuccessfully,
            (state, action) => {
                if (action.partialPromoCode){
                    return (adapter.updateOne(action.partialPromoCode, {
                        ...state,
                        actionsLoading: false,
                        lastAction: 'EDIT.UPDATE_MESSAGE',
                    }))
                }
            }
        ),
        on(PromoCodeActions.PromoCodeUpdateFailed,
            (state, action) => ({...state, actionsLoading: false, error: action.error})),

        //Delete
        on
        (PromoCodeActions.PromoCodeDeleted,
            (state, action) => ({...state, actionsLoading: true, lastAction: ''})),
        on(PromoCodeActions.PromoCodeDeletedSuccessfully,
            (state, action) => (adapter.removeOne(action.promoCodeId, {
                ...state,
                actionsLoading: false,
                lastAction: 'DELETE.DELETE_PROMO_CODE_SIMPLE.DELETE_SUCCESS'
            }))),
        on(PromoCodeActions.PromoCodeDeleteFailed,
            (state, action) => ({...state, error: action.error, actionsLoading: false})),
    )
;

export function promoCodesReducer(state: PromoCodeState | undefined, action: Action) {
    return promoCodeReducer(state, action)
}
