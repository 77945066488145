import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {ConfigurationModel} from "../_models/configuration.model";
import {QueryParamsModel} from "../../_base/crud";
import {Response} from "../../_base/crud/models/response";
import {Action, createReducer, on} from "@ngrx/store";
import * as ConfigurationsActions from "../_actions/configurations.actions";
import * as PromoCodeActions from "../_actions/promoCode.action";


export interface ConfigurationsState extends EntityState<ConfigurationModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedConfigurationId2: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
}

export const adapter: EntityAdapter<ConfigurationModel> = createEntityAdapter<ConfigurationModel>();

export const initialConfigurationState: ConfigurationsState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedConfigurationId2: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
});

const configurationReducer = createReducer(
    initialConfigurationState,
    on(ConfigurationsActions.search,
        state => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: ''
        })),
    on(ConfigurationsActions.ConfigurationsPageRequested,
        state => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: ''
        })),
    on(ConfigurationsActions.ConfigurationsPageLoadedSuccessfully,
        (state, action) => (adapter.addAll(action.configurations,
            {
                ...state,
                lastQuery: action.lastRequest,
                actionsLoading: false,
                listLoading: false,
                showInitWaitingMessage: false,
                totalCount: action.totalCount
            }))),
    on(ConfigurationsActions.ConfigurationsPageLoadFailed,
        (state, action) => ({
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),
    on(ConfigurationsActions.ConfigurationsFilterByDateRequested,
        state => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: ''
        })),
    on(ConfigurationsActions.ConfigurationsFilterByDateLoadedSuccessfully,
        (state, action) => (adapter.addAll(action.configurations,
            {
                ...state,
                actionsLoading: false,
                listLoading: false,
                showInitWaitingMessage: false,
                totalCount: action.totalCount,
                lastQuery:action.lastRequest
            }))),
    on(ConfigurationsActions.ConfigurationsFilterByDateLoadFailed,
        (state, action) => ({
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),
    //creation
    on(ConfigurationsActions.ConfigurationCreated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(ConfigurationsActions.ConfigurationCreatedSuccessfully,
        (state, action) => (adapter.addOne(action.configuration, {
            ...state,
            actionsLoading: true,
            lastCreatedConfigurationId2: action.configuration.id,

            lastAction: 'EDIT.ADD_MESSAGEA'
        }))),
    on(ConfigurationsActions.ConfigurationCreationFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
    //Update
    on(ConfigurationsActions.ConfigurationUpdated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(ConfigurationsActions.ConfigurationUpdatedSuccessfully,
        (state, action) =>
            (adapter.updateOne(action.partialConfiguration, {
                ...state,
                actionsLoading: false,
                lastAction: 'EDIT.UPDATE_MESSAGE'
            }))),
    on(ConfigurationsActions.ConfigurationUpdateFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
    // Update Activate
    on(ConfigurationsActions.ConfigurationUpdatedActivate,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(ConfigurationsActions.ConfigurationUpdatedActivateSuccessfully,
        (state, action) =>
            (adapter.updateOne(action.partialConfiguration, {
                ...state,
                actionsLoading: false,
                lastAction: 'EDIT.UPDATE_MESSAGE'
            }))),
    on(ConfigurationsActions.ConfigurationUpdateActivateFailed,
        (state, action) =>
            ({...state, actionsLoading: false, error: action.error})),
    //get all code discount sorted
    on(ConfigurationsActions.DiscountPageRequested,
        (state, action) => {
            return {
                ...state,
                actionsloading: true,
                listLoading: true,
                showInitWaitingMessage: true,
                error: null,
                lastQuery: action.page,
                promoState: action.state,
                startingDate: action.startingDate,
                endingDate: action.endingDate
            }
        }),
    on(ConfigurationsActions.DiscountLoadedSuccessfully,
        (state, action) => (adapter.addAll(action.Discount,
            {
                ...state,
                actionsLoading: false,
                listLoading: false,
                showInitWaitingMessage: false,
                totalCount: action.totalCount
            }))),
    on(ConfigurationsActions.DiscountLoadFailed,
        (state, action) => ({
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),
    //* vente flash list *//
    on(ConfigurationsActions.VenteFlashPageRequested,
        state => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: ''
        })),
    on(ConfigurationsActions.VenteFlashLoadedSuccessfully,
        (state, action) => (adapter.addAll(action.VenteFlash,
            {
                ...state,
                // lastQuery: action.lastRequest,
                actionsLoading: false,
                listLoading: false,
                showInitWaitingMessage: false,
                totalCount: action.totalCount
            }))),
    on(ConfigurationsActions.VenteFlashLoadFailed,
        (state, action) => ({
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),
    //Delete
    on(ConfigurationsActions.ConfigurationDeleted,
        (state, action) => ({...state, actionsLoading: true, lastAction: ''})),
    on(ConfigurationsActions.ConfigurationDeletedSuccessfully,
        (state, action) => (adapter.removeOne(action.configurationId, {
            ...state,
            actionsLoading: false,
            lastAction: 'DELETE.DELETE_CONFIGURATION_SIMPLE.DELETE_SUCCESS'
        }))),
    on(ConfigurationsActions.ConfigurationDeleteFailed,
        (state, action) => ({...state, error: action.error, actionsLoading: false})),

    );

export function configurationssReducer(state: ConfigurationsState | undefined, action: Action) {
    return configurationReducer(state, action)
}
