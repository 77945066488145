import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {PointOfSaleModel} from "../ek-models/point-of-sale.model";
import {QueryParamsModel} from "../../_base/crud";
import {Action, createReducer, on} from "@ngrx/store";
import * as POSActions from './../ek-actions/point-of-sale.action';

export interface POSState extends EntityState<PointOfSaleModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedPOSId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
    errorMessage: string;
}

export const adapter: EntityAdapter<PointOfSaleModel> =
    createEntityAdapter<PointOfSaleModel>();

export const initialPOSState: POSState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedPOSId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
    errorMessage: '',
});
const posReducer = createReducer(
    initialPOSState,
    on(POSActions.POSPageRequested, (state, action) => ({
        ...state,
        actionsLoading: true,
        listLoading: true,
        showInitWaitingMessage: true,
        error: null,
        lastAction: '',
        lastQuery: action.page,
    })),
    on(POSActions.POSPageLoadedSuccessfully, (state, action) =>
        adapter.addAll(action.pos, {
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            totalCount: action.totalCount,
        })
    ),
    on(POSActions.POSPageLoadFailed, (state, action) => ({
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
        error: action.error,
    })),
    // creation
    on(POSActions.POSCreated, (state) => ({
        ...state,
        actionsLoading: true,
        lastAction: '',
    })),
    on(POSActions.POSCreatedSuccessfully, (state, action) =>
        adapter.addOne(action.pos, {
            ...state,
            actionsLoading: false,
            lastCreatedPOSId: action.pos.id,
            lastAction: 'EDIT.ADD_MESSAGE',
        })
    ),
    on(POSActions.POSCreationFailed, (state, action) => ({
        ...state,
        actionsLoading: false,
        error: action.error,
    })),
    // Update
    on(POSActions.POSUpdated, (state) => ({
        ...state,
        actionsLoading: true,
        lastAction: '',
    })),
    on(POSActions.POSUpdatedSuccessfully, (state, action) =>
        adapter.updateOne(action.partialPOS, {
            ...state,
            actionsLoading: false,
            lastAction: 'EDIT.UPDATE_MESSAGE',
        })
    ),
    on(POSActions.POSUpdateFailed, (state, action) => ({
        ...state,
        actionsLoading: false,
        error: action.error,
    })),

    // Delete
    on(POSActions.POSDeleted, (state, action) => ({
        ...state,
        actionsLoading: true,
        lastAction: '',
    })),
    on(POSActions.POSDeletedSuccessfully, (state, action) =>
        adapter.removeOne(action.posId, {
            ...state,
            actionsLoading: false,
            lastAction: 'DELETE.DELETE_CATEGORY_SIMPLE.DELETE_SUCCESS',
        })
    ),
    on(POSActions.POSDeleteFailed, (state, action) => ({
        ...state,
        error: action.error,
        actionsLoading: false,
    }))
);
export function possReducer(state:POSState | undefined, action: Action) {
    return posReducer(state, action);
}

