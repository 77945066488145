import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';

import { DiscountModel } from './../../../../../../../../core/e-commerce/_models/discount.model';
import { OrderModel2 } from './../../../../../../../../core/e-commerce/_models/order2.model';
import { CustomerDiscountService } from './../../../../../../../../core/e-commerce/_services/customer-discount.service';
import { OrdersService } from './../../../../../../../../core/e-commerce/_services/orders.service';
import { PromoCodeService } from './../../../../../../../../core/e-commerce/_services/promoCode.service';

@Component({
  selector: "kt-apply-promo-code",
  templateUrl: "./apply-promo-code.component.html",
  styleUrls: ["./apply-promo-code.component.scss"],
})
export class ApplyPromoCodeComponent implements OnInit {
  @ViewChild("applyInput", { static: true }) applyInput: ElementRef;

  @Input() order: OrderModel2;
  @Input() promoCodeApplied: boolean;
  @Input() orderExists: boolean;
  @Input() discounts: DiscountModel[];

  @Output() updateTotalAfterPromo = new EventEmitter<any>();

  promoCodeVerified: boolean;

  loadingCodePromo: boolean = true;
  promoCode: string;
  codePromoMsg: string;
  codePromoValid: boolean;
  codePromoApplied: boolean;
  clientId: number;

  codePromoAlreadyUsed: boolean = false;
  promoCodeChoosed: boolean;
  orderId: number;
  discountClientValid: boolean;
  discountValue:number;
  constructor(
    private promoCodeService: PromoCodeService,
    private _snackBar: MatSnackBar,
    private ordersService: OrdersService,
    private route: ActivatedRoute,
    private customerDiscountService: CustomerDiscountService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.ordersService.selectedOrder$.subscribe(order => {
      this.order = order;
      if (this.order.promoCodeTO) {
        this.codePromoAlreadyUsed = true;
      } else {
        this.codePromoAlreadyUsed = false;
      }
    });

    if(this.order.clientId) {
      this.customerDiscountService.checkDiscountClient(this.order.clientId).subscribe(res => {
        if (res.body) {
          this.discountValue=res.body.value;
          this.discountClientValid = true
        } else {
          this.discountClientValid = false
        }
      })
    }
    this.checkPromoCode();
    this.clientId = this.order.clientId;
  }

  choosePromoCode() {
    this.promoCodeChoosed = true;
  }

  showPromotionCodeDetails() {
    if (this.order.promoCodeTO.id)
      this.router.navigate(['/ecommerce/promotionCode/edit/' + this.order.promoCodeTO.id])
  }

  openSnackBar() {
    if (this.codePromoApplied) {
      this._snackBar.open("Le code promo fonctionne", "Fermer", {
        duration: 3000,
      });
    }
  }
  checkPromoCode() {
    this.codePromoApplied = false;
    this.codePromoMsg = "";
    this.ordersService.promoCodeName = this.promoCode;
    if (this.clientId) {
      this.loadingCodePromo = true;
      this.promoCodeService
        .isPromoCodePossible(this.promoCode, this.clientId)
        .subscribe((res) => {
          if (res.body) {
            this.ordersService.promoCodeId = res.body.id;

            this.promoCodeVerified = true;
            this.codePromoApplied = true;
            this.codePromoValid = true;
            this.loadingCodePromo = false;
            this.openSnackBar();
          } else {
            this.codePromoApplied = false;
            this.codePromoValid = false;
            this.loadingCodePromo = false;

            switch (res.message) {
              case "this code promo does not exist":
                this.codePromoMsg = "Ce code n'existe pas! ";
                break;
              case "the date of code promo is terminated ":
                this.codePromoMsg = "Ce code promos à expiré";
                break;
              case "this client already use this promocode":
                this.codePromoMsg =
                  " Malheureusement vous avez dépassé le nombre d'utilisation autorisé pour ce code promos! ";
                break;
              case "this promo code is used too much":
                this.codePromoMsg =
                  " Malheureusement ce code promos a atteint sa limite d'utilisation! ";
                break;
              case "this code promo does not active":
                this.codePromoMsg = " Ce code promo n'est pas activé ";
                break;
              case "the sum of product in this order is less than the min value to apply this promocode":
                this.codePromoMsg = " Somme minimum d'achat non atteint ";
                break;
              case "this promotion code does not contain this products ":
                this.codePromoMsg = "Ces produits ne sont pas inclus dans ce code promo";
                break;
              default:
                this.codePromoMsg = res.message;
                break;
            }       
          }
        });
    }
  }

  activatePromoCode() {

    this.ordersService
      .applyPromoOnUpdate(this.order.id, this.promoCode)
      .subscribe((res: any) => {
        if (res.body) {
          this.codePromoAlreadyUsed = true;
          this.updateTotalAfterPromo.emit(res);
        } else {
          this.codePromoAlreadyUsed = false;

          switch (res.message) {
            case "this code promo does not exist":
              this.codePromoMsg = "Ce code n'existe pas! ";
              break;
            case "promoCode.doesnt.exist":
              this.codePromoMsg = "Ce code n'existe pas! ";
              break;
            case "the date of code promo is terminated ":
              this.codePromoMsg = "Ce code promos à expiré";
              break;
            case "this client already use this promocode":
              this.codePromoMsg =
                " Malheureusement vous avez dépassé le nombre d'utilisation autorisé pour ce code promos! ";
              break;
            case "this promo code is used too much":
              this.codePromoMsg =
                " Malheureusement ce code promos a atteint sa limite d'utilisation! ";
              break;
            case "this code promo does not active":
              this.codePromoMsg = " Ce code promo n'est pas activé ";
              break;
            case "the sum of product in this order is less than the min value to apply this promocode":
              this.codePromoMsg = " Somme minimum d'achat non atteint ";
              break;
            case "this promotion code does not contain this products ":
              this.codePromoMsg = "Ces produits ne sont pas inclus dans ce code promo";
              break;
            case "order.already.has.promocode":
              this.codePromoMsg = "Un code promo est deja actif";
              break;
          }
        }
      })
  }
}
