
import {Injectable} from "@angular/core";
import {AbstractService} from "../../services/abstract-service.service";
import {SmsHistoryModel} from "../ek-models/sms-history-model";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {Page} from "../../_base/crud/models/page";



@Injectable()
export class SmsHistoryService extends AbstractService<SmsHistoryModel>{
    url: string;
    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}historySms`;
    }

    getAllHistorySms(pageNumber: number = 0, pageSize: number = 50,sortBy : string, direction : string,): Observable<Page<SmsHistoryModel>> {

        let params = new HttpParams();
        params = params.append('pageNo', pageNumber.toString());
        params = params.append('pageSize', pageSize.toString());
        params = params.append('sortBy',sortBy );
        params = params.append('direction', direction);

        const options = {params};
        const url = `${this.url}/last`;
        return this.http.get<Page<SmsHistoryModel>>(url, options);
    }


    getLastHistorySmsByIdFolder(idFolder: number,pageNumber: number = 0, pageSize: number = 50): Observable<Page<SmsHistoryModel>> {
        let params = new HttpParams();
        params = params.append('pageNo', pageNumber.toString());
        params = params.append('pageSize', pageSize.toString());

        const options = {params};
        const url = `${this.url}/folder/${idFolder}`;
        return this.http.get<Page<SmsHistoryModel>>(url,options);
    }

}