// Angular
import {ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
// Material
import {MatPaginator, MatSort} from '@angular/material';
import {ConfigurationModel} from "../../../../../../core/e-commerce/_models/configuration.model";
import {ConfigurationService} from "../../../../../../core/e-commerce/_services/confguration.service";
import {MatTableDataSource} from "@angular/material/table";

@Component({
    selector: 'kt-products-new-arrival',
    templateUrl: './products-new-arrival.component.html',
    styleUrls: ['./products-new-arrival.component.scss']
})
export class ProductsNewArrivalComponent implements OnInit {
    viewLoading$ = null;
    updater: string;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    configuration: ConfigurationModel;
    pageSize: 100;
    totalElements: number;

    columns = [
        // {"columnDef": 'start', header: '', cell: (element: ConfigurationModel) => '', selected: true},

        {
            columnDef: 'id',
            header: 'ID',
            type: 'number',
            cell: (element: ConfigurationModel) => `${element.id}`,
            selected: true
        },
        {
            columnDef: 'creationDate',
            header: 'Date de création du produit',
            type: 'Date',
            cell: (element: ConfigurationModel) => `${element.createdAt}`,
            selected: true
        },
        {
            columnDef: 'name',
            type: '',
            header: 'Nom du produit',
            cell: (element: ConfigurationModel) => `${element.name}`,
            selected: true
        },
    ];

    displayedColumns = this.columns.filter(e => e.selected).map(c => c.columnDef);
    dataSource: MatTableDataSource<ConfigurationModel> = new MatTableDataSource(null)
    configurations: ConfigurationModel [] = [];

    constructor(
        private configurationService: ConfigurationService,
    ) {
    }

    ngOnInit(): void {
        this.updater=JSON.parse(localStorage.getItem('currentUser')).username;
        this.configurationService.getNew(0, this.pageSize).subscribe(
            confs => {
                this.dataSource.data = confs.body.content;
                this.configurations=confs.body.content;
                this.totalElements=confs.body.totalElements;
            }
        )

    }

    pageEvent(event: any) {
        this.configurationService.getNew(event.pageIndex, event.pageSize).subscribe(
            confs => {
                this.dataSource.data = confs.body.content;
                this.configurations=confs.body.content;
                this.totalElements=confs.body.totalElements;
              //  this.dataSource.paginator = this.paginator;
            }
        )
    }




}
