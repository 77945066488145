import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {LayoutUtilsService, MessageType} from "../../_base/crud";
import {map} from "rxjs/operators";
import {ParcelsResponseModel} from "../_models/parcelsResponse.model";
import {ClipboardService} from "ngx-clipboard";
import {ParcelStateModel} from "../_models/parcelState";
import {Wilaya} from "./wilaya.service";
import {Response} from "../../_base/crud/models/response";

@Injectable({
    providedIn: 'root'
})
export class ParcelService {

    protected url: string;
    private states = ["Pas encore expédié","A vérifier","En préparation","Pas encore ramassé",
        "Prêt à expédier","Ramassé","Transfert","Expédié","Centre","Vers Wilaya",
        "Reçu à Wilaya","En attente du client","Sorti en livraison","En attente","En alerte",
        "Tentative échouée","Livré","Echèc livraison","Retour vers centre","Retourné au centre",
        "Retour transfert","Retour groupé","Retour à retirer","Retour vers vendeur",
        "Retourné au vendeur","Echange échoué"]

    constructor(protected http: HttpClient,
                private clipboardApi: ClipboardService,
                private layoutUtilsService: LayoutUtilsService) {
        this.url = `${ADMIN_CONFIG.apiEndpoint + 'parcels'}`;
    }

    public findAll(page: string,pageSize:string,wilayaId:string,state:string): Observable<ParcelsResponseModel> {
        const options = {
            params: new HttpParams()
                .append('page', page)
                .append('pageSize',pageSize)
                .append('wilayaId',wilayaId)
                .append('state', state)
        };
        return this.http.get<ParcelsResponseModel>(this.url, options).pipe(map((body) => {
            var parcelReponse: ParcelsResponseModel;
            parcelReponse=JSON.parse(body['body']) as ParcelsResponseModel
            parcelReponse.id=Number(page);
            return parcelReponse;
        }));
        ;
    }

    public findParcelStates(tracking: string): Observable<ParcelStateModel[]> {
        const options = {
            params: new HttpParams()
                .append('tracking', tracking)
        };
        var parcelStates: ParcelStateModel[] = [];
        return this.http.get<ParcelStateModel[]>(this.url + '/states', options).pipe(map((body) => {
            for (let i = 0; i < JSON.parse(body['body']).data.length; i++) {
                let parcel = JSON.parse(body['body']).data[i] as ParcelStateModel;
                parcel.id = i
                parcelStates.push(parcel)
            }
            return parcelStates;
        }));
        ;
    }

    public copytracking(tracking: string) {
        this.clipboardApi.copyFromContent(tracking);
        const message = 'Code ' + tracking + ' copiée avec succès.';
        this.layoutUtilsService.showActionNotification(message, MessageType.Create, 10000, true, false);
    }

    public getStates(){
        return this.states
    }

    public filterWilaya(name: string,wilayas): Wilaya[] {
        const filterValue = name.toLowerCase();
        return wilayas.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    public filterState(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.states.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
    }
    public addParcel(id:number):Observable<any>{
        const url = `${this.url}/addParcel/${id}`;
            const res = this.http.post<Response<any>>(url, null).pipe(map(({body}) => body));
            return res;
        }

}
