import {Injectable} from '@angular/core';
import {AbstractService} from '../../services/abstract-service.service';
import {Region} from '../ek-models/region';
import {HttpClient} from '@angular/common/http';
import {ADMIN_CONFIG} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {Response} from '../../_base/crud/models/response';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RegionService extends AbstractService<Region> {

    protected url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}regions`;
    }

    getAllRegions(): Observable<any[]> {
        const url = `${this.url}/listing`;
        return this.http.get<Response<any[]>>(url).pipe(map(({body}) => body));
    }

    createRegion(region: Region) {
        const url = `${this.url}/create`;
        return this.http.post<Region>(url, region);
    }

    editRegion(region: Region) {
        const url = `${this.url}/modify`;
        return this.http.put<Region>(url, region);
    }

    getRegionList() {
        const url = `${this.url}/list`;
        return this.http.get<Response<any>>(url).pipe(map(({body}) => body));
    }

    getRegionNameByWilaya(wilaya:string) {
        const url = `${this.url}/get-region-by-wilaya?wilaya=${wilaya}`;
        return this.http.get<Response<any>>(url).pipe(map(({body}) => body));
    }

    getWilayaByRegion() {
        const url = `${this.url}/wilaya`;
        return this.http.get<Response<any>>(url).pipe(map(({body}) => body));
    }

    getPOSbyRegion(regionId: number) {
        const url = `${this.url}/pointOfSales/${regionId}`;
        return this.http.get<Response<any>>(url).pipe(map(({body}) => body));
    }
    getAgencyListbyRegion(regionId: number) {
        const url = `${this.url}/agencyBank/${regionId}`;
        return this.http.get<Response<any>>(url).pipe(map(({body}) => body));
    }
    deleteRegion(regionId: number) {
        const url = `${this.url}/delete/${regionId}`;
        return this.http.delete<Response<any>>(url).pipe(map(({body}) => body));
    }

}
