import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateFamilyState'
})
export class TranslateFamilyStatePipe implements PipeTransform {

  private statusTranslations: { [key: string]: string } = {
    'SINGLE': 'Célibataire',
    'MARRIED': 'Marié',
    'DIVORCE': 'Divorcé',
    'WIDOWER': 'Veuf'
  };

  transform(value: string): string {
    return this.statusTranslations[value] || value;
  }

}
