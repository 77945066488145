import {BaseModel} from "../../_base/crud";

export class CallModel extends BaseModel {
    id: number;
    callActionType: 'Replied' | 'Missed' | 'VoiceMailed' | 'Ended' | 'NoAction';
    callerId: number;
    state: string;
    orderId: number;
    callerName?: string;

    constructor(id: number, callActionType: "Replied" | "Missed" | "VoiceMailed" | "Ended" | "NoAction", callerId: number, state: string, orderId: number) {
        super();
        this.id = id;
        this.callActionType = callActionType;
        this.callerId = callerId;
        this.state = state;
        this.orderId = orderId;
    }
}
