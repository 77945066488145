import {createFeatureSelector, createSelector} from "@ngrx/store";
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";
import {CollaboratorState} from "../_reducers/collaborator.reducers";
import {CollaboratorModel} from "../_models/collaborator.model";


export const selectCollaboratorsState = createFeatureSelector<CollaboratorState>('collaborators');

export const selectCollaboratorById = (collaboratorById: number) => createSelector(
    selectCollaboratorsState,
    collaboratorsState => collaboratorsState.entities[collaboratorById]
);

export const selectCollaboratorsPageLoading = createSelector(
    selectCollaboratorsState,
    collaboratorsState => collaboratorsState.listLoading
);

export const selectError = createSelector(
    selectCollaboratorsState,
    collaboratorsState => collaboratorsState.error
);

export const selectlastAction = createSelector(
    selectCollaboratorsState,
    collaboratorsState => collaboratorsState.lastAction
);

export const selectCollaboratorsActionLoading = createSelector(
    selectCollaboratorsState,
    collaboratorsState => collaboratorsState.actionsLoading
);

export const selectLastCreatedCollaboratorId = createSelector(
    selectCollaboratorsState,
    collaboratorsState => collaboratorsState.lastCreatedCollaboratorId
);

export const selectCollaboratorShowInitWaitingMessage = createSelector(
    selectCollaboratorsState,
    collaboratorsState => collaboratorsState.showInitWaitingMessage
);

export const selectCollaboratorsInStore = createSelector(
    selectCollaboratorsState,
    collaboratorsState => {
        const items: CollaboratorModel[] = [];
        each(collaboratorsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: CollaboratorModel[] = httpExtension.sortArray(items, collaboratorsState.lastQuery.sortField, collaboratorsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, collaboratorsState.totalCount, '');
    }
);
