import {createAction, props} from "@ngrx/store";
import {SmsHistoryModel} from "../ek-models/sms-history-model";

export const SmsHistoryDetailsPageRequested = createAction(
    '[SmsHistoryDetails List Page] SmsHistory Page Requested ',
    props<{ idFolder: number}>()
);

export const SmsHistoryDetailsPageLoadedSuccessfully = createAction(
    '[SmsHistoryDetails API] SmsHistory Page Loaded Successfully ',
    props<{ SmsHistory: SmsHistoryModel[]; totalCount: number; page: number }>()
);

export const SmsHistoryDetailsPageLoadFailed = createAction(
    '[SmsHistoryDetails API] SmsHistory Page Request Failed ',
    props<{ error: any }>()
);
