import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import {DocumentService} from "../../../../../../../core/services/document.service";
import {DocumentModel} from "../../../../../../../core/ek-e-commerce/ek-models/document.model";
import {Response} from "../../../../../../../core/ek-e-commerce/ek-models/response.model";

@Injectable({
    providedIn: 'root'
})
export class DocumentResolver implements Resolve<Response<DocumentModel>> {
    constructor(private documentService:DocumentService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const id = +route.paramMap.get('idDoc');
         return this.documentService.getDocumentById(id)
    }
}
