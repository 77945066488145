import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface model {
    description: string;
    file: File;
}

@Component({
    selector: 'kt-ek-client-email',
    templateUrl: './ek-client-email.component.html',
    styleUrls: ['./ek-client-email.component.scss']
})
export class EkClientEmailComponent implements OnInit {

    images = [] = [];
    files: File[] = [];
    selectedFiles: FileList;
    imageSources = [];
    description = ''
    subject = '';
    constructor(private dialogRef: MatDialogRef<EkClientEmailComponent>,
                @Inject(MAT_DIALOG_DATA) data,
    ) {
    }

    ngOnInit(): void {

    }

    save() {
        this.dialogRef.close({description: this.description, file: this.fileList , subject : this.subject});
    }

    close() {
        this.dialogRef.close();
    }

    fileList: File[] = [];
    listOfFiles: any[] = [];
    isLoading = false;

    onFileChanged(event: any) {
        this.isLoading = true;
        for (var i = 0; i <= event.target.files.length - 1; i++) {
            var selectedFile = event.target.files[i];
            if (this.listOfFiles.indexOf(selectedFile.name) === -1) {
                this.fileList.push(selectedFile);
                this.listOfFiles.push(selectedFile.name);
            }
        }

        this.isLoading = false;

    }

    removeSelectedFile(index) {
        // Delete the item from fileNames list
        this.listOfFiles.splice(index, 1);
        // delete file from FileList
        this.fileList.splice(index, 1);
    }

}
