import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../core/reducers";
import {selectCarousels} from "../../../../../../core/home-page/carousel/carousel.selectors";
import {CarouselModel} from "../../../../../../core/home-page/carousel/carousel.model";
import {HttpEventType} from "@angular/common/http";
import {LayoutUtilsService, MessageType} from "../../../../../../core/_base/crud";
import {TranslateService} from "@ngx-translate/core";
//Actions
import * as CarouselActions from "../../../../../../core/home-page/carousel/carousel.action";
import {CarouselsService} from "../../../../../../core/home-page/carousel/carousels.service";
import {Update} from "@ngrx/entity";
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {CarouselsPageRequested} from "../../../../../../core/home-page/carousel/carousel.action";
import {MatSnackBar} from "@angular/material";


@Component({
    selector: 'kt-carousel-images',
    templateUrl: './carousel-images.component.html',
    styleUrls: ['./carousel-images.component.scss']
})
export class CarouselImagesComponent implements OnInit, OnDestroy {
    public file: any;

    //Subscriptions
    private subscriptions: Subscription[] = [];
    public selectedImageUrl: string;
    private carouselToAdd = new CarouselModel();
    uploading = false;
    //Progress
    progress = new BehaviorSubject<number>(0);
    carousels$: Observable<CarouselModel[]>;
    carousels_ = new BehaviorSubject<CarouselModel[]>([]) ;
    currentRole: string;
    constructor(
        private snackBar: MatSnackBar,
        private store: Store<AppState>,
        private resourcesService: CarouselsService,
        private layoutUtilsService: LayoutUtilsService,
        private translate: TranslateService,
    ) {
    }

    ngOnInit() {
        this.store.dispatch(CarouselActions.CarouselsPageRequested());
        // this.carousels$.value = this.store.select(selectCarousels);
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;

        this.carousels$ = this.store.select(selectCarousels);

        this.carousels$.subscribe(res=> this.carousels_.next(res));

        this.carousels_.subscribe(res => {});
    }


    onSelectedFile(files: FileList) {
        if (this.currentRole =='ROLE_GUEST_ADMIN') {
                event.preventDefault();
                event.stopPropagation();
                return
        }

        for (let i = 0; i < files.length; i++) {
            this.carouselToAdd.file = files.item(i);
            const file = files[i];
            if (file.size < 1000000) {
                const resourceSub = this.resourcesService.pushFileToStorage(this.carouselToAdd).subscribe(
                    res => {

                        this.uploading = true;

                        if (res.type == 4) {

                            this.store.dispatch(CarouselActions.CarouselsPageRequested());
                            this.uploading = false;
                            const message = `Carousel has been added Successfully.`;
                            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, false);
                            this.progress.next(0);
                            //the upload is in progress
                        } else if (res['type'] === HttpEventType.UploadProgress) {
                            this.progress.next(Math.round(100 * res['loaded'] / res['total']));
                        }
                    },
                    error => {
                        this.uploading = false;
                    }
                );
                this.subscriptions.push(resourceSub);
            } else {

                const message = `Image non pris en charge, la taille de l'image est supérieur a 1 Mo`;
                this.snackBar.open(message , '', {
                    duration: 2000,
                    panelClass: ['my-snack-bar']
                });




            }
        }
    }
    onUpdateUrl(carousel: CarouselModel, url) {
        const updated = {
            id: carousel.id,
            route: url,
            imageSrc: carousel.imageSrc,
            file: carousel.file,
            updatedAt :carousel.updatedAt,
            createdAt :carousel.createdAt,
            ord :carousel.ord,
        }
        const updateCarousel: Update<CarouselModel> = {
            id: carousel.id,
            changes: updated
        };
        this.store.dispatch(CarouselActions.CarouselUpdated({
            carousel: updated,
            partialCarousel: updateCarousel
        }));

        const message = `route has been updated Successfully.`;
        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, false);


    }

    drop(event: CdkDragDrop<CarouselModel[]>) {

        const currentValue = this.carousels_.getValue();

        if (event.currentIndex == event.previousIndex && currentValue[event.previousIndex].ord != null) {
            const message = `you chose the same order !`;
            // this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);

        }
        else{

                this.OnChangeOrderImages(currentValue[event.previousIndex].id, event.currentIndex, event.previousIndex);

                this.ChangeOrderONLocal(currentValue[event.previousIndex], event.currentIndex, event.previousIndex);
            // }
        }
    }

    ChangeOrderONLocal(img :CarouselModel,value:number, position :number){

        img.ord = value;
        if (position > value){
            this.carousels_.value.forEach(imgs=>{
                if( imgs.ord < position && imgs.ord >= value){
                    imgs.ord = imgs.ord+1;
                }
            })
        }
        else{
            this.carousels_.value.forEach(imgs=>{
                if( imgs.ord > position && imgs.ord <= value ){
                    imgs.ord = imgs.ord-1;
                }
            })
        }
        this.carousels_.value.sort((a, b) => a.ord - b.ord);
    }

    OnChangeOrderImages(id: number, value: number, position: number) {

        this.resourcesService.changeOrderImage(id, value, position).subscribe(
            res => {
                this.store.dispatch(CarouselsPageRequested());
            });

        const message = `Image order successfully has been changed.`;
        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);

    }

    onDelete(carousel: CarouselModel) {
        //
        // const _title: string = this.translate.instant('ECOMMERCE.HOME_PAGE.DELETE.IMAGE.TITLE');
        // const _description: string = this.translate.instant('ECOMMERCE.HOME_PAGE.DELETE.IMAGE.DESCRIPTION');
        // const _waitDesciption: string = this.translate.instant('ECOMMERCE.HOME_PAGE.DELETE.IMAGE.WAIT_DESCRIPTION');
        // //
        // const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        // const subdialog = dialogRef.afterClosed().subscribe(res => {
        //     if (!res) {
        //         return;
        //     }

            this.store.dispatch(CarouselActions.CarouselDeleted({
                carouselId: carousel.id,
            }));
            const message = `Image has been deleted Successfully.`;
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, false);

        }
        // this.subscriptions.push(subdialog);

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
