import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog, MatTableDataSource} from '@angular/material';
import {ResizeEvent} from 'angular-resizable-element';
import {EkConfiguration2AddComponent} from "../configuration2-add/configuration2-add.component";
import {EkCharacteristicsComponent} from "../characteristics/characteristics.component";

import {
    ConfigurationCharacteristicModel
} from "../../../../../../../core/e-commerce/_models/configuration-characteristic.model";
import {Store} from "@ngrx/store";
import {LayoutUtilsService, MessageType} from "../../../../../../../core/_base/crud";
import {AppState} from "../../../../../../../core/reducers";
import {Subscription} from "rxjs";
import {ConfigurationService} from "../../../../../../../core/e-commerce/_services/confguration.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {EkSettingsTabConfigComponent} from "../settings-tab-config/settings-tab-config.component";
import {ProductModel} from "../../../../../../../core/e-commerce";
import {ActivatedRoute, Router} from "@angular/router";
import {
    ConfigurationDeleted,
    ConfigurationsPageRequested,
    ConfigurationUpdated
} from "../../../../../../../core/e-commerce/_actions/configuration.action";
import {ConfigurationsDatasource} from "../../../../../../../core/e-commerce/_data-sources/configurations.datasource";
import {Update} from "@ngrx/entity";
import {delay} from "rxjs/operators";
import {DiscountModel} from "../../../../../../../core/e-commerce";
import {DiscountsService} from "../../../../../../../core/e-commerce";
import {ClipboardService} from "ngx-clipboard";
import {
    ConfigurationUpdateActivateFailed,
    ConfigurationUpdatedActivate,
    ConfigurationUpdatedActivateSuccessfully
} from "../../../../../../../core/e-commerce/_actions/configurations.actions";
import {Actions, ofType} from '@ngrx/effects';
import {ConfigurationModel} from "../../../../../../../core/ek-e-commerce/ek-models/configuration.model";

@Component({
    selector: 'kt-configuration2-tab',
    templateUrl: './configuration2-tab.component.html',
    styleUrls: ['./configuration2-tab.component.scss']
})
export class EkConfiguration2TabComponent implements OnInit {
    defaultWidth = 200;
    productId: number;
    updater: string;
    @Output() reloadStatusProduct: EventEmitter<any> = new EventEmitter<any>();
    @Output() reloadNameProduct: EventEmitter<any> = new EventEmitter<any>();

    columns = [

        {
            columnDef: 'name',
            width: this.defaultWidth,
            header: 'name',
            type: '',
            cell: (element: ConfigurationModel) => `${element.name}`,
            selected: true
        },


        {
            columnDef: 'state',
            width: this.defaultWidth,
            header: 'state',
            type: '',
            cell: (element: ConfigurationModel) => `${element.state}`,
            selected: true
        },


        {
            columnDef: 'prix',
            width: this.defaultWidth,
            header: 'prix',
            type: '',
            cell: (element: ConfigurationModel) => `${element.price}`,
            selected: false
        },
        {
            columnDef: 'prix_achat',
            width: this.defaultWidth,
            header: 'prix_achat',
            type: '',
            cell: (element: ConfigurationModel) => `${element.buyingPrice}`,
            selected: false
        },
        {
            columnDef: 'script',
            width: this.defaultWidth,
            header: 'script',
            type: '',
            selected: false
        },
        {
            columnDef: 'Date de disponibilité',
            width: this.defaultWidth,
            header: 'Date de disponibilité',
            type: '',
            cell: (element: ConfigurationModel) => `${element.availabilityDate}`,
            selected: false
        },
        {
            columnDef: 'images',
            width: this.defaultWidth,
            header: 'images',
            type: '',
            cell: (element: ConfigurationModel) => `${element.images}`,
            selected: false
        },
        {
            columnDef: 'date du dernier changement',
            width: this.defaultWidth,
            header: 'date du dernier changement',
            type: '',
            cell: (element: ConfigurationModel) => `${element.updatedAt}`,
            selected: false
        },
        {
            columnDef: 'updater',
            width: this.defaultWidth,
            header: 'updater',
            type: '',
            cell: (element: ConfigurationModel) => `${element.updater}`,
            selected: false
        },
        {
            columnDef: 'reference',
            width: this.defaultWidth,
            header: 'reference',
            type: '',
            cell: (element: ConfigurationModel) => `${element.reference}`,
            selected: false
        },
        //discount
        {
            columnDef: 'startingDate',
            width: this.defaultWidth,
            header: 'startingDate',
            type: '',
            cell: (element: ConfigurationModel) => `${element.discount.startingDate}`,
            selected: false
        },
        //updaterProduct

        {
            columnDef: 'updaterProduct',
            width: this.defaultWidth,
            header: 'updaterProduct',
            type: '',
            cell: (element: ConfigurationModel) => `${this.updaterProduct}`,
            selected: false
        },
        {
            columnDef: 'endingDate',
            width: this.defaultWidth,
            header: 'endingDate',
            type: '',
            cell: (element: ConfigurationModel) => `${element.discount.endingDate}`,
            selected: false
        },
        {columnDef: 'actions', width: 150, header: 'actions', cell: (element: any) => ``, selected: true},
    ]
    dataSource_: ConfigurationsDatasource;

    private componentSubscriptions: Subscription[] = [];
    productCharacteristics: ConfigurationCharacteristicModel[];
    configurations: ConfigurationModel[] = [];
    dataSource = new MatTableDataSource<ConfigurationModel>();
    id$ = 1;
    configurationForm: FormGroup;
    displayedColumns: any;
    checked: boolean;

    @Input() updaterProduct: string;

    private showAvailabilityDate: boolean;
    currentRole = '';

    constructor(private configurationFB: FormBuilder, private router: Router, private discountsService: DiscountsService, private clipboardApi: ClipboardService, private _actions$: Actions,
                private activatedRoute: ActivatedRoute, public dialog: MatDialog, public dialog1: MatDialog, private layoutUtilsService: LayoutUtilsService, private store: Store<AppState>, private configurationService: ConfigurationService) {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }

    ngOnInit(): void {


        if (JSON.parse(localStorage.getItem('saved-config-configuration')) && JSON.parse(localStorage.getItem('saved-config-configuration')).length > 0) {
            this.columns = JSON.parse(localStorage.getItem('saved-config-configuration'));
            ;
            this.displayedColumns = this.columns.filter(e => e.selected).map(c => c.columnDef);

        } else {
            this.displayedColumns = this.columns.filter(e => e.selected).map(c => c.columnDef);

        }
        this.updater = JSON.parse(localStorage.getItem('currentUser')).username;
        ;
        this.activatedRoute.params.subscribe(params => {
            const id = params.id;

            if (id && id > 0) {
                this.productId = id;
                this.loadConfigurationsList();
                this.dataSource_ = new ConfigurationsDatasource(this.store);
                this.dataSource_.entitySubject.subscribe(res => {
                    if (res.length > 0) {
                        this.dataSource.data = res;
                    }
                });
            } else {

            }
        });
        this.configurationForm = this.configurationFB.group({
            name: ['', Validators.required],
            price: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
            buyingPrice: ['', Validators.required],
            state: ['', Validators.required],
            used: (['', Validators.required]),
            script: ['', Validators.required],
            reference: ['', Validators.required],
            availabilityDate: ['', Validators.required],
            startingDate: [''],
            endingDate: [''],
            value: ['']
        });

    }

    onResizeEnd(event: ResizeEvent, columnName): void {
        if (event.edges.right) {
            const cssValue = event.rectangle.width + 'px';
            const columnElts = document.getElementsByClassName('mat-column-' + columnName);
            for (let i = 0; i < columnElts.length; i++) {
                const currentEl = columnElts[i] as HTMLDivElement;
                currentEl.style.width = cssValue;
            }
        }
    }


    addcarac(c: ConfigurationModel) {
        const doc = document.documentElement;
        const left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
        const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        const dialogRef = this.dialog1.open(EkCharacteristicsComponent, {
            data: c,
            panelClass: 'my-custom-dialog-class'

        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (top != 0 || left != 0) {
                    window.scroll({
                        top: top,
                        left: left,
                        behavior: "smooth"
                    });
                }
                this.dataSource.data = this.configurations;
            }
        });
    }


    editConfiguration(c: ConfigurationModel) {
        let date: Date;

        if (c.availabilityDate == null) {
            date = new Date();
        } else {
            date = new Date(c.availabilityDate)
        }

        this.configurationForm = this.configurationFB.group({
            name: [c.name, Validators.required],
            price: [c.price, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
            buyingPrice: [c.buyingPrice, Validators.required],
            state: [c.state, Validators.required],
            reference: [c.reference, Validators.required],
            availabilityDate: [date, Validators.required],
            startingDate: [date],
            endingDate: [date],
            value: [c.discount?.value]
        });
        if (c.state == 'BIENTOT_DISPONIBLE') {
            this.showAvailabilityDate = true
        }
        c._isEditMode = true;

    }

    OpenEditConfiguration(c: ConfigurationModel) {

        const dialogRef = this.dialog.open(EkConfiguration2AddComponent, {
            data: c,
            disableClose: true

        });
        dialogRef.afterClosed().subscribe(result => {

            if (this.productId && this.productId > 0) {
                this.loadConfigurationsList();

            }
            this.reloadNameProduct.emit(true);

        });
    }

    activateAvailabilityDate(bool) {
        this.showAvailabilityDate = bool == 'BIENTOT_DISPONIBLE';
    }

    cancelEditButtonOnClick(c: ConfigurationModel) {
        c._isEditMode = false;
    }

    saveUpdatedConfiguration(c: ConfigurationModel) { // I'll do the same using index
        let index = this.configurations.indexOf(c);
        c.name = this.configurationForm.get('name').value;
        c.buyingPrice = this.configurationForm.get('buyingPrice').value;
        c.price = this.configurationForm.get('price').value;
        c.state = this.configurationForm.get('state').value;
        c.reference = this.configurationForm.get('reference').value;
        c.availabilityDate = this.configurationForm.get('availabilityDate').value;
        let d = new DiscountModel();
        if (this.configurationForm.get('value').value) {
            c.discount.startingDate = this.configurationForm.get('startingDate').value;
            c.discount.endingDate = this.configurationForm.get('endingDate').value;
            c.discount.value = this.configurationForm.get('value').value;
            d = c.discount;
        } else {

        }

        c.updater = this.updater;
        if (this.productId && this.productId > 0) {
            if (c.discount) {
                this.discountsService.saveDiscount(d).subscribe(res => {
                        this.updateConfiguration(c, false);

                    },
                    error => {
                    })
                c._isEditMode = false;
            } else {
                this.updateConfiguration(c, false);
                c._isEditMode = false;
            }

        } else {

            this.configurations[index] = c;
            this.dataSource.data = this.configurations;
            c._isEditMode = false;
        }

    }

    updateConfiguration(_configuration: ConfigurationModel, withBack: boolean = false) {

        const product = new ProductModel();
        product.id = this.productId;
        _configuration.productId = product.id;
//
        const updatedConfiguration: Update<ConfigurationModel> = {
            id: _configuration.id,
            changes: _configuration
        };

        this.store.dispatch(ConfigurationUpdated({
            partialConfiguration: updatedConfiguration,
            configuration: _configuration
        }));

        if (withBack) {
        } else {
            const message = `Sauvegarde effectuer avec sucée.`;
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);
        }
    }

    deleteConfiguration(_item: ConfigurationModel) {

        const _deleteMessage = `Configuration has been deleted`;

        this.store.dispatch(ConfigurationDeleted({configurationId: _item.id}));
        this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
        delay(1000);
        this.loadConfigurationsList();

    }

    deleteConf(c: ConfigurationModel) {
        const index: number = this.configurations.indexOf(c);
        if (this.productId && this.productId > 0) {
            this.deleteConfiguration(c);
        } else {
            if (index !== -1) {
                this.configurations.splice(index, 1);
                this.dataSource.data = this.configurations;
                this.reloadNameProduct.emit(true);
            }
        }
    }

    showSettings() {
        const dialogRef = this.dialog.open(EkSettingsTabConfigComponent, {
            width: '600px',
            data: this.columns
        });

        dialogRef.afterClosed().subscribe(rep => {
            if (rep) {
                this.columns = rep;
                localStorage.setItem('saved-config-configuration', JSON.stringify(this.columns));
                this.displayedColumns = this.columns.filter(e => e.selected).map(c => c.columnDef);
            }
        })
    }

    /**
     * Load Specs List
     */
    loadConfigurationsList() {

        this.store.dispatch(ConfigurationsPageRequested({
            productId: this.productId,
        }));
        this.reloadStatusProduct.emit(true);
    }

    copyReference() {
        this.configurationService.generateReference().subscribe(res => {
            this.clipboardApi.copyFromContent(res["body"]);
            this.configurationForm.controls['reference'].setValue(res['body']);


        });

    }

    /**
     * Active Configuration
     */

    onChange(value, configuration: ConfigurationModel) {
        if (value.checked === true) {
            this.OnChangeActivateProduct(configuration, true, value);
            this.checked = true;
        }
        if (value.checked === false) {
            this.checked = false;
            this.OnChangeActivateProduct(configuration, false, value);
        }

    }

    OnChangeActivateProduct(configuration: ConfigurationModel, value: boolean, event: any) {
        const _configuration = new ConfigurationModel();
        _configuration.id = configuration.id
        _configuration.active = value;
        const updateConfiguration: Update<ConfigurationModel> = {
            id: configuration.id,
            changes: _configuration
        };

        this.store.dispatch(ConfigurationUpdatedActivate({
                    configuration: _configuration,
                    partialConfiguration: updateConfiguration,
                    activate: value,
                    updater: this.updater,
                    event: event,
                },
            )
        )

        //ConfigurationUpdatedActivateSuccessfully
        this._actions$
            .pipe(ofType(ConfigurationUpdatedActivateSuccessfully))
            .subscribe((data: any) => {

                configuration.updater = this.updater;

                this.reloadStatusProduct.emit(true);

                let message22 = '';

                if(event.source.checked){
                    message22 = 'Produit activé avec succès.';

                }else{
                    message22 = 'Produit désactivé avec succès.';
                }

                this.layoutUtilsService.showActionNotification(
                    message22,
                    MessageType.Update,
                    5000,
                    true,
                    true
                );
            });

        //ConfigurationUpdateActivateFailed
        this._actions$
            .pipe(ofType(ConfigurationUpdateActivateFailed))
            .subscribe((data: any) => {
                if (data && (data.error.error.code === 8 || data.error.error.code === 404)) {

                    switch (data.error.error.body) {

                        case "Configuration images are missing." :
                            const message = "vous devez insérer des images de configuration!";
                            this.layoutUtilsService.showActionNotification(
                                message,
                                MessageType.Update,
                                5000,
                                true,
                                true
                            );
                            break;

                        case "Configuration certificate URL is missing or empty." :
                            const message2 = "vous devez insérer le certificat de fabrication de cette configuration!";
                            this.layoutUtilsService.showActionNotification(
                                message2,
                                MessageType.Update,
                                5000,
                                true,
                                true
                            );
                            break;

                        case "Configuration price is missing or invalid." :
                            this.layoutUtilsService.showActionNotification(
                                "Le prix de configuration est manquant ou invalide.",
                                MessageType.Update,
                                5000,
                                true,
                                true
                            );
                            break;

                        case "Configuration description is missing or empty." :
                            this.layoutUtilsService.showActionNotification(
                                "La description de la configuration est manquante ou vide.",
                                MessageType.Update,
                                5000,
                                true,
                                true
                            );
                            break;

                        case "Product category or brand is missing." :
                            this.layoutUtilsService.showActionNotification(
                                "La catégorie ou la marque de produit est manquante.",
                                MessageType.Update,
                                5000,
                                true,
                                true
                            );
                            break;

                        case "Activation impossible. This Configuration has no StockEK" :
                            this.layoutUtilsService.showActionNotification(
                                "Activation impossible. Cette configuration n'a pas de Stock Ek.",
                                MessageType.Update,
                                5000,
                                true,
                                true
                            );
                            break;


                        case "Configuration name is missing or empty." :
                            this.layoutUtilsService.showActionNotification(
                                "Le nom de la configuration est manquant ou vide.",
                                MessageType.Update,
                                5000,
                                true,
                                true
                            );
                            break;

                        case "Product reference constructor is missing or empty." :
                            this.layoutUtilsService.showActionNotification(
                                "Le ref constructor produit est manquant ou vide.",
                                MessageType.Update,
                                5000,
                                true,
                                true
                            );
                            break;


                        default:
                            const message3 = data.error.error.body;
                            this.layoutUtilsService.showActionNotification(
                                message3,
                                MessageType.Update,
                                5000,
                                true,
                                true
                            );
                            break;

                    }

                }

            });

    }

    isStickyEnd(column: string): boolean {
        return column === 'actions' ? true : false;
    }

    isSticky(column: string): boolean {
        return column === 'name' ? true : false;
    }
}



