import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";
import {Response} from "../../_base/crud/models/response";
import {Action, createReducer, on} from "@ngrx/store";
import {CollaboratorModel} from "../_models/collaborator.model";
import * as CollaboratorActions from "../_actions/collaborator.action";

export interface CollaboratorState extends EntityState<CollaboratorModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedCollaboratorId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
}

export const adapter: EntityAdapter<CollaboratorModel> = createEntityAdapter<CollaboratorModel>();

export const initialCollaboratorState: CollaboratorState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedCollaboratorId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
});

const collaboratorReducer = createReducer(
    initialCollaboratorState,
    on(CollaboratorActions.CollaboratorsPageRequested,
        state => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: ''
        })),
    on(CollaboratorActions.CollaboratorsPageLoadedSuccessfully,
        (state, action) => (adapter.addAll(action.collaborators,
            {
                ...state,
                actionsLoading: false,
                listLoading: false,
                showInitWaitingMessage: false,
                totalCount: action.totalCount
            }))),
    on(CollaboratorActions.CollaboratorsPageLoadFailed,
        (state, action) => ({
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),
    //creation
    on(CollaboratorActions.CollaboratorCreated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(CollaboratorActions.CollaboratorCreatedSuccessfully,
        (state, action) => (adapter.addOne(action.collaborator, {
            ...state,
            actionsLoading: true,
            lastCreatedCollaboratorId: action.collaborator.id,
            lastAction: 'EDIT.ADD_MESSAGE'
        }))),
    on(CollaboratorActions.CollaboratorCreationFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
    //Update
    on(CollaboratorActions.CollaboratorUpdated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(CollaboratorActions.CollaboratorUpdatedSuccessfully,
        (state, action) =>
            (adapter.updateOne(action.partialCollaborator, {
                ...state,
                actionsLoading: false,
                lastAction: 'EDIT.UPDATE_MESSAGE'
            }))),
    on(CollaboratorActions.CollaboratorUpdateFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
    //Delete
    on(CollaboratorActions.CollaboratorDeleted,
        (state, action) => ({...state, actionsLoading: true, lastAction: ''})),
    on(CollaboratorActions.CollaboratorDeletedSuccessfully,
        (state, action) => (adapter.removeOne(action.collaboratorId, {
            ...state,
            actionsLoading: false,
            lastAction: 'DELETE.DELETE_COLLABORATOR_SIMPLE.DELETE_SUCCESS'
        }))),
    on(CollaboratorActions.CollaboratorDeleteFailed,
        (state, action) => ({...state, error: action.error, actionsLoading: false}))
);

export function collaboratorsReducer(state: CollaboratorState | undefined, action: Action) {
    return collaboratorReducer(state, action)
}
