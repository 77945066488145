import {AgencyBankService} from '../../../../../../core/ek-e-commerce/ek-services/agency-bank.service';
import {AgencyBankModel} from '../../../../../../core/ek-e-commerce/ek-models/agency-bank-model';
import {CreditModel} from "../../../../../../core/ek-e-commerce/ek-models/credit.model";
import {OrderSplitService} from "../../../../../../core/ek-e-commerce/ek-services/order-split.service";
import {OrderSplit} from "../../../../../../core/ek-e-commerce/ek-models/orderSplit";
import {ClientModel} from "../../../../../../core/ek-e-commerce/ek-models/client.model";
import {ocrInfo} from "../../../../../../core/ek-e-commerce/ek-models/document-ocr-information";
import {Component, Input, OnInit} from "@angular/core";
import {DocumentModel} from "../../../../../../core/ek-e-commerce/ek-models/document.model";
import {DocumentService} from "../../../../../../core/services/document.service";
import {PDFDocument, rgb, StandardFonts} from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import {ActivatedRoute} from "@angular/router";
import {BehaviorSubject} from "rxjs";
import {DatePipe} from "@angular/common";
import {CategoriesService} from "../../../../../../core/e-commerce";

@Component({
    selector: "kt-fourth-step",
    templateUrl: "./fourth-step.component.html",
    styleUrls: ["./fourth-step.component.scss"],
    providers: [DatePipe],
})
export class FourthStepComponent implements OnInit {
    @Input() bankDocuments!: DocumentModel[];
    @Input() information!: ocrInfo;
    @Input() client!: ClientModel;
    @Input() order!: OrderSplit;
    @Input() requestNumber!: String;

    sitProf: string = "";
    sitPerso: string = "";
    demandeOuvertureCompteB!: string;
    folderId!: number;
    image: any;
    credit: CreditModel;
    demandeConsultation!: string;
    categoriess: string[] = [];
    categoriesV2: string[]= [];

    downLoadFiles$ = new BehaviorSubject<boolean>(false);
    readonly loading$ = this.downLoadFiles$.asObservable();

    private creditSbj$ = new BehaviorSubject<CreditModel>(null);
    readonly creditObs$ = this.creditSbj$.asObservable();

    currentRole: string = '';
    salesContract: any;
    individualEquipPurchase: any;
    deposit: any;
    agency!: AgencyBankModel;

    constructor(
        private categoryService: CategoriesService,
        private documentService: DocumentService,
        private route: ActivatedRoute,
        private agencyBankService: AgencyBankService,
        private orderSplitService: OrderSplitService,
        public datePipe: DatePipe
    ) {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }


    ngOnInit() {

        this.route.paramMap.subscribe((params: any) => {
            this.folderId = Number(params.get("idFolder"));
        });

        this.agencyBankService.getAcencyByFolderId(this.folderId).subscribe({
            next: (agencyResp) => {
                if (agencyResp.body) this.agency = agencyResp.body
            },
            error: (err) => {
            }
        })

        this.orderSplitService.selectedOrder$.subscribe((res) => {
            if (res) {
                this.categoriess = [];
                this.order = res;
                res.orderItems.forEach((config) => {
                    this.categoryService
                        .getParentCategoryByIdProduct(config.configurationTO.productId)
                        .subscribe((category) => {
                            this.categoriesV2.push(category)
                            if (category.includes("MEUBLES")) {
                                this.categoriess.push("تجهيزات  تأثيث المنزل");
                            } else {
                                this.categoriess.push("تجهيزات كهرومنزلية");
                            }
                        });
                });

                this.orderSplitService.creditSubject.subscribe(cr => {
                    if (cr) {
                        this.credit = cr;
                        this.creditSbj$.next(cr);
                    }
                });

                this.documentService.downloadDocuments(res.id, "BANK_EMPTY").subscribe({
                    next: (res: any) => {
                        let obj = res.body.find((o: any) => {
                            switch (o.documentsTitle) {
                                case 'CREM_REQUESTS_FROM_THE_CENTRAL_FOR_INDIVIDUAL_RISKS': {
                                    this.demandeConsultation = o.documentUrlPreSigned;
                                    break;
                                }
                                case 'ACCOUNT_OPENING_REQUEST': {
                                    this.demandeOuvertureCompteB = o.documentUrlPreSigned;
                                    break;
                                }
                                case 'PROMISE_TO_PURCHASE': {
                                    this.salesContract = o.documentUrlPreSigned;
                                    break;
                                }
                                case 'DEPOSIT_RECEIPT': {
                                    this.deposit = o.documentUrlPreSigned;
                                    break;
                                }

                                case "INDIVIDUAL_EQUIPMENT_PURCHASE_REQUEST": {
                                    this.individualEquipPurchase = o.documentUrlPreSigned;
                                    break;
                                }
                            }
                        });
                    },
                });

                switch (this.information?.personnelInformation?.socialClientCategory) {
                    case "SINGLE": {
                        this.sitPerso = "Célibataire";
                        break;
                    }
                    case "MARRIED": {
                        this.sitPerso = "Marié";
                        break;
                    }
                    case "DIVORCE": {
                        this.sitPerso = "Divorcé";
                        break;
                    }
                    case "WIDOWER": {
                        this.sitPerso = "Veuf";
                        break;
                    }
                }

                switch (this.information?.professionalSituation?.professionalClientCategory) {
                    case "EMPLOYEE": {
                        this.sitProf = "Employé";
                        break;
                    }
                    case "RETRIED": {
                        this.sitProf = "Retraité";
                        break;
                    }
                    case "MILITARY": {
                        this.sitProf = "Militaire";
                        break;
                    }
                    case "MILITARY_RETIRED": {
                        this.sitProf = "Retraité militaire";
                        break;
                    }
                }

            }
        });

        // this.documentService
        //     .getDocumentsByFolderId(this.folderId)
        //     .subscribe((res: any) => {
        //         this.bankDocuments$.next(
        //             res.body.filter(
        //                 (document: DocumentModel) => document.documentsType === "BANK"
        //             )
        //         );
        //     });

    }

    async createPdf() {

        this.downLoadFiles$.next(true);
        // Loading Arabic Font
        const url = 'https://ekiclik-docs.s3.eu-west-2.amazonaws.com/NotoSansArabic-Black.ttf';
        const fontBytes = await fetch(url).then((res) => res.arrayBuffer());


        let manufactureNames=[];
        let brands=[];
        let cat=[];

        this.order.orderItems.forEach((item) => {
            manufactureNames.push(item.configurationTO.manufactureName);
            brands.push(item.configurationTO.productBrand);
            cat.push(item.configurationTO.categoryLabel);
        });

        manufactureNames = Array.from(new Set(manufactureNames));
        manufactureNames = manufactureNames.length >= 4 ? manufactureNames.slice(0, 4) : manufactureNames
        let unicManufactureNames = Array.from(new Set(manufactureNames)).join(" ").toLocaleUpperCase();
        // *********************** Demande de consultation de la centrale des risque entreprises et Menages de la banque d'algerie **********************************
        const demandeConsultation = await fetch(this.demandeConsultation).then(
            (res) => res.arrayBuffer()
        );

        const DCC = await PDFDocument.load(demandeConsultation);
        DCC.registerFontkit(fontkit);
        const arabicFont2 = await DCC.embedFont(fontBytes);
        const helveticaFont2 = await DCC.embedFont(StandardFonts.HelveticaBold);

        const DCCpages = DCC.getPages();
        const DCCfirstPage = DCCpages[0];
        const DCCSecondPage = DCCpages[1];

        DCCfirstPage.drawText(
            `${this.requestNumber ? this.requestNumber : ''}`,
            {
                x: 75,
                y: 727,
                size: 11,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );


        DCCfirstPage.drawText(
            `${
                this.information.personnelInformation?.nationalIdNumber
                    ? String(this.information.personnelInformation?.nationalIdNumber)
                        .split('')
                        .join('  ')
                    : ''
            }`,
            {
                x: 307,
                y: 660,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );


        DCCfirstPage.drawText(
            `${
                this.information.personnelInformation?.lastName
                    ? this.information.personnelInformation?.lastName
                    : ''
            }`,
            {
                x: 150,
                y: 635,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        DCCfirstPage.drawText(
            `${
                this.information.personnelInformation?.firstName
                    ? this.information.personnelInformation?.firstName
                    : ''
            }`,
            {
                x: 160,
                y: 611,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        if (this.information.personnelInformation?.socialClientCategory === 'MARRIED') {
            DCCfirstPage.drawRectangle({
                x: 156,
                y: 585,
                width: 29,
                height: 10,
                color: rgb(1, 1, 1),
            });
        } else {
            DCCfirstPage.drawRectangle({
                x: 138,
                y: 585,
                width: 25,
                height: 10,
                color: rgb(1, 1, 1),
            });
        }

        DCCfirstPage.drawText(
            `${
                this.information.personnelInformation?.birthDay
                    ? this.information.personnelInformation?.birthDay.replace(/-/g, ' ').split(' ').join('  ')
                    : ''
            }`,
            {
                x: 197,
                y: 565,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        DCCfirstPage.drawText(
            `${
                this.information.personnelInformation?.actOfBrithNumber
                    ? String(this.information.personnelInformation?.actOfBrithNumber)
                        .split('')
                        .join('   ')
                    : ''
            }`,
            {
                x: 210,
                y: 542,
                size: 11.4,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );


        DCCfirstPage.drawText(
            `${
                this.information.personnelInformation?.birthWilaya
                    ? this.information.personnelInformation?.birthWilaya
                    : ''
            }`,
            {
                x: 240,
                y: 520,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        DCCfirstPage.drawText(
            `${

                this.information.personnelInformation?.placeOfBrith
                    ? this.information.personnelInformation?.placeOfBrith
                    : ''
            }`,
            {
                x: 260,
                y: 495,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        DCCfirstPage.drawText(
            `${
                this.information.personnelInformation?.idCardNumber
                    ? this.information.personnelInformation?.idCardNumber
                    : ''
            }`,
            {
                x: 250,
                y: 400,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        DCCfirstPage.drawText(
            `${
                this.information.personnelInformation?.deliveryDaira
                    ? this.information.personnelInformation?.deliveryDaira
                    : ''
            }`,
            {
                x: 170,
                y: 377,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        DCCfirstPage.drawText(
            `${
                this.information.personnelInformation?.validationDate
                    ? this.stringToDate(this.information.personnelInformation?.validationDate)!.replace(/\//g, ' ').split(' ').join('       ')
                    : ''
            }`,
            {
                x: 400,
                y: 377,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        DCCfirstPage.drawText(
            `${
                this.information.informationAddress.wilayaNameAscii
                    ? this.information.informationAddress.wilayaNameAscii
                    : ''
            }`,
            {
                x: 240,
                y: 328,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );
        DCCfirstPage.drawText(
            `${
                this.information.personnelSituation.personnelSituationCommune
                    ? this.information.personnelSituation.personnelSituationCommune
                    : ''
            }`,
            {
                x: 246,
                y: 305,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        DCCfirstPage.drawText(
            `${
                this.information.personnelSituation.personnelSituationCommune
                    ? this.information.personnelSituation.personnelSituationCommune
                    : ''
            }`,
            {
                x: 343,
                y: 185,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        DCCfirstPage.drawText(
            `${this.datePipe.transform(new Date(), "dd/MM/yyyy")}`,
            {
                x: 450,
                y: 185,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),

            }
        );

        // Demande ouverture de compte --------------------------------------------------

        const demandeOuvertureCompteB = await fetch(
            this.demandeOuvertureCompteB
        ).then((res) => res.arrayBuffer());

        const DOCB = await PDFDocument.load(demandeOuvertureCompteB);
        DOCB.registerFontkit(fontkit);
        const arabicFont = await DOCB.embedFont(fontBytes);
        const helveticaFont = await DOCB.embedFont(StandardFonts.HelveticaBold);

        const DOCBpages = DOCB.getPages();
        const DOCBfirstPage = DOCBpages[0];

        DOCBfirstPage.drawText(
            `${this.requestNumber ? this.requestNumber : ''}`,
            {
                x: 40,
                y: 712,
                size: 12,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            }
        );
        DOCBfirstPage.drawText(
            `${
                this.information.personnelInformation?.fistNameEnArab
                    ? this.information.personnelInformation?.fistNameEnArab!
                    : ''
            }   ${
                this.information.personnelInformation?.lastNameEnArab
                    ? this.information.personnelInformation?.lastNameEnArab
                    : ''
            }`,
            {
                x: this.getX(
                    450,
                    this.information.personnelInformation?.lastNameEnArab! +
                    this.information.personnelInformation?.fistNameEnArab!,
                    arabicFont,
                    10
                ),
                y: 630,
                size: 10,
                font: arabicFont,
                color: rgb(0, 0, 0),
            }
        );

        DOCBfirstPage.drawText(
            `${
                this.information.personnelInformation?.birthDay
                    ? this.stringToDate(this.information.personnelInformation?.birthDay)!
                    : ''
            }`,
            {
                x: this.getX(
                    495,
                    this.information.personnelInformation?.birthDay!,
                    helveticaFont,
                    10
                ),
                y: 612,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            }
        );

        DOCBfirstPage.drawText(
            `${
                this.information.personnelInformation?.placeOfBrithArabic
                    ? this.information.personnelInformation?.placeOfBrithArabic!
                    : ''
            }`,
            {
                x: this.getX(
                    270,
                    this.information.personnelInformation?.placeOfBrithArabic!,
                    arabicFont,
                    10
                ),
                y: 612,
                size: 10,
                font: arabicFont,
                color: rgb(0, 0, 0),
            }
        );

        DOCBfirstPage.drawText(
            `${
                this.information.personnelInformation?.idCardNumber
                    ? this.information.personnelInformation?.idCardNumber!
                    : ''
            }`,
            {
                x: this.getX(
                    460,
                    String(this.information.personnelInformation?.idCardNumber)!,
                    arabicFont,
                    10
                ),
                y: 597.5,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            }
        );

        DOCBfirstPage.drawText(
            `${
                this.information.personnelInformation?.deliveryDate
                    ? this.stringToDate(this.information.personnelInformation?.deliveryDate)!
                    : ''
            }`,
            {
                x: this.getX(
                    310,
                    this.stringToDate(this.information.personnelInformation?.deliveryDate)!,
                    helveticaFont,
                    10
                ),
                y: 597.5,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            }
        );

        DOCBfirstPage.drawText(
            `${
                this.information.personnelInformation?.deliveryDairaArabic
                    ? this.information.personnelInformation?.deliveryDairaArabic!
                    : ''
            }`,
            {
                x: this.getX(
                    150,
                    String(this.information.personnelInformation?.deliveryDairaArabic)!,
                    arabicFont,
                    10
                ),
                y: 597.5,
                size: 10,
                font: arabicFont,
                color: rgb(0, 0, 0),
            }
        );


        DOCBfirstPage.drawText(
            `${
                this.information.personnelSituation.personnelSituationAddress
                    ? this.invertNumbers(this.information.personnelSituation.personnelSituationAddress!)
                    : ''
            }
        `,
            {
                x: this.getX(
                    525,
                    String(this.information.personnelSituation.personnelSituationAddress!),
                    arabicFont,
                    10
                ),
                y: 580,
                size: 10,
                font: arabicFont,
                color: rgb(0, 0, 0),
            }
        );

        DOCBfirstPage.drawText(
            `${
                this.information.personnelSituation.zipCode
                    ? this.information.personnelSituation.zipCode!
                    : ''
            }
        `,
            {
                x: this.getX(
                    510,
                    String(this.information.personnelSituation.zipCode!),
                    arabicFont,
                    10
                ),
                y: 564,
                size: 10,
                font: arabicFont,
                color: rgb(0, 0, 0),
            }
        );

        DOCBfirstPage.drawText(
            `${
                this.information.personnelInformation?.communeArabic
                    ? this.information.personnelInformation.communeArabic!
                    : ''
            }
        `,
            {
                x: this.getX(
                    370,
                    String(this.information.personnelInformation.communeArabic!),
                    arabicFont,
                    10
                ),
                y: 564,
                size: 10,
                font: arabicFont,
                color: rgb(0, 0, 0),
            }
        );

        DOCBfirstPage.drawText(
            `الجزائر`,
            {
                x: this.getX(
                    150,
                    'الجزائر',
                    arabicFont,
                    10
                ),
                y: 564,
                size: 10,
                font: arabicFont,
                color: rgb(0, 0, 0),
            }
        );
        DOCBfirstPage.drawText(
            `${this.order.phone ? this.order.phone : ''}`,
            {
                x: this.getX(
                    350,
                    this.order.phone!,
                    helveticaFont,
                    10
                ),
                y: 547.5,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            }
        );
        DOCBfirstPage.drawText(
            `${this.client.email ? this.client.email : ''}`,
            {
                x: this.getX(
                    180,
                    this.client.email!,
                    helveticaFont,
                    10
                ),
                y: 547.5,
                size: 10,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            }
        );
        DOCBfirstPage.drawText(
            `${this.datePipe.transform(new Date(), "dd/MM/yyyy")!.replace(/\//g, ' ').split(' ').join('   ')}`,
            {
                x: 460,
                y: 125,
                size: 11,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            }
        );


        // Contrat de vente par facilité --------------------------------------------------

        const salesContract = await fetch(this.salesContract).then((res) =>
            res.arrayBuffer()
        );

        const CDVF = await PDFDocument.load(salesContract);
        CDVF.registerFontkit(fontkit);
        const arabicFont4 = await CDVF.embedFont(fontBytes);
        const helveticaFont4 = await CDVF.embedFont(StandardFonts.HelveticaBold);

        const CDVFpages = CDVF.getPages();
        const CDVFfirstPage = CDVFpages[0];

        CDVFfirstPage.drawText(
            `${this.requestNumber ? this.requestNumber : ''}`,
            {
                x: 59,
                y: 758,
                size: 12,
                font: helveticaFont4,
                color: rgb(0, 0, 0),
            }
        );

        //client first name and last name
        CDVFfirstPage.drawText(
            `${
                this.information.personnelInformation?.fistNameEnArab
                    ? this.information.personnelInformation?.fistNameEnArab!
                    : ""
            } ${
                this.information.personnelInformation?.lastNameEnArab
                    ? this.information.personnelInformation?.lastNameEnArab
                    : ""
            }`,
            {
                x: this.getX(
                    380,
                    this.information.personnelInformation?.lastNameEnArab! +
                    this.information.personnelInformation?.fistNameEnArab!,
                    arabicFont4,
                    10
                ),
                y: 719,
                size: 11,
                font: arabicFont4,
                color: rgb(0, 0, 0),
            }
        );

        //national card number
        CDVFfirstPage.drawText(
            `${
                this.information.personnelInformation?.idCardNumber
                    ? this.information.personnelInformation?.idCardNumber!
                    : ""
            }`,
            {
                x: 340,
                y: 700,
                size: 11,
                font: helveticaFont4,
                color: rgb(0, 0, 0),
            }
        );

         //card delivery daira
        CDVFfirstPage.drawText(
            `${
                this.information.personnelInformation?.deliveryDairaArabic
                    ? this.information.personnelInformation?.deliveryDairaArabic!
                    : ""
            }`,
            {
                x: this.getX(
                    450,
                    String(this.information.personnelInformation?.deliveryDairaArabic!),
                    arabicFont4,
                    11
                ),
                y: 682,
                size: 11,
                font: arabicFont4,
                color: rgb(0, 0, 0),
            }
        );

        // CDVFfirstPage.drawText(
        //     `${this.information.personnelInformation?.deliveryDaira ? this.information.personnelInformation?.deliveryDaira : ''}`,
        //     {
        //         x: this.getX(390, String(this.information.personnelInformation?.deliveryDaira)!, helveticaFont4, 9),
        //         y: 566.5,
        //         size: 9,
        //         font: helveticaFont4,
        //         color: rgb(0, 0, 0),
        //     }
        // );

        CDVFfirstPage.drawText(
            `${
                this.requestNumber
                    ? this.requestNumber!
                    : ""
            }`,
            {
                x: this.getX(
                    340,
                    String(this.requestNumber),
                    helveticaFont4,
                    10
                ),
                y: 663,
                size: 10,
                font: helveticaFont4,
                color: rgb(0, 0, 0),
            }
        );


        cat = Array.from(new Set(this.categoriesV2));
        let unicCat = Array.from(new Set(cat));
        if (unicCat.length > 2) {
            let firstLine = unicCat.slice(0, 2).join(",").toLocaleUpperCase();
            let secondLine = unicCat.slice(2).join(",").toLocaleUpperCase();
            CDVFfirstPage.drawText(`${firstLine ? firstLine : ""}`, {
                x: this.getX(  185 , String(firstLine!), helveticaFont4, 9),
                y: 663,
                size:  9,
                font: helveticaFont4,
                color: rgb(0, 0, 0),
            });

            CDVFfirstPage.drawText(`${secondLine ? secondLine : ""}`, {
                x: this.getX(  210 , String(firstLine!), helveticaFont4, 9),
                y: 653,
                size:  9,
                font: helveticaFont4,
                color: rgb(0, 0, 0),
            });
        } else {
            CDVFfirstPage.drawText(
                `${unicCat ? unicCat : ""}`,
                {
                    x: this.getX(  185 , String(unicCat!), helveticaFont4, 8),
                    y: 663,
                    size:  9,
                    font: helveticaFont4,
                    color: rgb(0, 0, 0),
                }
            );
        }

        CDVFfirstPage.drawText(
            `${
                this.credit?.hamicheElDjidia
                    ? this.credit?.hamicheElDjidia
                    : "00"
            }`,
            {
                x: 400,
                y: 575,
                size: 11,
                font: helveticaFont4,
                color: rgb(0, 0, 0),
            }
        );

        //montant de financement
        CDVFfirstPage.drawText(
            `${
                this.credit?.fundingAmount
                    ? this.addSeparation(this.credit?.fundingAmount)!
                    : ""
            } DA`,
            {
                x: 150,
                y: 575,
                size: 11,
                font: helveticaFont4,
                color: rgb(0, 0, 0),
            }
        );

        // Contrat de vente par facilité --------------------------------------------------

        const deposit = await fetch(this.deposit).then((res) =>
            res.arrayBuffer()
        );

        const DEPO = await PDFDocument.load(deposit);
        DEPO.registerFontkit(fontkit);
        const arabicFont5 = await DEPO.embedFont(fontBytes);
        const helveticaFont5 = await DEPO.embedFont(StandardFonts.HelveticaBold);

        const DEPOpages = DEPO.getPages();
        const DEPOfirstPage = DEPOpages[0];

        DEPOfirstPage.drawText(
            `${this.requestNumber ? this.requestNumber : ''}`,
            {
                x: 400,
                y: 738,
                size: 12,
                font: helveticaFont5,
                color: rgb(0, 0, 0),
            }
        );

        DEPOfirstPage.drawText(
            `${this.agency?.nameAgency ? this.agency?.nameAgency : ''}`,
            {
                x: 150,
                y: 734.5,
                size: 13,
                font: helveticaFont5,
                color: rgb(1, 0, 0),
            }
        );

        DEPOfirstPage.drawText(
            `${this.agency?.addressAgency ? this.agency?.addressAgency : ''}`,
            {
                x: 100,
                y: 721,
                size: 10,
                font: helveticaFont5,
                color: rgb(1, 0, 0),
            }
        );

        DEPOfirstPage.drawText(
            `${this.agency?.phone ? 'Téléphone ' + this.agency?.phone : ''}`,
            {
                x: 100,
                y: 707,
                size: 10,
                font: helveticaFont5,
                color: rgb(1, 0, 0),
            }
        );

        DEPOfirstPage.drawText(
            `${this.agency?.fax ? 'Fax ' + this.agency?.fax : ''}`,
            {
                x: 100,
                y: 693.5,
                size: 10,
                font: helveticaFont5,
                color: rgb(1, 0, 0),
            }
        );

        DEPOfirstPage.drawText(
            `${this.information.personnelInformation?.firstName ? this.information.personnelInformation?.firstName!.toLocaleUpperCase() : ''} ${
                this.information.personnelInformation?.lastName ? this.information.personnelInformation?.lastName.toLocaleUpperCase() : ''
            }`,
            {
                x: 310,
                y: 539.8,
                size: 13,
                font: helveticaFont5,
                color: rgb(1, 0, 0),
            }
        );


        // Demande d'achat d'équipement Particulier --------------------------------------------------


        const individualEquipPurchase = await fetch(this.individualEquipPurchase).then((res) =>
            res.arrayBuffer()
        );

        const DAP = await PDFDocument.load(individualEquipPurchase);
        DAP.registerFontkit(fontkit);

        const arabicFont6 = await DAP.embedFont(fontBytes);
        const helveticaFont6 = await DAP.embedFont(StandardFonts.HelveticaBold);


        const DAPpages = DAP.getPages();
        const DAPfirstPage = DAPpages[0];

        DAPfirstPage.drawText(
            `${this.requestNumber ? this.requestNumber : ''}`,
            {
                x: 58,
                y: 751,
                size: 12,
                font: helveticaFont6,
                color: rgb(0, 0, 0),
            }
        );

        DAPfirstPage.drawText(
            `${
                this.information.personnelInformation?.fistNameEnArab
                    ? this.information.personnelInformation?.fistNameEnArab!
                    : ""
            } ${
                this.information.personnelInformation?.lastNameEnArab
                    ? this.information.personnelInformation?.lastNameEnArab
                    : ""
            }`,
            {
               x: this.getX(
                    388,
                    this.information.personnelInformation?.lastNameEnArab! +
                    this.information.personnelInformation?.fistNameEnArab!,
                    arabicFont6,
                    10
                ),
                y: 712,
                size: 10,
                font: arabicFont6,
                color: rgb(0, 0, 0),
            }
        );
        DAPfirstPage.drawText(
            `${
                this.information.personnelInformation?.idCardNumber
                    ? this.information.personnelInformation?.idCardNumber!
                    : ""
            }`,
            {
                x: 353,
                y: 693,
                size: 10,
                font: helveticaFont6,
                color: rgb(0, 0, 0),
            }
        );
        DAPfirstPage.drawText(
            `${
                this.information.personnelInformation?.deliveryDate
                    ? this.stringToDate(
                        this.information.personnelInformation?.deliveryDate
                    )
                    : ""
            }`,
            {
                x: this.getX(
                    265,
                    this.information.personnelInformation?.deliveryDate!,
                    helveticaFont6,
                    10
                ),
                y: 693,
                size: 10,
                font: helveticaFont6,
                color: rgb(0, 0, 0),
            }
        );

        DAPfirstPage.drawText(
            `${
                this.information.personnelInformation?.deliveryDairaArabic
                    ? this.information.personnelInformation?.deliveryDairaArabic!
                    : ""
            }`,
            {
                x: this.getX(
                    198,
                    String(this.information.personnelInformation?.deliveryDairaArabic!),
                    arabicFont6,
                    10
                ),
                y: 693,
                size: 10,
                font: arabicFont6,
                color: rgb(0, 0, 0),
            }
        );
        DAPfirstPage.drawText(
            `${
                this.information.personnelSituation.personnelSituationAddress
                    ? this.invertNumbers(
                        this.information.personnelSituation.personnelSituationAddress!
                    )
                    : ""
            }`,
            {
                x: this.getX(
                    485,
                    this.information.personnelSituation.personnelSituationAddress!,
                    arabicFont6,
                    10
                ),
                y: 674,
                size: 10,
                font: arabicFont6,
                color: rgb(0, 0, 0),
            }
        );
        DAPfirstPage.drawText(`${this.order.phone ? this.order.phone! : ""}`, {
            x: this.getX(440, String(this.order.phone!), helveticaFont6, 10),
            y: 656,
            size: 10,
            font: helveticaFont6,
            color: rgb(0, 0, 0),
        });

        DAPfirstPage.drawText(
            `${
                unicManufactureNames
                    ? unicManufactureNames
                    : ""
            }`,
            {
                x: this.getX(manufactureNames.length >= 4 ? 500 : 470, String(unicManufactureNames!), helveticaFont6, 10),
                y: 523,
                size: manufactureNames.length >= 4 ? 8 : 10,
                font: helveticaFont6,
                color: rgb(0, 0, 0),
            }
        );

        brands = Array.from(new Set(brands));
        brands = brands.length >= 4 ? brands.slice(0, 4) : brands
        let unicBrands = Array.from(new Set(brands)).join(" ").toLocaleUpperCase();

        DAPfirstPage.drawText(`${unicBrands ? unicBrands : ""}`,
            {
                x: this.getX(brands.length >= 4 ? 470 : 463, String(unicBrands!), helveticaFont6, 10),
                y: 506,
                size: brands.length >= 4 ? 8 : 10,
                font: helveticaFont6,
                color: rgb(0, 0, 0),
            });

        DAPfirstPage.drawText(`${unicCat ? unicCat : ""}`, {
            x: this.getX(unicCat.length >= 4 ? 490 : 460, String(unicCat!), helveticaFont6, 10),
            y: 488,
            size: unicCat.length >= 4 ? 8 : 10,
            font: helveticaFont6,
            color: rgb(0, 0, 0),
        });

        //Saving Files To Download Them Modified
        const pdfBytes1 = await DCC.save();
        this.saveByteArray('Demande de Consultation de la Centrale des Risques', pdfBytes1);
        const pdfBytes2 = await DOCB.save();
        this.saveByteArray("Demande ouverture de compte.pdf", pdfBytes2);
        const pdfBytes3 = await CDVF.save();
        this.saveByteArray('وعد بشراء تجهيزات بالمرابحة.pdf', pdfBytes3);
        const pdfBytes4 = await DEPO.save();
        this.saveByteArray('Récépissé de dépôt.pdf', pdfBytes4);
         const pdfBytes5 = await DAP.save();
         this.saveByteArray("Demande d\'achat d\'équipement Particulier", pdfBytes5);
        this.downLoadFiles$.next(false);

    }

    invertNumbers(input: string): string {
        return input.replace(/\d+/g, match => {
            return match.split('').reverse().join('');
        });
    }


    stringToDate(dateString: string): string {
        if (dateString && dateString.length > 12) {
            const dateObject = new Date(dateString);
            return this.datePipe.transform(dateObject, 'dd/MM/yyyy')
        } else {
            return dateString;
        }

    }

    saveByteArray(reportName: any, byte: any) {
        var blob = new Blob([byte], {type: "application/pdf"});
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
    }

    addSeparation(value: number): string {
        if (value) {
            const stringValue = value.toString();
            return stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        } else {
            return "";
        }
    }

    getX(x: number, value: string, font: any, fontSize: number) {
        if (value) {
            const textWidth = font.widthOfTextAtSize(value, fontSize);
            return x - textWidth;
        } else {
            return 0;
        }
    }
}
