import {Component, Input, OnInit} from '@angular/core';
import {ClientInfoScoringDTO} from "../../../../../../../../core/ek-e-commerce/ek-models/scoring.model";
import {
    ClientOcrInformationService
} from "../../../../../../../../core/ek-e-commerce/ek-services/clientOcrInformation.service";
import {LayoutUtilsService, MessageType} from "../../../../../../../../core/_base/crud";
import {BehaviorSubject} from "rxjs";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'kt-rating-details',
    templateUrl: './rating-details.component.html',
    styleUrls: ['./rating-details.component.scss']
})
export class RatingDetailsComponent implements OnInit {

    @Input() ClientInfoScoring: ClientInfoScoringDTO;
    idOrder = 0;
    idFolder = 0;


    loadingSimulationSubject$ = new BehaviorSubject<boolean>(false);
    readonly loadingSimulationForm$ = this.loadingSimulationSubject$.asObservable();

    constructor(private clientOcrInformationService: ClientOcrInformationService,
                private route: ActivatedRoute,
                private layoutUtilsService: LayoutUtilsService) {
    }

    ngOnInit(): void {

        // from route
        this.route.paramMap.subscribe((params) => {
            this.idOrder = Number(params.get("idOrder"));
            this.idFolder = Number(params.get("idFolder"));
        });

    }

    calculateAge(birthDateString: string) {
        if (birthDateString) {
            const birthDate = new Date(birthDateString);
            const today = new Date();
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDifference = today.getMonth() - birthDate.getMonth();

            if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }

            return age;
        }
        return '';
    }

    downloadRatingEdition() {
        this.clientOcrInformationService.downloadScoringSimulation(this.idFolder, this.idOrder)
            .subscribe({
                next: (response) => {
                    if (response.message === 'success' && response.body) {
                        this.loadingSimulationSubject$.next(true);
                        const byteCharacters = atob(response.body);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers [i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        const nav = (window.navigator as any);
                        const blob = new Blob([byteArray], {type: 'application / vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                        if (nav && nav.msSaveOrOpenBlob) {
                            nav.msSaveOrOpenBlob(blob);
                            return;
                        }
                        const url = window.URL.createObjectURL(blob);
                        const anchor = document.createElement('a');
                        anchor.download = `ratingEdition` + '_' + this.idFolder + '.pdf';
                        anchor.href = url;

                        anchor.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
                        this.loadingSimulationSubject$.next(false);
                        setTimeout(function () {
                            window.URL.revokeObjectURL(url);
                            anchor.remove();
                        }, 100);
                    }

                },
                error: (error) => {
                    const message = 'Erreur lors du téléchargement de la fiche de simulation ! Veuillez réessayer.';
                    this.layoutUtilsService.showActionNotification(
                        message,
                        MessageType.Update,
                        5000,
                        true,
                        true
                    );
                    this.loadingSimulationSubject$.next(false);
                }
            });
    }

}
