import { EkSnackBarService } from './../../../../../../../core/services/ek-snackBar.service';
import { FoldersService } from './../../../../../../../core/ek-e-commerce/ek-services/folders.service';
import { CreditAnalystModel } from './../../../../../../../core/ek-e-commerce/ek-models/credit-analyst.model';
import { UserService } from './../../../../../../../core/services/user.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';


@Component({
  selector: 'kt-analyst-list-popup',
  templateUrl: './analyst-list-popup.component.html',
  styleUrls: ['./analyst-list-popup.component.scss']
})
export class AnalystListPopupComponent implements OnInit {

  dataSource: MatTableDataSource<CreditAnalystModel>;
  selection: SelectionModel<CreditAnalystModel>;

  currentCreditAnalystId: number;

  displayedColumns: string[] = ['selected_row', 'id', 'username', 'email', 'pos', 'affected'];



  constructor(
    private userService: UserService,
    private folderService: FoldersService,
    public dialogRef: MatDialogRef<AnalystListPopupComponent>,
    private ekSnackBar: EkSnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: number[]
  ) {
    this.dataSource = new MatTableDataSource<CreditAnalystModel>();
    this.selection = new SelectionModel<CreditAnalystModel>(true, []);
   }

  ngOnInit(): void {
    
    this.userService.getCreditAnalystList().subscribe(res => {
      if(res) {
        this.dataSource.data = res
      }
  })
  }

  selectRow(event: any, row: any) {
    this.currentCreditAnalystId = row.id
  }

  getPosNames(element: CreditAnalystModel): string {
    return element.pointOfSaleNames.join(', ');
  }

  assignCredit() {
    this.folderService.assignCreditAnalyst(this.data, this.currentCreditAnalystId).subscribe({
      next : (res) => {
        if(res.message === 'adding  source successfully') {
          const msg = "Les dossiers ont été affecté avec succés"
          this.ekSnackBar.openSnackBar(msg, ' ', 'error-snackbar')
          this.dialogRef.close()
        } else {
          const msg = "Une erreur est survenu"
          this.ekSnackBar.openSnackBar(msg, ' ', 'error-snackbar')
          this.dialogRef.close()
        }
      },
      error: (error) => {
        const msg = "Une erreur est survenu"
          this.ekSnackBar.openSnackBar(msg, ' ', 'error-snackbar')
          this.dialogRef.close()
      }
    })
  }

  closeDialog() {
    this.dialogRef.close()
  }
}
