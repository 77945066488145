import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EkSnackbarComponent } from '../ek-e-commerce/ek-snackbar/ek-snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class EkSnackBarService {

  constructor(private snackBar: MatSnackBar) {}
  public openSnackBar(message: string, action: string, type: string) {

    this.snackBar.openFromComponent(EkSnackbarComponent, {
      duration: 10000,
      panelClass: [type],

      data: { message: message,type:type,action:action}
    });
  }

}
