import {BaseDataSource, QueryResultsModel} from "../../_base/crud";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import {
    selectAgencyBanksInStore,
    selectAgencyBanksPageLoading,
    selectAgencyBanksShowInitWaitingMessage
} from "../ek-selectors/agency-bank.selector";

export class AgencyBankDatasource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectAgencyBanksPageLoading),
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectAgencyBanksShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectAgencyBanksInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }

}
