import {BaseModel} from "../../_base/crud";

export class EkCategoryModel extends BaseModel {

    id: number;
    label: string;
    labelArabic: string;
    description: string;
    parentId: number;
    image: string;
    file: File;
    activate: boolean;
    position: number;
    updater: string;
    isVisible: boolean;


    clear() {
        this.id = undefined;
        this.label = '';
        this.labelArabic = '';
        this.description = '';
        this.parentId = undefined;
        this.image = '';
        this.activate = true;
        this.position = undefined;
        this.updater= '';
        this.isVisible = true;

    }
}

