import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../../reducers';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {LayoutUtilsService, MessageType} from '../../_base/crud';
import {CustomerDiscountService} from '../_services/customer-discount.service';
import * as CustomerDiscountAction from '../_actions/customer-discount.action';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import * as PromoCodeActions from "../_actions/promoCode.action";


@Injectable()
export class DiscountCustomerEffects {
    constructor(private actions$: Actions, private discountCustomerService: CustomerDiscountService, private layoutUtilsService: LayoutUtilsService,
                private dialog: MatDialog,) {
    }

/*
    DiscountCustomerByClientIdPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomerDiscountAction.CustomerDiscountByClientIdPageRequested),
            switchMap((action) =>
                this.discountCustomerService.getClientDiscountById(action.idClient)
                    .pipe(
                        map(res => CustomerDiscountAction.CustomerDiscountByClientIdPageLoadedSuccessfully(
                            {
                                discountCustumers: res
                            })),
                        catchError(error => of(CustomerDiscountAction.CustomerDiscountByClientIdPageLoadFailed({error})))
                    ))));*/
    DiscountCustomerCreated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomerDiscountAction.CustomerdiscountCreated),
            switchMap((action) =>
                this.discountCustomerService.createDiscountClient(action.discountCustomer)
                    .pipe(
                        map(data => this.verifyCustomerdiscountCreation(data)
                        ),
                        catchError(error => of(CustomerDiscountAction.CustomerdiscountCreationFailed({error})))
                    ))));

    DiscountCustomerUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomerDiscountAction.CustomerdiscountUpdated),
            switchMap((action) =>
                this.discountCustomerService.updateDiscount(action.discountCustumer)
                    .pipe(
                        map(res => this.messageUpdateDiscount(res, action.discountCustumer, action.partialDiscount)),
                        catchError(error => of(CustomerDiscountAction.CustomerdiscountUpdatedFailed({error})))
                    ))));

    DiscountCustomerDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomerDiscountAction.CustomerdiscountDeleted),
            switchMap((action) =>
                this.discountCustomerService.deleteDiscount(action.discountCustumerId)
                    .pipe(
                        map(res => this.messageDeleteDiscount(res.message, action.discountCustumerId)),
                        catchError(error => of(CustomerDiscountAction.CustomerdiscountDeleteFailed({error})))
                    ))));

    message(message,) {

        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);

    }
    DiscountSearchRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomerDiscountAction.DiscountSearchRequested),
            switchMap((action) =>
                this.discountCustomerService.searchSorted(action.page.sortOrder,action.page.sortField,action.page.pageNumber,action.page.pageSize,action.page.filter,action.discountState, action.id)
                    .pipe(
                        map(Discount_Customer => CustomerDiscountAction.DiscountSearchLoadedSuccessfully(
                            {
                                Discount_Customer:Discount_Customer.content,
                                page: Discount_Customer.totalElements,
                                totalCount: Discount_Customer.totalElements,
                                lastRequest: action.page
                            })),
                        catchError(error => of(CustomerDiscountAction.DiscountSearchLoadFailed({error})))
                    ))));
    CategoryUpdatedActivate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomerDiscountAction.CustomerDiscountUpdatedActivate),
            switchMap((action) =>
                this.discountCustomerService.updateDiscountCostumer(action.discountCustumer.id, action.active,action.event)
                    .pipe(
                        map(res =>

                        {

                            return this.messageActiveDiscount(res.message, action.discountCustumer,action.partialdiscountCustumer,action.active)

                        }
                            ),
                        catchError(error => of(CustomerDiscountAction.CustomerdiscountUpdatedFailed({error})))
                    ))));
    DiscountclientFiltreDate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomerDiscountAction.DiscountClientFilterByDate),
            switchMap((action) =>
                this.discountCustomerService.filterDiscountByDate(action.page.pageNumber,action.page.pageSize,action.startingDate,action.endingDate,action.clientId)
                    .pipe(
                        map(DiscountClient => CustomerDiscountAction.DiscountClientFilterByDateSuccessfully({
                            Discount_Client: DiscountClient.content,
                            page: DiscountClient.totalElements,
                            totalCount: DiscountClient.totalElements,
                            lastRequest:action.page
                        })),
                        catchError(error => of(CustomerDiscountAction.DiscountClientFilterByDateFailed({error})))
                    ))));

    messageDeleteDiscount(message, discountCustumerId) {

        if (message == "can not delete this discount client ") {
            this.layoutUtilsService.showActionNotification("Cette remise était appliquer sur une commande, vous ne pouvez donc pas la supprimer", MessageType.Update, 10000, true, true);
            return CustomerDiscountAction.CustomerDiscountUpdateActivateFailed(message);
        } else {
            this.layoutUtilsService.showActionNotification("discountCustomer à été  supprimé avec succès!", MessageType.Update, 10000, true, true);

            return CustomerDiscountAction.CustomerdiscountDeletedSuccessfully({discountCustumerId: discountCustumerId})

        }
    }

    messageUpdateDiscount(message: any, discountCustumer, partialDiscount) {

        if (message == "this Discount Client Already Used") {
            this.layoutUtilsService.showActionNotification("Cette remise était appliquer sur une commande, vous ne pouvez donc pas la modifié !", MessageType.Update, 10000, true, true);
            return CustomerDiscountAction.CustomerdiscountUpdatedFailed(message);
        } else {
            this.layoutUtilsService.showActionNotification("discountCustomer à été modifier  avec succès!", MessageType.Update, 10000, true, true);
            return CustomerDiscountAction.CustomerdiscountUpdatedSuccessfully({
                discountCustumer: discountCustumer,
                partialDiscount: partialDiscount
            })

        }

    }

    verifyCustomerdiscountCreation(response) {

        switch (response.message) {
            case "can not create discount client ,there is a promo code active":
                this.openDialoge(response.body,"promoCodeCase");
                return CustomerDiscountAction.CustomerdiscountCanNotBeCreated({configurations: response.body});
                break;
            case "can not create discount client  ,there is a discount active":
                this.openDialoge(response.body,"DiscountCase");
                return CustomerDiscountAction.CustomerdiscountCanNotBeCreated({configurations: response.body});
                break;
            case"discount client.added.successfully":
                return CustomerDiscountAction.CustomerdiscountCreatedSuccessfully({discountCustumer: response.body});
                break;
        }
    }

    openDialoge(data,case_) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '500px';

        if (case_ === 'promoCodeCase') {
            dialogConfig.data = {
                list: data,
                case: 'discountCase',
                title: 'Liste des Configurations',
                errorMs: 'Création de la remise client refusé, un ou plusieurs code promo existe déjà durant cette période'
            };
        } else {
            dialogConfig.data = {
                list: data,
                case: 'discountCase',
                title: 'List des configurations',
                errorMs: 'Création de la remise client refusé, une promotion existe déjà durant cette période'
            };
        }
    }
    messageActiveDiscount(message: any ,discountCustumer, partialDiscount,active) {

        if (message !== "there.is.discountClient.active") {
            const saveMessage = `discountCustomer status est change avec succes!`;
            this.layoutUtilsService.showActionNotification(saveMessage, MessageType.Update, 10000, true, true);
            return CustomerDiscountAction.CustomerDiscountUpdateActivateFailed(message);
        } else {
            this.layoutUtilsService.showActionNotification("discountCustomer status est change avec succes!", MessageType.Update, 10000, true, true);
            return CustomerDiscountAction.CustomerDiscountUpdatedActivateSuccessfully({
                discountCustumer: discountCustumer,
                partialDiscountCustomer: partialDiscount,
                active:active
            })
        }

    }

}
