import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";
import {User} from "../_models/user.model";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  url: string;

  constructor(protected http: HttpClient) {
    this.url = `${ADMIN_CONFIG.apiEndpoint}ws-users`;
  }
public getUserById(id:number): Observable<User>{
  const url = `${this.url}/${id}`;
  return this.http.get<Response<User>>(url).pipe(map(({body}) => body));


}
}
