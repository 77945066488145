import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'kt-canceling-reason-pop-up',
  templateUrl: './canceling-reason-pop-up.component.html',
  styleUrls: ['./canceling-reason-pop-up.component.scss']
})
export class CancelingReasonPopUpComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

}
