import { Action, createReducer, on } from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";
import * as ParcelActions from "../_actions/parcel.actions";
import {ParcelsResponseModel} from "../_models/parcelsResponse.model";



export const regionFeatureKey = 'parcel';

export interface ParcelState extends EntityState<ParcelsResponseModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    lastQuery: QueryParamsModel;
    totalCount: number;
    showInitWaitingMessage: boolean;
    error: any;
    errorMessage: string;
    lastAction: string;
    progress: number;
}

export const adapter: EntityAdapter<ParcelsResponseModel> = createEntityAdapter<ParcelsResponseModel>();

export const initialParcelState: ParcelState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    errorMessage: '',
    lastAction: '',
    progress: 0
});


export const parcelReducer = createReducer(
    initialParcelState,

    on(ParcelActions.loadParcels,
        (state, action) => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: ''

        })),
    on(ParcelActions.loadParcelsSuccess,
        (state, action) =>
            (adapter.addAll(
                [action.data],
                {
                    ...state,
                    actionsLoading: false,
                    listLoading: false,
                    showInitWaitingMessage: false,
                    totalCount: action.data.total_data
                }))),
    on(ParcelActions.loadParcelsFailure,
        (state, action) => ({
            ...state,
            actionsLoading: true,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),

);

export function ParcelReducer(state: ParcelState | undefined, action: Action) {
    return parcelReducer(state, action)
}

