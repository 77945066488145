export const CPA_ENVIRONMENTS = {
    productionUrl: "https://cpa-bank.admin.ekiclik.dz",
    devUrl: "https://dev.cpa-bank.admin.ekiclik.com",
    stagingUrl: "https://staging.cpa-bank.admin.ekiclik.com",
    localHostUrl: "http://localhost:4200",
};


export const PDVA_ENVIRONMENTS = {
    productionUrl: "https://pdva.ekiclik.com",
    stagingUrl: "https://pdva-stg.ekiclik.com",
    devUrl: "https://pdva-dev.ekiclik.com",
    localHostUrl: "http://localhost:4200",
};
