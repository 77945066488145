// Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
// NGRX
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
// Translate
import {TranslateModule} from '@ngx-translate/core';
import {PartialsModule} from '../../partials/partials.module';
// Services
import {HttpUtilsService, InterceptService, LayoutUtilsService, TypesUtilsService} from '../../../core/_base/crud';
// Shared
import {ActionNotificationComponent} from '../../partials/content/crud';
// Components
import {RegionComponent} from "../apps/e-commerce/region/region.component";
import {RegionEditComponent} from "../apps/e-commerce/region/region-edit/region-edit.component";
import {EkUserManagementComponent} from "./ek-user-management.component";
import { EkUsersListComponent } from './ek-users-list/ek-users-list.component';
import { EkUsersEditComponent } from './ek-users-edit/ek-users-edit.component';
import { EkChangePswComponent } from './ek-subs/ek-change-psw/ek-change-psw.component';
import { EkSocialNetworksComponent } from './ek-subs/ek-social-networks/ek-social-networks.component';
import { EkUserRolesComponent } from './ek-subs/ek-user-roles/ek-user-roles.component';

// Material
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule
} from '@angular/material';
import {UserEffects, usersReducer} from '../../../core/auth';
import { EkUserPosComponent } from './ek-subs/ek-user-pos/ek-user-pos.component';
import {CoreModule} from "../../../core/core.module";


const routes: Routes = [
    {
        path: '',
        component: EkUserManagementComponent,
        children: [
            {
                path: '',
                redirectTo: 'roles',
                pathMatch: 'full'
            },
            {
                path: 'users',
                component: EkUsersListComponent,
                // canActivate:[ModuleGuard],
                data: {
                    role: 'ROLE_ADMIN'
                }
            },
            {
                path: 'users:id',
                component: EkUsersListComponent
            },
            {
                path: 'users/add',
                component: EkUsersEditComponent
            },
            {
                path: 'users/add:id',
                component: EkUsersEditComponent
            },
            {
                path: 'users/edit',
                component: EkUsersEditComponent
            },
            {
                path: 'users/edit/:id',
                component: EkUsersEditComponent
            },
            {
                path: 'region',
                component: RegionComponent,
                // canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        // only: ['ALL_POINTRELAIT', 'READ_POINTRELAIT'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'region/edit',
                component: RegionEditComponent,
                // canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        // only: ['ALL_POINTRELAIT', 'READ_POINTRELAIT'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'region/edit/:id',
                component: RegionEditComponent,
                // canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        // only: ['ALL_POINTRELAIT', 'READ_POINTRELAIT'],
                        redirectTo: '/error/403'
                    }
                }
            },
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        PartialsModule,
        RouterModule.forChild(routes),
        StoreModule.forFeature('users', usersReducer),
        EffectsModule.forFeature([UserEffects]),
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatExpansionModule,
        MatTabsModule,
        MatTooltipModule,
        MatDialogModule,
        CoreModule
    ],
    providers: [
        InterceptService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptService,
            multi: true
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                panelClass: 'kt-mat-dialog-container__wrapper',
                height: 'auto',
                width: '900px'
            }
        },
        HttpUtilsService,
        TypesUtilsService,
        LayoutUtilsService
    ],
    entryComponents: [
        ActionNotificationComponent,
    ],
    declarations: [
        EkUserManagementComponent,
        EkUsersEditComponent,
        EkUserRolesComponent,
        EkSocialNetworksComponent,
        EkUsersListComponent,
        EkChangePswComponent,
        EkUserPosComponent,
    ]
})
export class EkUserManagementModule {
}
