// NGRX
import {createFeatureSelector, createSelector} from '@ngrx/store';
// Lodash
// Selectors
// Models
import * as fromAuthReducer from "../_reducers/auth.reducers";
import {AuthState} from "../_reducers/auth.reducers";

const getAuthState = createFeatureSelector<AuthState>(
    fromAuthReducer.authFeatureKey
);

export const isLoggedIn = createSelector(getAuthState, (state: AuthState) => state.loggedIn);
export const isLoggedOut = createSelector(getAuthState, (state: AuthState) => !state.loggedIn);
export const currentToken = createSelector(getAuthState, (state: AuthState) => state.authToken);
export const isLoading = createSelector(getAuthState, (state: AuthState) => state.isLoading);
export const isUserLoaded = createSelector(getAuthState, (state: AuthState) => state.isUserLoaded);
export const currentUser = createSelector(getAuthState, (state: AuthState) => state.user);
export const error = createSelector(getAuthState, (state: AuthState) => state.error);
export const currentUserRoles = createSelector(
    currentUser,
    user => {
        if (!user) {
            return '';
        }

        return user.roles;
    }
);

// export const checkHasUserPermission = (permission: string) => createSelector(
//     currentUserRoles,
//     (roles: string) => {
//         return roles.some(role => role === permission);
//     }
// );
