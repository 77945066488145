import { Pipe, PipeTransform } from '@angular/core';
import { DocumentModel } from '../../../../core/ek-e-commerce/ek-models/document.model';

@Pipe({
  name: 'getId'
})
export class GetIdPipe implements PipeTransform {

  transform(value: DocumentModel[], documentGender: string): number {
    let id = 0
    if(value) {
      let obj = value.find(o => o.documentsGender == documentGender)
      id = obj?.id!
    }
    return id;
  }

}
