import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DATE_LOCALE, MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {OrdersService} from "../../../../../../core/e-commerce/_services/orders.service";
import * as XLSX from "xlsx";
// tslint:disable-next-line:no-duplicate-imports
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {DateAdapter, MAT_DATE_FORMATS} from "@angular/material/core";
import { DatePipe } from '@angular/common';
import {FormControl, FormGroup} from "@angular/forms";
export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'DD MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
export interface WrongOrders{
    orderIds:number;
    hasError:boolean;
}

@Component({
    selector: 'kt-order-excel-monthly',
    templateUrl: './order-excel-monthly.component.html',
    styleUrls: ['./order-excel-monthly.component.scss'],
    providers: [

        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },

        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
})
export class OrderExcelMonthlyComponent implements OnInit {
    startDate =  new Date();
    endDate =  new Date();
    dateFilter = new FormGroup({
        startDate: new FormControl(''),
        endDate: new FormControl(''),
    });
    dateSystem = new Date();
    private monthSysytem: number;
    public myDate = new Date();
    systemDate: Date;
    laodingExcel = false;
    messageSuccess = true;
    constructor(private dialogRef: MatDialogRef<OrderExcelMonthlyComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private orderService: OrdersService,
                private datePipe: DatePipe
    ) {
    }
    date: Date;
    error: string = '';
    wrongOrders: WrongOrders [];
    fileName = "export-wrong-orders-monthly.xlsx";

    ngOnInit(): void {
        this.datePipe.transform(this.dateSystem,"yyyy-MM-dd");
        this.monthSysytem  = this.dateSystem.getMonth();
    }
    monthSelected(event, dp, input) {
        dp.close();
        input.value = event.toISOString().split('-').join('/').substr(0, 7);
        const d = new Date();
        d.setFullYear(input.value.toString().substr(0, 4))
        d.setMonth(input.value.toString().substr(5, 6));
        d.setMonth(d.getMonth()-1);
        this.date = d;
        this.error = '';
    }

    exportOrdersExcelMonthly() {
        this.startDate =    new Date(this.dateFilter.get('startDate').value),
            this.endDate =    new Date(this.dateFilter.get('endDate').value),
            this.laodingExcel=true;

        this.orderService.ExportExcelOrdersMonthly( this.startDate,  this.endDate).subscribe((response: any) => {
            if (response.message == 'success' && response.body.data) {
                this.wrongOrders = response.body.error.filter(e => e.hasError == true);
                const byteCharacters = atob(response.body.data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers [i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                let blob = new Blob([byteArray], {type: 'application / vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                this.laodingExcel=false;

                anchor.download = `CommandesM.xlsx`;
                anchor.href = url;

                anchor.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
                this.laodingExcel=false;
                setTimeout(function () {
                    window.URL.revokeObjectURL(url);
                    anchor.remove();
                }, 100)

                if (this.wrongOrders.length > 0) {
                    this.exportWrongOrders();
                }
            }
        }  )}
        /**
        if (this.date) {
            if(this.date.getMonth() < this.monthSysytem){
                this.laodingExcel=true;
                this.orderService.ExportExcelOrdersMonthly(this.date).subscribe((response: any) => {
                    if (response.message == 'success' && response.body.data) {
                            this.wrongOrders = response.body.error.filter(e => e.hasError == true);
                            const byteCharacters = atob(response.body.data);
                            const byteNumbers = new Array(byteCharacters.length);
                            for (let i = 0; i < byteCharacters.length; i++) {
                                byteNumbers [i] = byteCharacters.charCodeAt(i);
                            }
                            const byteArray = new Uint8Array(byteNumbers);
                            let blob = new Blob([byteArray], {type: 'application / vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                window.navigator.msSaveOrOpenBlob(blob);
                                return;
                            }
                            const url = window.URL.createObjectURL(blob);
                            const anchor = document.createElement('a');
                            anchor.download = `CommandesM.xlsx`;
                            anchor.href = url;

                            anchor.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
                            this.laodingExcel=false;
                            setTimeout(function () {
                                window.URL.revokeObjectURL(url);
                                anchor.remove();
                            }, 100)

                            if (this.wrongOrders.length > 0) {
                                this.exportWrongOrders();
                            }
                        }
                        this.orderService.ExportExcelOrdersMonthly(this.date).pipe(finalize(()=>this.laodingExcel=false))
                    },
                );
            } else {
                this.error = "Vous devez attendre la fin du mois sélectionné pour pouvoir crée le rapport ou sélectionné le mois qui précède le mois actuel";
            }
        }
        else{
            this.error="Le mois et l'annee sont vides "
        } **/

    exportWrongOrders(): void {
        //pass here the table id
        let element = document.getElementById('wrongOrder_table');
        const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

        //generate workbook and add the worksheet
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        //save to file
        XLSX.writeFile(wb, this.fileName);

    }


}
