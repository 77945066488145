import {createFeatureSelector, createSelector} from "@ngrx/store";
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";
import {CategoryPopularModel} from "../_models/category-popular.model";
import {CategoryPopularState} from "../_reducers/category-popular.reducers";


export const selectCategoriesPopularState = createFeatureSelector<CategoryPopularState>('categories-popular');

export const selectCategoryPopularById = (categoryId: number) => createSelector(
    selectCategoriesPopularState,
    categoriesPopularState => categoriesPopularState.entities[categoryId]
);

export const selectCategoriesPopularPageLoading = createSelector(
    selectCategoriesPopularState,
    categoriesPopularState => categoriesPopularState.listLoading
);

export const selectError = createSelector(
    selectCategoriesPopularState,
    categoriesPopularState => categoriesPopularState.error
);

export const selectlastAction = createSelector(
    selectCategoriesPopularState,
    categoriesPopularState => categoriesPopularState.lastAction
);

export const selectCategoriesPopularActionLoading = createSelector(
    selectCategoriesPopularState,
    categoriesPopularState => categoriesPopularState.actionsLoading
);

export const selectCategoriesPopularShowInitWaitingMessage = createSelector(
    selectCategoriesPopularState,
    categoriesPopularState => categoriesPopularState.showInitWaitingMessage
);

export const selectCategoriesPopularInStore = createSelector(
    selectCategoriesPopularState,
    categoriesPopularState => {
        const items: CategoryPopularModel[] = [];
        each(categoriesPopularState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: CategoryPopularModel[] = httpExtension.sortArray(items, null, null);
        return new QueryResultsModel(result, categoriesPopularState.totalCount, '');
    }
);
