import {AbstractService} from "../../services/abstract-service.service";
import {CustomerModel} from "../_models/customer.model";
import {Injectable} from "@angular/core";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Page} from "../../_base/crud/models/page";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";

@Injectable()
export class CustomersService extends AbstractService<CustomerModel> {

    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}clients`;
    }

    public getAllSorted(page: number = 0, size: number = 100, sorting: string, direction: string, criteria: string, startDate: string, endDate: string, clientStatus: string): Observable<Page<CustomerModel>> {

        let params = new HttpParams();

        params = page ? params.append('pageNo', page.toString()) : params;
        params = size ? params.append('pageSize', size.toString()) : params;
        params = sorting ? params.append('sortBy', sorting) : params;
        params = direction ? params.append('direction', direction.toString()) : params;
        params = criteria ? params.append('criteria', criteria['criteria']) : params;
        params = startDate ? params.append('date', startDate) : params;
        params = endDate ? params.append('date1', endDate) : params;
        params = clientStatus ? params.append('clientStatus', clientStatus) : params;

        const options = {params};

        const url = `${this.url}/withOrdersCount`;
        return this.http.get<Response<Page<CustomerModel>>>(url, options).pipe(map(({body}) => {
            return body;
        }));
    }

    public getGuestMail(firstname: string, lastname: string) {
        const options = {
            params: new HttpParams()
                .append('firstname', firstname)
                .append('lastname', lastname)
        };
        const url = `${this.url}/guestmail`;
        return this.http.get(url, options)
    }


    public changeActiveCustomer(blocked: boolean, id: number, reasonForBlocking?: string) {
        let clientState = {
            blocked: blocked,
            id: id,
            reasonForBlocking: reasonForBlocking
        };
        const url = `${this.url}/blockingClients/`;
        return this.http.patch(url, clientState);
    }

    public getAllFilterByStatus(status: string = '', direction: string = 'desc', pageNo: number = 0, pageSize: number = 100, sortBy: string): Observable<Page<CustomerModel>> {

        const options = {
            params: new HttpParams()
                .append('clientStatus', status)
                .append('direction', direction)
                .append('pageNo', pageNo.toString())
                .append('pageSize', pageSize.toString())
                .append('sortBy', sortBy)
        };

        const url = `${this.url}/filterByStatus`;
        return this.http.get<Response<Page<CustomerModel>>>(url, options).pipe(map(({body}) => {
            return body;
        }));
    }


    public getAllFilterByDate(startDate: string, endDate: string, page: number = 0, size: number = 100): Observable<Page<CustomerModel>> {
        let params = new HttpParams();

        params = startDate ? params.append('date', startDate) : params;
        params = endDate ? params.append('date1', endDate) : params;
        params = page ? params.append('pageNo', page.toString()) : params;
        params = size ? params.append('pageSize', size.toString()) : params;

        const options = {params};

        const url = `${this.url}/filterByDate/`;
        return this.http.get<Response<Page<CustomerModel>>>(url, options).pipe(map(({body}) => body));
    }

    public sendMail(idClient: number = 0, message: string, file: File[] , object:string): Observable<Response<string>> {
        const formData = new FormData();


        for (const files of file) {
            formData.append('file', files, files.name);
        }

        formData.append('message', message);
        formData.append('id', idClient.toString());
        formData.append('object', object);


        const url = `${this.url}/sendMail`;
        return this.http.post<Response<any>>(url, formData);
    }

    public sendMails(idClient: number [], message: string, file: File[]): Observable<Response<string>> {
        const formData = new FormData();

        for (const files of file) {
            formData.append('file', files, files.name);
        }
        formData.append('message', message);
        formData.append('ids', idClient.toString());
        const url = `${this.url}/sendMails`;
        return this.http.post<Response<any>>(url, formData);
    }


    public ExportExcelCustomers(): Observable<Response<any>> {
        const url = `${this.url}/export/excel`;
        return this.http.get<Response<any>>(url);
    }

    public resetPasswordRequest(email: string) {
        const url = `${this.url}/reset-password-request`;

        return this.http.post<Response<any>>(url, email).pipe(
            map((response: Response<any>) => {
                if (response.code === 0) {
                    return response;
                }
                if (response.message === 'entity.doesnt.exist') {
                    return response;
                } else {
                    throw new Error();
                }
            })
        );
    }

    public sendEmailActivation(id: number): Observable<Response<any>> {
        const url = `${this.url}/sendActivationEmailByAdmin?id=${id}`;
        let params = new HttpParams();
        params = params.append('id', id.toString())
        return this.http.post<Response<any>>(url, {params})
            ;
    }


}
