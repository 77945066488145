// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap, switchMap, catchError} from 'rxjs/operators';
import {Observable, defer, of, forkJoin, pipe, EMPTY} from 'rxjs';
// NGRX
import {Effect, Actions, ofType, createEffect, act} from '@ngrx/effects';
import {Store, select, Action} from '@ngrx/store';
// CRUD
import {QueryResultsModel, QueryParamsModel} from '../../_base/crud';
// State
import {AppState} from '../../../core/reducers';
import * as UserActions from '../_actions/user.actions';
// Services
import {UserService} from "../../services/user.service";


@Injectable()
export class UserEffects {

    constructor(private actions$: Actions, private store: Store<AppState>, private userService: UserService) {
    }


    allUsersRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.AllUsersRequested),
            switchMap((action) =>
                this.userService.findAllEkUsers( action.page.pageSize, action.page.pageNumber , action.key, action.role, action.page.sortOrder)
                    .pipe(
                        map(users => {
                            return UserActions.AllUsersLoaded({
                            users : users.content,
                            totalCount : users.totalElements,
                            page : users.totalPages
                        });
                        }),
                        catchError(error => of(UserActions.AllUsersRequestedFailed({error})))
                    ))));

    deleteUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.UserDeleted),
            switchMap((action) => this.userService.deleteUserById(action.id)
                .pipe(
                    map(() => UserActions.UserDeletedSuccessfully({id: action.id})),
                    catchError(error => of(UserActions.UserDeletingFailed({error})))
                ))
        )
    );

    addUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.UserOnServerCreated),
            switchMap((action) => this.userService.saveEkUser(action.user)
                .pipe(
                    map(user => UserActions.UserAddedSuccessfully({user})),
                    catchError(error => of(UserActions.AddUserFailed({error})))
                ))
        ));

    updateUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.UserUpdated),
            switchMap((action) => this.userService.updateEkUser(action.user)
                .pipe(
                    map(user => UserActions.UserUpdatedSuccessfully({user, partialUser: action.partialUser})),
                    catchError(error => of(UserActions.UserUpdateFailed({error})))
                ))
        ));

    updatePwdUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.UserPwdUpdated),
            switchMap((action) => this.userService.updatePwdEkUser(action.user)
                .pipe(
                    map(user => UserActions.UserPwdUpdatedSuccessfully({user, partialUser: action.partialUser})),
                    catchError(error => of(UserActions.UserPwdUpdateFailed({error})))
                ))
        ));

    // @Effect()
    // loadUsersPage$ = this.actions$
    //     .pipe(
    //         ofType<UsersPageRequested>(UserActionTypes.UsersPageRequested),
    //         mergeMap(( { payload } ) => {
    //             this.store.dispatch(this.showPageLoadingDistpatcher);
    //             const requestToServer = this.auth.findUsers(payload.page);
    //             const lastQuery = of(payload.page);
    //             return forkJoin(requestToServer, lastQuery);
    //         }),
    //         map(response => {
    //             const result: QueryResultsModel = response[0];
    //             const lastQuery: QueryParamsModel = response[1];
    //             return new UsersPageLoaded({
    //                 users: result.items,
    //                 totalCount: result.totalCount,
    //                 page: lastQuery
    //             });
    //         }),
    //     );

    // @Effect()
    // deleteUser$ = this.actions$
    //     .pipe(
    //         ofType<UserDeleted>(UserActionTypes.UserDeleted),
    //         mergeMap(( { payload } ) => {
    //                 this.store.dispatch(this.showActionLoadingDistpatcher);
    //                 return this.userService.deleteUserById(payload.id);
    //             }
    //         ),
    //         map(() => {
    //             return this.hideActionLoadingDistpatcher;
    //         }),
    //     );


    // @Effect()
    // updateUser$ = this.actions$
    //     .pipe(
    //         ofType<UserUpdated>(UserActionTypes.UserUpdated),
    //         mergeMap(( { payload } ) => {
    //             this.store.dispatch(this.showActionLoadingDistpatcher);
    //             return this.auth.updateUser(payload.user);
    //         }),
    //         map(() => {
    //             return this.hideActionLoadingDistpatcher;
    //         }),
    //     );

    // @Effect()
    // createUser$ = this.actions$
    //     .pipe(
    //         ofType<UserOnServerCreated>(UserActionTypes.UserOnServerCreated),
    //         mergeMap(( { payload } ) => {
    //             this.store.dispatch(this.showActionLoadingDistpatcher);
    //             return this.auth.createUser(payload.user).pipe(
    //                 tap(res => {
    //                     this.store.dispatch(new UserCreated({ user: res }));
    //                 })
    //             );
    //         }),
    //         map(() => {
    //             return this.hideActionLoadingDistpatcher;
    //         }),
    //     );

}
