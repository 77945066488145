// Angular
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {AbstractService} from "../../services/abstract-service.service";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {NewArrivalModel} from "../_models/newArrival.model";


@Injectable()
export class NewArrivalService extends AbstractService<NewArrivalModel> {

    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}products/new-arrival`;
    }


}
