import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-folder-step-four',
  templateUrl: './folder-step-four.component.html',
  styleUrls: ['./folder-step-four.component.scss']
})
export class FolderStepFourComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
