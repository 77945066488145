import { selectProductsInitWaitingMessage } from '../ek-selectors/ekproduct.selectors';
// RxJS
// CRUD
import { QueryResultsModel, BaseDataSource } from '../../_base/crud';
// State
import { Store, select } from '@ngrx/store';
import { AppState } from '../../reducers';
// Selectors
import { selectProductsInStore, selectProductsPageLoading } from '../ek-selectors/ekproduct.selectors';

export class EkProductDatasource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();
        this.loading$ = this.store.pipe(
            select(selectProductsPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectProductsInitWaitingMessage)
        );

        this.store.pipe(
            select(selectProductsInStore)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }
}
