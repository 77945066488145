import {BaseDataSource, QueryResultsModel} from "../../_base/crud";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../reducers";

import {
    selectConfigurationsInStore,
    selectConfigurationsPageLoading,
    selectDiscountPageLoading,
    selectConfigurationsShowInitWaitingMessage
} from "../_selectors/configurations.selector";

export class ConfigurationsDatasource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectConfigurationsPageLoading),

        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectConfigurationsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectConfigurationsInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }

}
