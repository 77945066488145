import {BaseModel} from "../../_base/crud";
import {CharacteristicModel} from "./characteristic.model";


export class ConfigurationCharacteristicModel extends BaseModel{

    id: number;
    configurationId: number;
    characteristicId: number;
    value: string;
    _isEditMode: boolean;
    characteristic : CharacteristicModel;
    ord: number;


    clear(configurationId: number){
        this.configurationId = configurationId;
        this.value = '';
        this._isEditMode = false;
    }
}
