import {BaseModel} from "../../_base/crud";

export class CategoryPopularModel extends BaseModel {

    id: number;
    label: string;
    parentId: number;
    image: string;
    updater: string;
    parentName: string;
    activate: boolean;


    clear() {
        this.id = undefined;
        this.label = '';
        this.parentId = undefined;
        this.image = '';
        this.updater= '';
    }
}

