import {Component, Inject, OnInit} from '@angular/core';
import {licenseKeyModel} from "../../../../../../../../core/e-commerce/_models/licenseKey.model";
import {LayoutUtilsService, MessageType} from "../../../../../../../../core/_base/crud";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ImagesService} from "../../../../../../../../core/e-commerce/_services/images.service";
import {ClipboardService} from "ngx-clipboard";

@Component({
    selector: 'kt-license-keys-pop-up',
    templateUrl: './license-keys-pop-up.component.html',
    styleUrls: ['./license-keys-pop-up.component.scss']
})
export class LicenseKeysPopUpComponent implements OnInit {

    keysList: Array<licenseKeyModel> = [];
    image = [];
    imageUrl = "";
    nestedReactiveForm: any = FormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<LicenseKeysPopUpComponent>,
                private layoutUtilsService: LayoutUtilsService,
                private dialog: MatDialog,
                private imagesService: ImagesService,
                private fb: FormBuilder,
                private clipboardApi: ClipboardService,
    ) {
    }

    ngOnInit() {

        //add form controls in FormArray at runtime depending on number of keys
        this.addKeyField(this.data.currentOrderItem.quantity);

        let prevLisenceKeysForm = this.nestedReactiveForm.controls.keysForms.controls;

        //reset keys FormArray
        this.nestedReactiveForm.controls.keysForms.reset();

        //previous licenseKeys list if it's already excites
        if (this.data.currentOrderItem.licenseKeyTOSet.length > 0) {
            this.keysList = this.data.currentOrderItem.licenseKeyTOSet;
            for (let i = 0; i < this.keysList.length; i++) {
                prevLisenceKeysForm[i].controls['key'].setValue(this.keysList[i].key);
                prevLisenceKeysForm[i].controls['image'].setValue(this.keysList[i].imageUrl);
            }

        } else {
            //initialise key list
            this.keysList = [];
            for (let i = 0; i < this.data.currentOrderItem.quantity; i++) {
                prevLisenceKeysForm[i].controls['image'].setValue(null);
            }
        }

    }

    keysForms(): FormGroup {
        return this.fb.group(
            {
                key: [{value: '', disabled: false}, Validators.compose([Validators.required])],
                image: ['']
            });
    }

    newKey(): FormGroup {
        return this.fb.group({
            key: [{value: '', disabled: false}, Validators.compose([Validators.required])],
            image: '',
        });
    }

    removeKey(i: number) {
        this.keys.removeAt(i);
    }

    get keys(): FormArray {
        return this.nestedReactiveForm.get("keysForms") as FormArray
    }

    addKeyField(itemQuan) {

        //initialisation
        this.nestedReactiveForm = this.fb.group(
            {
                keysForms: this.fb.array([this.keysForms()])
            }
        );

        //generate form controls depending on product quantity
        for (let i = 1; i < itemQuan; i++) {
            this.keys.push(this.newKey());
        }
    }

    saveKeys() {

        this.keysList = [];

        let newKeys = this.keys.value.filter(item => item.key !== null);

        for (let i = 0; i < newKeys.length; i++) {
            if (newKeys[i].key) {
                let licenseKeysObj = new licenseKeyModel();
                licenseKeysObj.key = newKeys[i].key;
                licenseKeysObj.imageUrl = newKeys[i].image;
                this.keysList.push(licenseKeysObj);
            } else {
                this.removeKey(i);
            }
        }

        //notification
        this.layoutUtilsService.showActionNotification('Clés enregistré avec succès, merci de cliquer sur Envoyé les clés! ', MessageType.Update);

        //close dialog
        this.dialogRef.close( this.keysList);

    }

    copyText(key) {
        this.clipboardApi.copyFromContent(key);
    }

    makeItAsArray(number) {

        let list = [];
        for (let i = 0; i < number; i++) {
            list.push(i);
        }
        return list;
    }

    fileUpload(event, keyIndex) {

        const file = event.target.files;

        const control = this.nestedReactiveForm.controls.keysForms ['controls'][keyIndex].controls['image'];

        for (let i = 0; i < file.length; i++) {

            const reader = new FileReader();
            reader.onload = (e) => {
                this.imagesService.uploadImage(file).subscribe((res: any) => {
                    if (res.data) {
                        control.setValue(res.data);
                    }
                })
            };
            reader.readAsDataURL(file[i]);
        }

        event.srcElement.value = null;

    }

    removeImage(keyIndex) {
        const control = <FormArray>this.nestedReactiveForm.controls.keysForms ['controls'][keyIndex].controls['image'];
        control.setValue(null)
    }

    openNewTab(url) {
        let image = new Image();
        image.src = url;
        let w = window.open("");
        w.document.write(image.outerHTML);
    }

}
