import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {ConfigurationCharacteristicsService} from "../_services/configuration-characteristics.service";
import * as ConfigurationCharacteristicActions from "../_actions/configuration-characteristic.actions";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";


@Injectable()
export class ConfigurationCharacteristicEffects {


    constructor(private actions$: Actions, private configurationCharacteristicService: ConfigurationCharacteristicsService) {
    }

    ConfigurationCharacteristicPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationCharacteristicActions.ConfigurationCharacteristicsPageRequested),
            switchMap((action) =>
                this.configurationCharacteristicService.findByConfigurationId(action.configurationId)
                    .pipe(
                        map(configurationCharacteristicPage => ConfigurationCharacteristicActions.ConfigurationCharacteristicsPageLoadedSuccessfully(
                            {
                                configurationCharacteristics: configurationCharacteristicPage
                            })),
                        catchError(error => of(ConfigurationCharacteristicActions.ConfigurationCharacteristicsPageLoadFailed({error})))
                    ))));

    // ConfigurationCharacteristicCreated$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(ConfigurationCharacteristicActions.ConfigurationCharacteristicCreated),
    //         switchMap((action) =>
    //             this.configurationCharacteristicService.save(action.configurationCharacteristic)
    //                 .pipe(
    //                     map(data => ConfigurationCharacteristicActions.ConfigurationCharacteristicCreatedSuccessfully({configurationCharacteristic: data})),
    //                     catchError(error => of(ConfigurationCharacteristicActions.ConfigurationCharacteristicCreationFailed({error})))
    //                 )))
    //
    // );
    ConfigurationCharacteristicCreatedWithUpdater$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationCharacteristicActions.ConfigurationCharacteristicCreatedWithUpdater),
            switchMap((action) =>
                this.configurationCharacteristicService.saveWithUpdater(action.configurationCharacteristic,action.updater)
                    .pipe(
                        map(data => ConfigurationCharacteristicActions.ConfigurationCharacteristicCreatedSuccessfullyWithUpdater({configurationCharacteristic: data,updater:action.updater})),
                        catchError(error => of(ConfigurationCharacteristicActions.ConfigurationCharacteristicCreationFailedWithUpdater({error})))
                    )))

    );

    // ConfigurationCharacteristicUpdated$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(ConfigurationCharacteristicActions.ConfigurationCharacteristicUpdated),
    //         switchMap((action) =>
    //             this.configurationCharacteristicService.update(action.configurationCharacteristic)
    //                 .pipe(
    //                     map(configurationCharacteristic => ConfigurationCharacteristicActions.ConfigurationCharacteristicUpdatedSuccessfully({
    //                         configurationCharacteristic,
    //                         partialConfigurationCharacteristic: action.partialConfigurationCharacteristic
    //                     })),
    //                     catchError(error => of(ConfigurationCharacteristicActions.ConfigurationCharacteristicUpdateFailed({error})))
    //                 ))));
    ConfigurationCharacteristicUpdatedSaveUpdater$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationCharacteristicActions.ConfigurationCharacteristicUpdatedSaveUpdater),
            switchMap((action) =>
                this.configurationCharacteristicService.updateAndSaveUpdater(action.configurationCharacteristic,action.updater)
                    .pipe(
                        map(configurationCharacteristic => ConfigurationCharacteristicActions.ConfigurationCharacteristicUpdatedSuccessfullySaveUpdater({
                            configurationCharacteristic,
                            partialConfigurationCharacteristic: action.partialConfigurationCharacteristic,
                            updater:action.updater
                        })),
                        catchError(error => of(ConfigurationCharacteristicActions.ConfigurationCharacteristicUpdateFailedSaveUpdater({error})))
                    ))));

    // ConfigurationCharacteristicDeleted$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(ConfigurationCharacteristicActions.ConfigurationCharacteristicDeleted),
    //         switchMap((action) =>
    //             this.configurationCharacteristicService.delete(action.configurationCharacteristicId)
    //                 .pipe(
    //                     map(() => ConfigurationCharacteristicActions.ConfigurationCharacteristicDeletedSuccessfully({configurationCharacteristicId: action.configurationCharacteristicId})),
    //                     catchError(error => of(ConfigurationCharacteristicActions.ConfigurationCharacteristicDeleteFailed({error})))
    //                 ))));
    ConfigurationCharacteristicDeletedAndSaveUpdater$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationCharacteristicActions.ConfigurationCharacteristicDeletedSaveUpdater),
            switchMap((action) =>
                this.configurationCharacteristicService.deleteConfigurationCharacteristic(action.configurationCharacteristicId,action.updater)
                    .pipe(
                        map(() => ConfigurationCharacteristicActions.ConfigurationCharacteristicDeletedSuccessfullySaveUpdate({configurationCharacteristicId: action.configurationCharacteristicId,updater:action.updater})),
                        catchError(error => of(ConfigurationCharacteristicActions.ConfigurationCharacteristicDeleteFailedSaveUpdater({error})))
                    ))));
}
