import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DATE_LOCALE, MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {OrdersService} from "../../../../../../core/e-commerce/_services/orders.service";
import * as XLSX from "xlsx";
// tslint:disable-next-line:no-duplicate-imports
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {DateAdapter, MAT_DATE_FORMATS} from "@angular/material/core";
import { DatePipe } from '@angular/common';
import {FormControl, FormGroup} from "@angular/forms";
import {WrongOrders} from "../order-excel-monthly/order-excel-monthly.component";
import {finalize} from "rxjs/operators";
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'DD MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'kt-order-excel-product-vendu',
  templateUrl: './order-excel-product-vendu.component.html',
  styleUrls: ['./order-excel-product-vendu.component.scss'],
  providers: [

    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class OrderExcelProductVenduComponent implements OnInit {
  startDate =  new Date();
  endDate =  new Date();
  dateFilter = new FormGroup({
    startDate: new FormControl(''),
    endDate: new FormControl(''),
  });
  dateSystem = new Date();
  laodingExcel = false;

  constructor(
      private dialogRef: MatDialogRef<OrderExcelProductVenduComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private orderService: OrdersService,
      private datePipe: DatePipe


  ) { }
  error: string = '';
  wrongOrders: WrongOrders [];
  fileName = "export-wrong-orders-product-vendu.xlsx";
  ngOnInit(): void {
    this.datePipe.transform(this.dateSystem,"yyyy-MM-dd");

  }
  /** exporter les orders sans detail  **/



  exportOrdersExcelSansDetail() {
      this.startDate =    new Date(this.dateFilter.get('startDate').value),
          this.endDate =    new Date(this.dateFilter.get('endDate').value),
          this.laodingExcel=true;
      this.orderService.ExportExcelSansDetail(this.startDate,  this.endDate).pipe(
          finalize(()=>this.laodingExcel=false)).subscribe((response) => {
            if (response.message == 'success' && response.body) {
              const byteCharacters = atob(response.body);
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers [i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              let blob = new Blob([byteArray], {type: 'application / vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return;
              }
              const url = window.URL.createObjectURL(blob);
              const anchor = document.createElement('a');
              anchor.download = `CommandesSansDetail.xlsx`;
              anchor.href = url;

              anchor.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
              this.laodingExcel=false;
              setTimeout(function () {
                window.URL.revokeObjectURL(url);

                anchor.remove();

              }, 100)
            }
          },
      );
    }
}
