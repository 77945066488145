import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {SectionType} from '../ek-e-commerce/ek-models/section-type.model';
// @ts-ignore
import {BehaviorSubject, Observable} from "rxjs";
import {ADMIN_CONFIG} from "../../../environments/environment";
import {ocrInfo} from "../ek-e-commerce/ek-models/document-ocr-information";
import {DocumentModel} from "../ek-e-commerce/ek-models/document.model";
import {Response} from "../ek-e-commerce/ek-models/response.model";
import {VerificationFullObject} from "../ek-e-commerce/ek-models/ocr-validations";


@Injectable({providedIn: "root"})
export class DocumentService {
    currentRole = '';
    basicUrl: string;
    documentUrl: string;
    documentClientUrl: string;
    folderUrl: string;

    private isFilesChanges$ = new BehaviorSubject<boolean>(false);

    // Subjects
    public ocrSubject: BehaviorSubject<ocrInfo> = new BehaviorSubject<ocrInfo>(
        null as any
    );


    isBankDocCheckedSubject = new BehaviorSubject<boolean>(false);

    allOneByOneIsCheckedSubject = new BehaviorSubject<boolean>(false);

    currentDocSbj$ = new BehaviorSubject<DocumentModel>(null);

    constructor(private http: HttpClient) {
        this.basicUrl = `${ADMIN_CONFIG.apiEndpoint}`;
        this.documentUrl = `${ADMIN_CONFIG.apiEndpoint}document`;
        this.documentClientUrl = `${ADMIN_CONFIG.apiEndpoint}documentClient`;
        this.folderUrl = `${ADMIN_CONFIG.apiEndpoint}shippingAddressFolder`;
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }

    public uploadDocument(file: File, document: DocumentModel) {
        const url = `${this.documentUrl}/new/save`;
        const formData = new FormData();
        formData.append("files", file, file.name);
        formData.append("document", JSON.stringify(document));
        this.isFilesChanges$.next(true);
        return this.http.post<Response<any>>(url, formData);
    }

    public uploadProfileDocument(file: File, document: DocumentModel) {
        const url = `${this.documentClientUrl}`;
        const formData = new FormData();
        formData.append("files", file, file.name);
        formData.append("document", JSON.stringify(document));
        return this.http.post<Response<any>>(url, formData);
    }

    public updateDocument(file: File, document: DocumentModel) {
        const url = `${this.documentUrl}/new`;
        const formData = new FormData();
        formData.append("files", file, file.name);
        formData.append("document", JSON.stringify(document));
        this.isFilesChanges$.next(true);
        return this.http.put<Response<any>>(url, formData);
    }

    public updateProfileDocument(file: File, document: DocumentModel) {
        const url = `${this.documentClientUrl}`;
        const formData = new FormData();
        formData.append("files", file, file.name);
        formData.append("document", JSON.stringify(document));
        return this.http.put<Response<any>>(url, formData);
    }

    public getDocumentsByFolderId(id: number) {
        const url = `${this.documentUrl}/folder/${id}`;
        return this.http.get<Response<any>>(url);
    }

    public downloadDocuments(orderId: number, documentType: string) {
        let params = new HttpParams().set('type', documentType)
        const url = `${this.documentUrl}/bank/${orderId}`;
        return this.http.get<Response<any>>(url, {params});
    }

    public checkBankDocuments(folderId: number) {
        const params = new HttpParams().set("type", "BANK");
        const url = `${this.basicUrl}document/check/${folderId}`;
        return this.http.get<Response<any>>(url, {params});
    }

    public getDocumentById(id: number): Observable<Response<DocumentModel>> {
        const url = `${this.documentUrl}/${id}`;
        return this.http.get<Response<DocumentModel>>(url);
    }

    public updateDocumentState(idDoc: number, state: string, openAt: string) {
        const url = `${this.documentUrl}/state/${idDoc}?state=${state}&openAt=${openAt}`;
        return this.http.patch<Response<any>>(url, {});
    }

    public getValidationResponse(folderId: number) {
        const url = `${this.basicUrl}OCR/reponse/${folderId}`;
        return this.http.get<Response<VerificationFullObject>>(url);
    }

    public getClientInfo(folderId: number) {
        const url = `${ADMIN_CONFIG.apiEndpoint}clientInformation/findClientInfoByIdFolder/${folderId}`;
        return this.http.get<Response<ocrInfo | any>>(url);
    }

    public verifyDocuments(folderId: number) {
        const url = `${ADMIN_CONFIG.apiEndpoint}OCR/checkDocument/admin/${folderId}`;
        return this.http.get<Response<any>>(url);
    }

    public downloadFiles(idFolder: number, sectionType: SectionType) {
        if (this.currentRole !== 'ROLE_POS_EK' && this.currentRole !== 'ROLE_COMMERCIAL_POS_EK') {
            const url = `${this.documentUrl}/pdf/getAllFiles/${sectionType}/${idFolder}`;
            return this.http.get<any>(url);
        }
    }

    public verifyOcr(folderId: number, feedBack: string) {
        const url = `${this.documentUrl}/verifyOcr/${folderId}`;
        const params = new HttpParams().set('aBoolean', feedBack);
        return this.http.get<Response<any>>(url, {params});
    }
}
