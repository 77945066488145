import {BaseModel} from "../../_base/crud";

export class CollaboratorModel extends BaseModel {
    id: number;
    firstname: string;
    lastname: string;
    _isEditMode: boolean;

    clear() {
        this._isEditMode = false;
    }
}
