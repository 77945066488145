import { BaseModel } from '../../_base/crud';

export class Role extends BaseModel {
    id: string;
    title: string;
    permissions: number[];
    isCoreRole = false;

    clear(): void {
        this.id = undefined;
        this.title = '';
        this.permissions = [];
        this.isCoreRole = false;
	}

	constructor(id:string = undefined, title: string = '', permission: number[] = [], isCoreRole: boolean = false) {
        super();
        this.id = id;
        this.title = title;
        this.permissions = permission;
        this.isCoreRole = isCoreRole;
    }
}
