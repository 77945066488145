// Angular
import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
// RxJS
import {BehaviorSubject} from 'rxjs';
// NGRX
import {Store} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// Auth
import {selectUserById, User} from '../../../../../core/auth/';
// State
import {AppState} from '../../../../../core/reducers';
// Layout
import {LayoutUtilsService, MessageType} from '../../../../../core/_base/crud';
// Actions
import * as UserActions from '../../../../../core/auth/_actions/user.actions';
import {MustMatch} from "../../../dashboard/profile/profile/profile.component";

@Component({
    selector: 'kt-ek-change-psw',
    templateUrl: './ek-change-psw.component.html',
    styleUrls: ['./ek-change-psw.component.scss']
})
export class EkChangePswComponent implements OnInit {

    // Public properties
    @Input() user: User;
    @Input() userForm: FormGroup;
    @Input() loadingSubject = new BehaviorSubject<boolean>(false);
    hasFormErrors = false;
    userHasFormErrors = false;
    changePasswordForm: FormGroup;
    hide = true;

    /**
     * Component constructor
     *
     * @param fb: FormBuilder
     * @param auth: AuthService
     * @param store: Store<AppState>
     * @param layoutUtilsService: LayoutUtilsService
     */
    constructor(private fb: FormBuilder, private store: Store<AppState>,
                // tslint:disable-next-line:align
                private layoutUtilsService: LayoutUtilsService) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        this.createForm();
        this.loadData();
    }

    /**
     * Load data
     */
    loadData() {
        this.store.select(selectUserById(this.user.id)).subscribe(res => {
            if (res) {
                this.user = new User();
                // this.user.password = res.password;
                this.user.id = res.id;
                this.user.username = res.username;
                this.user.address = res.address;
                this.user.firstname = res.firstname;
                this.user.lastname = res.lastname;
                this.user.email = res.email;
                this.user.state = res.state;
                this.user.phone = res.phone;
                this.user.roles = res.roles;
                this.user.codification = this.userForm.controls.codification.value ? this.userForm.controls.codification.value : res.codification;
            }
        });
    }

    /**
     * Init form
     */
    createForm() {
        this.changePasswordForm = this.fb.group({
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required]
        }, {
            validator: MustMatch('password', 'confirmPassword')
        });
    }

    /**
     * Reset
     */
    reset() {
        this.hasFormErrors = false;
        this.loadingSubject.next(false);
        this.changePasswordForm.markAsPristine();
        this.changePasswordForm.markAsUntouched();
        this.changePasswordForm.updateValueAndValidity();
    }

    /**
     * Save data
     */
    onSubmit() {

        this.loadingSubject.next(true);
        this.hasFormErrors = false;
        this.userHasFormErrors = false;

        const userControls = this.userForm.controls;

        /** check uer form */
        if (this.userForm.invalid) {

            Object.keys(userControls).forEach(controlName =>
                userControls[controlName].markAsTouched()
            );

            const message = `probablement y a une erreur dans les informations de base !`;
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, false);

            this.userHasFormErrors = true;

            this.loadingSubject.next(false);

            return;

        }

        const controls = this.changePasswordForm.controls;

        /** check psw form */
        if (this.changePasswordForm.invalid) {

            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;

            this.loadingSubject.next(false);

            return;

        }

        this.user.password = controls.password.value;
        const updatedUser: Update<User> = {
            id: this.user.id,
            changes: this.user
        };

        this.store.dispatch(UserActions.UserUpdated({partialUser: updatedUser, user: this.user}));

        this.loadData();
        this.loadingSubject.next(false);
        const message = `User password successfully has been changed.`;
        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, false);
        this.reset();
    }

    /**
     * Close alert
     *
     * @param $event: Event
     */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

}
