import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
    ProductActions,
    ProductModel,
    ProductsDataSource,
    ProductsService,
    selectProductsPageLastQuery
} from "../../../../../../../../core/e-commerce";
import {LayoutUtilsService, MessageType, QueryParamsModel} from "../../../../../../../../core/_base/crud";
import {SelectionModel} from "@angular/cdk/collections";
import {fromEvent, merge, Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {SubheaderService} from "../../../../../../../../core/_base/layout";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../../../../../../../core/reducers";
import {debounceTime, distinctUntilChanged, skip, tap} from "rxjs/operators";
import {Update} from "@ngrx/entity";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {OrdersService} from "../../../../../../../../core/e-commerce/_services/orders.service";
import {OrderProductDetailsComponent} from "../order-product-details/order-product-details.component";

@Component({
    selector: 'kt-add-order-product-dialog',
    templateUrl: './add-order-product-dialog.component.html',
    styleUrls: ['./add-order-product-dialog.component.scss']
})
export class AddOrderProductDialogComponent implements OnInit {

    // TODO review - Note : this code is copied from products/products-list.component


    // Table fields
    dataSource: ProductsDataSource;
    displayedColumns = ['id', 'name', 'brand', 'status', 'image', 'actions'];
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort1', {static: true}) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    filterStatus = '';
    filterCondition = '';
    lastQuery: QueryParamsModel;
    // Selection
    selection = new SelectionModel<ProductModel>(true, []);
    productsResult: ProductModel[] = [];
    private subscriptions: Subscription[] = [];

    checked: boolean = false;
    updater: string;
    addingOrder = false;

    constructor(private dialogRef: MatDialogRef<AddOrderProductDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data,
                private orderService: OrdersService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private subheaderService: SubheaderService,
                private layoutUtilsService: LayoutUtilsService,
                private productService: ProductsService,
                private dialog: MatDialog,
                private store: Store<AppState>,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.addingOrder = this.data.addingOrder
        this.updater = JSON.parse(localStorage.getItem('currentUser')).username;
        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        /* Data load will be triggered in two cases:
        - when a pagination event occurs => this.paginator.page
        - when a sort event occurs => this.sort.sortChange
        **/
        this.paginator._changePageSize(8);
        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this.loadProductsList())
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        // Filtration, bind to searchInput
        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(() => {
                this.paginator.pageIndex = 0;
                this.loadProductsList();
            })
        )
            .subscribe();
        this.subscriptions.push(searchSubscription);

        // Set title to page breadCrumbs
        this.subheaderService.setTitle('Products');

        // Init DataSource
        this.dataSource = new ProductsDataSource(this.store);
        const entitiesSubscription = this.dataSource.entitySubject.pipe(
            skip(1),
            distinctUntilChanged()
        ).subscribe(res => {
            this.productsResult = res;
        });
        this.subscriptions.push(entitiesSubscription);
        const lastQuerySubscription = this.store.pipe(select(selectProductsPageLastQuery)).subscribe(res => this.lastQuery = res);
        // Load last query from store
        this.subscriptions.push(lastQuerySubscription);

        // Read from URL itemId, for restore previous state
        const routeSubscription = this.activatedRoute.queryParams.subscribe(params => {
            if (params.id) {
                this.restoreState(this.lastQuery, +params.id);
            }

            // First load
            this.loadProductsList();
        });
        this.subscriptions.push(routeSubscription);
    }

    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    /**
     * Load Products List
     */
    loadProductsList() {
        this.selection.clear();
        const queryParams = new QueryParamsModel(
            this.filterConfiguration(),
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex,
            this.paginator.pageSize
        );
        // Call request from server
        this.store.dispatch(ProductActions.ProductsPageRequested({page: queryParams}));
        this.selection.clear();
    }

    /**
     * Returns object for filter
     */
    filterConfiguration(): any {
        const filter: any = {};
        const searchText: string = this.searchInput.nativeElement.value;

        if (this.filterStatus && this.filterStatus.length > 0) {
            filter.status = +this.filterStatus;
        }

        if (this.filterCondition && this.filterCondition.length > 0) {
            filter.condition = +this.filterCondition;
        }

        filter.query = searchText;
        return filter;
    }

    /**
     * Restore state
     *
     * @param queryParams: QueryParamsModel
     * @param id: number
     */
    restoreState(queryParams: QueryParamsModel, id: number) {

        if (!queryParams.filter) {
            return;
        }

        if ('condition' in queryParams.filter) {
            this.filterCondition = queryParams.filter.condition.toString();
        }

        if ('status' in queryParams.filter) {
            this.filterStatus = queryParams.filter.status.toString();
        }

        if (queryParams.filter.model) {
            this.searchInput.nativeElement.value = queryParams.filter.model;
        }
    }

    /** ACTIONS */

    addProduct(product: ProductModel) {
        const dialogRef = this.dialog.open(OrderProductDetailsComponent, {
            data: {product, orderId: this.data.order.id, addingOrder: this.addingOrder},
        });

        dialogRef.afterClosed().subscribe(res => {

            /*if (!res) {
                 return;
             }*/

            if (this.addingOrder)
                this.dialogRef.close(res);
            else
                this.dialogRef.close();
        });

    }

    /**
     * Delete products
     */
    deleteProducts() {
        const _title = 'Products Delete';
        const _description = 'Are you sure to permanently delete selected products?';
        const _waitDesciption = 'Products are deleting...';
        const _deleteMessage = 'Selected products have been deleted';

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            const idsForDeletion: number[] = [];
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.selection.selected.length; i++) {
                idsForDeletion.push(this.selection.selected[i].id);
            }
            //todo make this work

            // this.store.dispatch(new ManyProductsDeleted({ ids: idsForDeletion }));
            this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
            this.selection.clear();
        });
    }

    /**
     * Fetch selected products
     */
    fetchProducts() {
    }

    /**
     * Update status dialog
     */
    updateStatusForProducts() {
    }


    createProduct() {
        this.router.navigateByUrl('/ecommerce/products/add');
    }

    /**
     * Check all rows are selected
     */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.productsResult.length;
        return numSelected === numRows;
    }

    /**
     * Selects all rows if they are not all selected; otherwise clear selection
     */
    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.productsResult.forEach(row => this.selection.select(row));
        }
    }

    getItemStatusString(status: number = 0): string {
        switch (status) {
            case 0:
                return 'Selling';
            case 1:
                return 'Sold';
        }
        return '';
    }

    getItemCssClassByStatus(status: number = 0): string {
        switch (status) {
            case 0:
                return 'success';
            case 1:
                return 'metal';
        }
        return '';
    }


    getItemConditionString(condition: number = 0): string {
        switch (condition) {
            case 0:
                return 'New';
            case 1:
                return 'Used';
        }
        return '';
    }

    getItemCssClassByCondition(condition: number = 0): string {
        switch (condition) {
            case 0:
                return 'accent';
            case 1:
                return 'primary';
        }
        return '';
    }

    OnChangeActivateProduct(product: ProductModel, value: boolean, updater: string) {
        const _product = new ProductModel();
        _product.id = product.id;
        _product.activate = value;
        _product.updater = updater
        const updateProduct: Update<ProductModel> = {
            id: product.id,
            changes: _product
        };


        this.store.dispatch(ProductActions.ProductUpdatedActivate({
            product: _product,
            partialProduct: updateProduct,
            activate: value,
            updater: updater

        }));


        const message = `Product successfully has been changed.`;
        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);


    }
}
