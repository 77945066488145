export const ENUMERATE_FOLDER_STATES= [
    {name: '1. En cours de création', value: 'EK_FOLDER_CREATED', order: 1},
    {name: '2. Dossier en cours de traitement Ekiclik', value: 'EK_FOLDER_IN_PROGRESS', order: 2},
    {name: '3. Dossier réserve Ekiclik', value: 'EK_FOLDER_RESERVE', order: 3},
    {name: '4. Dossier refusé Ekiclik', value: 'EK_FOLDER_REFUSED', order: 4},
    {name: '5. Pré-notification banque', value: 'EK_BANK_IN_PROGRESS', order: 5},
    {name: '6. Notification de la banque (Accord)', value: 'BANK_NOTIFICATION', order: 6},
    {name: '7. Control avant impression des garnaties EkiClik', value: 'EK_CONTROL_BEFORE_GUARANTEES_PRINTING', order: 7},
    {name: '8. Dossier réserve banque', value: 'EK_BANK_RESERVE', order: 8},
    {name: '9. Dossier Rejeté Banque', value: 'EK_BANK_REFUSED', order: 9},
    {name: '10. Garanties en cours de signature', value: 'EK_GUARTENTEES_PENDING_SIGNATURE', order: 10},
    {name: '11. Garanties signés', value: 'GUARTENTEES_SIGNED_IN_PROGRESS_BANK', order: 11},
    {name: '12. Dossiers financés', value: 'FINANCIAL_FOLDER', order: 12},
    {name: '13. Dossier annulé (CLIENT)', value: 'EK_CLIENT_FOLDER_CANCELED', order: 13},
    {name: '14. Dossier annulé (ADMIN)', value: 'EK_ADMIN_FOLDER_CANCELED', order: 14},
]