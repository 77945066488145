import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {HistoryStateFolderService} from "../ek-services/history-state-folder.service";
import * as changeHistoryActions from "../ek-actions/change-history.actions";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";


@Injectable()
export class ChangeHistoryEffects {
    constructor(private actions$: Actions,
                private historyStateFolderService: HistoryStateFolderService,
    ) {
    }

    folderChangeHistoryPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(changeHistoryActions.ChangeHistoryPageRequested),
            switchMap((action) =>
                this.historyStateFolderService.getAllFolderStateHistoryListing(action.page.pageNumber,action.page.pageSize ,action.page.sortField, action.page.sortOrder,action.page.filter, action.firstDate, action.lastDate, action.folderState, action.email)
                    .pipe(
                        map(data => {
                            return changeHistoryActions.ChangeHistoryPageLoadedSuccessfully({
                                ChangeHistory: data.body.content,
                                totalCount: data.body.totalElements,
                                page : data.body.totalPages
                            });
                        }),
                        catchError(error => of(changeHistoryActions.ChangeHistoryPageLoadFailed({error})))
                    ))));

}
