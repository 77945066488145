import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {
  forbiddenProvinceValidator,
  Wilaya,
  WilayaService
} from "../../../../../../../../core/e-commerce/_services/wilaya.service";
import {Town} from "../../../../../../../../core/e-commerce/_models/town";
import {ClientModel} from "../../../../../../../../core/ek-e-commerce/ek-models/client.model";
import {AddressesService} from "../../../../../../../../core/e-commerce/_services/addresses.service";
import {Address} from "../../../../../../../../core/e-commerce/_models/address.model";
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {Observable} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {map, startWith} from "rxjs/operators";
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatSnackBar} from "@angular/material/snack-bar";
import {NgxPermissionsService} from "ngx-permissions";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../../../core/reducers";



@Component({
  selector: 'kt-new-address-pop-up',
  templateUrl: './new-address-pop-up.component.html',
  styleUrls: ['./new-address-pop-up.component.scss']
})
export class NewAddressPopUpComponent implements OnInit {

  // Public properties
  addressEditForm: FormGroup;
  viewLoading = true;
  loadingAfterSubmit = false;
  private PERMISSIONS = ['ALL_CLIENT', 'UPDATE_CLIENT'];
  canEdit = false;
  country = 'ALGERIE';
  public wilayasList: Wilaya[] = [];
  public towns: Town[] = [];
  filteredOptions: Observable<Wilaya[]>;
  townFilteredOptions: Observable<Town[]>;
  private PHONE_REGEX = /^(00213|\+213|0)(5|6|7)(\s*?[0-9]\s*?){5,8}$/;
  isNewAdr = false;
  newId: number;

  /**
   * Component constructor
   *
   * @param dialogRef: MatDialogRef<AddressEditDialogComponent>
   * @param data: any
   * @param fb: FormBuilder
   * @param cdr: ChangeDetectorRef
   * @param ngxPermissionService: NgxPermissionService
   * @param wilayaService
   * @param addressesService
   * @param store
   */
  constructor(
      public dialogRef: MatDialogRef<NewAddressPopUpComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private fb: FormBuilder,
      private cdr: ChangeDetectorRef,
      private ngxPermissionService: NgxPermissionsService,
      private wilayaService: WilayaService,
      private addressesService: AddressesService,
      private store: Store<AppState>) {
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    //wilaya list
    this.wilayasList = this.wilayaService.getWilayasItems();

    if (this.wilayasList.length > 0)
      this.initSpecificationForm();


    this.viewLoading = false;
    this.checkPermissionToUpdate();

    this.isNewAdr = this.data.isNew;
  }

  /**
   * Form initalization
   * Default params, validators
   */
  initSpecificationForm() {
    const address = this.data.address.addressLine1;
    //const addressLine2 = this.data.address.addressLine2;
    //const fullname = this.data.address.fullName;
    //const country = this.data.address.country;
    const wilaya = this.data.address.province;
    const town = this.data.address.city;
    const phoneNumber = this.data.address.phoneNumber;
    //const type = this.data.address.type;

    this.addressEditForm = this.fb.group({
      address: [address, [Validators.required]],
      //addressLine2: [addressLine2, [ Validators.required]],
      //fullname: [fullname, [ Validators.required]],
      //country: [country, [Validators.required]],
      wilaya: [wilaya, [forbiddenProvinceValidator(this.wilayasList)]],
      town: new FormControl({value: town}, [Validators.required, this.forbiddenTownsValidator()]),
      phoneNumber: [phoneNumber, Validators.compose([Validators.required, Validators.minLength(10), Validators.pattern(this.PHONE_REGEX)])],
      //type: [type, [Validators.required]],
    });

    this.filteredOptions = this.addressEditForm.get('wilaya').valueChanges
        .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value),
            map(name => name ? this._filter(name.toString()) : this.wilayasList.slice())
        );


    this.getWilayaTownsDefault();

  }

  private _filter(name: string): Wilaya[] {
    const filterValue = name.toLowerCase();
    return this.wilayasList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
   * Close dialog
   */
  onNoClick(): void {
    this.dialogRef.close(null);
  }

  /**
   * Disable the form if the used doesnt have the permission to update
   */
  checkPermissionToUpdate() {
    this.ngxPermissionService.hasPermission(this.PERMISSIONS).then(hasPermission => {
      if (!hasPermission) {
        this.addressEditForm.disable();
        this.canEdit = false;
      } else
        this.canEdit = true;
    });
  }

  /**
   * Save specification
   */
  save() {
    const controls = this.addressEditForm.controls;
    /** check form */
    if (this.addressEditForm.invalid) {
      Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
      );
      return;
    }

    this.loadingAfterSubmit = true;
    this.viewLoading = true;
    const newAddress = new Address();

    // const specId = this.getSpecificationIndexByName(controls.name.value);
    newAddress.id = this.data.address ? this.data.address.id : null;
    newAddress.addressLine1 = controls.address.value;
    newAddress.addressLine2 = "";
    newAddress.fullName = "";
    newAddress.firstname = this.data.client.firstname;
    newAddress.lastname = this.data.client.lastname;
    newAddress.country = this.country;
    newAddress.city = controls.town.value;
    newAddress.province = controls.wilaya.value;
    newAddress.phoneNumber = controls.phoneNumber.value;
    newAddress.type = 'DELIVERY';
    newAddress.clientId = this.data.client.id;

    //add new address to client
    if (this.isNewAdr) {
      this.addressesService.addClientAddress(newAddress).subscribe(res => {
        if (res) {
          newAddress.id = res.id;
          //Server loading imitation. Remove this on real code
          this.viewLoading = false;
          this.closeDialog(newAddress);
        }
      });

    } else {
      this.updateClientAddress(newAddress);
      this.viewLoading = false;
      this.closeDialog(newAddress);
    }


  }


  updateClientAddress(editedAdr) {
    this.addressesService.updateClientAddress(editedAdr).subscribe();
  }

  /**
   * Close dialog
   *
   * @param specId: any
   */
  closeDialog(address: Address) {
    this.dialogRef.close(address);
  }

  /**
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to valitors name
   */
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.addressEditForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  private townFilter(name: string): Town[] {
    const filterValue = name.toLowerCase();
    return this.towns.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  getWilayaTowns(event) {

    const selectedWilaya = event.option.value;

    //reset towns
    this.addressEditForm.get('town').reset();

    if (this.addressEditForm.controls.wilaya.dirty) {
      this.addressEditForm.controls.town.enable();
    }
    this.getTowns(this.wilayasList.filter(value => {
      if (value.value == selectedWilaya)
        return value
    })[0].id);
  }

  getTowns(wilayaId) {

    this.wilayaService.getTownsByWilayaId(wilayaId).subscribe(res => {
      this.towns = res;
      this.townFilteredOptions = this.addressEditForm.get('town').valueChanges
          .pipe(
              startWith(''),
              map(value => typeof value === 'string' ? value : value),
              map(name => name ? this.townFilter(name.toString()) : this.towns.slice())
          );
    })
  }

  getWilayaTownsDefault() {

    this.addressEditForm.get('town').setValue(this.data.address.city);

    if (this.addressEditForm.controls.wilaya.dirty) {
      this.addressEditForm.controls.town.enable();
    }

    if (this.data.address.id) {
      this.getTowns(this.wilayasList.filter(value => {
        if (value.value == this.data.address.province)
          return value
      })[0].id);
    }

  }

  forbiddenTownsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const index = this.towns.findIndex(town => {
        return (new RegExp('\^' + town.name + '\$')).test(control.value);
      });
      return index < 0 ? {'forbiddentowns': {value: control.value}} : null;
    };
  }

}
