import {Injectable} from "@angular/core";
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {NotificationModel} from "../_models/notification.model";
import {BehaviorSubject, Observable} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";
import {NotificationType} from "../_models/notificationType.model";


@Injectable()
export class NotificationService extends AbstractService<NotificationModel> {

    url: string;

    public  totalNotOpened$ = new BehaviorSubject<number>(null);
    public  totalNotOpened  = this.totalNotOpened$.asObservable();

    public  registrations$ = new BehaviorSubject<number>(null);
    public  registrations  = this.registrations$.asObservable();

    public  outOfStock$ = new BehaviorSubject<number>(null);
    public  outOfStock  = this.outOfStock$.asObservable();

    public  orders$ = new BehaviorSubject<number>(null);
    public  orders  = this.orders$.asObservable();



    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}Notifications`;
    }

    public changeOpened(id: number, opened: boolean): Observable<Response<NotificationModel>> {
   /*     const url = `${this.url}/${id}/${opened}`;
        return this.http.patch<Response<NotificationModel>>(url,"").pipe(
            map((response: Response<NotificationModel>) => {
                if (response.body) {
                    return response;
                } else {
                    throw new Error();
                }
            })
        );*/
        return null;
    }

    public findAllOpened (): Observable<NotificationModel[]> {
        /*const url = `${this.url}/All-Opened`;
        return this.http.get<Response<NotificationModel[]>>(url).pipe(map(({body}) => body));*/
        return null;

    }

    public FindNotificationsByType(type: NotificationType): Observable<NotificationModel[]> {
  /*      const url = `${this.url}/type/${type}`;
        return this.http.get<Response<NotificationModel[]>>(url).pipe(map(({body}) => body));*/
        return null;

    }

}