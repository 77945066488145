import {Pipe, PipeTransform} from '@angular/core';
import {DiscountModel} from "../../../e-commerce/_models/discount.model";

@Pipe({
    name: 'discountRemainingDays'
})
export class DiscountRemainingDays implements PipeTransform {


    constructor() {
    }

    transform(d:DiscountModel): number {

        if (d != null) {
            //convert the date timing to CET ALG offset (GMT+1)
            const startPromoDate = new Date(d.startingDate);
            const endPromoDate = new Date(d.endingDate);


            const dateNow = new Date();
            const seconds = Math.floor((endPromoDate.getTime() - dateNow.getTime()) / 1000);
            const minutes = Math.floor(seconds / 60); // it may be helpful later
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);

            if (new Date() >= startPromoDate) {
                if (days > 1) {
                    return days;
                } else if (days <= 1 && (hours >= 1 || minutes > 15)) {
                    return 1;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}
