import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";
import {Response} from "../../_base/crud/models/response";
import {Action, createReducer, on} from "@ngrx/store";
import * as ConfigurationsActions from "../_actions/products-best-seller.actions";
import {ProductsBestSellerModel} from "../_models/products-best-seller.model";

export interface BestSellerState extends EntityState<ProductsBestSellerModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedConfigurationId2: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
}

export const adapter: EntityAdapter<ProductsBestSellerModel> = createEntityAdapter<ProductsBestSellerModel>();

export const initialBestSellerConfigurationState: BestSellerState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedConfigurationId2: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
});

const configurationReducer = createReducer(
    initialBestSellerConfigurationState,
    on(ConfigurationsActions.BestSellerPageRequested,
        (state, action) => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: '',
            lastQuery: action.page
        })),
    on(ConfigurationsActions.BestSellerPageLoadedSuccessfully,
        (state, action) => (
            adapter.addAll(action.bestSeller,
                {
                    entities: undefined, ids: undefined,
                    ...state,
                    actionsLoading: false,
                    listLoading: false,
                    showInitWaitingMessage: false,
                    totalCount: action.totalCount
                }))),
    on(ConfigurationsActions.BestSellerPageLoadFailed,
        (state, action) => ({
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),
    on(ConfigurationsActions.BestSellerUpdated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),

    on(ConfigurationsActions.BestSellerUpdatedSuccessfully,
        (state, action) =>
            (adapter.updateOne(action.partialBestSeller, {
                ...state,
                actionsLoading: false,
                lastAction: 'EDIT.UPDATE_MESSAGE'
            }))),
    on(ConfigurationsActions.BestSellerUpdateFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),

);

export function bestSellerConfigurationsReducer(state: BestSellerState | undefined, action: Action) {
    return configurationReducer(state, action)
}
