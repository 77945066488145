
import { QueryResultsModel, BaseDataSource } from '../../_base/crud';
// State
import { Store, select } from '@ngrx/store';
import { AppState } from '../../reducers';
// Selectors
import {selectBestSellerPageLoading,selectBestSellerInStore,selectBestSellerInitWaitingMessage} from "../_selectors/products-best-seller.selector";

export class ProductBestSellerDataSource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();
        this.loading$ = this.store.pipe(
            select(selectBestSellerPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectBestSellerInitWaitingMessage)
        );

        this.store.pipe(
            select(selectBestSellerInStore)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }
}
