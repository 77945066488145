import {createAction, props} from "@ngrx/store";
import {Update} from "@ngrx/entity";
import {PromotionCodeModel} from "../_models/promotionCode.model";


import {ConfigurationModel} from "../_models/configuration.model";
import {Discount_CustomerModel} from "../_models/discount_Customer.model";
import {QueryParamsModel} from "../../_base/crud";



export const PromotionCodesPageRequested = createAction(' [PromotionCode List Page] PromotionCodes Page Requested ', props<{ page: QueryParamsModel , promotionCodeType:string, promoState:string , startingDate: string, endingDate: string}>());
export const PromotionCodesLoadedSuccessfully = createAction('[PromotionCode API] PromotionCodes Page Loaded Successfully ',props<{promotionCodes: PromotionCodeModel[],totalCount: number, page: number}>());
export const PromotionCodesLoadFailed = createAction('[PromotionCode API] PromotionCodes Page Request Failed ',props<{error: any}>());

export const PromoCodeCreated = createAction('[PromoCode List Page] PromoCode Created ',props<{promoCode: PromotionCodeModel}>());
export const PromoCodeCreatedSuccessfully = createAction('[PromoCode List Page] PromoCode Created Successfully ',props<{response: any}>());
export const PromoCodeCreationFailed = createAction('[PromoCode List Page] PromoCode Creation Failed ',props<{error: any}>());

export const PromoCodeUpdated = createAction('[PromoCode List Page] PromoCode Updated ',props<{promoCode: PromotionCodeModel, partialPromoCode: Update<PromotionCodeModel>}>());
export const PromoCodeUpdatedSuccessfully = createAction('[PromoCode List Page] PromoCode Updated Successfully ',props<{promoCode: PromotionCodeModel, partialPromoCode: Update<PromotionCodeModel>}>());
export const PromoCodeUpdateFailed = createAction('[PromoCode List Page] PromoCode Update Failed ',props<{error: any}>());

export const PromoCodeDeleted = createAction('[PromoCode List Page] PromoCode Deleted ',props<{promoCodeId: number}>());
export const PromoCodeDeletedSuccessfully = createAction('[PromoCode List Page] PromoCode deleted Successfully ',props<{promoCodeId: number}>());
export const PromoCodeDeleteFailed = createAction('[PromoCode List Page] PromoCode delete Failed ',props<{error: any}>());

export const PromoCodeUpdatedActivate = createAction('[PromoCode List Page] PromoCode Updated activate',props<{promoCode: PromotionCodeModel, partialPromoCode: Update<PromotionCodeModel>, activate:boolean, updater:string}>());
export const PromoCodeUpdatedActivateSuccessfully = createAction('[PromoCode List Page] PromoCode Updated Successfully ',props<{promoCode: PromotionCodeModel, partialPromoCode: Update<PromotionCodeModel>,activate:boolean, updater:string}>());
export const PromoCodeUpdateActivateFailed = createAction('[PromoCode List Page] PromoCode Update Failed ',props<{error: any}>());

