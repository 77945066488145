import { createAction, props } from '@ngrx/store';
import {ParcelsResponseModel} from "../_models/parcelsResponse.model";
import {ParcelStateModel} from "../_models/parcelState";


export const loadParcels = createAction('[Parcel] Load Parcels',props<{ page:string, pageSize:string,wilayaId:string,state:string}>());
export const loadParcelsSuccess = createAction('[Parcel] Load Parcels Success', props<{ data: ParcelsResponseModel }>());
export const loadParcelsFailure = createAction('[Parcel] Load Parcels Failure', props<{ error: any }>());


export const loadParcelStates = createAction('[Parcel] Load Parcel States',props<{ tracking:string}>());
export const loadParcelStatesSuccess = createAction('[Parcel] Load Parcel States Success', props<{ data: ParcelStateModel[] }>());
export const loadParcelStatesFailure = createAction('[Parcel] Load Parcel States Failure', props<{ error: any }>());

