import {CategoriesService} from "../../../../../../../core/e-commerce";
import {AgencyBankModel} from "../../../../../../../core/ek-e-commerce/ek-models/agency-bank-model";
import {AgencyBankService} from "../../../../../../../core/ek-e-commerce/ek-services/agency-bank.service";
import {FoldersService} from '../../../../../../../core/ek-e-commerce/ek-services/folders.service';
import {CreditModel} from "../../../../../../../core/ek-e-commerce/ek-models/credit.model";
import {Component, Input, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import fontkit from "@pdf-lib/fontkit";
import {PDFDocument, rgb, StandardFonts} from "pdf-lib";

import {DocumentModel} from "../../../../../../../core/ek-e-commerce/ek-models/document.model";
import {OrderSplitService} from "../../../../../../../core/ek-e-commerce/ek-services/order-split.service";
import {ocrInfo} from "../../../../../../../core/ek-e-commerce/ek-models/document-ocr-information";
import {OrderSplit} from "../../../../../../../core/ek-e-commerce/ek-models/orderSplit";
import {OrderItemModel} from "../../../../../../../core/ek-e-commerce/ek-models/orderItem.model";
import {DocumentService} from "../../../../../../../core/services/document.service";
import {BehaviorSubject} from "rxjs";
import {ToWords} from "to-words";
import {DatePipe} from "@angular/common";

@Component({
    selector: "kt-folder-step-six",
    templateUrl: "./folder-step-six.component.html",
    styleUrls: ["./folder-step-six.component.scss"],
    providers: [DatePipe],
})
export class FolderStepSixComponent implements OnInit {
    @Input() information!: ocrInfo;
    @Input() order!: OrderSplit;
    @Input() requestNumber!: String;
    orderSplit: OrderSplit;
    loading: boolean;
    checkAccountAgreement: any;
    financingAgreement: any;
    math = Math;
    credit: CreditModel;
    orderId: number;
    folderId: number;
    categories: OrderItemModel[] = [];
    agency!: AgencyBankModel;
    profitMargin: number;
    guaranteesDocuments$ = new BehaviorSubject<DocumentModel[]>(null);
    invoicessDocuments$ = new BehaviorSubject<DocumentModel[]>(null);
    downLoadFiles$ = new BehaviorSubject<boolean>(false);
    readonly loading$ = this.downLoadFiles$.asObservable();

    private creditSbj$ = new BehaviorSubject<CreditModel>(null);
    readonly creditObs$ = this.creditSbj$.asObservable();

    currentRole: string = "";
    canDownload: boolean;
    ordreVirement: any;
    demandeAchat: any;
    categoriess: string[] = [];

    constructor(
        private documentService: DocumentService,
        private orderService: OrderSplitService,
        private route: ActivatedRoute,
        private agencyBankService: AgencyBankService,
        private categoryService: CategoriesService,
        private datePipe: DatePipe,
        public folderService: FoldersService
    ) {
        this.currentRole = JSON.parse(localStorage.getItem("currentUser")).roles;
        this.route.paramMap.subscribe((params) => {
            this.orderId = Number(params.get("idOrder"));
            this.folderId = Number(params.get("idFolder"));
        });
    }

    async createPdf() {
        this.loading = true;

        const toWords = new ToWords({
            localeCode: "fr-FR",
            converterOptions: {
                currency: true,
                ignoreDecimal: false,
                currencyOptions: {
                    name: "Dinar",
                    plural: "Dinars",
                    symbol: "",
                    fractionalUnit: {
                        name: "Centime",
                        plural: "Centimes",
                        symbol: "",
                    },
                },
            },
        });
        let totalToWords = toWords.convert(this.credit?.salePriceCPAWithTax);

        const url =
            "https://ekiclik-docs.s3.eu-west-2.amazonaws.com/NotoSansArabic-Black.ttf";
        const fontBytes = await fetch(url).then((res) => res.arrayBuffer());

        // Contrat de vente par facilité --------------------------------------------------

        const checkAccountAgreement = await fetch(
            this.checkAccountAgreement
        ).then((res) => res.arrayBuffer());

        const CCA = await PDFDocument.load(checkAccountAgreement);
        CCA.registerFontkit(fontkit);
        const arabicFont2 = await CCA.embedFont(fontBytes);
        const helveticaFont2 = await CCA.embedFont(StandardFonts.HelveticaBold);

        const CCApages = CCA.getPages();
        const CCAfirstPage = CCApages[0];
        const CCAsecondPage = CCApages[1];
        const CCAthirdPage = CCApages[2];

        CCAfirstPage.drawText(
            `${this.requestNumber ? this.requestNumber : ""}`,
            {
                x: 61,
                y: 756,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        CCAsecondPage.drawText(
            `${this.requestNumber ? this.requestNumber : ""}`,
            {
                x: 418,
                y: 791,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        CCAthirdPage.drawText(
            `${this.requestNumber ? this.requestNumber : ""}`,
            {
                x: 58,
                y: 751,
                size: 12,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        CCAfirstPage.drawText(
            `${
                this.agency?.firstNameManager
                    ? this.agency?.firstNameManager!
                    : ""
            }  ${
                this.agency?.lastNameManager
                    ? this.agency?.lastNameManager
                    : ""
            }`,
            {
                x: this.getX(
                    428,
                    this.agency?.firstNameManager! + this.agency?.lastNameManager!,
                    arabicFont2,
                    8
                ),
                y: 632,
                size: 8,
                font: arabicFont2,
                color: rgb(0, 0, 0),
            }
        );

        CCAfirstPage.drawText(
            `${
                this.agency?.codeAgency
                    ? this.agency?.codeAgency  
                    : ""
            }`,
            {
                x: this.getX(
                    210,
                    this.agency?.jobPosition! + this.agency?.codeAgency,
                    arabicFont2,
                    8
                ),
                y: 632,
                size: 8,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        CCAfirstPage.drawText(
            `${
                this.agency?.jobPosition
                    ? this.agency?.jobPosition 
                    : ""
            }`,
            {
                x: this.getX(
                    242,
                    this.agency?.jobPosition!,
                    arabicFont2,
                    8
                ),
                y: 632,
                size: 8,
                font: arabicFont2,
                color: rgb(0, 0, 0),
            }
        );

        CCAfirstPage.drawText(
            `${
                this.information.personnelInformation?.fistNameEnArab
                    ? this.information.personnelInformation?.fistNameEnArab!
                    : ""
            }   ${
                this.information.personnelInformation?.lastNameEnArab
                    ? this.information.personnelInformation?.lastNameEnArab
                    : ""
            }`,
            {
                x: this.getX(
                    410,
                    this.information.personnelInformation?.lastNameEnArab! +
                    this.information.personnelInformation?.fistNameEnArab!,
                    arabicFont2,
                    8
                ),
                y: 561,
                size: 8,
                font: arabicFont2,
                color: rgb(0, 0, 0),
            }
        );

        let mihna: string = ''

        switch (this.information?.professionalSituation?.professionalClientCategory) {
            case 'EMPLOYEE' : {
                mihna = 'موظف'
                break;
            }
            case 'RETRIED' : {
                mihna = 'متقاعد'
                break;
            }
            case 'MILITARY' : {
                mihna = 'موظف'
                break;
            }
            case 'MILITARY_RETIRED' : {
                mihna = 'متقاعد'
                break;
            }
        }


        CCAfirstPage.drawText(
            `${
                mihna
                    ? mihna!
                    : ""
            }`,
            {
                x: this.getX(
                    480,
                    mihna!,
                    arabicFont2,
                    8
                ),
                y: 548,
                size: 8,
                font: arabicFont2,
                color: rgb(0, 0, 0),
            }
        );

        CCAfirstPage.drawText(
            `${
                this.information.personnelSituation.personnelSituationAddress
                    ? this.invertNumbers(
                        this.information.personnelSituation.personnelSituationAddress!
                    )
                    : ""
            }`,
            {
                x: this.getX(
                    497,
                    String(
                        this.information.personnelSituation.personnelSituationAddress!
                    ),
                    arabicFont2,
                    8
                ),
                y: 536,
                size: 8,
                font: arabicFont2,
                color: rgb(0, 0, 0),
            }
        );

        CCAfirstPage.drawText(
            `${
                this.information.personnelInformation?.idCardNumber
                    ? this.information.personnelInformation?.idCardNumber!
                    : ""
            }`,
            {
                x: this.getX(
                    425,
                    String(this.information.personnelInformation?.idCardNumber!),
                    arabicFont2,
                    8
                ),
                y: 524,
                size: 8,
                font: arabicFont2,
                color: rgb(0, 0, 0),
            }
        );

        CCAfirstPage.drawText(
            `${
                this.information.personnelInformation?.deliveryDairaArabic
                    ? this.information.personnelInformation?.deliveryDairaArabic!
                    : ""
            }`,
            {
                x: this.getX(
                    260,
                    String(this.information.personnelInformation?.deliveryDairaArabic!),
                    arabicFont2,
                    8
                ),
                y: 524,
                size: 8,
                font: arabicFont2,
                color: rgb(0, 0, 0),
            }
        );

        CCAfirstPage.drawText(
            `${
                this.information.personnelInformation?.deliveryDate
                    ? this.stringToDate(
                        this.information.personnelInformation?.deliveryDate
                    )
                    : ""
            }`,
            {
                x: this.getX(
                    150,
                    this.information.personnelInformation?.deliveryDate!,
                    helveticaFont2,
                    8
                ),
                y: 524,
                size: 8,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        CCAfirstPage.drawText(`${this.order.phone ? this.order.phone! : ""}`, {
            x: this.getX(470, String(this.order.phone!), helveticaFont2, 8),
            y: 511,
            size: 8,
            font: helveticaFont2,
            color: rgb(0, 0, 0),
        });

        let manufactureNames: string[] = [];
        let brands: string[] = [];
        let cat: string[] = [];

        this.order.orderItems.forEach((item) => {
            manufactureNames.push(item.configurationTO.manufactureName);
            brands.push(item.configurationTO.productBrand);
            cat.push(item.configurationTO.categoryLabel);
        });

        manufactureNames = Array.from(new Set(manufactureNames));
        manufactureNames = manufactureNames.length >= 4 ? manufactureNames.slice(0, 4) : manufactureNames
        let unicManufactureNames = Array.from(new Set(manufactureNames)).join(" ").toLocaleUpperCase();
        CCAfirstPage.drawText(
            `${
                unicManufactureNames
                    ? unicManufactureNames
                    : ""
            }`,
            {
                x: this.getX(manufactureNames.length >= 4 ? 520 : 470, String(unicManufactureNames!), helveticaFont2, 8),
                y: 273,
                size: manufactureNames.length >= 4 ? 6 : 8,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        brands = Array.from(new Set(brands));
        brands = brands.length >= 4 ? brands.slice(0, 4) : brands
        let unicBrands = Array.from(new Set(brands))
        CCAfirstPage.drawText(`${unicBrands ? unicBrands.join(" ").toLocaleUpperCase() : ""}`,
            {
                x: this.getX( 450, String(unicBrands!), helveticaFont2, 8),
                y: 263,
                size: 8,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            });


        cat = Array.from(new Set(cat));
        cat = cat.length >= 4 ? cat.slice(0, 4) : cat;
        let unicCat = Array.from(new Set(cat)).join(" ").toLocaleUpperCase();
        CCAfirstPage.drawText(`${unicCat ? unicCat : ""}`, {
            x: this.getX(unicCat.length >= 4 ? 500 : 470, String(unicCat!), helveticaFont2, 8),
            y: 250,
            size: unicCat.length >= 4 ? 6 : 8,
            font: helveticaFont2,
            color: rgb(0, 0, 0),
        });

        const totalWordsTable2 = toWords
            .convert(this.credit?.fundingAmount)
            .split(" ");
        if (totalWordsTable2.length > 11) {
            let firstLine = totalWordsTable2.slice(0, 11);
            let secondLine = totalWordsTable2.slice(11);

            CCAsecondPage.drawText(`${firstLine ? firstLine.join(" ") : ""}`, {
                x: 120,
                y: 733,
                size: 8,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            });

            CCAsecondPage.drawText(`${secondLine ? secondLine.join(" ") : ""}`, {
                x: 120,
                y: 723,
                size: 8,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            });
        } else {
            CCAsecondPage.drawText(
                `${totalWordsTable2 ? totalWordsTable2.join(" ") : ""}`,
                {
                    x: 120,
                    y: 733,
                    size: 8,
                    font: helveticaFont2,
                    color: rgb(0, 0, 0),
                }
            );
        }
        CCAsecondPage.drawText(
            `${
                this.credit?.fundingAmount
                    ? this.addSeparation(+this.credit?.fundingAmount.toFixed(2)) + "DA"
                    : ""
            }`,
            {
                x: this.getX(
                    470,
                    String(this.credit?.fundingAmount!) + "       ",
                    helveticaFont2,
                    8
                ),
                y: 715,
                size: 8,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        // CCAfirstPage.drawText(`${this.credit?.debtCapacity ? this.addSeparation(this.credit?.debtCapacity)+',00 DA' : ""}`, {
        //   x: this.getX(485, String(this.credit?.debtCapacity!)+'       ', helveticaFont2, 8),
        //   y: 370,
        //   size: 8,
        //   font: helveticaFont2,
        //   color: rgb(0, 0, 0),
        // });
        const totalWordsTable4 = toWords
            .convert(this.profitMargin)
            .split(" ");
        if (totalWordsTable4.length > 11) {
            let firstLine = totalWordsTable4.slice(0, 11);
            let secondLine = totalWordsTable4.slice(11);

            CCAsecondPage.drawText(`${firstLine ? firstLine.join(" ") : ""}`, {
                x: 110,
                y: 677,
                size: 8,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            });

            CCAsecondPage.drawText(`${secondLine ? secondLine.join(" ") : ""}`, {
                x: 110,
                y: 669,
                size: 8,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            });
        } else {
            CCAsecondPage.drawText(
                `${totalWordsTable4 ? totalWordsTable4.join(" ") : ""}`,
                {
                    x: 110,
                    y: 677,
                    size: 8,
                    font: helveticaFont2,
                    color: rgb(0, 0, 0),
                }
            );
        }

        CCAsecondPage.drawText(
            `${
                this.profitMargin
                    ? this.addSeparation(+this.profitMargin.toFixed(2)) + " DA"
                    : ""
            }`,
            {
                x: this.getX(
                    530,
                    String(this.profitMargin!) + "       ",
                    helveticaFont2,
                    8
                ),
                y: 648,
                size: 8,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        const totalWordsTable3 = toWords
            .convert(this.credit?.salePriceCPAWithTax)
            .split(" ");
        if (totalWordsTable3.length > 11) {
            let firstLine = totalWordsTable3.slice(0, 11);
            let secondLine = totalWordsTable3.slice(11);

            CCAsecondPage.drawText(`${firstLine ? firstLine.join(" ") : ""}`, {
                x: 105,
                y: 635,
                size: 8,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            });

            CCAsecondPage.drawText(`${secondLine ? secondLine.join(" ") : ""}`, {
                x: 105,
                y: 622,
                size: 8,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            });
        } else {
            CCAsecondPage.drawText(
                `${totalWordsTable3 ? totalWordsTable3.join(" ") : ""}`,
                {
                    x: 105,
                    y: 631,
                    size: 8,
                    font: helveticaFont2,
                    color: rgb(0, 0, 0),
                }
            );
        }

        CCAsecondPage.drawText(
            `${
                this.credit?.salePriceCPAWithTax
                    ? this.addSeparation(+this.credit?.salePriceCPAWithTax.toFixed(2)) + " DA"
                    : ""
            }`,
            {
                x: this.getX(
                    480,
                    String(this.credit?.salePriceCPAWithTax!) + "   ",
                    helveticaFont2,
                    8
                ),
                y: 618,
                size: 8,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        CCAsecondPage.drawText(`0,00 DZD`, {
            x: 207,
            y: 362,
            size: 8,
            font: helveticaFont2,
            color: rgb(0, 0, 0),
        });

        CCAsecondPage.drawText(`0,00 DZD`, {
            x: 133,
            y: 362,
            size: 8,
            font: helveticaFont2,
            color: rgb(0, 0, 0),
        });

        CCAsecondPage.drawText(`${this.credit?.numberOfMonths ? this.credit?.numberOfMonths : ''}`, {
            x: 450,
            y: 350,
            size: 8,
            font: helveticaFont2,
            color: rgb(0, 0, 0),
        });

        CCAsecondPage.drawText(
            `${
                this.credit?.monthlyPaymentWithTax
                    ? this.addSeparation(+this.credit.monthlyPaymentWithTax.toFixed(2)) + "DZD"
                    : ""
            }`,
            {
                x: 215,
                y: 350,
                size: 8,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        const monthlyWords = toWords
            .convert(this.credit?.monthlyPaymentWithTax)
            .split(" ");
        if (monthlyWords.length > 2) {
            let firstLine = monthlyWords.slice(0, 2);
            let secondLine = monthlyWords.slice(2);

            CCAsecondPage.drawText(`${firstLine ? firstLine.join(" ") : ""}`, {
                x: 267,
                y: 349,
                size: 8,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            });

            CCAsecondPage.drawText(`${secondLine ? secondLine.join(" ") : ""}`, {
                x: 267,
                y: 337,
                size: 8,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            });
        } else {
            CCAsecondPage.drawText(`${monthlyWords ? monthlyWords.join(" ") : ""}`, {
                x: 267,
                y: 349,
                size: 8,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            });
        }

        // Convention de financement --------------------------------------------------

        const ConvensionDeFinancement = await fetch(
            this.financingAgreement
        ).then((res) => res.arrayBuffer());

        const CDF = await PDFDocument.load(ConvensionDeFinancement);
        CDF.registerFontkit(fontkit);
        const arabicFont3 = await CDF.embedFont(fontBytes);
        const helveticaFont3 = await CDF.embedFont(StandardFonts.HelveticaBold);

        const CDFpages = CDF.getPages();
        const CDFfirstPage = CDFpages[0];
        const CDFsecondPage = CDFpages[1];
        const CDFthirdPage = CDFpages[2];

        CDFfirstPage.drawText(
            `${this.requestNumber ? this.requestNumber : ""}`,
            {
                x: 74,
                y: 758,
                size: 12,
                font: helveticaFont3,
                color: rgb(0, 0, 0),
            }
        );
        CDFsecondPage.drawText(
            `${this.requestNumber ? this.requestNumber : ""}`,
            {
                x: 67,
                y: 749,
                size: 12,
                font: helveticaFont3,
                color: rgb(0, 0, 0),
            }
        );
        CDFthirdPage.drawText(
            `${this.requestNumber ? this.requestNumber : ""}`,
            {
                x: 67,
                y: 750,
                size: 12,
                font: helveticaFont3,
                color: rgb(0, 0, 0),
            }
        );
        // CDFfirstPage.drawText(
        //     `${this.folder?.requestNumber ? this.folder?.requestNumber : ""}`,
        //     {
        //         x: 37,
        //         y: 750,
        //         size: 12,
        //         font: helveticaFont3,
        //         color: rgb(0, 0, 0),
        //     }
        // );

        CDFfirstPage.drawText(
            `${
                this.agency?.firstNameManager
                    ? this.agency?.firstNameManager!
                    : ""
            }  ${
                this.agency?.lastNameManager
                    ? this.agency?.lastNameManager
                    : ""
            }`,
            {
                x: this.getX(
                    498,
                    this.agency?.firstNameManager! + this.agency?.lastNameManager!,
                    arabicFont3,
                    8
                ),
                y: 695,
                size: 8,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            }
        );

        CDFfirstPage.drawText(
            `${
                this.agency?.codeAgency
                    ? this.agency?.codeAgency!
                    : ""
            }`,
            {
                x: this.getX(
                    259,
                    this.agency?.codeAgency!,
                    helveticaFont2,
                    7
                ),
                y: 696,
                size: 7,
                font: helveticaFont2,
                color: rgb(0, 0, 0),
            }
        );

        CDFfirstPage.drawText(
            `${
                this.agency?.jobPosition
                    ? this.agency?.jobPosition!
                    : ""
            }`,
            {
                x: this.getX(
                    309,
                    this.agency?.jobPosition!,
                    arabicFont3,
                    8
                ),
                y: 695,
                size: 8,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            }
        );

        CDFfirstPage.drawText(
            `${
                this.information.personnelInformation?.fistNameEnArab
                    ? this.information.personnelInformation?.fistNameEnArab!
                    : ""
            }   ${
                this.information.personnelInformation?.lastNameEnArab
                    ? this.information.personnelInformation?.lastNameEnArab
                    : ""
            }`,
            {
                x: this.getX(
                    415,
                    this.information.personnelInformation?.lastNameEnArab! +
                    this.information.personnelInformation?.fistNameEnArab!,
                    arabicFont3,
                    9
                ),
                y: 645,
                size: 10,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            }
        );

        CDFfirstPage.drawText(
            `${
                this.information.personnelInformation?.placeOfBrithArabic
                    ? this.information.personnelInformation?.placeOfBrithArabic
                    : ""
            }`,
            {
                x: this.getX(
                    442,
                    this.information.personnelInformation?.placeOfBrithArabic!,
                    arabicFont3,
                    9
                ),
                y: 632,
                size: 10,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            }
        );

        CDFfirstPage.drawText(
            `${
                this.information.personnelInformation?.birthDay
                    ? this.information.personnelInformation?.birthDay
                    : ""
            }`,
            {
                x: this.getX(
                    432,
                    this.information.personnelInformation?.placeOfBrithArabic +
                    this.information.personnelInformation?.birthDay!,
                    arabicFont3,
                    9
                ),
                y: 632,
                size: 10,
                font: helveticaFont3,
                color: rgb(0, 0, 0),
            }
        );

        CDFfirstPage.drawText(
            `${
                mihna
                    ? mihna!
                    : ""
            }`,
            {
                x: this.getX(
                    227,
                    mihna!,
                    arabicFont3,
                    10
                ),
                y: 632,
                size: 10,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            }
        );

        CDFfirstPage.drawText(
            `${
                this.information.personnelSituation.personnelSituationAddress
                    ? this.invertNumbers(
                        this.information.personnelSituation.personnelSituationAddress!
                    )
                    : ""
            }`,
            {
                x: this.getX(
                    480,
                    this.information.personnelSituation.personnelSituationAddress!,
                    arabicFont3,
                    9
                ),
                y: 620,
                size: 10,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            }
        );

        CDFfirstPage.drawText(
            `${
                this.information.personnelInformation?.idCardNumber
                    ? this.information.personnelInformation?.idCardNumber
                    : ""
            }`,
            {
                x: this.getX(
                    420,
                    String(this.information.personnelInformation?.idCardNumber!),
                    helveticaFont3,
                    9
                ),
                y: 607,
                size: 10,
                font: helveticaFont3,
                color: rgb(0, 0, 0),
            }
        );
        CDFfirstPage.drawText(
            `${
                this.information.personnelInformation?.deliveryDairaArabic
                    ? this.information.personnelInformation?.deliveryDairaArabic
                    : ""
            }`,
            {
                x: this.getX(
                    280,
                    String(this.information.personnelInformation?.deliveryDairaArabic!),
                    arabicFont3,
                    8
                ),
                y: 607,
                size: 8,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            }
        );

        CDFfirstPage.drawText(
            `${
                this.information.personnelInformation?.deliveryDate
                    ? this.stringToDate(
                        this.information.personnelInformation?.deliveryDate
                    )
                    : ""
            }`,
            {
                x: this.getX(
                    170,
                    this.stringToDate(this.information.personnelInformation?.deliveryDate),
                    helveticaFont3,
                    9
                ),
                y: 607,
                size: 10,
                font: helveticaFont3,
                color: rgb(0, 0, 0),
            }
        );

        CDFfirstPage.drawText(
            ` ${this.invertNumbers(
                String(this.folderId)
            )}`,
            {
                x: this.getX(
                    467,
                    this.invertNumbers(String(this.folderId)) +
                    "مرابحة تجهيز حسب الفاتورة الشكلية رقم ",
                    arabicFont3,
                    9
                ),
                y: 349.5,
                size: 9,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            }
        );

        let categoriesArabic = Array.from(new Set(this.categoriess));
        if (categoriesArabic.length > 1) {
            CDFfirstPage.drawText(`${categoriesArabic[0]}`, {
                x: this.getX(540, categoriesArabic[0], arabicFont3, 9),
                y: 255,
                size: 9,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            });
            CDFfirstPage.drawText(`${categoriesArabic[1]}`, {
                x: this.getX(540, categoriesArabic[1], arabicFont3, 9),
                y: 244,
                size: 9,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            });
        } else {
            CDFfirstPage.drawText(`${categoriesArabic[0]}`, {
                x: this.getX(540, categoriesArabic[0], arabicFont3, 9),
                y: 250,
                size: 9,
                font: arabicFont3,
                color: rgb(0, 0, 0),
            });
        }

        CDFfirstPage.drawText(
            `${
                this.credit?.fundingAmount
                    ? this.addSeparation(this.credit?.fundingAmount)
                    : ""
            }`,
            {
                x: this.getX(
                    400,
                    String(this.credit?.fundingAmount),
                    helveticaFont3,
                    9
                ),
                y: 250,
                size: 9,
                font: helveticaFont3,
                color: rgb(0, 0, 0),
            }
        );

        // CDFfirstPage.drawText(`100%`, {
        //   x: 305,
        //   y: 292,
        //   size: 9,
        //   font: helveticaFont3,
        //   color: rgb(0, 0, 0),
        // });

        // CDFfirstPage.drawText(`0%`, {
        //   x: 273,
        //   y: 262,
        //   size: 9,
        //   font: helveticaFont3,
        //   color: rgb(0, 0, 0),
        // });

        let benefitPercent = (0.08 * 100).toFixed(2);

        // CDFfirstPage.drawText(`${benefitPercent}%`, {
        //   x: 228,
        //   y: 292,
        //   size: 9,
        //   font: helveticaFont3,
        //   color: rgb(0, 0, 0),
        // });

        // CDFfirstPage.drawText(`0%`, {
        //   x: 200,
        //   y: 292,
        //   size: 9,
        //   font: helveticaFont3,
        //   color: rgb(0, 0, 0),
        // });

        CDFfirstPage.drawText(`${this.credit?.numberOfMonths}`, {
            x: 175,
            y: 250,
            size: 9,
            font: helveticaFont3,
            color: rgb(0, 0, 0),
        });
        // CDFfirstPage.drawText(`3`, {
        //   x: 142,
        //   y: 292,
        //   size: 9,
        //   font: helveticaFont3,
        //   color: rgb(0, 0, 0),
        // });
        // CDFfirstPage.drawText(
        //       `30/09/2026`,
        //       {
        //         x: 89,
        //         y: 292,
        //         size: 7,
        //         font: helveticaFont3,
        //         color: rgb(0, 0, 0),

        //       }
        //     );
        // CDFfirstPage.drawText(`نقدا `, {
        //   x: 48,
        //   y: 292,
        //   size: 9,
        //   font: arabicFont3,
        //   color: rgb(0, 0, 0),
        // });

        // CDFfirstPage.drawText(`التأمين التكافلي على الحياة`, {
        //   x: 368,
        //   y: 227.5,
        //   size: 9,
        //   font: arabicFont3,
        //   color: rgb(0, 0, 0),
        // });

        // Ordre de virement permanant --------------------------------------------------

        const ordreVirement = await fetch(this.ordreVirement).then((res) =>
            res.arrayBuffer()
        );

        const OVIR = await PDFDocument.load(ordreVirement);
        OVIR.registerFontkit(fontkit);
        const arabicFont4 = await OVIR.embedFont(fontBytes);
        const helveticaFont4 = await OVIR.embedFont(StandardFonts.HelveticaBold);

        const OVIRpages = OVIR.getPages();
        const OVIRfirstPage = OVIRpages[0];

        OVIRfirstPage.drawText(
            `${this.requestNumber ? this.requestNumber : ''}`,
            {
                x: 68,
                y: 745,
                size: 12,
                font: helveticaFont4,
                color: rgb(0, 0, 0),
            }
        );

        OVIRfirstPage.drawText(
            `${
                this.information.personnelInformation?.firstName
                    ? this.information.personnelInformation?.firstName!
                    : ""
            } ${
                this.information.personnelInformation?.lastName
                    ? this.information.personnelInformation?.lastName
                    : ""
            }`,
            {
                x: 200,
                y: 705,
                size: 11,
                font: helveticaFont4,
                color: rgb(0, 0, 0),
            }
        );

        OVIRfirstPage.drawText(
          `${
            this.information.personnelInformation?.birthDay
              ? this.information.personnelInformation?.birthDay!
              : ""
          }`,
          {
            x: 453.5,
            y: 705,
            size: 11,
            font: helveticaFont4,
            color: rgb(0, 0, 0),
          }
        );

        OVIRfirstPage.drawText(
          `${
            this.information.personnelInformation?.placeOfBrith
              ? this.information.personnelInformation?.placeOfBrith!
              : ""
          }`,
          {
            x: 78,
            y: 691.5,
            size: 11,
            font: helveticaFont4,
            color: rgb(0, 0, 0),
          }
        );

        OVIRfirstPage.drawText(
            `${
                this.information.personnelInformation?.idCardNumber
                    ? this.information.personnelInformation?.idCardNumber!
                    : ""
            }`,
            {
                x: 300,
                y: 691.5,
                size: 11,
                font: helveticaFont4,
                color: rgb(0, 0, 0),
            }
        );

        // OVIRfirstPage.drawText(
        //     `${
        //         this.information.personnelInformation?.deliveryDairaArabic
        //             ? this.information.personnelInformation?.deliveryDairaArabic!
        //             : ""
        //     }`,
        //     {
        //         x: this.getX(
        //             320,
        //             String(this.information.personnelInformation?.deliveryDairaArabic!),
        //             arabicFont4,
        //             11
        //         ),
        //         y: 702,
        //         size: 11,
        //         font: arabicFont4,
        //         color: rgb(0, 0, 0),
        //     }
        // );

        OVIRfirstPage.drawText(
          `${this.credit?.numberOfMonths ? this.credit.numberOfMonths! : ""}`,
          {
            x: 152,
            y: 650,
            size: 11,
            font: helveticaFont4,
            color: rgb(0, 0, 0),
          }
        );

        OVIRfirstPage.drawText(
          `${
            this.credit?.monthlyPaymentWithTax
              ? this.addSeparation(this.credit.monthlyPaymentWithTax)!
              : ""
          }`,
          {
            x: 160,
            y: 635.5,
            size: 11,
            font: helveticaFont4,
            color: rgb(0, 0, 0),
          }
        );

        const totalWordsTable = toWords
          .convert(this.credit.monthlyPaymentWithTax)
          .split(" ");
        if (totalWordsTable.length >= 6) {
          let firstLine = totalWordsTable.slice(0, 6);
          let secondLine = totalWordsTable.slice(6);

          OVIRfirstPage.drawText(`${firstLine ? firstLine.join(" ") : ""}`, {
            x: 325,
            y: 635.5,
            size: 10,
            font: helveticaFont4,
            color: rgb(0, 0, 0),
          });

          OVIRfirstPage.drawText(`${secondLine ? secondLine.join(" ") : ""}`, {
            x: 65,
            y: 622.5,
            size: 10,
            font: helveticaFont4,
            color: rgb(0, 0, 0),
          });
        } else {
          OVIRfirstPage.drawText(`${totalToWords ? totalToWords : ""}`, {
            x: 330,
            y: 635.5,
            size: 10,
            font: helveticaFont4,
            color: rgb(0, 0, 0),
          });
        }

        OVIRfirstPage.drawText(
            `${
                this.credit?.fundingAmount
                    ? this.addSeparation(this.credit?.salePriceCPAWithTax)!
                    : ""
            } DA`,
            {
                x: 210,
                y: 580,
                size: 11,
                font: helveticaFont4,
                color: rgb(0, 0, 0),
            }
        );

        //Saving Files To Download Them Modified

        const pdfBytes2 = await CCA.save();
        this.saveByteArray("Contrat de vente par facilité.pdf", pdfBytes2);
        const pdfBytes3 = await CDF.save();
        this.saveByteArray("Convention de financement.pdf", pdfBytes3);
        const pdfBytes4 = await OVIR.save();
        this.saveByteArray("Ordre de virement permanant.pdf", pdfBytes4);


        this.loading = false;
    }

    saveByteArray(reportName: any, byte: any) {
        const blob = new Blob([byte], {type: "application/pdf"});
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = reportName;
        link.click();
    }

    getX(x: number, value: string, font: any, fontSize: number) {
        if (value) {
            const textWidth = font.widthOfTextAtSize(value, fontSize);
            return x - textWidth;
        } else {
            return 0;
        }
    }

    getXFr(x: number, value: string, font: any, fontSize: number) {
        if (value) {
            const textWidth = font.widthOfTextAtSize(value, fontSize);
            return x + textWidth;
        } else {
            return 0;
        }
    }

    downloadInvoices() {
        this.orderService.downloadInvoices(this.orderId);
    }

    stringToDate(dateString: string): string {
        // const date = new Date(dateString)
        // const formatedDate = String(this.datePipe.transform(date, 'dd/MM/yyyy') || '');
        // const parts = formatedDate.includes('-') ? formatedDate.split('-') : formatedDate.split('/');
        // const day = +parts[0];
        // const month = +parts[1] - 1; // Months are 0-based in JavaScript Date
        // const year = +parts[2];

        // return new Date(year, month, day);

        if (dateString && dateString.length > 12) {
            const dateObject = new Date(dateString);
            return this.datePipe.transform(dateObject, "dd/MM/yyyy");
        } else {
            return dateString;
        }
    }

    addSeparation(value: number): string {
        if (value) {
            const stringValue = value.toString();
            return stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        } else {
            return "";
        }
    }

    invertNumbers(input: string): string {
        return input.replace(/\d+/g, (match) => {
            return match.split("").reverse().join("");
        });
    }

    downloadDocuments() {
        this.createPdf();
        this.downloadInvoices();
    }

    ngOnInit(): void {

        this.orderService.selectedOrder$.subscribe((res) => {
            if (res) {
                this.categoriess = [];
                res.orderItems.forEach((config) => {
                    this.categoryService
                        .getParentCategoryByIdProduct(config.configurationTO.productId)
                        .subscribe((category) => {
                            if (category.includes("MEUBLES")) {
                                this.categoriess.push("تجهيزات  تأثيث المنزل");
                            } else {
                                this.categoriess.push("تجهيزات كهرومنزلية");
                            }
                        });
                });
            }
        });

        this.orderService.creditSubject.subscribe(cr => {
            if (cr) {
                this.creditSbj$.next(cr);
                this.credit = cr;
                this.profitMargin = this.credit.salePriceCPAWithTax - this.credit.fundingAmount;
            }
        });

        this.folderService.folderStateSubj$.subscribe((currentFolderState) => {

            const bankerInvalidStates = ["EK_CONTROL_BEFORE_GUARANTEES_PRINTING", "EK_BANK_RESERVE", "EK_BANK_REFUSED", "EK_GUARTENTEES_PENDING_SIGNATURE", "GUARTENTEES_SIGNED_IN_PROGRESS_BANK", "FINANCIAL_FOLDER"];
            const POS_ValidStates = ["EK_BANK_RESERVE", "EK_BANK_REFUSED", "EK_GUARTENTEES_PENDING_SIGNATURE", "GUARTENTEES_SIGNED_IN_PROGRESS_BANK", "FINANCIAL_FOLDER"];

            if (this.currentRole === 'ROLE_SUPERADMIN' || this.currentRole === 'ROLE_ADMIN' || this.currentRole === 'ROLE_CREDIT_ANALYST_EK') {

                this.canDownload = bankerInvalidStates.includes(currentFolderState);
            }

            if (this.currentRole === 'ROLE_BANKER_EK') {
                this.canDownload = !bankerInvalidStates.includes(currentFolderState);
            }

            if (this.currentRole === 'ROLE_POS_EK' || this.currentRole === 'ROLE_COMMERCIAL_POS_EK') {
                this.canDownload = POS_ValidStates.includes(currentFolderState);
            }

        });

        this.orderService.selectedOrderSubject.subscribe((res) => {
            if (res) {
                this.documentService
                    .downloadDocuments(res.id, "GUARANTEES_EMPTY")
                    .subscribe({
                        next: (res: any) => {
                            const obj = res.body.find((o: any) => {
                                switch (o.documentsTitle) {
                                    case "SALES_AGREEMENT": {
                                        this.checkAccountAgreement = o.documentUrlPreSigned;
                                        break;
                                    }
                                    case "INDIVIDUAL_FINANCING_AGREEMENT": {
                                        this.financingAgreement = o.documentUrlPreSigned;
                                        break;
                                    }
                                    case "STANDING_TRANSFER_ORDER": {
                                        this.ordreVirement = o.documentUrlPreSigned;
                                        break;
                                    }
                                    case "INDIVIDUAL_EQUIPMENT_PURCHASE_REQUEST": {
                                        this.demandeAchat = o.documentUrlPreSigned;
                                        break;
                                    }
                                }
                            });
                        },
                    });
            }
        });

        this.getAcencyByFolderId();

        this.getDocumentsByFolderId();

    }


    getAcencyByFolderId() {
        this.agencyBankService.getAcencyByFolderId(this.folderId).subscribe({
            next: (agencyResp) => {
                if (agencyResp.body) this.agency = agencyResp.body;
            },
            error: (err) => {
            },
        });
    }


    getDocumentsByFolderId() {
        this.documentService.getDocumentsByFolderId(this.folderId)
            .subscribe((res: any) => {
                this.guaranteesDocuments$.next(
                    res.body.filter(
                        (document: DocumentModel) => document.documentsType === "GUARANTEES"
                    )
                );
                this.invoicessDocuments$.next(
                    res.body.filter(
                        (document: DocumentModel) => document.documentsType === "INVOICE"
                    )
                );
            });
    }

}
