import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WilayaListPopUpComponent} from '../wilaya-list-pop-up/wilaya-list-pop-up.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Region} from '../../../../../../core/ek-e-commerce/ek-models/region';
import {RegionService} from '../../../../../../core/ek-e-commerce/ek-services/region.service';
import WILAYA from '../../../../../../../assets/JSON/Wilaya_Of_Algeria.json';
import {UserService} from '../../../../../../core/services/user.service';
import {User} from '../../../../../../core/auth';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'kt-new-region',
  templateUrl: './new-region.component.html',
  styleUrls: ['./new-region.component.scss']
})
export class NewRegionComponent implements OnInit {

  form!: FormGroup;
  commercialRegionManagers: User [] = [];
  regionWilayaList: string [] = [];

  currentRole = '';
  region: Region;

  constructor(
      public dialogRef: MatDialogRef<NewRegionComponent>,
      private fb: FormBuilder,
      private dialog: MatDialog,
      private regionService: RegionService,
      private userService: UserService,
      private snackBar: MatSnackBar,
      @Inject(MAT_DIALOG_DATA) public data: { idRegion: number }) {


    // get all Commercial Region Managers
    this.userService.getAllCommercialRegionManager().subscribe({
      next: (res) => {
        this.commercialRegionManagers = res;
      }
    });

    if (this.data.idRegion) {
      this.getRegionById();
    }
    this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;

    this.form = this.fb.group({
      name: ['', Validators.compose([Validators.required])],
      code: ['', Validators.compose([Validators.required])],
      user: ['', Validators.compose([Validators.required])],
    });
  }

  getUserById(id: number) {
    if (id) {
      const manager = this.commercialRegionManagers.find(item => item.id === id);
      return manager.firstname + ', ' + manager.lastname;
    }
    return '';
  }

  getRegionById() {
    this.regionService.getById(this.data.idRegion).subscribe({
      next: (data) => {
        this.region = data;
        this.regionWilayaList = data.wilayaSet;
        this.form.controls.name.setValue(data.name);
        this.form.controls.code.setValue(data.code);
        this.form.controls.user.setValue(data.userId);
      }
    });
  }

  ngOnInit(): void {

  }

  wilayaList() {
    const dialogRef = this.dialog.open(WilayaListPopUpComponent,
        {
          data: {
            wilayaList: this.data.idRegion ? this.region.wilayaSet : this.regionWilayaList,
            fromListing: false
          },
          width: '70rem',
          height: 'auto'
        });
    dialogRef.afterClosed().subscribe((data: string []) => {
      if (data) {
        this.regionWilayaList = data;
      }
    });
  }

  saveRegion() {

    const tempRegion = new Region();
    tempRegion.wilayaSet = this.regionWilayaList;
    tempRegion.name = this.form.controls.name.value;
    tempRegion.code = this.form.controls.code.value;
    tempRegion.userId = this.form.controls.user.value;

    if (this.data.idRegion) {
      this.editRegion(tempRegion);
    } else {
      this.addNewRegion(tempRegion);
    }

  }

  addNewRegion(region) {
    this.regionService.createRegion(region).subscribe({
      next: (res) => {
        const msg = 'la nouvelle région a été crée avec succès';
        this.snackBar.open(msg, 'ok', {
          panelClass: 'success-snackbar',
        });
        this.dialogRef.close();
      },
      error: (err) => {
        if (err.error.code === 401) {
          const msg = 'Ce responsable régional a déjà une région associée !';
          this.snackBar.open(msg, 'ok', {
            panelClass: 'error-snackbar',
          });
        }
      }
    });
  }


  getWilayaName(wilayaEnMaj) {
    return WILAYA.find(item => item.value === wilayaEnMaj).name;
  }

  editRegion(region) {
    region.id = this.region.id;
    this.regionService.editRegion(region).subscribe({
      next: () => {
        const msg = 'la nouvelle région a été modifiée avec succès';
        this.snackBar.open(msg, 'ok', {
          panelClass: 'success-snackbar',
        });
        this.dialogRef.close();
      }, error: (err) => {
        this.snackBar.open('erreur lors de la mise à jour de la région ! réessayez', 'ok', {
          panelClass: 'error-snackbar',
        });
      }
    });
  }
}
