import {BaseModel} from "../../_base/crud";


export class DeliveryAddressModel extends BaseModel {

    id: number;
    addressLine1: string;
    addressLine2: string;
    firstname: string;
    lastname: string;
    orderId: number;
    phone: string;
    postalCode: number;
    wilaya: string;
    ville :string;
    email?:string;


}
