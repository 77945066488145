// NGRX
import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// Models
import {CustomerModel} from '../_models/customer.model';
import {QueryParamsModel} from "../../_base/crud";


export const CustomerCreated = createAction('[Edit Customer Dialog] Customer On Server Created', props<{ customer: CustomerModel }>());
export const CustomerCreatedSuccessfully = createAction('[Customers API] Customer Created successfully', props<{ customer: CustomerModel }>());
export const CustomerCreationFailed = createAction('[Customers API] Customer Creation Failed', props<{ error: any }>());

export const CustomerUpdated = createAction('[Edit Customer Dialog] Customer Updated', props<{ customer: CustomerModel, partialCustomer: Update<CustomerModel> }>());
export const CustomerUpdatedSuccessfully = createAction('[Customers API] Customers Status Updated Successfully', props<{ customer: CustomerModel, partialCustomer: Update<CustomerModel> }>());
export const CustomerUpdateFailed = createAction('[Customers API] Customer Update Failed', props<{ error: any }>());

export const OneCustomerDeleted = createAction('[Customers List Page] One Customer Deleted', props<{ id: number }>());
export const OneCustomerDeletedSuccessfully = createAction('[Customers API] One Customer Deleted Successfully', props<{ id: number }>());
export const OneCustomerDeleteFailed = createAction('[[Customers API] One Customer Deleted', props<{ error: any }>());

export const CustomersPageRequested = createAction('[Customers List Page] Customers Page Requested', props<{ page: QueryParamsModel, startDate: string, endDate: string, clientStatus: string }>());
export const CustomersPageLoadedSuccessfully = createAction('[Customers API] Customers Page Loaded Successfully', props<{ customers: CustomerModel[], totalCount: number, page: number }>());
export const CustomersPageLoadingFailed = createAction('[Customers API] Customers Page Loading Failed', props<{ error: any }>());
