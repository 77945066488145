import { DomSanitizer } from '@angular/platform-browser';
import { Directive, HostBinding, HostListener, Output, EventEmitter } from '@angular/core';
import { FileHandle } from '../../../../core/ek-e-commerce/ek-models/file-handle.model';

@Directive({
  selector: '[appDrag]',
})
export class DragDirective {

  @Output() file: EventEmitter<FileHandle> = new EventEmitter()

  @HostBinding('style.background') private background = '';
  constructor(
    private sanitizer: DomSanitizer
  ) {}

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.background = '#eee';
  }
  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.background = '';
  }
  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.background = '#fff';

    let fileHandle: FileHandle = null as any

    const file = event.dataTransfer!.files[0]
    const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file))

    fileHandle = {file, url}
    this.file.emit(fileHandle)
  }
}
