export const FOLDER_STATES= [
    {name: 'En cours de création', value: 'EK_FOLDER_CREATED', order: 1},
    {name: 'Dossier en cours de traitement Ekiclik', value: 'EK_FOLDER_IN_PROGRESS', order: 2},
    {name: 'Dossier réserve Ekiclik', value: 'EK_FOLDER_RESERVE', order: 3},
    {name: 'Dossier refusé Ekiclik', value: 'EK_FOLDER_REFUSED', order: 4},
    {name: 'Pré-notification banque', value: 'EK_BANK_IN_PROGRESS', order: 5},
    {name: 'Notification de la banque (Accord)', value: 'BANK_NOTIFICATION', order: 6},
    {name: 'Control avant impression des garnaties EkiClik', value: 'EK_CONTROL_BEFORE_GUARANTEES_PRINTING', order: 7},
    {name: 'Dossier réserve banque', value: 'EK_BANK_RESERVE', order: 8},
    {name: 'Dossier Rejeté Banque', value: 'EK_BANK_REFUSED', order: 9},
    {name: 'Garanties en cours de signature', value: 'EK_GUARTENTEES_PENDING_SIGNATURE', order: 10},
    {name: 'Garanties signés', value: 'GUARTENTEES_SIGNED_IN_PROGRESS_BANK', order: 11},
    {name: 'Dossiers financés', value: 'FINANCIAL_FOLDER', order: 12},
    {name: 'Dossier annulé (CLIENT)', value: 'EK_CLIENT_FOLDER_CANCELED', order: 13},
    {name: 'Dossier annulé (ADMIN)', value: 'EK_ADMIN_FOLDER_CANCELED', order: 14},





]
