import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {map, startWith} from "rxjs/operators";
import {BehaviorSubject, Observable} from "rxjs";
import {PointOfSaleModel} from "../../../../../core/ek-e-commerce/ek-models/point-of-sale.model";
import {FormBuilder, FormGroup} from "@angular/forms";
import {PointOfSaleService} from "../../../../../core/ek-e-commerce/ek-services/point-of-sale.service";
import {Router} from "@angular/router";

@Component({
    selector: 'kt-ek-user-pos',
    templateUrl: './ek-user-pos.component.html',
    styleUrls: ['./ek-user-pos.component.scss']
})
export class EkUserPosComponent implements OnInit {

    @Input() userId: number;
    @Input() pointOfSaleList: PointOfSaleModel[] = []
    @Output() userPosEvent = new EventEmitter<PointOfSaleModel[]>();

    userPointOfSaleList: PointOfSaleModel[] = [];
    pointOfSleFilteredOptions!: Observable<PointOfSaleModel[]>;
    selectedRelayPoint = '0';
    selectedRelay!: PointOfSaleModel;

    pointOfSaleList2: PointOfSaleModel[] = [];

    loadingPOSSubject$ = new BehaviorSubject<PointOfSaleModel[]>([]);
    readonly loadingRecap$ = this.loadingPOSSubject$.asObservable();


    form: FormGroup = this.fb.group({
        pointOfSale: [''],
    });

    constructor(private pointOfSaleService: PointOfSaleService,
                private fb: FormBuilder, private router: Router
    ) {
    }

    ngOnInit(): void {

        this.getPOSByIdUser();

        //emit list of point of sales to edit component...
        this.form.get('pointOfSale').valueChanges.subscribe(change => this.userPosEvent.emit(this.userPointOfSaleList));


        this.loadingPOSSubject$.subscribe(points => {
            if (points) {
                //remove selected point of sales from list of selections
                this.pointOfSaleList2 = this.removeSimilarItems(this.pointOfSaleList, this.userPointOfSaleList);
            }
        });

    }

    getPOSByIdUser() {
        this.pointOfSaleService.getAllPOSByIdUser(this.userId).subscribe(points => {
            this.userPointOfSaleList = points;
            this.loadingPOSSubject$.next(points);
        });
    }


    removeSimilarItems(list1: PointOfSaleModel[], list2: PointOfSaleModel[]): PointOfSaleModel[] {
        return list1.filter(item1 => !list2.some(item2 => this.areSimilar(item1, item2)));
    }

    areSimilar(item1: any, item2: any): boolean {
        return item1.id === item2.id;
    }

    addPos(idPos) {
        this.pointOfSaleService.addPosByIdUser(idPos, this.userId).subscribe();
    }

    filteredPointsExecuted() {
        this.pointOfSleFilteredOptions = this.form.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map((name: any) => name ? this._filter(name.toString()) : this.pointOfSaleList2.slice())
            );
    }

    getRelayPoint(selectedRelayPoint: any) {

        this.selectedRelayPoint = selectedRelayPoint;

        this.selectedRelay = this.pointOfSaleList2.find(list => this.selectedRelayPoint === (list.posWilaya
            || list.posCommune || list.namePos))!;

        //save all pos selected by user
        this.userPointOfSaleList.push(this.selectedRelay);

        this.loadingPOSSubject$.next(this.userPointOfSaleList);

        this.form.get('pointOfSale').reset();

        //add new pos to user point of sales list
        this.addPos(this.selectedRelay.id);

    }

    private _filter(name: string): PointOfSaleModel[] {
        const filterValue = name.toLowerCase();
        return this.pointOfSaleList2.filter(option => (option.posWilaya.toLowerCase().indexOf(filterValue) === 0)
            || (option.posCommune.toLowerCase().indexOf(filterValue) === 0) || (option.namePos.toLowerCase().indexOf(filterValue) === 0));
    }

    deletePOS(deletePos: PointOfSaleModel) {

        this.pointOfSaleService.deletePosByIdUser(deletePos.id, this.userId).subscribe();

        this.userPointOfSaleList = this.userPointOfSaleList.filter(item => item.id !== deletePos.id);

        this.loadingPOSSubject$.next(this.userPointOfSaleList);

    }

    navToPOS(Pos: PointOfSaleModel) {
        this.router.navigateByUrl('ek-ecommerce/point-of-sale');
    }

}
