import {BaseModel} from "../../_base/crud";
import {EkClientAddress} from "./ek-client-address";

export enum ClientType {
    ENTREPRISE = 'ENTREPRISE',
    PARTICULIER = 'PARTICULIER'
}

export enum EkAuthProvider {
    Local = 'local',
    Facebook = 'facebook',
    Google = 'google',
    Github = 'github'
}

type ProfessionalClientCategory = 'EMPLOYEE' | 'RETRIED' | 'MILITARY' | 'MILITARY_RETIRED';
type SocialClientCategory = 'SINGLE' | 'MARRIED' | 'DIVORCE' | 'WIDOWER';
type Providers = 'local' | 'facebook' | 'google' | 'github';
type Gender = 'Male' | 'Female' | 'None';
type ClientStatus = 'BLOCKED' | 'ACTIVE' | 'WAITING';

export class ClientModel extends BaseModel {
    id: number;
    firstname: string;
    lastname: string;
    email: string;
    username: string;
    gender: Gender;
    state: number; // 0 = Active | 1 = Suspended | Pending = 2
    type: ClientType;
    phone: string;
    registerNumber: string;
    articleNumber: string;
    addresses: EkClientAddress[];
    password: string;
    enabled: boolean;
    profileImgUrl: string;
    remark: string;
    ordersCount: number;
    clientStatus: ClientStatus; // 0:BLOCKED | 1:ACTIVE | 2:WAITING
    reasonForBlocking: string;

    /////////////////////// ek-client

    activity: string;
    address: string;
    birthAddressAr: string;
    birthCertificateNumber: number;
    cartId: number;
    cartSplitId: number;
    createdAt: string;
    dairaIssuanceIdCard: string;
    dateOfBirth: string;
    dateOfIssueOfIdCard: string;
    defaultAddress: number;
    endingDateIdCard: string;
    firstNameAr: string;
    idCardType: number;
    idcCardNumber: number;
    lastNameAr: string;
    nationalIdentificationNumber: number;
    productName: string;
    professionalClientCategory: ProfessionalClientCategory;
    provider: Providers;
    socialClientCategory: SocialClientCategory;
    socialSecurityNumber: number;
    taxNumber: string;


    clear() {
        this.firstname = '';
        this.lastname = '';
        this.email = '';
        this.username = '';
        this.gender = null;
        this.type = null;
        this.state = 0;
        this.phone = '';
        this.activity = '';
        this.registerNumber = undefined;
        this.articleNumber = undefined;
        this.taxNumber = undefined;
        this.addresses = [];
        this.password = '';
        this.enabled = false;
        this.provider = EkAuthProvider.Local;
        this.profileImgUrl = '';
        this.clientStatus = 'ACTIVE';
        this.reasonForBlocking = "";
    }
}
