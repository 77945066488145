// RxJS
// NGRX
import {select, Store} from '@ngrx/store';
// CRUD
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';
// State
import {AppState} from '../../../core/reducers';
import {
    selectUsersInStore,
    selectUsersPageLoading,
    selectUsersShowInitWaitingMessage
} from '../_selectors/user.selectors';
import {JwtHelperService} from "@auth0/angular-jwt";


export class UsersDataSource extends BaseDataSource {

    hasPermission: boolean;
    //Permissions
    PERMISSIONS = ['ROLE_SUPERADMIN'];

    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectUsersPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectUsersShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectUsersInStore)
        ).subscribe((response: QueryResultsModel) => {
            this.hasPermission = this.isSuperAdmin();
            this.paginatorTotalSubject.next(response.totalCount);
            // if the user is not superadmin remove the superadmins from the list
            if (!this.hasPermission)
                this.entitySubject.next(response.items.filter(user => user.role !== 'ROLE_SUPERADMIN'));
            else
                this.entitySubject.next(response.items);
        });
    }

    isSuperAdmin(): boolean {
        const helper = new JwtHelperService();
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken != "null" && accessToken != null) {
            const decodedToken = helper.decodeToken(accessToken);
            if (decodedToken.authorities.includes('ROLE_SUPERADMIN'))
                return true
            else
                return false;
        } else
            return false;

    }
}
