import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {CollaboratorService} from "../_services/collaborator.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import * as CollaboratorActions from "../_actions/collaborator.action";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";


@Injectable()
export class CollaboratorEffects {


    constructor(private actions$: Actions, private collaboratorService: CollaboratorService, private store: Store<AppState>) {
    }

    CollaboratorPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CollaboratorActions.CollaboratorsPageRequested),
            switchMap((action) =>
                this.collaboratorService.getAllPaged(action.page, action.size)
                    .pipe(
                        map(collaboratorPage => CollaboratorActions.CollaboratorsPageLoadedSuccessfully(
                            {
                                collaborators: collaboratorPage.content,
                                page: collaboratorPage.totalElements,
                                totalCount: collaboratorPage.totalElements
                            })),
                        catchError(error => of(CollaboratorActions.CollaboratorsPageLoadFailed({error})))
                    ))));

    CollaboratorCreated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CollaboratorActions.CollaboratorCreated),
            switchMap((action) =>
                this.collaboratorService.save(action.collaborator)
                    .pipe(
                        map(data => CollaboratorActions.CollaboratorCreatedSuccessfully({collaborator: data})),
                        catchError(error => of(CollaboratorActions.CollaboratorCreationFailed({error})))
                    ))));

    CollaboratorUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CollaboratorActions.CollaboratorUpdated),
            switchMap((action) =>
                this.collaboratorService.update(action.collaborator)
                    .pipe(
                        map(collaborator => CollaboratorActions.CollaboratorUpdatedSuccessfully({
                            collaborator,
                            partialCollaborator: action.partialCollaborator
                        })),
                        catchError(error => of(CollaboratorActions.CollaboratorUpdateFailed({error})))
                    ))));

    CollaboratorDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CollaboratorActions.CollaboratorDeleted),
            switchMap((action) =>
                this.collaboratorService.delete(action.collaboratorId)
                    .pipe(
                        map(() => CollaboratorActions.CollaboratorDeletedSuccessfully({collaboratorId: action.collaboratorId})),
                        catchError(error => of(CollaboratorActions.CollaboratorDeleteFailed({error})))
                    ))));
}
