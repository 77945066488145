export class Address {

    addressLine1: string;
    addressLine2: string;
    firstname: string;
    lastname:string;
    city: string;
    clientId: number;
    country: string;
    createdAt: string;
    fullName: string;
    id: number;
    phoneNumber: string;
    province: string;
    type: string;
    updatedAt: string;
    zipCode: string;
    _isEditMode: boolean;


    clear() {
        this.addressLine1 = '';
        this.addressLine2 = '';
        this.city = '';
        this.clientId = 0;
        this.country = '';
        this.createdAt = '';
        this.fullName = '';
        this.id = 0;
        this.phoneNumber = '';
        this.province = '';
        this.type = '';
        this.updatedAt = '';
        this.zipCode = '';
        this._isEditMode = false;

    }
}
