import {createAction, props} from "@ngrx/store";
import {QueryParamsModel} from "../../_base/crud";
import {ClientModel} from "../ek-models/client.model";
import {Update} from "@ngrx/entity";

export const clientsPageRequested = createAction('[ek clients List Page] clients Page Requested ', props<{ page: QueryParamsModel , clientState:string , firstDate:string , lastDate:string}>());
export const clientsPageLoadedSuccessfully = createAction('[ek clients API] clients Page Loaded Successfully ', props<{ clients: ClientModel[], totalCount: number, page: number }>());
export const clientsPageLoadFailed = createAction('[ek clients API] clients Page Request Failed ', props<{ error: any }>());

export const CustomerCreated = createAction('[Edit Customer Dialog] Customer On Server Created', props<{ customer: ClientModel }>());
export const CustomerCreatedSuccessfully = createAction('[Customers API] Customer Created successfully', props<{ customer: ClientModel }>());
export const CustomerCreationFailed = createAction('[Customers API] Customer Creation Failed', props<{ error: any }>());

export const CustomerUpdated = createAction('[Edit Customer Dialog] Customer Updated', props<{ customer: ClientModel, partialCustomer: Update<ClientModel> }>());
export const CustomerUpdatedSuccessfully = createAction('[Customers API] Customers Status Updated Successfully', props<{ customer: ClientModel, partialCustomer: Update<ClientModel> }>());
export const CustomerUpdateFailed = createAction('[Customers API] Customer Update Failed', props<{ error: any }>());

export const OneCustomerDeleted = createAction('[Customers List Page] One Customer Deleted', props<{ id: number }>());
export const OneCustomerDeletedSuccessfully = createAction('[Customers API] One Customer Deleted Successfully', props<{ id: number }>());
export const OneCustomerDeleteFailed = createAction('[[Customers API] One Customer Deleted', props<{ error: any }>());

export const clientsOrdersPageRequested = createAction('[order clients List Page] clients Orders Page Requested ', props<{ productId: number, page: QueryParamsModel }>());
export const clientsOrdersPageLoadedSuccessfully = createAction('[order clients API] clients Orders Page Loaded Successfully ', props<{ clients: ClientModel[], totalCount: number, page: number }>());
export const clientsOrdersPageLoadFailed = createAction('[order clients API] clients Orders Page Request Failed ', props<{ error: any }>());
