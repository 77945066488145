import {createAction, props} from "@ngrx/store";
import {OrderSplitAdmin} from "../ek-models/OrderSplitAdmin";
import {Update} from "@ngrx/entity";
import {OrderSplit} from "../ek-models/orderSplit";
import {OrderSplitUpdateAdmin} from "../ek-models/orderSplitUpdateAdmin";

export const OrderSplitCreated = createAction('[OrderSplit List Page] OrderSplit Created ',props<{OrderSplit: OrderSplitAdmin}>());
export const OrderSplitCreatedSuccessfully = createAction('[OrderSplit List Page] OrderSplit Created Successfully ',props<{response: any}>());
export const OrderSplitCreationFailed = createAction('[OrderSplit List Page] OrderSplit Creation Failed ',props<{error: any}>());

export const OrderSplitUpdated = createAction('[OrderSplit List Page] OrderSplitAdmin Updated ',props<{idClient:number , idOrderSplit:number , OrderSplit: any, partialOrderSplit: Update<any>}>());
export const OrderSplitUpdatedSuccessfully = createAction('[OrderSplit List Page] OrderSplitAdmin Updated Successfully ',props<{OrderSplit: any, partialOrderSplit: Update<any>}>());
export const OrderSplitUpdateFailed = createAction('[OrderSplit List Page] OrderSplitAdmin Update Failed ',props<{error: any}>());

export const OrderSplitDeleted = createAction('[OrderSplit List Page] OrderSplit Deleted ',props<{OrderSplitId: number}>());
export const OrderSplitDeletedSuccessfully = createAction('[OrderSplit List Page] OrderSplit deleted Successfully ',props<{OrderSplitId: number}>());
export const OrderSplitDeleteFailed = createAction('[OrderSplit List Page] OrderSplit delete Failed ',props<{error: any}>());

export const OrderSplitById = createAction('[OrderSplit] OrderSplit Created ',props<{OrderSplitId: number}>());
export const OrderSplitByIdLoadedSuccessfully = createAction('[OrderSplit] OrderSplit Loaded Successfully ',props<{OrderSplit: OrderSplit}>());
export const OrderSplitByIdLoadingFailed = createAction('[OrderSplit] OrderSplit Loading Failed ',props<{error: any}>());
